import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router';
import { Price } from "../../../components/__simple/_price";
export const ServiceOrderButton = ({ title = '', onClick }) => {
    return (
        <div
            className="mainteance-service-btn nice-flex nice-flex-center"
            style={{ backgroundImage: `url('/assets/images/maintenance.png')` }}
            onClick={() => {
                if (onClick) {
                    onClick(true);
                }
            }}
        >
            <div className="text-24 text-white text-bold">
                {title}
            </div>
        </div>
    );
};
export const MaintenanceServiceBox = ({ serviceID = '', title = '', to = '', minPrice = 0, maxPrice = 0, image, onServiceOrder }) => {
    const history = useHistory();
    const { t: trans } = useTranslation("info");
    return (
        <a
            className="nice-pointer"
            onClick={
                (event) => {

                    if (to == "#") {

                        event.stopPropagation();
                        if (onServiceOrder) {
                            onServiceOrder(serviceID);
                        }
                    } else {
                        history.push(to);

                    }

                }
            }
        >
            <div className="mainteance-box" style={{ backgroundImage: image }}>
                {/* <div className="mainteance-box-phone">
                    <h1 className="text-16 text-white text-bold">{title}</h1>
                </div> */}
                {/* <div className="mainteance-box-actions">
                    <div onClick={(event) => {
                        event.stopPropagation();
                        if (onServiceOrder) {
                            onServiceOrder(serviceID);
                        }
                    }} className="nice-bg-btn-border bg-primary text-white text-14">
                        {trans("info:order-service")}
                    </div>
                </div> */}
                <div
                    onClick={(event) => {
                        event.stopPropagation();
                        if (onServiceOrder) {
                            onServiceOrder(serviceID);
                        }
                    }}

                    className="mainteance-box-details nice-flex nice-flex-column p-4">
                    <h1 className="text-16 text-black text-bold">{title}</h1>
                    <div className="nice-pointer mainteance-box-details-links nice-flex-v-center mt-2">
                        <a onClick={(event) => {
                            event.stopPropagation();
                            if (onServiceOrder) {
                                onServiceOrder(serviceID);
                            }
                        }} className="text-20 text-primary">{trans("info:order-service")}</a>
                    </div>
                </div>
            </div>
        </a>

    );
};