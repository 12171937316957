import { useRef, useState } from "react";
import CountUp from 'react-countup';
export default function Counter({ counterData, title, subTitel, desc, BackOpicity, backImage }) {


    return (

        <div className=" mb-32 what-offer" id='aboutUsElmenet'>

            <div className="what-offer-second-theme-overlay" style={BackOpicity ? { background: "linear-gradient( #090d0b 0%, rgba(26, 28, 27, 0.76) " + BackOpicity + "%, rgba(42, 42, 42, 0.13) 100%)" } : { background: 'none' }}>

            </div>
            <div className="what-offer-second-theme-bg"><img src={"data:image/png;base64," + backImage} alt="" onError={e => e.target.style.display = 'none'} /></div>
            <div className="what-offer-second-theme-text">
                <h3 className={"text-30 font-bold mb-8"}>{title}</h3>
                <p className={"text-20"}>
                    {desc}
                </p>
            </div>
            <div className="what-offer-second-theme-boxes">
                {
                    counterData?.map((item, index) => {

                        return (

                            <div key={index} className="what-offer-second-theme-box">
                                {

                                    <img src={"data:image/png;base64," + item?.icon} alt="" onError={e => e.target.style.display = 'none'} />
                                    // <img src={item?.icon} alt="" onError={e => e.target.style.display = 'none'} />
                                }

                                <h3 data-animation-name="counter" data-animation-event="scroll" data-animation-duration="3000">


                                    <CountUp
                                        className="account-balance"
                                        start={0}
                                        end={item?.number}
                                        duration={100}
                                        // useEasing={true}
                                        // useGrouping={true}
                                        // enableScrollSpy={true}
                                        scrollSpyOnce={true}
                                    // separator=" "
                                    // decimals={4}
                                    // decimal=","
                                    // prefix="EUR "
                                    // suffix=" left"
                                    // onComplete={onComplete}
                                    // onStart={onStart}
                                    />

                                    <span>{item?.subTitel}</span>
                                </h3>
                                <small>{item?.titelAr}</small>
                            </div>



                        );


                    })

                }



            </div>

        </div>
    );
}