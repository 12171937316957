import { Fragment, useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router";
import axios from "axios";
import { ContentLoading } from '../../components/contentLoading';
import config from '../../configuration/config.json';
import Popup from "../../User/popup";
export default function RedirectPage() {
    const { t: trans } = useTranslation(["new","cartFile"]);
    let { page } = useParams();
    const history = useHistory();
    const [showFinalPopup, setShowFinalPopup] = useState(false);
    const [OriginalUrl, setOriginalUrl] = useState(null);
    useEffect(() => {
        callback();
    }, []);

    const callback = useCallback(async () => {
        const Body = {
            "name": "GetOriginalUrl",
            "values": {
                "ShortenedUrl": page
            }
        }

        axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {
                console.log("GetOriginalUrl:", response.data[0]);
                setOriginalUrl(response.data[0]);
            })
            .catch(function (error) {

                setOriginalUrl({});
            });

    }, [page]);

    useEffect(() => {

        try {
            // Redirect to another page after some condition or event
            console.log("OriginalUrl:", OriginalUrl);
            console.log("OriginalUrl:", OriginalUrl?.OriginalUrl);
            if (OriginalUrl) {

                if (OriginalUrl?.Expired == true || OriginalUrl?.Expired == 1) {
                    setShowFinalPopup(true);
                } else {
                    if (OriginalUrl?.OriginalUrl)
                        window.location.href = 'https://' + OriginalUrl?.OriginalUrl;
                    else
                        window.location.href = '/404';
                }


            }
        } catch (error) {
            window.location.href = '/404';
        }


    }, [OriginalUrl]);


    return (
        <>
            {
                showFinalPopup == false &&
                <ContentLoading />
            }



            <Popup show={showFinalPopup}>
                <div className="w-full  flex flex-column justify-center items-center" >
                    <div className="cart-finalized" >
                        <div className="cart-finalized-container flex flex-col">

                            <div className="cart-finalized-container-img flex flex-col">
                                <img src="/assets/images/finalized.svg" />
                            </div>
                            {
                                <>
                                    <div className="flex flex-wrap justify-center mx-20">
                                        <span className="text-20 font-bold  mx-2"> {trans("new:link_has_expired")}</span>
                                    </div>

                                </>

                            }
                            <div className="flex flex-center flex-col  mt-5 pt-5 w-80">
                                <div className=" mb-5 w-100">
                                    <div className="nice-sm-btn cart-notification-go  bg-primary text-white text-16" onClick={() => {

                                        setShowFinalPopup(false);
                                        history.push("/");

                                    }}>
                                        {trans("cartFile:Continue_browsing")}
                                    </div>
                                </div>
                            </div>


                        </div>

                    </div>

                </div>
            </Popup>
        </>
    );
}