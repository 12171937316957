import React from 'react';

const GeneralQuestion = ({isMoreProp = false, questionProp, answerProp , onClickProp}) => {
    return (
        <>
            <div className="border-b-2 pb-8 px-8 my-5 w-full">
                <div className="flex justify-between cursor-pointer" onClick={onClickProp}>
                    <span className="text-3xl font-bold">{questionProp}</span>
                    <div>
                        {
                            (!isMoreProp && answerProp)
                                ? <img className="" src="/assets/icons/arrow-primary.svg" alt="image arrow"/>
                                : <img className="rotate-180" src="/assets/icons/arrow-primary.svg"
                                       alt="image arrow rotate-180"/>
                        }
                    </div>
                </div>
                {
                    (isMoreProp && answerProp != null)
                        ? <div className="mt-8 mx-8">
                        <span className="text-2xl">
                            {answerProp}
                        </span>
                        </div>
                        : <></>
                }
            </div>
        </>
    );
};

export default GeneralQuestion;
