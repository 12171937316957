/**
 * this function is used to reset the drop down and choose which array
// you really want to return 
 * @param {func} callback 
 * @param {Array} values 
 * @param {Boolean} forceReturn 
 */
export default function dropDownResetter(callback,values,forceReturn = false){
    if(callback){
        callback();
    }
    if(forceReturn){
        return values
    }
    };