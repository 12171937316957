import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { DropDownInput } from "../../../components/_input/_dropdown";
import { DropDownInput2 } from "../../../components/_input/_dropdown2";
import { useEffect, useState } from "react";
import axios from "axios";
import { InputField } from "../../../components/_input/_input";
import config from '../../../configuration/config.json';
import dropDownResetter from "../../../utils/resetDropDown";
import { concat, reverse } from "lodash";
import { string } from "joi";
export function PartSearch({ search,Titel }) {
    //public verb
    const { t: trans } = useTranslation(["common", "eparts", 'inputs', "info", "new"]);
    const [vehicle, setVehicle] = useState("");
    const [lang, setlang] = useState(localStorage.getItem('i18nextLng'));
    const languageDir = 'language-' + lang;
    const [Brands, setBrands] = useState([]);
    const [ProductGroups, setProductGroups] = useState([]);
    const [ProductModel, setProductModel] = useState([]);
    const [ProductModelTypes, setProductModelTypes] = useState([]);

    const [engineGroups, setEngineGroups] = useState([]);
    const [engineGroupsSup, setEngineGroupsSup] = useState([]);
    const [engineGroupsSup2, setEngineGroupsSup2] = useState([]);

    //values for filter dropdown


    const [FProductGroups, setFProductGroups] = useState([]);

    const [FProductModelTypes, setFProductModelTypes] = useState([]);

    //values for search of Vichals
    const [brand, setBrand] = useState(null);
    const [group, setGroup] = useState(null);
    const [year, setYear] = useState(null);
    const [Stype, setStype] = useState(null);

    const [CatMain, setCatMain] = useState(null);
    const [SuPCat, setSupCat] = useState(null);
    const [SSuPCat, setSSupCat] = useState(null);

    const [Product, setPro] = useState("0");

    const GetUrl = config.baseURL + 'details';


    async function SendRequset() {

        const BodyBrands = {
            "object": "InvBrands",
            "ObjectSettings": {
                "MetaData": false
            }
            , "option": "column",
            "Fields": "BrandID,DescriptionAr,DescriptionEn",
            "filters": " where IsEnabled =1"

        };
        const requestBrands = axios.request({
            url: GetUrl,
            data: BodyBrands, method: "POST", responseType: "json"
        });

        const BodyProductGroups = {
            "object": "InvProductGroups",
            "ObjectSettings": {
                "MetaData": false,
            },
            "Option": "Column",
            "Fields": "DescriptionAr,DescriptionEn,ProductGroupID,BrandID"
        };
        const requestProductGroups = axios.request({
            url: GetUrl,
            data: BodyProductGroups, method: "POST", responseType: "json"
        });

        const BodyProductModel = {
            "object": "InvProductModel",
            "ObjectSettings": {
                "MetaData": false
            }
        };
        const requestProductModel = axios.request({
            url: GetUrl,
            data: BodyProductModel, method: "POST", responseType: "json"
        });

        const BodyProductModelTypes = {
            "object": "InvProductModelTypes",
            "ObjectSettings": {
                "MetaData": false
            }
        };
        const requestProductModelTypes = axios.request({
            url: GetUrl,
            data: BodyProductModelTypes, method: "POST", responseType: "json"
        });

        const BodyEngineGroups = {
            "object": "Site_categorySP",
            "ObjectSettings": {
                "MetaData": false
            }
        };
        const requestEngineGroups = axios.request({
            url: GetUrl,
            data: BodyEngineGroups, method: "POST", responseType: "json"
        });

        const BodyEngineGroupsSUP = {
            "object": "Site_SupCategory",
            "ObjectSettings": {
                "MetaData": false
            }
        };
        const requestEngineGroupsSUP = axios.request({
            url: GetUrl,
            data: BodyEngineGroupsSUP, method: "POST", responseType: "json"
        });

        const BodyEngineGroupsSUP2 = {
            "object": "Site_ItemsSupCategory",
            "ObjectSettings": {
                "MetaData": false
            }
        };
        const requestEngineGroupsSUP2 = axios.request({
            url: GetUrl,
            data: BodyEngineGroupsSUP2, method: "POST", responseType: "json"
        });


        await axios.all([requestBrands, requestProductGroups, requestProductModel, requestProductModelTypes, requestEngineGroups, requestEngineGroupsSUP, requestEngineGroupsSUP2]).then(axios.spread(function (Brands, ProductGroups, ProductModel, ProductModelTypes, EngineGroups, EngineGroupsSup, EngineGroupsSup2) {

            setTimeout(setBrands(Brands.data.ApiObjectData), 1000);
            setTimeout(setProductGroups(ProductGroups.data.ApiObjectData), 1000);
            setTimeout(setProductModel(reverse(ProductModel.data.ApiObjectData)), 1000);
            setTimeout(setProductModelTypes(ProductModelTypes.data.ApiObjectData), 1000);
            setTimeout(setEngineGroups(EngineGroups.data.ApiObjectData), 1000);
            setTimeout(setEngineGroupsSup(EngineGroupsSup.data.ApiObjectData), 1000);
            setTimeout(setEngineGroupsSup2(EngineGroupsSup2.data.ApiObjectData), 1000);

        })).catch(function (error) {
            console.log(error);
        });

    }
    function filterGroup(option) {
        setBrand(null);
        setGroup(null);
        setYear(null);
        setStype(null);
        setFProductGroups([]);
        setFProductModelTypes([]);
        setBrand(option);
        if (option == '0' || option == '') {
            dropDownResetter(() => {
                setFProductGroups([]);
                setFProductModelTypes([]);
            });
            return;
        }

        setFProductGroups(ProductGroups.filter(a => a.BrandID === option));
        console.log(FProductGroups);


    }
    function filterModelType(option) {
        setGroup(null);
        setYear(null);
        setStype(null);
        setGroup(option);
        setFProductModelTypes([]);
        if (option == '0' || option == '') {
            dropDownResetter(() => {
                setFProductModelTypes([]);
            });
            return;
        }
        setFProductModelTypes(ProductModelTypes.filter(a => a.ProductGroupID === option));
    }
    function filterYear(option) {
        setYear(option);
    }
    function filterModel(option) {
        setYear(null);
        setStype(option);
        if (option == '0' || option == '') {
            dropDownResetter(() => {
                setYear(null);
            });
            return;
        }

    }





    //for the search 
    //query select Cat
    let Engine_Groups = CatMain;
    let Engine_GroupsSup = SuPCat;
    let Engine_GroupsSup2 = SSuPCat;

    //query select Vehicle
    let BrandID = brand;
    let ModelID = year;
    let ProductGroupID = group;


    //query select ProductID
    let ProductID = Product;

    useEffect(() => {
        // requestBrands(); requestProductGroups();requestProductModel();requestProductModelTypes();

        SendRequset();
        console.log(engineGroups);
    }, []);

    function refreshPage(type) {
        //   setTimeout(()=>{
        //      window.location.reload(false);
        //  }, 500);
        //  console.log('page to reload')
        if (search) {
            if (type === "Cat") {
                search.FunCat(Engine_Groups, Engine_GroupsSup, Engine_GroupsSup2);
            }
            if (type === "Vehicle") {

                search.FunVech(parseInt(BrandID), Stype, ProductGroupID, ModelID);
            }
            if (type === "ProductID") {
                search.FunPro(Product);
            }


            // console.log("fun search",search)
        }
    }



    return (
        <div className={"container mb-32 flex flex-col  text-center" + languageDir}>


            <h2 className="text-50  flex nice-flex-v-center text-center my-20" >{Titel ? Titel : ""}</h2>

            <div className={"bg-secondary-light p-10 "}>



                <div className={"flex items-center flex-wrap nice-flex-v-center" + vehicle}>

                    {/* <Link onClick={() => refreshPage("Vehicle")} to={"/eparts/sereach?" + "brand=" + brand + "&group=" + group + "&type=" + Stype + "&year=" + year} className={"btn btn-primary text-16 py-3 px-10 mr-4"}>{trans("eparts:search")}</Link> */}



                    <div className="filter-search-grid filter-search-4 w-80">



                        <DropDownInput
                            className={"flex-grow-1"}
                            options={[
                                ...Brands.map(B => {
                                    if (lang === "ar") {
                                        return { value: B.BrandID, text: B.DescriptionAr };
                                    } else {
                                        return { value: B.BrandID, text: B.DescriptionEn };
                                    }

                                })]}


                            fieldTextClass={"text-gray text-bold "}
                            classDropdownField={" nice-shadow-0  dropdown-field-border-bottom "}
                            // errorLabel={error?.brand}
                            defaultOption={(brand == '0' || brand == null) ? { text: trans("info:company"), value: '0' } : null}

                            selectedValue={brand}
                            onSelect={filterGroup}
                        />
                        <DropDownInput

                            className={"flex-grow-1"}

                            options={[
                                ...FProductGroups.map(B => {

                                    if (lang === "ar") {
                                        return { value: B.ProductGroupID, text: B.DescriptionAr };
                                    } else {
                                        return { value: B.ProductGroupID, text: B.DescriptionEn };
                                    }

                                })]}


                            fieldTextClass={"text-gray text-bold "}
                            classDropdownField={" nice-shadow-0  dropdown-field-border-bottom "}
                            // errorLabel={error?.group}
                            defaultOption={((brand == '0' || brand == null) || (group == '0' || group == null)) ? { text: trans("eparts:vehicle"), value: '0' } : null}

                            selectedValue={group}
                            onSelect={filterModelType}


                        />
                        <DropDownInput
                            className={"flex-grow-1"}
                            options={[
                                ...FProductModelTypes.map(B => {

                                    if (lang === "ar") {
                                        return { value: B.ProductTypeID, text: B.DescriptionAr };
                                    } else {
                                        return { value: B.ProductTypeID, text: B.DescriptionEn };
                                    }

                                })]}


                            fieldTextClass={"text-gray text-bold "}
                            classDropdownField={" nice-shadow-0  dropdown-field-border-bottom "}
                            // errorLabel={error?.group}
                            defaultOption={((brand == '0' || brand == null) || (group == '0' || group == null) || (Stype == '0' || Stype == null)) ? { text: trans("eparts:model"), value: '0' } : null}

                            selectedValue={Stype}
                            onSelect={filterModel}
                        />

                        <DropDownInput
                            className={"flex-grow-1"}

                            options={[,
                                ...ProductModel.map(B => {
                                    return { value: B.ModelID, text: B.ModelID };
                                })]}

                            fieldTextClass={"text-gray text-bold "}
                            classDropdownField={" nice-shadow-0  dropdown-field-border-bottom "}
                            // errorLabel={error?.group}
                            defaultOption={((brand == '0' || brand == null) || (group == '0' || group == null) || (Stype == '0' || Stype == null)) ? { text: trans("info:created_year"), value: '0' } : null}

                            selectedValue={year}
                            onSelect={filterYear}

                        />


                    </div>

                    <div className={"LandengButton mx-10 "}>
                        <button
                            onClick={() => {

                            }}
                            className={" text-24  py-5"}>{trans("eparts:search")}</button>
                    </div>


                </div>


            </div>


        </div>);
}
