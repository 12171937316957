import {Fragment , useEffect,useState} from "react";
import SwiperCore, {Pagination, Navigation, Autoplay, EffectFade} from "swiper";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router";
import Banner from "../../components/banner";
import {DropDownInput} from "../../components/_input/_dropdown";
import Breadcrumb from "../../components/__simple/breadcrumb";
import {Link} from "react-router-dom";
import axios from "axios";
import config from '../../configuration/config.json';
import BrandImage from "../../components/brandImage";

SwiperCore.use([Pagination, Navigation, Autoplay, EffectFade]);

export function FinanceDetailPage() {
    const {t: trans} = useTranslation(["heading","finance","common","inputs"]);
    const location = useLocation();
    const query = new  URLSearchParams(location.search);
    const [financeDetails,setFinanceDetails] = useState({});
    const [branches,setBranches] = useState([]);
    useEffect(()=>{
    try{
    initialBranches();
    const financeId = query.toString().split('_')[1];
    axios.post(
        config.baseURL + "details",
        {
            "Object":"siteCarsFinanceView",
            "ObjectSettings":{
                "MetaData":false
            },
            "Filters":`where BankTermID = '${financeId}'`
        }
    ).then(response=>{
     const fullResult = response.data.ApiObjectData;
     if(fullResult.length > 0){
      const result = fullResult[0];
      const showData = {
          id:result.BankTermID,bankId:result.BankID,firstPayment:result.FirstPayment,monthsCount:result.MonthsNumber,model:result.ModelRange,
          monthsQuantity:result.MonthlyQuantity,groupId:result.GroupID,groupDescription:result[`${trans("finance:groupDescription")}`],
          brandDescription:result[`${trans("finance:brandDescription")}`],image:result.GroupImage,offerId:result.OfferID,rate:result.ManagmentFees
      };
      setFinanceDetails(showData);
     }
    });
    }catch(error){
    console.log(error);
    }
    },[]);


    const initialBranches = ()=>{
     axios.post(
         config.baseURL + "details",
         {
             "Object":"InvSites",
             "ObjectSettings":{
                 "MetaData":false
             },
             "Option":"Column",
             "Fields":"SiteID,DescriptionAr,DescriptionEn"
         }
     ).then(response=>{
      const result = response.data.ApiObjectData;
      const customizedResult = result.map(e=>{
          return {
              value:e.SiteID , text:e[`${trans("model:description")}`]
          }
      });
     setBranches(customizedResult);
     }).catch(error=>{ 
     console.log(error);
     });
    };
    
    const getFullPrice = ()=>{
     let total = (financeDetails?.firstPayment + (financeDetails?.monthsQuantity * financeDetails?.monthsCount));
     if(financeDetails.rate){
        const totalAfterRate = total * (financeDetails?.rate / 100);
        total+=totalAfterRate;
     }
     return total;
    };

    return (
        <Fragment>

            <Banner
                subtitle={"التمويل"}
                title={"أحسب أقساطك الشهرية واحصل على سيارتك"}
                desc={"هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق"}
                background={"/assets/images/pages/emp.png"}
            />

            <div className="mt-16">
                <Breadcrumb className={"container mt-16 "}
                            links={[
                                {
                                    name: "التمويل",
                                    to: "/finance"
                                },
                                {
                                    name: "اسم السيارة"
                                }
                            ]}
                />

            </div>


            <div className=" mb-32 bg-white box-shadow py-20">
                <div className="container">
                    <div className="finance-detail">
                        <div className="finance-detail-car">
                            <div className="img ">
                                <BrandImage brand = {financeDetails?.brandDescription}/>
                                <img className={"car-img"} src={financeDetails?.image ? ("data:image/png;base64," + financeDetails.image) : '/assets/images/car-finance.png'}/>
                                <div className="flex-list items-end mt-4">
                                    <h2 className={"text-24 font-bold"}>{financeDetails?.groupDescription}</h2>
                                </div>
                            </div>
                            <div className="car-info w-full space-y-6">
                                <div className="w-1/3 space-y-6">
                                    <div className="flex-list">
                                        <h4 className={"text-gray-dark"}>الدفعة الأولى</h4>
                                        <p className={"text-primary-dark font-bold"}>{financeDetails?.firstPayment +' ' + trans("common:rial")}</p>
                                    </div>
                                    <div className="flex-list">
                                        <h4 className={"text-gray-dark"}>القسط الشهري</h4>
                                        <p className={"text-primary-dark font-bold"}>{financeDetails?.monthsQuantity + ' ' + trans("common:rial")}</p>
                                    </div>
                                    <div className="flex-list">
                                        <h4 className={"text-gray-dark"}>مدة التمويل</h4>
                                        <p className={"text-gray-dark"}> {financeDetails?.monthsCount + ' ' + trans("finance:month")}</p>
                                    </div>
                                </div>


                                <DropDownInput
                                    className={"flex-grow-1"}
                                    title={"الفرع"}
                                    isSecondary={true}
                                    options = {[{value:'0' , text : trans("inputs:select")},...branches]}
                                />
                            </div>
                        </div>
                        <div>
                            <h2 className="text-16 font-bold mt-10 mb-8">حساب الاقساط</h2>
                            <div className="finance-detail-money ">
                                <table className={"finance-table"}>
                                    <tbody>
                                    <tr className={"finance-table-row"}>
                                        <td>السعر<span className={"text-12 text-gray"}> (غير شامل الضريبة)</span></td>
                                        <td>
                                            {
                                                getFullPrice()
                                            } <span className={"text-12 text-gray"}>{trans("common:rial")}</span>
                                        </td>
                                    </tr>
                                    <tr className={"finance-table-row"}>
                                        <td>الدفعة الأولى</td>
                                        <td>{financeDetails?.firstPayment +' '} <span className={"text-12 text-gray"}>{trans("common:rial")}</span></td>
                                    </tr>
                                    <tr className={"finance-table-row"}>
                                        <td>القسط الشهري</td>
                                        <td> {financeDetails?.monthsQuantity + ' ' } <span className={"text-12 text-gray"}>{trans("common:rial")}</span></td>
                                    </tr>
                                    <tr className={"finance-table-row"}>
                                        <td>رسوم إدارية</td>
                                        <td>{financeDetails.rate ? (financeDetails.rate + '%') :'لايوجد'}</td>
                                    </tr>
                                    <tr className={"finance-table-row"}>
                                        <td>عدد الأشهر</td>
                                        <td>{financeDetails?.monthsCount + ' ' + trans("finance:month")}</td>
                                    </tr>
                                    </tbody>
                                </table>
                                <Link className={"btn btn-primary text-16 m-0"}>تقدم طلب تمويل</Link>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </Fragment>
    );
}