import {Fragment, useState, useEffect} from "react";
import Banner from "../../components/banner";
import {Part} from "./components/part";
import PageHeading from "../../components/__simple/_pageheading";
import {SwiperSlide} from "swiper/react";
import SwiperCore, {Autoplay, EffectFade, Navigation, Pagination} from "swiper";
import PartSearch from "./components/partSearch";
import config from '../../configuration/config.json';
import Breadcrumb from "../../components/__simple/breadcrumb";
import axios from "axios";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router";
SwiperCore.use([Pagination, Navigation, Autoplay, EffectFade]);

export function EpartsGroupPage() {
    const GetUrl = config.baseURL + 'details';
    const [PWD, setPWD] = useState([]);
    let {GroupId}=useParams();
    const [lang,setlang]=useState( localStorage.getItem('i18nextLng'));
    function RequsetProductsWithDetails(ID) {
        const  Body=  {
            "name":"site_FeatchPartsCategory",
            "values":{
                "Category":ID
            }}
         axios.request({
            url: config.baseURL + 'func',
            data: Body, method:"POST",responseType:"json"
        }).then(function (response) {
                // console.log(response.data.ApiObjectData);
                setTimeout(setPWD(response.data), 100);

            })
            .catch(function (error) {
                console.log(error);
            });


    }
    const {t: trans} = useTranslation("eparts");
    const [EngineGroups,setEngineGroups]=useState([]);
    function  RequsetsetEngineGroups(){
        const BodyEngineGroups={
            "object":"Site_categorySP",
            "ObjectSettings":{
                "MetaData":false
            },
            "Filters":"where GUID='"+GroupId+"'"
        };
        axios.request({
            url: GetUrl,
            data: BodyEngineGroups, method:"POST",responseType:"json"
        })
            .then(function (response) {
                // console.log(response.data.ApiObjectData);
                setEngineGroups(response.data.ApiObjectData);
                RequsetProductsWithDetails(response.data.ApiObjectData[0].GroupIDMain)
            })
            .catch(function (error) {
                console.log(error);
            });


    }
    useEffect(async () => {
       RequsetsetEngineGroups();
    }, []);



    return (
        <Fragment>
            {EngineGroups?.map(H=>{
                if(lang=="en"){
                    return(<Banner
                        background={"data:image/png;base64,"+H.image}
                        title={H.DescriptionEn}
                        // subtitle={H.DescriptionEn}
                        // desc={H.DescriptionEn}
                        backOpasity={H?.backOpasity}
                    />);
                }else {
                    return(<Banner
                        background={"data:image/png;base64,"+H.image}
                        title={H.DescriptionAr}
                        // subtitle={H.DescriptionAr}
                        // desc={H.DescriptionAr}
                        backOpasity={H?.backOpasity}
                    />);
                }
            })}
            
            <div className="container">
                <Breadcrumb className={"mt-16 "}
                            links={[
                                {
                                    name: trans("eparts:EpartTitel"),
                                    to: "/eparts"
                                },
                                {
                                    name: trans("eparts:DenamoEpart")
                                }
                            ]}
                />
            </div>

            <PartSearch/>


            <div className="container mb-36">

{/* 
                <PageHeading
                    title={trans("eparts:DenamoEpart")}
                /> */}

                <div className="grid-4-cols mb-16 news-gap">
                    {PWD?.map(P => (

                        <SwiperSlide className={"px-3"} key={P.ProductID}>
                            <Part   GUID={P.GUID} 
                                    ProductImage={P.ProductImage} 
                                    DescriptionEn={P.DescriptionEn}
                                    DescriptionAr={P.DescriptionAr}
                                    NetSalePrice={P.SalesPriceDiscount > 0 ? P.SalesPriceDiscount:P.SalesPrice}
                                    SalesPriceBefor={P.SalesPriceDiscount > 0 ?P.SalesPrice:0}
                                    ProductID={P.ProductID}/>
                        </SwiperSlide>
                    ))}
                </div>
            </div>


        </Fragment>
    );
}