import {Fragment, useEffect, useState} from "react";
import News from "./components/news";
import NewsBanner from "./components/newsBanner";
import {SwiperSlide, Swiper} from 'swiper/react';
import Loader from "react-loader-spinner";
import {useHistory, useParams} from "react-router";
import Pagination from "react-js-pagination";
import SwiperCore, {Pagination as SlidePagination, Navigation, Autoplay, EffectFade} from "swiper";
import PageHeading from "../../components/__simple/_pageheading";
import {useTranslation} from "react-i18next";
import Breadcrumb from "../../components/__simple/breadcrumb";
import {FilterButton} from "../../components/__simple/_filterButton";
import axios from "axios";
import {slugCombine} from '../../utils/slugManager';
import config from "../../configuration/config.json";
SwiperCore.use([SlidePagination, Navigation, Autoplay, EffectFade]);

export function NewsPage() {
    const {page = 1} = useParams();

    let lang = localStorage.getItem("i18nextLng");
    const languageDir = "language-" + lang;
    
    const [activePage, setActivePage] = useState(0);
    let history = useHistory();

    const {t: trans} = useTranslation(["inputs", "heading","info"]);
    // const news = useSelector(state => state.entities.news.items);

    const [news, setNews] = useState([]);
    // news shown in the slider
    const [sliderNews, setSliderNews] = useState([]);

    // the news filters
    const [newsFilter, setNewsFilter] = useState([]);

    const [filter, setFilter] = useState(null);

    const [filteredNews, setFilteredNews] = useState(news);

    const [dataStatus, setDataStatus] = useState(0);
    const [itemCount, setItemCount] = useState(0);

    useEffect(async () => {
        setDataStatus(1);
        try {
            const response = await axios.request({
                 baseURL: config.controllURL,
                url: lang + "/api/news/news/" + page,
                method: "get",
            });

            setItemCount(response.data.totalItemsCount);
            setNews(response.data.news);
            setSliderNews(response.data.sliderNews);
            setNewsFilter(response.data.filters);

        } catch (error) {
         console.log(error);
        }
        setActivePage(parseInt(page));
    }, [page]);

    useEffect(() => {
        setFilteredNews(news);
    }, [news])

    function filterNews(brand) {
        setFilter(brand);
        let filterData;
        if (brand != null)
            filterData = news.filter((data) => {
                return data.brand === brand;
            });
        else {
            filterData = news;
        }
        setFilteredNews(filterData);
    }

    if (itemCount === 0)
        return (
            <div className={"flex justify-center items-center p-32 h-screen"}>
                {/*<Loader*/}
                {/*    type="Puff"*/}
                {/*    color="#3BD461"*/}
                {/*    height={100}*/}
                {/*    width={100}*/}
                {/*/>*/}
            </div>
        );
    else if (dataStatus === 1)
        return (

            <Fragment>
                <Swiper
                    className={"slide-container slide-news"}
                    spaceBetween={0}
                    slidesPerView={1}
                    pagination
                    effect={"fade"}
                    resizeObserver={"true"}
                    grabCursor={true}
                    watchSlidesVisibility
                    watchSlidesProgress
                    autoplay={true}
                    loop={false}
                    observer={true}

                >
                    {
                        sliderNews.map((data) => {
                            return (
                                <SwiperSlide>
                                    <NewsBanner
                                        background={config.mainURL +data.imagePath}
                                        title={data.title}
                                        desc={data.excerpt}
                                        link={"/news/detail/" + slugCombine(data.slug,data.otherLink)}
                                    />
                                </SwiperSlide>
                            );
                        })
                    }
                </Swiper>
                <div className="container mb-32">
                    <Breadcrumb className={"mt-10"}
                                links={[
                                    {
                                        name: trans("info:news")
                                    }
                                ]}
                    />
                    <PageHeading
                        subtitle={trans("info:news")}
                        title={trans("heading:news-full")}
                    >
                        <div className={"filter-btns " + languageDir}>
                            {
                                newsFilter?.map((_filter) => {
                                    return (
                                        <FilterButton name={_filter.content}
                                                      isActive={filter === _filter.content}
                                                      onclick={() => filterNews(_filter.content)}
                                        />
                                    );
                                })
                            }

                            <FilterButton name={trans("inputs:all")} isActive={filter === null}
                                          onclick={() => filterNews(null)}/>
                        </div>
                    </PageHeading>

                    <div className={"grid-4-cols mb-16 news-gap " + languageDir}>

                        {
                            filteredNews.map((data) => {
                                return (
                                    <News
                                        title={data.title}
                                        desc={data.excerpt}
                                        date={data.date}
                                        image={config.mainURL +data.imagePath}
                                        link={"/news/detail/" + slugCombine(data.slug,data.otherLink)}
                                        brand={data.brand}
                                    />
                                );
                            })
                        }
                    </div>
                   {itemCount > 4 && <Pagination
                        activePage={activePage}
                        itemsCountPerPage={4}
                        totalItemsCount={itemCount}
                        pageRangeDisplayed={4}
                        onChange={(number) => {
                         history.push('/news/' + number);
                        }}
                    />
}
                </div>
            </Fragment>
        );
    else
        return ("error happened");
}