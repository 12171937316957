import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import SectionHeading from "../../../components/sectionHeading";
// to fetch all the branches info
const BranchesInfo = ({headingTitle = null , values = []})=>{
const { t: trans } = useTranslation("new");
return (
    <SectionHeading
    title={headingTitle ?? trans("new:avilable_branches")}
    hasRuler={false}
    >
        {
            values?.length > 0  &&
             <div className="cart-branches mb-2">
              {
                  values.map((item,index)=>{
                   return(
                       <BranchInfoCard
                       title={item.title}
                       details={item.details}
                       lat={item.lat}
                       long = {item.long}
                       key={index}
                       />
                   )
                  })
              }
            </div>
        }  
    </SectionHeading>
)
};

export default BranchesInfo;
// to fetch branch info card
export const BranchInfoCard = ({ title = '', details = '', lat = null, long = null}) => {
    const { t: trans } = useTranslation("new");
    const googleMapPoints = useMemo(() => {
        let url = null;
        if (lat !== null && long !== null) {
            url = `https://www.google.com/maps/?q=${lat},${long}`;
        }
        return url;
    }, [lat, long]);
    return (
        <div className="cart-branches-cart p-4">
            <div className="nice-flex nice-flex-column">
                <h2 className="text-black font-bold text-16 nice-space-normal">{title}</h2>
                <div className="nice-line my-2"></div>
                <p className="text-14 text-gray nice-space-normal mb-2">{details}</p>
                { 
                  googleMapPoints && <a target='_blank' href={googleMapPoints} className="text-14"><div className="nice-flex nice-flex-row nice-align-item-center">
                    <img src='/assets/icons/location.svg' className="filter-primary icon-2" />
                    <span className="text-14 text-primary">{trans("new:show_location")}</span></div></a>
                }
            </div>
        </div>
    )
};


