import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

export function Job({title, location, job, desc, link, titleLink}) {
    const {t: trans, i18n} = useTranslation("common");
    const languageDir = 'language-' + i18n.language;
    return (
        <Link to={link}>

            <div className={"job-card border-radius box-shadow " + languageDir}>
                <div className="job-card-text">

                    <div className="grid grid-cols-9 gap-1 mb-16  h-24">

                        <div className="col-span-4 job-card-text-upper">
                            <h3 className={" text-20 text-line-max-width font-bold text-line-clamp text-line-clamp-1"}
                                title={title}>{title}</h3>
                        </div>
                        
                        <div className={"col-span-2 bg-gray text-center   border-radius-sm  text-14 font-bold text-white flex justify-center items-center"} title={location}>{location}</div>
                        
                        <div className={"col-span-3 bg-primary text-center border-radius-sm  text-14 font-bold text-white flex justify-center items-center "} 
                             title={job}>{job}</div>
                    </div>
                    {/*<div className={"job-card-text-upper mb-16"}>*/}
                    {/*<h3 className={"text-20 text-line-max-width font-bold text-line-clamp text-line-clamp-1"} title={title}>{title}</h3>*/}
                    {/*    */}
                    {/*    <div className="nice-flex nice-flex-row nice-gap-1rem bg-yellow-600">*/}
                    {/*        <div className={"job-tag ml-2"} title={location}>{location}</div>*/}
                    {/*        <div className={"job-tag bg-primary "} title={job}>{job}</div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div>

                        <p className={"text-16  text-line-clamp text-line-clamp-6"}>
                            {desc}
                        </p>

                    </div>

                    <div className={"text-left mt-auto ml-4"}>
                        <Link to={link} className={"text-16 font-bold  text-primary"}>{titleLink ?? trans("show-more")}</Link>
                    </div>
                </div>
            </div>
        </Link>

    );
}

