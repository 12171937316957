import {useState} from "react";

export default function InfoCircle({key=null,icon = null, titleEn,titleAr, subtitle}) {

    const [lang,setlang]=useState( localStorage.getItem('i18nextLng'));
    let title= lang==="en"?titleEn:titleAr;
    const _icon = icon ? (icon) :  "/assets/icons/why/why1.svg";
    return (
        <div className="info-box" key={key}>
            <div className="info-box-circle">
                <div className="info-box-img w-64 h-64">
                    <img src={_icon} alt={title}/>
                </div>
            </div>
            <h3 className={"info-box-title"}>{title}</h3>

        </div>
    );
}
