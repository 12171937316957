import UserNav from "./components/sideNavbar";
import SwiperCore, {Autoplay, EffectFade, Navigation, Pagination} from "swiper";
import {Route, Switch, useRouteMatch} from "react-router";
import {UserNotificationPage} from "./notifications";
import UserHomePage from "./home";
import UserOfferPage from "./offers";
import UserVehicle from './vehicles';
import Profile from "./profile";
import ProtectedRoute from "../components/protectedRoute";
import NotFoundPage_404 from "../pages/_errors/404";
import UserVehiclesDetailsPage from "./detailsUserVehicles";
import MyBookings from "./myBookings";
import UserOrdersPage from "./userOrders";
import PhoneMenu from "./components/phoenMenu";
import { FavoritePage } from "./favorite";
SwiperCore.use([Pagination, Navigation, Autoplay, EffectFade,]);

export function UserPage() {
    const {path, url} = useRouteMatch();
    return (
        <div className={"user-page mx-10 mb-"}>

            <UserNav url={url}/>

            <div className={"user-page-content"}>
                 <PhoneMenu url = {url}/>
                <Switch>
                    <Route exact path={path} component={UserHomePage}/>
                    <ProtectedRoute exact path ={`${path}/profile`} component={Profile}/>
                    <Route exact path={`${path}/notifications`} component={UserNotificationPage}/>
                    <Route exact path={`${path}/offers`} component={UserOfferPage}/>
                    <Route exact path={`${path}/favorite`} component={FavoritePage}/>
                    <Route exact path={`${path}/vehicles`}  component={UserVehicle}/>
                    <Route exact path={`${path}/myOrders`} component={UserOrdersPage}/>
                    <Route exact path={`${path}/vehicles/privateVehicles/:car/:fetchType`}  component={UserVehiclesDetailsPage}/>
                    <Route exact path={`${path}/myBookings/`}  component={MyBookings}/>
                    <NotFoundPage_404/>

                </Switch>
            </div>
        </div>
    );
}