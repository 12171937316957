import {createSlice} from '@reduxjs/toolkit'

export const navSlice = createSlice({
    name: 'nav',
    initialState: {
        isFixed: true,
        iconsBlack: false,
    },
    reducers: {
        changeHeader: (nav, action) => {
            nav.isFixed = action.payload.isFixed;
        },
        changeIcons: (nav, action) => {
            nav.iconsBlack = action.payload.iconsBlack;
        },

        isFixedHeader: (nav) => {
            return nav.isFixed;
        }

    }
})

// Action creators are generated for each case reducer function
export const {changeHeader, isFixedHeader,changeIcons} = navSlice.actions

export default navSlice.reducer