import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function Breadcrumb({links, className}) {
    const {t: trans} = useTranslation("common");
    const languageDir = 'language-' + localStorage.getItem('i18nextLng');

    return (
        <div className={`breadcrumb ${languageDir}-t ` + className}>

            <Link to={"/"} >{trans("common:home")}</Link>
            {
                links?.map((link,index) => {
                    if(link != null&& link != "null") 
                    return (<Link className={link.className} to={link.to ? link.to : "#"} key={index}>{link.name}</Link>);
                })
            }
        </div>
    );
}

