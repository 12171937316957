import { Fragment, useEffect, useRef, useState, useContext } from "react"; import ErrorLabel from '../../components/error';
import { InputField } from "../_input/_input"
import { useTranslation } from "react-i18next";
export function DropDownInput2({
    styles,
    title,
    placeholder,
    disabled = false,
    className,
    classDropdownField,
    fieldTextClass,
    isSecondary,
    selectedValue = null,
    inputName,
    options = null,
    onSelect,
    defaultOption = null,
    currentOption = null,
    onfilter,
    isepart,
    errorLabel,
    force = false
}) {

    const { t: trans, i18n } = useTranslation(["mobileInputs"]);
    const optionsRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState((defaultOption != null && defaultOption != undefined) ? defaultOption : { text: '', value: '' });
    const [filterOptions, setfilterOptions] = useState(options);
    const lang = i18n.language;
    const languageDir = "language-" + lang;
    useEffect(() => {
        setfilterOptions(options);

    }, [options]);

    useEffect(() => {

        function handleClickOutside(event) {
            if (optionsRef.current && !optionsRef.current.contains(event.target) && isOpen === true) {
                setIsOpen(false);
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [optionsRef, isOpen]);
    function selectOption(option) {

        if (selectedOption.value !== option.value) {
            if (onSelect) {
                const val = option.value !== '0' ? option.value : '';
                onSelect(val);
            }
            setIsOpen(false);
            setSelectedOption(option);
            if (onfilter)
                if (defaultOption?.value !== option.value)
                    onfilter(option);
        } else {

            setIsOpen(false);
        }
    }

    useEffect(() => {

        // console.log("options>>>>>>>>>>>>>",options);
        // console.log("selectedValue>>>>>>>>>>>>>",selectedValue);
        if (selectedValue == null || selectedValue == undefined) {
            setSelectedOption((defaultOption != null && defaultOption != undefined) ? defaultOption : ((defaultOption != null && defaultOption != undefined) ? defaultOption : { text: '', value: '' }));
        } else {

            var vv = options.find(item => item?.value == selectedValue) ?? null;
            setSelectedOption(vv ? vv : { text: '', value: '' });
        }

    }, [selectedValue]);

    return (

        <Fragment>

            {
                inputName ? (
                    <input type="hidden" value={selectOption.value} />
                ) : null
            }

            <div className={"dropdown " + languageDir + ' ' + className}>

                {
                    title &&
                    <div className={"dropdown-label " + (isSecondary && "secondary")}>{title}
                    </div>
                }

                <div ref={optionsRef} className={"dropdown-input"}>
                    <div className={"dropdown-field-extra  bg-theme-up " + classDropdownField + " " + (errorLabel && 'border-danger') + " " + (isSecondary && "secondary")} style={styles ? styles : null} onClick={() => {
                        setIsOpen(!isOpen);
                    }}>
                        <div className={" dropdown-field-extra-lable " + fieldTextClass}>

                            <span className={"dropdown-field-extra-text " + fieldTextClass}>
                                {
                                    selectedOption.text
                                }


                            </span>

                            {

                                selectedOption?.icon &&
                                < img className={"dropdown-field-icon "} src={selectedOption?.icon} alt="" />
                            }
                        </div>
                        <div className={"dropdown-field-arrow " + (isOpen && "invert")}>
                            <img src="/assets/icons/arrow.svg" alt="arrow" />
                        </div>

                    </div>
                    {
                        !disabled && <div className={"dropdown-options   secondary-theme  " + (isOpen || "hidden")}>

                            {
                                filterOptions && options && options.length > 10 &&
                                < InputField
                                    extraClasses={""}
                                    placeholder={trans("info:search_now")}
                                    onChangeValue={(value) => {
                                        if (value.target.value) {
                                            const filterData = options?.filter(a => a.text.includes(value.target.value));
                                            setfilterOptions(filterData);
                                        } else {
                                            setfilterOptions(options);
                                        }

                                    }} />

                            }

                            <ul>
                                {
                                    filterOptions?.map((option, index) => {

                                        return (
                                            <li key={index}
                                                className={" flex  flex-row nice-gap-1rem " + (((index % 2) == 0) ? " third-theme " : "") + ((option?.value == selectedOption?.value) ? " bg-gray-light " : "")}

                                                onClick={() => {
                                                    selectOption(option);
                                                }}>
                                                <span>{option.text}</span>
                                                {

                                                    option?.icon &&
                                                    < img className={"dropdown-field-icon "} src={option?.icon} alt="" />
                                                }
                                            </li>
                                        );

                                    }
                                    )
                                }

                            </ul>
                        </div>
                    }


                </div>

            </div>

        </Fragment>
    );
}
