 const  CarFeature = ({ value = 0, iconName = 'petrol', text, iconStyle}) => {
    return (
        <div>
            <span className={"text-24 flex items-center flex-col"}>
                <span className={"text-24"}>{value}</span>
                <span className={"text-size-14r text-bold "}>{text}</span>
            </span>
            <div className="order-spec-img" style={iconStyle}>
                <img className={"dark-filter-gray"} src={`/assets/icons/car/${iconName}.svg`} alt={iconName} />
            </div>

        </div>
    );
};

export default CarFeature;