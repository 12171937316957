import { Fragment, useCallback, useEffect, useState, useRef } from "react";
import SwiperCore, { Pagination, Navigation, Autoplay, EffectFade } from "swiper";
import { SwiperSlide, Swiper } from 'swiper/react';
import HeaderLandengPage from "../../components/headerLandengPage";
import { SimpleBanner } from "../../components/__simple/_simpleBanner";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { WhyHJSection } from "../../sections/whyHJSection";
import config from '../../configuration/config.json';
import { PartSearch } from "./sections/partSearch";
import { TestimonialSection } from "../../sections/testimonialSection";
import { MapBanner } from "./sections/mapBanner";
import { ServiceBox } from "../../components/serviceBox";
import { useParams, useRouteMatch } from "react-router";
import Navbar from './sections/navbar/navbar';
import LandengSlide from "./sections/landengSlide";
import Counter from "./sections/counter";
import ImageGallery from "react-image-gallery";
import { ContentLoading } from "../../components/contentLoading";
SwiperCore.use([Pagination, Navigation, Autoplay, EffectFade]);

export function LandengPage() {
    const { t: trans, i18n } = useTranslation(["common", "inputs", "offers", "home"]);
    const languageDir = 'language-' + localStorage.getItem('i18nextLng')
    const popRef = useRef(null);
    const [slides, setSlides] = useState([]);
    const lang = i18n.language;
    const [AddVich, setAddVich] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState(false);
    // const [pageLOading, setPageLoading] = useState(true);
    const [EngineGroups, setEngineGroups] = useState([]);
    const [dynamicPages, setDynamicPages] = useState({});
    const [dynamicPagesSections, setDynamicPagesSections] = useState([]);
    const [sortingSections, setSortingSections] = useState(null);
    const [activeImage, setActiveImage] = useState(0);
    const { pageID } = useParams();

    const [DynamicCounters, setDynamicCounters] = useState([]);
    useEffect(() => {
        callback();
    }, []);

    useEffect(() => {

        console.log("sortingSections>>>>>>>>>>>>>>>>>>>>>>>:", sortingSections);

    }, [sortingSections]);

    const callback = useCallback(async () => {


        //----------------------------

        const Body2 = {
            "name": "GetDynamicPagesSections",
            "values": {

                "pageID": parseInt(pageID)
            }
        };
        await axios.request({
            url: config.baseURL + 'func',
            data: Body2, method: "POST", responseType: "json"
        })
            .then(function (response) {
                const result = response.data;
                setDynamicPagesSections(result);


                var sec = {};
                result?.map((item) => {
                    sec[item?.code] = ""



                })

                setSortingSections(sec);

            })
            .catch(function (error) {
                console.log(error);

                var sec = {};
                setSortingSections(sec);
            });


        //----------------------------

        var pageFun = "GetMainDynamicPage";

        if (pageID) {

            pageFun = "GetDynamicPage";
        }

        const Body = {
            "name": pageFun,
            "values": {

                "pageID": parseInt(pageID)
            }
        };
        await axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {
                const result = response.data[0];
                setDynamicPages(result);

            })
            .catch(function (error) {
                console.log(error);
            });







    }, [lang]);


    useEffect(async () => {

        if (dynamicPages?.pageID) {


            var sections = { ...sortingSections };


            //------------------------------------------------

            // sections["CounterSection"] = dynamicPages?.viewCounter == true &&
            //     <Counter
            //         counterData={DynamicCounters}
            //         backImage={dynamicPages?.counterBackImage}
            //         BackOpicity={dynamicPages?.counterBackOpecity}
            //         title={lang == "ar" ? dynamicPages?.counterTitelAr : dynamicPages?.counterTitel}
            //         desc={lang == "ar" ? dynamicPages?.counterDescAr : dynamicPages?.counterDesc}
            //     />


            {

                sections["MainBannerSection"] = dynamicPages?.viewMainBanner == true &&
                    <HeaderLandengPage
                        Titel={lang == "ar" ? dynamicPages?.MainBannerTitelAr : dynamicPages?.MainBannerTitel}
                        SubTitel={lang == "ar" ? dynamicPages?.MainBannerSubTitelAr : dynamicPages?.MainBannerSubTitel}
                        BtnText={lang == "ar" ? dynamicPages?.MainBannerBtnTextAr : dynamicPages?.MainBannerBtnText}
                        Desc={lang == "ar" ? dynamicPages?.MainBannerDescAr : dynamicPages?.MainBannerDesc}
                        BackOpicity={dynamicPages?.MainBannerBackOpicity}
                        backImage={lang == "ar" ? dynamicPages?.MainBannerBackImageAr : dynamicPages?.MainBannerBackImage}
                        viewSocialIcons={dynamicPages?.viewMainBannerSocialIcons}
                    />
            }
            sections["GreatMessageSection"] =
                dynamicPages?.viewGreatMessage == true &&
                <div className={" mb-32  " + languageDir}>

                    {
                        <SimpleBanner borderRadius={false}
                            icon={"data:image/png;base64," + dynamicPages?.GreatMessageBackIcon} banner={"data:image/png;base64," + dynamicPages?.GreatMessageBackImage}
                            title={lang == "ar" ? dynamicPages?.viewGreatMessageTitelAr : dynamicPages?.viewGreatMessageTitel}
                            subtitle={lang == "ar" ? dynamicPages?.viewGreatMessageDescAr : dynamicPages?.viewGreatMessageDesc} />

                    }

                </div>

            sections["TestimonialsSection"] =
                dynamicPages?.viewTestimonials == true &&
                <TestimonialSection

                    title={(lang == "ar" ? dynamicPages?.TestimonialsTitelAr : dynamicPages?.TestimonialsTitel) ?? null}
                    subtitle={(lang == "ar" ? dynamicPages?.TestimonialsSubTitelAr : dynamicPages?.TestimonialsSubTitel) ?? null}
                />

            {
                sections["WhyChooseUsSection"] =
                    dynamicPages?.viewWhyChooseUs == true &&
                    <WhyHJSection
                        title={(lang == "ar" ? dynamicPages?.WhyChooseUsTitelAr : dynamicPages?.WhyChooseUsTitel) ?? null}
                        subtitle={(lang == "ar" ? dynamicPages?.WhyChooseUsSubTitelAr : dynamicPages?.WhyChooseUsSubTitel) ?? null}
                        secondTheme={true} />
            }


            sections["TopicSection"] = dynamicPages?.viewTopicSection == true &&
                <div className="container mb-32 mt-32">
                    <div className={`info-section ${languageDir}`}>
                        <div className={"info-section-text my-16"}>
                            <h2>{lang == "ar" ? dynamicPages?.TopicTitelAr : dynamicPages?.TopicTitel}</h2>
                            <p>{lang == "ar" ? dynamicPages?.TopicDescAr : dynamicPages?.TopicDesc}</p>
                        </div>
                        <div className="info-section-img border-radius">
                            <img src={'data:image/png;base64,' + (lang == "ar" ? dynamicPages?.TopicImageAr : dynamicPages?.TopicImage)}
                                alt={""} className={"border-radius"} />
                        </div>
                    </div>
                </div>

            {

                sections["MAPSection"] = dynamicPages?.viewMap == true &&

                    <div className={"mb-20  mt-xs"}>
                        <MapBanner />
                    </div>
            }
            setSortingSections(sections);


            if (dynamicPages?.viewMainSliders == true) {
                var mm = await RequsetMainSliders(sections);
                sections["MainSlidersSection"] = mm;
            }




            if (dynamicPages?.viewOurOffers == true) {

                RequsetsetSite_categorySP(sections);
            }


            if (dynamicPages?.viewCounter == true) {

                var mm = await RequsetDynamicCounters(sections);

                console.log("RequsetDynamicGallery>>>>>>>>>>>>>>>>", mm);
                sections["CounterSection"] = mm;

            }



            RequsetSBanner(sections);

            if (dynamicPages?.viewWhatWeServices == true)
                RequsetOurServices(sections);

            if (dynamicPages?.viewGallery == true && dynamicPages?.GUID) {

                var mm = await RequsetDynamicGallery(sections);

                console.log("RequsetDynamicGallery>>>>>>>>>>>>>>>>", mm);
                sections["GallerySection"] = mm;
            }






            setSortingSections(sections);

        }

    }, [dynamicPages]);



    async function RequsetDynamicCounters(sections) {


        const Body = {
            "name": "GetDynamicCounters",
            "values": {

                "pageID": parseInt(dynamicPages?.pageID)
            }
        };
        var response = await axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        })

        if (response.status == 200) {
            setDynamicCounters(response.data);
            return sections["CounterSection"] =
                <Counter
                    counterData={response.data}
                    backImage={dynamicPages?.counterBackImage}
                    BackOpicity={dynamicPages?.counterBackOpecity}
                    title={lang == "ar" ? dynamicPages?.counterTitelAr : dynamicPages?.counterTitel}
                    desc={lang == "ar" ? dynamicPages?.counterDescAr : dynamicPages?.counterDesc}
                />
        } else {


            return null;
        }







    }


    const [OurServices, setOurServices] = useState([]);
    async function RequsetOurServices(sections) {


        const Body = {
            "name": "GetDynamicOurServices",
            "values": {

                "pageID": parseInt(dynamicPages?.pageID)
            }
        };
        axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {

                setTimeout(setOurServices(response.data), 500);



                sections["OurServices"] =
                    (dynamicPages?.viewWhatWeServices == true && OurServices.length > 0) &&
                    <div className={"HJService   mb-20 flex flex-col nice-flex-v-center  text-center py-10  bg-secondary-light " + languageDir}>


                        <h2 className="text-30 text-black flex nice-flex-v-center text-center my-20 container" >{(lang == "ar" ? dynamicPages?.WeServicesTitelAr : dynamicPages?.WeServicesTitel) ?? ""}</h2>

                        <div className="row flex flex-wrap container  nice-flex-v-center">

                            {

                                OurServices?.map((item, index) => {

                                    return (

                                        <div key={index} className="HJService-section">
                                            <div className="block flex flex-col nice-align-item-center text-center">
                                                <div className="img ">
                                                    <img src={"data:image/png;base64," + item?.icon} alt="" />
                                                </div>
                                                <h2 className="HJService-section-titel text-30 text-black ">{lang == "ar" ? item?.titleAr : item?.titleEn}</h2>
                                                <p className="HJService-section-desc text-20 text-gray ">   {lang == "ar" ? item?.contentAr : item?.contentEn}</p>
                                                <a className="a1 text-black " href="#">more</a>
                                            </div>
                                        </div>


                                    );



                                })
                            }

                        </div>

                    </div>

                setSortingSections(sections);

            })
            .catch(function (error) {
                console.log(error);
            });


    }

    const [MainSliders, setMainSliders] = useState([]);
    async function RequsetMainSliders() {


        const Body = {
            "name": "GetDynamicMainSliders",
            "values": {

                "pageID": parseInt(dynamicPages?.pageID)
            }
        };
        var response = await axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        })
        if (response.status == 200) {

            setMainSliders(response.data);


            //------------------------------------------------

            return response.data.length > 0 ?

                <div>

                    {
                        (dynamicPages?.viewMainSliders == true && response.data?.length > 0) &&
                        <Swiper
                            className={"slide-landeng-container "}
                            spaceBetween={0}
                            slidesPerView={1}
                            navigation
                            pagination
                            breakpoints={{
                                576: {
                                    effect: "coverflow",
                                },
                            }}
                            effect="coverflow"
                            watchSlidesVisibility
                            watchSlidesProgress
                            autoplay={true}
                            loop={false}
                            observer="true"
                            resizeObserver={"true"}
                            onUpdateSlider={(slide) => {
                                slide.update();
                            }}
                        >
                            {
                                response.data?.map((slide, index) => {

                                    return (
                                        <SwiperSlide key={index}>
                                            <LandengSlide

                                                Titel={lang == "ar" ? slide?.titelAr : slide?.titelEn}
                                                SubTitel={lang == "ar" ? slide?.MainBannerSubTitelAr : slide?.MainBannerSubTitel}
                                                BtnText={lang == "ar" ? slide?.MainBannerBtnTextAr : slide?.MainBannerBtnText}
                                                Desc={lang == "ar" ? slide?.descAr : slide?.descEn}
                                                BackOpicity={slide?.backOpacity}
                                                backImage={lang == "ar" ? slide?.imageAr : slide?.image}
                                                viewSocialIcons={true}
                                            />
                                        </SwiperSlide>
                                    )
                                })
                            }

                        </Swiper>
                    }

                </div>

                :
                null


        } else {


            return null;
        }


    }

    const [DynamicGallery, setDynamicGallery] = useState([]);
    async function RequsetDynamicGallery(sections) {

        const Body = {
            "name": "GetDynamicGallery",
            "values": {

                "pageGUID": dynamicPages?.GUID
            }
        };
        var response = await axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        })


        if (response.status == 200) {


            const filterG = [];

            response?.data?.map((item, index) => {
                item["original"] = config.mainURL + item.Image_URL;
                item["thumbnail"] = config.mainURL + item.Image_URL;
                item["originalHeight"] = "50";
                item["originalWidth"] = "50";
                item["sizes"] = "50";
                item["fullscreen"] = config.mainURL + item.Image_URL;
                filterG.push(item);


            });



            setDynamicGallery(filterG);

            return filterG?.length > 0 ?
                < div className="simple-gallery" >
                    {
                        filterG?.map((img, index) => {
                            return <div
                                key={index}
                                className={"simple-gallery-item"}
                                onClick={(e) => {

                                    setActiveImage(index);

                                    try {
                                        if (popRef) {


                                            popRef.current.toggleFullScreen();
                                            popRef.current.slideToIndex(index)
                                        }

                                    } catch (error) {

                                    }
                                }}

                            >
                                <img src={config.mainURL + img.Image_URL}
                                    onError={e => e.target.style.display = 'none'}
                                    className={"object-full object-cover"}
                                    alt={"car gellary"}
                                />

                                <span className="simple-gallery-item-ovrlay">

                                    <svg class="image-gallery-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                        <path d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3">

                                        </path>
                                    </svg>
                                </span>
                            </div>
                        })
                    }

                </div >
                : null

        } else {

            return null;
        }


    }
    const [Sbanner, setSbanner] = useState([]);
    function RequsetSBanner() {
        const Body = {
            "object": "siteSimpleBanner_eperts",
            "ObjectSettings": {
                "MetaData": false
            }
        };



        axios.request({
            url: config.baseURL + 'details',
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {
                setTimeout(setSbanner(response.data.ApiObjectData), 1000);

            })
            .catch(function (error) {
                console.log(error);
            });


    }

    async function RequsetsetSite_categorySP() {
        const Body = {
            "name": "GetDynamicOurOffers",
            "values": {

                "pageID": parseInt(dynamicPages?.pageID)
            }
        };
        axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {
                setTimeout(setEngineGroups(response.data), 500);

            })
            .catch(function (error) {
                console.log(error);
            });


    }

    if (!dynamicPages?.pageID)
        return (

            <ContentLoading />

        );

    else
        return (
            <Fragment>

                {

                    dynamicPages?.viewMenu == true &&
                    <Navbar
                        pageID={dynamicPages?.pageID}

                    />
                }

                <div>



                    {
                        sortingSections && dynamicPagesSections &&
                        dynamicPagesSections.map(function (keyName, keyIndex) {
                            return (
                                sortingSections[keyName?.code]

                            );
                        })
                    }


                    {
                        sortingSections &&
                        Object.keys(sortingSections).map(function (keyName, keyIndex) {

                            return (
                                (dynamicPagesSections && dynamicPagesSections?.filter(a => a.code == keyName).length == 0) ?
                                    sortingSections[keyName]
                                    :
                                    null

                            );


                        })
                    }

                    {/* 
                    {

                        <div>

                            {
                                (dynamicPages?.viewMainSliders == true && MainSliders?.length > 0) &&
                                <Swiper
                                    className={"slide-landeng-container "}
                                    spaceBetween={0}
                                    slidesPerView={1}
                                    navigation
                                    pagination
                                    breakpoints={{
                                        576: {
                                            effect: "coverflow",
                                        },
                                    }}
                                    effect="coverflow"
                                    watchSlidesVisibility
                                    watchSlidesProgress
                                    autoplay={true}
                                    loop={false}
                                    observer="true"
                                    resizeObserver={"true"}
                                    onUpdateSlider={(slide) => {
                                        slide.update();
                                    }}
                                >
                                    {
                                        MainSliders?.map((slide, index) => {

                                            return (
                                                <SwiperSlide key={index}>
                                                    <LandengSlide

                                                        Titel={lang == "ar" ? slide?.titelAr : slide?.titelEn}
                                                        SubTitel={lang == "ar" ? slide?.MainBannerSubTitelAr : slide?.MainBannerSubTitel}
                                                        BtnText={lang == "ar" ? slide?.MainBannerBtnTextAr : slide?.MainBannerBtnText}
                                                        Desc={lang == "ar" ? slide?.descAr : slide?.descEn}
                                                        BackOpicity={slide?.backOpacity}
                                                        backImage={lang == "ar" ? slide?.imageAr : slide?.image}
                                                        viewSocialIcons={true}
                                                    />
                                                </SwiperSlide>
                                            )
                                        })
                                    }

                                </Swiper>



                            }
                        </div>
                    } */}
                    {/* 
                    {


                        dynamicPages?.viewTopicSection == true &&
                        <div className="container mb-32 mt-32">
                            <div className={`info-section ${languageDir}`}>
                                <div className={"info-section-text my-16"}>
                                    <h2>{lang == "ar" ? dynamicPages?.TopicTitelAr : dynamicPages?.TopicTitel}</h2>
                                    <p>{lang == "ar" ? dynamicPages?.TopicDescAr : dynamicPages?.TopicDesc}</p>
                                </div>
                                <div className="info-section-img border-radius">
                                    <img src={'data:image/png;base64,' + (lang == "ar" ? dynamicPages?.TopicImageAr : dynamicPages?.TopicImage)}
                                        alt={""} className={"border-radius"} />
                                </div>
                            </div>
                        </div>
                    } */}



                    <div>

                        {/* {
                            // slides?.slice(0, 1).map((slide, index) => {
                            //     if (slide.minPrice > 0)
                            //         return (
                            dynamicPages?.viewMainBanner == true &&
                            <HeaderLandengPage
                                Titel={lang == "ar" ? dynamicPages?.MainBannerTitelAr : dynamicPages?.MainBannerTitel}
                                SubTitel={lang == "ar" ? dynamicPages?.MainBannerSubTitelAr : dynamicPages?.MainBannerSubTitel}
                                BtnText={lang == "ar" ? dynamicPages?.MainBannerBtnTextAr : dynamicPages?.MainBannerBtnText}
                                Desc={lang == "ar" ? dynamicPages?.MainBannerDescAr : dynamicPages?.MainBannerDesc}
                                BackOpicity={dynamicPages?.MainBannerBackOpicity}
                                backImage={lang == "ar" ? dynamicPages?.MainBannerBackImageAr : dynamicPages?.MainBannerBackImage}
                                viewSocialIcons={dynamicPages?.viewMainBannerSocialIcons}
                            />

                            //         )
                            // })
                        } */}



                        {
                            dynamicPages?.viewSearchCar == true &&
                            <div id={"search-car"}>
                                <PartSearch
                                    Titel={lang == "ar" ? dynamicPages?.SearchCarTitelAr : dynamicPages?.SearchCarTitel}
                                />
                            </div>

                        }

                    </div>



                    {/* {
                        dynamicPages?.viewCounter == true &&
                        <Counter
                            counterData={DynamicCounters}
                            backImage={dynamicPages?.counterBackImage}
                            BackOpicity={dynamicPages?.counterBackOpecity}
                            title={lang == "ar" ? dynamicPages?.counterTitelAr : dynamicPages?.counterTitel}
                            desc={lang == "ar" ? dynamicPages?.counterDescAr : dynamicPages?.counterDesc}
                        />
                    } */}

                    {
                        dynamicPages?.viewOurOffers == true &&
                        <div className={"container mb-40 " + languageDir}>
                            <Swiper
                                spaceBetween={0}
                                slidesPerView={1}
                                breakpoints={{
                                    576: {
                                        slidesPerView: 3,
                                    },
                                }}
                                pagination={{ clickable: true }}
                                className={"mb-16k"}
                                resizeObserver={"true"}
                                autoplay={false}
                                loop={false}
                                observer={true}
                            >

                                {EngineGroups.map((H, index) => {


                                    return (
                                        <SwiperSlide className={"px-3"} key={index}>
                                            <ServiceBox
                                                key={index}
                                                small={false}
                                                titlebtn={trans("eparts:titlebtn_ServiceBox")}
                                                link={null}
                                                img={H.image}
                                                titleEn={H?.titelEn}
                                                titleAr={H?.titelAr}
                                                subtitleEn={H?.descEn}
                                                subtitleAr={H?.descAr}
                                            />
                                        </SwiperSlide>
                                    );

                                })}

                            </Swiper>

                        </div>

                    }

                    {/* {
                        dynamicPages?.viewWhyChooseUs == true &&
                        <WhyHJSection
                            title={(lang == "ar" ? dynamicPages?.WhyChooseUsTitelAr : dynamicPages?.WhyChooseUsTitel) ?? null}
                            subtitle={(lang == "ar" ? dynamicPages?.WhyChooseUsSubTitelAr : dynamicPages?.WhyChooseUsSubTitel) ?? null}
                            secondTheme={true} />
                    } */}

                    {/* {
                        (dynamicPages?.viewWhatWeServices == true && OurServices.length > 0) &&
                        <div className={"HJService   mb-20 flex flex-col nice-flex-v-center  text-center py-10  bg-secondary-light " + languageDir}>


                            <h2 className="text-30 text-black flex nice-flex-v-center text-center my-20 container" >{(lang == "ar" ? dynamicPages?.WeServicesTitelAr : dynamicPages?.WeServicesTitel) ?? ""}</h2>

                            <div className="row flex flex-wrap container  nice-flex-v-center">


                                {


                                    OurServices?.map((item, index) => {

                                        return (

                                            <div key={index} className="HJService-section">
                                                <div className="block flex flex-col nice-align-item-center text-center">
                                                    <div className="img ">
                                                        <img src={"data:image/png;base64," + item?.icon} alt="" />
                                                    </div>
                                                    <h2 className="HJService-section-titel text-30 text-black ">{lang == "ar" ? item?.titleAr : item?.titleEn}</h2>
                                                    <p className="HJService-section-desc text-20 text-gray ">   {lang == "ar" ? item?.contentAr : item?.contentEn}</p>
                                                    <a className="a1 text-black " href="#">more</a>
                                                </div>
                                            </div>


                                        );



                                    })
                                }

                            </div>

                        </div>

                    } */}


                    {
                        // (dynamicPages?.viewGallery == true && DynamicGallery.length > 0) &&
                        // <div className="simple-gallery my-20">
                        //     {
                        //         DynamicGallery.map((img, index) => {
                        //             return <img key={index} src={config.mainURL + img?.Image_URL}
                        //                 className={"object-full object-cover"}
                        //                 alt={"car gellary"} />
                        //         })
                        //     }

                        // </div>


                        // <div className="simple-gallery">
                        //     {
                        //         DynamicGallery?.map((img, index) => {
                        //             return <div
                        //                 key={index}
                        //                 className={"simple-gallery-item"}
                        //                 onClick={(e) => {

                        //                     setActiveImage(index);

                        //                     try {
                        //                         if (popRef) {


                        //                             popRef.current.toggleFullScreen();
                        //                             popRef.current.slideToIndex(index)
                        //                         }

                        //                     } catch (error) {

                        //                     }
                        //                 }}

                        //             >
                        //                 <img src={config.mainURL + img.Image_URL}
                        //                     onError={e => e.target.style.display = 'none'}
                        //                     className={"object-full object-cover"}
                        //                     alt={"car gellary"}
                        //                 />

                        //                 <span className="simple-gallery-item-ovrlay">

                        //                     <svg class="image-gallery-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                        //                         <path d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3">

                        //                         </path>
                        //                     </svg>
                        //                 </span>
                        //             </div>
                        //         })
                        //     }

                        // </div>

                    }
                    {/* {
                        dynamicPages?.viewTestimonials == true &&
                        <TestimonialSection

                            title={(lang == "ar" ? dynamicPages?.TestimonialsTitelAr : dynamicPages?.TestimonialsTitel) ?? null}
                            subtitle={(lang == "ar" ? dynamicPages?.TestimonialsSubTitelAr : dynamicPages?.TestimonialsSubTitel) ?? null}
                        />
                    } */}

                    {/* {
                        dynamicPages?.viewGreatMessage == true &&
                        <div className={" mb-32  " + languageDir}>

                            {
                                <SimpleBanner borderRadius={false}
                                    icon={"data:image/png;base64," + dynamicPages?.GreatMessageBackIcon} banner={"data:image/png;base64," + dynamicPages?.GreatMessageBackImage}
                                    title={lang == "ar" ? dynamicPages?.viewGreatMessageTitelAr : dynamicPages?.viewGreatMessageTitel}
                                    subtitle={lang == "ar" ? dynamicPages?.viewGreatMessageDescAr : dynamicPages?.viewGreatMessageDesc} />

                            }

                        </div>

                    } */}

                    {/* {
                        dynamicPages?.viewMap == true &&

                        <div className={"mb-20  mt-xs"}>
                            <MapBanner />
                        </div>
                    } */}



                </div>


                <div className={""} style={{
                    opacity: (isFullScreen == true ? 1 : 0),
                    position: (isFullScreen == true ? "relative" : "fixed"), zIndex: (isFullScreen == true ? 999999 : -1)
                }}>
                    <ImageGallery ref={popRef} items={DynamicGallery} onScreenChange={(e) => {

                        setIsFullScreen(e);
                    }} />
                </div>
            </Fragment>
        );
}