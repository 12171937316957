import {useEffect} from "react";

const ServiceFeatureList = ({ title = '', values = [] }) => {

    return (
        <div className="my-12">
            <h1 className="text-30 text-bold text-black mb-12">{title}</h1>
            <div className="nice-flex nice-flex-row nice-flex-wrap-content nice-flex-center nice-gap-7rem">
                {
                    values.map((item, index) => {
                        return (
                        <div key={index} className="nice-flex nice-flex-column nice-flex-center">
                            <div className="mainteance-feature mb-4">
                                <img src= {item.image} alt={item.title}/>
                            </div>
                            <h2 className="text-20 mb-3">{item.title}</h2>
                            <div className="ruler"></div>
                        </div>
                        )
                    })
                }
            </div>
        </div>
    )
};
export default ServiceFeatureList;