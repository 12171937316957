import { Fragment, useCallback, useEffect, useState } from "react";
import SwiperCore, { Pagination, Navigation, Autoplay, EffectFade } from "swiper";
import { SwiperSlide, Swiper } from 'swiper/react';
import Slide from "../../components/slide";
import Info from "../../components/_info";
import IconText from "../../components/iconText";
import { TestimonialSection } from "../../sections/testimonialSection";
import { WhyHJSection } from "../../sections/whyHJSection";
import axios from "axios";
import { useTranslation } from "react-i18next";
import HomeOfferSection from "./sections/homeOfferSection";
import HomeVehicleSection from "./sections/homeVehicleSection";
import AdsBanner from "../../components/adsBanner";
import { Loading } from '../loading/index';
import config from "../../configuration/config.json";
import Skeleton from 'react-loading-skeleton'
import { saveData, User_Data_Key, getData } from "../../utils/localStorageManager";
import { Helmet } from "react-helmet";
SwiperCore.use([Pagination, Navigation, Autoplay, EffectFade]);

export function Home() {
    const { t: trans, i18n } = useTranslation(["common", "inputs", "offers", "home", "info"]);

    const [cars, setCars] = useState([]);
    const [brands, setBrands] = useState([]);
    const [home, setHome] = useState({});
    const [offerPart, setOfferPart] = useState([]);
    const [offerPartFilters, setOfferPartFilters] = useState([]);
    const [offerVehicle, setOfferVehicle] = useState([]);
    const [offerVehicleFilters, setOfferVehicleFilters] = useState([]);
    const [slides, setSlides] = useState([]);
    const [hjInfo, setHjInfo] = useState(null);
    const lang = i18n.language;
    const [AddVich, setAddVich] = useState(false);
    // const [pageLOading, setPageLoading] = useState(true);
    const [isLoadingslides, setLoadingslides] = useState(true);
    const [isLoadingInfo, setLoadingInfo] = useState(true);
    const FetchAttachmentImage = async (GUIDList, FieldNameList) => {
        try {



            if (GUIDList)
                GUIDList = GUIDList.toString();




            if (FieldNameList)
                FieldNameList = FieldNameList.toString().replace("[,]", "");


            const Body = {
                "name": "App_FetchAttachmentImage",
                "values": {
                    "GUIDList": GUIDList,
                    "FieldNameList": FieldNameList
                }
            }

            var respons = await axios.request({
                url: config.baseURL + 'func',
                data: Body, method: "POST", responseType: "json"
            });


        } catch (error) {
            console.log("error:", error);
        }


        return respons;
    };
    useEffect(() => {
        callback();
    }, []);

    const callback = useCallback(async () => {
        // axios.defaults.timeout = 150000;

        var curentSlides;
        setLoadingslides(true);

        try {

            if ("caches" in window) {
                let url = "slides";

                // Opening that particular cache
                const cacheStorage = await caches.open("HJWeb");
                // Fetching that particular cache data
                const cachedResponse = await cacheStorage.match(
                    url
                );
                let data = await cachedResponse?.json();
                setSlides(data);
                curentSlides = data;
                setLoadingslides(false);
            }

        } catch (error) {
        }
        axios.request({
            baseURL: config.controllURL,
            url: lang + "/api/home/slides",
            method: "get",
        }).then(response => {

            setSlides(response.data.slides);
            curentSlides = response.data.slides;
            setLoadingslides(false);
            const data = new Response(JSON.stringify(curentSlides));

            if ("caches" in window) {
                // Opening given cache and putting our data into it
                caches.open("HJWeb").then((cache) => {
                    cache.put("slides", data);

                });
            }

        }).catch(error => {
            setLoadingslides(false);
        });


        let curentInfo;
        try {


            setLoadingInfo(true);
            try {

                if ("caches" in window) {
                    let url = "siteHomePage";

                    // Opening that particular cache
                    const cacheStorage = await caches.open("HJWeb");
                    console.log("vcacheStorage", cacheStorage);
                    // Fetching that particular cache data
                    const cachedResponse = await cacheStorage.match(
                        url
                    );
                    let data = await cachedResponse?.json();
                    console.log("data", data);
                    curentInfo = {
                        title: data[`section1${trans("info:title")}`],
                        description: data[`section1${trans("info:description")}`],
                        image: data.section1Image
                    }

                    setHjInfo({ ...curentInfo });
                    setLoadingInfo(false);
                }

            } catch (error) {
            }


            axios.post(
                config.baseURL + 'details',
                {
                    "Object": "siteHomePage",
                    "ObjectSettings": {
                        "MetaData": false
                    },
                    "Option": "Column",
                    "Fields": "section1TitleAr,section1TitleEn,section1DescAr,section1DescEn"
                }
            ).then(response => {
                setLoadingInfo(false);
                const responseData = response.data.ApiObjectData;
                if (responseData.length > 0) {
                    const firstResult = responseData[0];

                    curentInfo = {
                        title: firstResult[`section1${trans("info:title")}`],
                        description: firstResult[`section1${trans("info:description")}`],
                        image: firstResult.section1Image
                    }

                    setHjInfo({ ...curentInfo });
                    const data = new Response(JSON.stringify(firstResult));

                    if ("caches" in window) {
                        // Opening given cache and putting our data into it
                        caches.open("HJWeb").then((cache) => {
                            cache.put("siteHomePage", data);

                        });
                    }
                }

            }).catch(error => {
                console.log(error);
                setLoadingInfo(false);
            });


        } catch (error) {
            console.log("error:", error);
            setLoadingInfo(false);
        }


        try {
            let homedata = [];
            if ("caches" in window) {
                var curl = "/api/home/home";

                // Opening that particular cache
                const cacheStorage = await caches.open("HJWeb");
                // Fetching that particular cache data
                const cachedResponse = await cacheStorage.match(
                    curl
                );
                homedata = await cachedResponse?.json();

            }

            if(homedata){
                    setBrands(homedata)

            }else{

                axios.request({
                    baseURL: config.controllURL,
                    url: lang + "/api/home/home",
                    method: "get",
                }).then(response => {
        
        
                    try {
                        // setHome(response.data.home);
                        setBrands(response.data.brands);
        
                        if ("caches" in window) {
                            // Opening given cache and putting our data into it
                            caches.open("HJWeb").then((cache) => {
                                cache.put("/api/home/home", new Response(JSON.stringify(response.data.brands)));
        
                            });
                        }
                    } catch (error) {
        
                    }
        
                }).catch(error => {
                    console.log(error);
                });

            }

        } catch (error) {
        }
 


        try {

            if ("caches" in window) {
                let url = "vehicles-" + lang;

                // Opening that particular cache
                const cacheStorage = await caches.open("HJWeb");
                console.log("vcacheStorage", cacheStorage);
                // Fetching that particular cache data
                const cachedResponse = await cacheStorage.match(
                    url
                );
                let data = await cachedResponse?.json();
                console.log("data", data);
                setCars(data.data.vehicles);

            }

        } catch (error) {
        }

        axios.request({
            baseURL: config.controllURL,
            url: lang + "/api/home/vehicles",
            method: "get",
        }).then(response => {
            setCars(response?.data?.vehicles);
            const data = new Response(JSON.stringify(response));

            if ("caches" in window) {
                // Opening given cache and putting our data into it
                caches.open("HJWeb").then((cache) => {
                    cache.put("vehicles-" + lang, data);

                });
            }


        }).catch(error => {
            console.log(error);
        });





        try {

            if ("caches" in window) {
                let url = "HomeExtra-" + lang;

                // Opening that particular cache
                const cacheStorage = await caches.open("HJWeb");
                console.log("vcacheStorage", cacheStorage);
                // Fetching that particular cache data
                const cachedResponse = await cacheStorage.match(
                    url
                );
                var response = await cachedResponse?.json();

                setOfferPart(response.data.offerPart);

                setOfferVehicle(response.data.offerVehicle);

                setOfferPartFilters(response.data.offerPartTypes);

                setOfferVehicleFilters(response.data.offerVehicleTypes);;

            }

        } catch (error) {
        }


        await axios.request({
            baseURL: config.controllURL,
            url: lang + "/api/home/HomeExtra",
            method: "get",
        }).then(response => {


            setOfferPart(response.data.offerPart);

            setOfferVehicle(response.data.offerVehicle);

            setOfferPartFilters(response.data.offerPartTypes);


            setOfferVehicleFilters(response.data.offerVehicleTypes);

            const data = new Response(JSON.stringify(response));

            if ("caches" in window) {
                // Opening given cache and putting our data into it
                caches.open("HJWeb").then((cache) => {
                    cache.put("HomeExtra-" + lang, data);

                });
            }



        }).catch(error => {
            console.log(error);
        });



        try {

            if (curentInfo) {
                await axios.post(
                    config.baseURL + 'details',
                    {
                        "Object": "siteHomePage",
                        "ObjectSettings": {
                            "MetaData": false
                        },
                        "Option": "Column",
                        "Fields": "section1Image"
                    }
                ).then(response => {
                    const responseData = response.data.ApiObjectData;
                    if (responseData.length > 0) {
                        const firstResult = { ...curentInfo }

                        firstResult.image = responseData[0].section1Image;

                        setHjInfo(firstResult);
                    }
                }).catch(error => {
                    console.log(error);

                });

            }

        } catch (error) {
            console.log("error:", error);

        }



    }, [lang]);
    const [seoPageInfo, setSeoPageInfo] = useState({});
    useEffect(async () => {

        var page = "Home"

        const response = await axios.post(
            config.baseURL + 'func',
            {
                "Name": "SiteFetchSeoPages",
                "Values": {
                    "page": page
                }
            }
        );
        if (response.status == 200) {

            var resdata = response.data[0];

            setSeoPageInfo(resdata);


        }
    }, []);
    return (
        <Fragment>
            {
                seoPageInfo &&

                <Helmet>

                    <meta charSet="utf-8" />
                    {
                        seoPageInfo?.title &&
                        <title>{seoPageInfo?.title}</title>
                    }
                    {
                        seoPageInfo?.link &&
                        <link rel="canonical" href={seoPageInfo?.link} />

                    }
                    {
                        seoPageInfo?.MetaDescription &&
                        <meta name="description" content={seoPageInfo?.MetaDescription} />
                    }
                    {
                        seoPageInfo?.MetaGroups &&
                        <meta name="keywords" content={seoPageInfo?.MetaGroups} />
                    }

                </Helmet>


            }

            <div>
                <div>

                    {
                        isLoadingslides ? (

                            <>
                                <Skeleton count={1} borderRadius={"2rem"} width={"100%"} height={"50rem"}
                                    containerClassName={""} className={"slide-container main-slider  mx-10"}
                                    inline={true} />

                            </>


                        ) : (

                            <Swiper
                                className={"slide-container main-slider"}
                                spaceBetween={0}
                                slidesPerView={1}
                                // navigation
                                // pagination
                                breakpoints={{
                                    576: {
                                        effect: "fade",
                                    },
                                }}
                                effect="fade"
                                watchSlidesVisibility
                                watchSlidesProgress
                                autoplay={true}
                                loop={false}
                                observer="true"
                                resizeObserver={"true"}
                                onUpdateSlider={(slide) => {
                                    slide.update();
                                }}
                            >
                                {
                                    slides?.map((slide, index) => {

                                        return (
                                            <SwiperSlide key={index}>
                                                <Slide
                                                    type={slide.type}
                                                    seats={slide.seatsNumber}
                                                    year={slide.year}
                                                    hybridImage={slide.hybridImage}
                                                    id={slide.siteId}
                                                    img={slide.image}
                                                    bg={slide.background}
                                                    name={slide.name}
                                                    cylinders={slide.cylinders}
                                                    petrol={slide.petrol}
                                                    colorID={slide.colorID}
                                                    HP={slide.hp}
                                                    brand={slide.brand}
                                                    brandID={slide.brandID}
                                                    otherBrand={slide.otherBrand}
                                                    group={slide.group}
                                                    slug={slide.slug}
                                                    minPrice={slide.minPrice}
                                                    ProductId={slide.productId}
                                                    RateCareDiscount={slide.rateCareDiscount}
                                                    Tax={slide.rateTaxCar}
                                                    modelTypes={slide.modelTypes}
                                                    AddVichl={() => {
                                                        setAddVich(true)
                                                    }}
                                                    end_Offer={slide.end_at}
                                                    NewCar_DateEnd={slide.newCar_DateEnd}
                                                    NewCar={slide.newCar}
                                                    Hybird={slide.hybird}
                                                    showPric={slide.showPric}
                                                    viewSliderIcon={slide.viewSliderIcon}
                                                    viewSliderDetails={slide.viewSliderDetails}
                                                    viewBtns={slide.viewBtns}
                                                    sliderBackOpasity={slide.sliderBackOpasity}
                                                />
                                            </SwiperSlide>
                                        )
                                    })
                                }

                            </Swiper>

                        )

                    }
                </div>


                {/* <div className="container mb-32 mt-32">


                    <video width="750" height="500" controls >
                        <source src="https://hassanjameel.com.sa/app/uploads/2020/12/2021-Lexus-LC-Convertible-Luxury-Coupe.mp4" type="video/mp4" />
                    </video>
                </div> */}
                <div className="container my-32 text-center">
                    <IconText
                        link={"/finance"}
                        title={trans("home:finance")}
                        icon={"/assets/icons/services/fin.svg"}
                    />
                    {/* <IconText
                        link='/compare'
                        title={trans("home:compare")}
                        icon={"/assets/icons/services/compare.svg"}
                    /> */}
                    <IconText
                        link={"/VehicleOnline"}
                        title={trans("home:vehicles-shop")}
                        icon={"/assets/icons/services/shop.svg"}
                    />
                    <IconText
                        link={"/offers"}
                        title={trans("home:offers")}
                        icon={"/assets/icons/services/offer.svg"}
                    />
                    <IconText
                        link={"/maintenance"}
                        title={trans("home:maintenance")}
                        icon={"/assets/icons/services/mant.svg"}
                    />
                    {/* <IconText
                        link={"/eparts"}
                        title={trans("home:eparts")}
                        icon={"/assets/icons/services/engine.svg"}
                    /> */}
                </div>
                <WhyHJSection />

                {
                    cars &&
                    <HomeVehicleSection cars={cars} brands={brands} />
                }

                <AdsBanner />

                {/*----------------------Start offers sections------------------*/}
                {
                    offerPart?.length > 0 && <HomeOfferSection
                        offers={offerPart}
                        filters={offerPartFilters}
                        type={"offer-parts"}
                        link={""}
                    />
                }
                {
                    offerVehicle?.length > 0 && <HomeOfferSection
                        offers={offerVehicle}
                        filters={offerVehicleFilters}
                        type={"offer-vehicles"}
                        link={""}
                    />
                }
                {/*----------------------End offers sections------------------*/}

                <div className="container mb-32 mt-32">


                    {
                        isLoadingInfo ? (

                            <>
                                <Skeleton count={1} borderRadius={"1rem"} width={"100%"} height={"30rem"}
                                    containerClassName={""} className={""}
                                    inline={true} />

                            </>


                        ) : (
                            (hjInfo) &&
                            <Info hjData={hjInfo} />

                        )


                    }


                </div>


                <TestimonialSection />
            </div>
        </Fragment>
    );
}