import { useTranslation } from "react-i18next";
const CallCenter = ({servicePhone = '00966920018996' , phoneNumber = '920018996'})=>{
    const {t:trans} = useTranslation("common");
    return (
        <div
        className="header-secondary  flex justify-between items-center bg-secondary border-radius py-3 px-6 my-4">
        {/* <h3 className="text-20">{trans("common:hj")}</h3> */}
        <div className="flex flex-row items-center nice-gap-1rem">
                  <a  href={`tel:${phoneNumber}`} >
                 <div className="btn-call"><img src="/assets/icons/phone.svg" className="bg-black " alt="phone number"/><span className="block text-20">{phoneNumber}</span></div>
                </a>
                {/* <a href={`tel:${servicePhone}`}>
                     <span className="block text-20">{trans("common:service_no")}:{servicePhone}</span>
                </a> */}
        </div>
    </div>
    );
}

export  default CallCenter;