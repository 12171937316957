import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Banner from "../../components/banner";
import { Job } from "./components/job";
import Breadcrumb from "../../components/__simple/breadcrumb";
import axios from "axios";
import { useHistory, useLocation, useParams } from "react-router";
import Pagination from "react-js-pagination";
import { JobFilters } from "./components/filters";
import { slugCombine } from '../../utils/slugManager';
import config from "../../configuration/config.json";
import { Helmet } from "react-helmet";
export function EmploymentPage() {
    const { t: trans, i18n } = useTranslation(["common", "heading", "inputs", "employment"]);
    const { page = 1 } = useParams();
    const history = useHistory();
    const location = useLocation();

    const query = new URLSearchParams(location.search);

    const [itemCount, setItemCount] = useState(0);
    const [banner, setBanner] = useState({});
    const [jobs, setJobs] = useState([]);
    const [jobDepartments, setJobDepartments] = useState([])
    const [jobBranches, setJobBranches] = useState([]);
    const [activePage, setActivePage] = useState(0);
    const lang = i18n.language;

    const [dept, setDept] = useState(query.get("dept") ?? "");
    const [branch, setBranch] = useState(query.get("branch") ?? "");

    useEffect(async () => {
        try {

            try {
                if ("caches" in window) {
                    var curl = "employment-" + page + dept + branch;

                    // Opening that particular cache
                    const cacheStorage = await caches.open("HJWeb");

                    // Fetching that particular cache data
                    const cachedResponse = await cacheStorage.match(
                        curl
                    );
                    var datacash = await cachedResponse?.json();

                    if (datacash) {
                        setItemCount(datacash.totalItemsCount);
                        setBanner(datacash.banner);
                        setJobs(datacash.employments);
                        setJobDepartments(datacash.jobDepartments);
                        setJobBranches(datacash.jobBranches);

                    }



                }
            } catch (error) {

            }

            const response = await axios.request({
                baseURL: config.controllURL,
                url: lang + "/api/employment/employment/" + page + "?dept=" + dept + "&branch=" + branch,
                method: "get",
            });
            setItemCount(response.data.totalItemsCount);
            setBanner(response.data.banner);
            setJobs(response.data.employments);
            setJobDepartments(response.data.jobDepartments);
            setJobBranches(response.data.jobBranches);

            const dataResponse = new Response(JSON.stringify(response.data));

            if ("caches" in window) {
                // Opening given cache and putting our data into it
                caches.open("HJWeb").then((cache) => {
                    cache.put("employment-" + page + dept + branch, dataResponse);

                });
            }
        } catch (error) {
            console.log(error);
        }
        setActivePage(parseInt(page));
    }, [lang, page, location]);

    function departmentFilter(option) {
        setDept(option.value);
    }

    function branchFilter(option) {
        setBranch(option.value);
    }


    const [seoPageInfo, setSeoPageInfo] = useState({});
    useEffect(async () => {

        var page = "Employment"


        const response = await axios.post(
            config.baseURL + 'func',
            {
                "Name": "SiteFetchSeoPages",
                "Values": {
                    "page": page
                }
            }
        );
        if (response.status == 200) {

            var resdata = response.data[0];

            setSeoPageInfo(resdata);


        }
    }, []);
    return (
        <Fragment>


            {
                seoPageInfo &&

                <Helmet>

                    <meta charSet="utf-8" />
                    {
                        seoPageInfo?.title &&
                        <title>{seoPageInfo?.title}</title>
                    }
                    {
                        seoPageInfo?.link &&
                        <link rel="canonical" href={seoPageInfo?.link} />

                    }
                    {
                        seoPageInfo?.MetaDescription &&
                        <meta name="description" content={seoPageInfo?.MetaDescription} />
                    }
                    {
                        seoPageInfo?.MetaGroups &&
                        <meta name="keywords" content={seoPageInfo?.MetaGroups} />
                    }

                </Helmet>


            }
            <Banner
                subtitle={banner?.subtitle}
                title={banner?.title}
                desc={banner?.excerpt}
                background={banner?.imagePath}
                backOpasity={banner?.backOpasity}
            />

            <JobFilters branchFilter={branchFilter}
                departmentFilter={departmentFilter}
                jobBranches={jobBranches}
                jobDepartments={jobDepartments}
                onSearchClick={() => {
                    history.push("/employment/" + page + "?dept=" + dept + "&branch=" + branch);
                }}
            />
            <div className="container mb-32">

                <Breadcrumb
                    links={[
                        {
                            name: trans("common:employment")
                        }
                    ]}
                />
                <div className="grid-3-cols mb-16 job-grid">

                    {
                        jobs?.map((job) => {
                            return (
                                <Job
                                    key={job.id}
                                    title={job.position}
                                    job={job.department}
                                    location={job.branch}
                                    link={"/employment/detail/" + slugCombine(job.slug, job.otherLink)}
                                    desc={job.desc}
                                    titleLink={trans("presentation")}
                                />

                            );
                        })
                    }
                </div>
                {
                    itemCount > 10 &&
                    <Pagination
                        activePage={activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={itemCount}
                        pageRangeDisplayed={5}
                        onChange={(number) => {
                            console.log('Page number', number);
                            history.push("/employment/" + number + "?dept=" + dept + "&branch=" + branch,);
                        }}
                    />

                }


            </div>
        </Fragment>
    );
}