import axios from "axios";
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import {useParams, useRouteMatch} from "react-router"
import Breadcrumb from "../../../components/__simple/breadcrumb";
import PageHeading from "../../../components/__simple/_pageheading"
import { WhyHJSection } from "../../../sections/whyHJSection";
import { MaintenanceServiceBox } from "../components/maintenance";
import ServiceDescription from "../components/serviceDescription";
import ServiceHeaderBanner from "../components/serviceHeaderBanner";
import ServicesList from "../components/servicesList";
import MaintenanceContext from "../maintenanceContext";
import config from "../../../configuration/config.json";
const ServicePage = () => {
    const [maintenanceInfo, setMaintenanceInfo] = useState({});
    const { t: trans, i18n } = useTranslation(["info", "common"]);
    const { handleServiceOrder } = useContext(MaintenanceContext);
    const { path } = useRouteMatch();
    const languageDir = 'language-' + i18n.language;
    let { ID } = useParams();    const [SubService, setSubService] = useState([]);
    function  fetchSubService(IDMain){
        const  Body=  {
            "name":"site_FetchServices"    ,
            "values":{
                "GUID":IDMain,
                "ModelCode":null
            }
        }

        axios.request({
            url: config.baseURL + 'func',
            data: Body, method:"POST",responseType:"json"
        })
            .then(function (response) {
                // console.log(response.data.ApiObjectData);
                setTimeout(setSubService(response.data),1000);

            })
            .catch(function (error) {
                console.log(error);
            });



    }
    const [lang, setlang] = useState(localStorage.getItem('i18nextLng'));

    const [SubInfo, setSubInfo] = useState([]);
    function  fetchSubnfo(IDMain){
        const  Body=  {
            "name":"site_fetchInfo_SubService2"    ,
            "values":{
                "SubID":IDMain
            }
        }

        axios.request({
            url: config.baseURL + 'func',
            data: Body, method:"POST",responseType:"json"
        })
            .then(function (response) {
                // console.log(response.data.ApiObjectData);
                setTimeout(setSubInfo(response.data),1000);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    useEffect(async () => {
        try {
            fetchSubService(ID);
            fetchSubnfo(ID);
            const response = await axios.request({
                 baseURL: config.controllURL,
                url: i18n.language + "/api/maintenance/index",
                method: "get",
            });
            setMaintenanceInfo(response.data);
        } catch (error) {
            console.log(error);
        }
    }, []);
    return (
        <>
            {
                SubInfo.map((p)=>{
                    return(  <ServiceHeaderBanner
                        bannerDescriptor={{image:p.img,subTitle:'',title:lang==="en"?p.DescriptionEn:p.DescriptionAr}}
                        hasRedirect={false}
                    />)
                })
            }
            <div className="container">
                <Breadcrumb className={"mt-16 "}
                    links={[
                        {
                            name: trans("info:maintenance"),
                            to: '/maintenance'
                        },
                        {
                            name: trans("info:main_sub_main_service"),
                            to: '/maintenance/subMainService'
                        },
                        {
                            name: trans("info:main_service_types"),
                            to: '/maintenance/subMainService/subService'
                        }, {
                            name: trans("info:main_service_types"),
                            to: { path }
                        }
                    ]}
                />
            </div>
            <div className={"container mb-32 " + languageDir}>
                <PageHeading
                    title={trans("info:main_service_types")}
                />

                <div className="nice-row">
                    {
                        SubService.map(item => {
                            return <div className="nice-col-md-3 nice-col-sm-6 nice-col-6 mb-12">
                                <MaintenanceServiceBox
                                   image={item.img?  `url('${(config.mainURL + item.img.replaceAll("\\", "/"))}')`:`url('/assets/images/defult.png')`}
                                    to={`${path}`}
                                    title={lang==='ar'?item.ServiceNameAR:item.ServiceName}
                                    maxPrice={100000}
                                    minPrice={50000}
                                    onServiceOrder={() => {
                                        handleServiceOrder({
                                            type:"sub2",
                                            showPopup: true
                                            ,...item
                                        });
                                    }}
                                />
                            </div>
                        })
                    }
                </div>
              
            </div>
            <WhyHJSection />
        </>
    );
}
export default ServicePage;