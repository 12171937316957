import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, EffectFade, Navigation, Pagination } from "swiper";
SwiperCore.use([Pagination, Navigation, Autoplay, EffectFade]);

export default function AwardsSection({ awards }) {
    if (awards == null)
        return null;

    return (
        <div className="container mb-32" id='rewardsElement'>

            <Swiper
                spaceBetween={5}
                slidesPerView={2}
                breakpoints={{
                    576: {
                        slidesPerView: 4,
                       
                    },
                }}
                pagination
                className={"about-w-1/4 "}
                resizeObserver={"true"}
            >
                {
                    awards.map((award) => {
                        return (
                            <SwiperSlide className={"px-3"}>
                                <div className={"flex flex-col items-center"}>
                                    <h3 className="text-center text-24 font-bold mb-8">{award.title}</h3>
                                    <p className="text-center text-16 text-line-clamp text-line-clamp-1">{award.description}</p>
                                    <img src={"data:image/png;base64," + award.imagePath} alt={""} className="award-img dark-filter-gray my-5" />
                                </div>
                            </SwiperSlide>
                        );
                    })
                }

            </Swiper>

            {/* <div className={"award-container"}>

                {
                    awards.map((award) => {
                        return (
                            <img src={"data:image/png;base64," + award.imagePath} alt={award.title} className="award-img dark-filter-gray" />
                        );
                    })
                }
            </div> */}
        </div>
    );
}