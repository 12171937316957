import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from "react-router-dom";
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';
import i18next from "i18next";
import { ContentLoading } from './components/contentLoading';

ReactDOM.render(
    <BrowserRouter basename={"/" + getLanguage()}>
        <Suspense fallback={<ContentLoading/>}>
            <React.StrictMode>
                <App/>
            </React.StrictMode>
        </Suspense>
    </BrowserRouter>
    ,
    document.getElementById('root')
)
;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// updateFontSize();
//
// function updateFontSize() {
//     let btns = document.querySelectorAll('.font-size-js');
//     btns.forEach((btn) => {
//         let width = btn.offsetWidth;
//         let textWidth = btn.querySelector('span').clientWidth;
//         let fontSize = ((width - textWidth) / 3) > 12 ? 12 : ((width - textWidth) / 3);
//         btn.querySelector('span').style.fontSize = fontSize + 'px';
//         console.log({width: width, textWidth: textWidth, total: ((width - textWidth) / 3)})
//     })
// }

function getLanguage() {
    return i18next.language ||
        (typeof window !== 'undefined' && window.localStorage.i18nextLng) ||
        'en';
}
;


