export function UploadControl({ name, errorLabel, title, value, onPicker, accept = '*', placeholder ,extraClasses}) {
    let lang = localStorage.getItem("i18nextLng");
    const languageDir = "language-" + lang;
    return (
        <label htmlFor="contained-button-file " className={"bg-theme-up m-0 w-100 custom-input-file border-none  border-radius-sm   nice-shadow-2 " + (errorLabel && 'border-danger')}>
            <input
                accept={accept}
                className={"input-field bg-theme-up "}
                id="contained-button-file"
                type="file"
                name={name}
                onChange={(e) => {
                    onPicker(e)
                }
                    
                    
                  
                
                
                }
            // onChange={disabled ? () => { } : onChange}
            />

            <div className={" custom-input-file-content "}>
                <input className={"input-field bg-theme-up "+extraClasses}
                    readOnly={true}
                    placeholder={placeholder}
                    value={value}
                    type={"text"}
                />
                <img src={"/assets/icons/add-file-icon.svg"} />
            </div>
        </label>
    );
}
