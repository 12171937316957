import {createSlice} from '@reduxjs/toolkit'

export const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        count: 0,
    },
    reducers: {
        changeCount: (nav, action) => {
            nav.count = action.payload.count;
        },
     

    }
})

// Action creators are generated for each case reducer function
export const {changeCount} = cartSlice.actions

export default cartSlice.reducer