import {Fragment, useEffect, useState} from "react";
import UserNav from "./components/sideNavbar";
import PageHeading from "../components/__simple/_pageheading";
import {FilterButton} from "../components/__simple/_filterButton";
import {Swiper, SwiperSlide} from "swiper/react";
import Offer from "../components/offer";
import SwiperCore, {Autoplay, EffectFade, Navigation, Pagination} from "swiper";
import axios from "axios";
import config from "../configuration/config.json";
import { getData, User_Data_Key } from "../utils/localStorageManager";
import { groupBy, mapValues, omit } from "lodash";
import { useTranslation } from "react-i18next";
import SectionHeading from "../components/sectionHeading";
import { slugCombine } from "../utils/slugManager";
SwiperCore.use([Pagination, Navigation, Autoplay, EffectFade,]);

export default function UserOfferPage() {
    const {t:trans} = useTranslation(["global","model"]);
    const [offers,setOffers] = useState([]);
     useEffect(()=>{
     initialUserOffers();
    },[]);
    // to fetch all the user offers
    const initialUserOffers = () => {
        try {
         const isLogin = getData("isAuthenticated");
         if(isLogin){
          const userID = getData('secretKey');
          axios.post(
              config.baseURL + 'func',
              {
                  "Name": "SiteFetchUserOffers",
                  "Values": {
                      "UserID": String(userID)
                  }
              }
           ).then(response=>{ 
             const result = response.data;
             const groupResult = mapValues(groupBy(result, 'OfferType'), fList => fList.map(items => omit(items, 'OfferType')));
             let groupValues = {};
             Object.assign(groupValues, groupResult);
             let finalResults = [];
             //----------------------------------------------------------------------------  
             for (let item in groupValues) {
                 const obj = { heading: '', items: [] };
                 let title = '';
                 const  results =  groupValues[item].map(item=> item[`Type${trans("model:description")}`]);
                 if(results?.length > 0){
                     title = results[0];
                 }
                 obj.heading = title;
                 obj.items = groupValues[item];
                 finalResults.push(obj);
             } 
             setOffers(finalResults);
           }).catch(error=>{
               console.log(error);
           })
         }
        } catch (error) {
            console.log(error);
        }
    };

    return (
         <SectionHeading
         hasRuler = {false}
         title = {trans("global:my_reservations")}
         >
         <div className="my-8">
         {
            offers.map((parent,index)=>{
              return (
                <>
                <h2 className="text-30 text-bold my-6">{parent.heading}</h2>
                <Swiper
                key={index}
                spaceBetween={0}
                slidesPerView={2}
                breakpoints={{
                    576: {
                        slidesPerView: 4,
                    },
                }}
                className={"mb-16"}
                resizeObserver={true}
                watchSlidesVisibility
                watchSlidesProgress
                autoplay={false}
                loop={false}
                observer={true}
            >
                          {
                              parent.items?.map((item, index) => {
                                  return (
                                      <SwiperSlide
                                       className={"px-3"}
                                        key={index}>
                                          <Offer 
                                          endDate={item.EndDate}
                                          startDate={item.StartDate}
                                          totalDays={item.TotalDays}
                                          slug={slugCombine(item.slugAr,item.slugEn)}
                                          img={item.Image}
                                          excerpt={item[`Sub${trans("model:description")}`]}
                                          name={item[`Title${trans("model:description")}`]} 
                                          type={item.OfferType} />
                                      </SwiperSlide>
                                  )
                              })
                          }

                </Swiper>
                </>
   
              )
            })
         }
         </div>
         </SectionHeading>
    )
}