import { Fragment, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import Banner from "../../components/banner";
import Breadcrumb from "../../components/__simple/breadcrumb";
import { toast } from 'react-toastify';
import axios from "axios";
import { DropDownInput } from "../../components/_input/_dropdown";
import { FormButton } from "../../components/_input/_button";
import Popup from "../../User/popup";
import { Compare } from '../compare/comp/compare';
import config from '../../configuration/config.json';
import { useCompare } from "../../utils/compareProvider";
import dropDownResetter from "../../utils/resetDropDown";

export function ComparePage() {
    const { t: trans } = useTranslation(["model", "compare", "inputs", "form", "common"]);
    let lang = localStorage.getItem("i18nextLng");
    const languageDir = 'language-' + lang;
    const [showPopup, setShowPopup] = useState(false);
    const [brands, setBrands] = useState([]);
    const [products, setProducts] = useState([]);
    const [productGroups, setProductGroups] = useState([]);
    const [banner, setbanner] = useState([]);
    const [fProductGroups, setFProductGroups] = useState([]);
    const [fProducts, setFProduct] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedValues, setSelectedValues] = useState({
        brand: '',
        group: '',
        product: ''
    })
    //-----------------------------------------------------
    const popRef = useRef(null);

    //-----------------------------------------------------
    useEffect(() => {
        RequsetBanner();
    }, []);

    const { properities, formatProperities, onDeleteItem, onInsert } = useCompare(null);
    useEffect(() => {
        getAllBrands();
        getAllGroups();
        getAllProducts();
    }, []);

    useEffect(() => {
        function handleClickOutside(event) {
            if (popRef.current && !popRef.current.contains(event.target) && showPopup === true) {
                setShowPopup(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showPopup]);
    const RequsetBanner = async () => {
        // const Body = {
        //     "object": "sitePageBanners",
        //     "ObjectSettings": {
        //         "MetaData": false
        //     },
        //     "filters": "where bannerId=6"
        // };
        // axios.request({
        //     url: config.baseURL + 'details',
        //     data: Body, method: "POST", responseType: "json"
        // })
        // .then(function (response) {
        // setbanner(response.data.ApiObjectData);
        //  })
        // .catch(function (error) {
        // console.log(error);
        // });

        try {
            const response = await axios.request({
                baseURL: config.controllURL,
                url: lang + "/api/sections/Compare",
                method: "get",
            });

            setbanner(response.data.banner);

        } catch (error) {
            console.log(error);
        }

    }
    const getAllBrands = () => {
        axios.post(
            config.baseURL + "details",
            {
                "object": "InvBrands",
                "ObjectSettings": {
                    "MetaData": false
                }
                , "option": "column",
                "Fields": "BrandID,DescriptionAr,DescriptionEn",
                "filters": " where IsEnabled =1"
            }
        ).then(response => {
            const result = response.data.ApiObjectData;
            setBrands(result.map(brand => {
                return {
                    value: brand.BrandID, text: brand[`${trans("model:description")}`]
                }
            }));
        }).catch(err => {
            console.log(err);
        });
    };
    const getAllGroups = () => {
        // axios.post(
        //     config.baseURL + "details",
        //     {
        //         "object": "InvProductGroups",
        //         "ObjectSettings": {
        //             "MetaData": false,
        //         },
        //         "Option": "Column",
        //         "Fields": "DescriptionAr,DescriptionEn,ProductGroupID,BrandID",
        //         
        //     }
        // ).then(response => {
        //     const result = response.data.ApiObjectData;
        //     setProductGroups(result);
        // }).catch(err => {
        //     console.log(err);
        // });


        const Body = {
            "name": "SiteFetchAvailableGroupsOnSite"
        }
        axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {
                setProductGroups(response.data);



            })
            .catch(function (error) {
                console.log(error);
            });

    };
    const getAllProducts = () => {
        axios.post(
            config.baseURL + "func",
            {
                "Name": "SiteFetchAvailableCarsOnSite",
            }
        ).then(response => {
            const result = response.data;
            setProducts(result);
        }).catch(err => {
            console.log(err);
        });




    };
    const filterModel = (option) => {
        const currentSelected = { ...selectedValues };
        currentSelected.brand = option.value;
        setSelectedValues(currentSelected);

        if (option.value == '' || option.value == null) {
            dropDownResetter(() => {
                setFProductGroups([]);
                setFProduct([]);
            });
            return;
        }
        const fGroups = productGroups.filter(a => a.BrandID == option.value);
        setFProductGroups(fGroups.map(group => {
            return {
                value: group.ProductGroupID, text: group[`${trans("model:description")}`]
            }
        }));
    };
    const filterProductsByGroup = (option) => {
        const currentSelected = { ...selectedValues };
        currentSelected.group = option.value;
        setSelectedValues(currentSelected);

        if (option.value == '' || option.value == null) {
            dropDownResetter(() => {
                setFProduct([]);
            });
            return;
        }
        const filteredProducts = products.filter(e => e.ProductGroupID == option.value);
        setFProduct(filteredProducts.map(product => {
            return { value: product.ProductID, text: product[`${trans("model:description")}`] }
        }));
    };
    const handleOnAdd = (value) => {
        setShowPopup(true);
    };
    const handleOnDelete = (value, id) => {
        onDeleteItem(value, id);
    };
    const handleOnAddFromPopup = () => {
        if (selectedProduct == '' || selectedProduct == null) {
            toast.error(trans('compare:compare_not_product_selected_error'));
            return;
        }
        onInsert(selectedProduct);
        setShowPopup(false);
    };

    //-----------------------------------------------
    return (
        <Fragment>
            {/* 
            {banner.map(BN => {

                if (lang === "en") {
                    return (<Banner
                        background={"data:image/png;base64," + BN.image}
                        title={BN.titleEn}
                        subtitle={BN.subTitleEn}
                        desc={BN.excerptEn}
                        backOpasity={BN?.backOpasity}
                    />);
                } else {
                    return (<Banner
                        background={"data:image/png;base64," + BN.image}
                        title={BN.titleAr}
                        subtitle={BN.subTitleAr}
                        desc={BN.excerptAr}
                        backOpasity={BN?.backOpasity}
                    />);
                }
            })} */}

            <Banner
                subtitle={banner?.subtitle}
                title={banner?.title}
                desc={banner?.excerpt}
                background={banner?.imagePath}
                backOpasity={banner?.backOpasity}
            />

            <div className="container">
                <Breadcrumb className={"mt-16 "}
                    links={[

                        {
                            name: trans("common:compare")
                        }
                    ]}
                />
                <Compare
                    headerItems={properities}
                    bodyItems={formatProperities}
                    onAdd={handleOnAdd}
                    onDelete={handleOnDelete}
                />
            </div>
            <Popup show={showPopup}>
                <div ref={popRef} className={"compare-model"}>
                    <h3 className={"text-16 font-bold"}>{trans('compare:compare_model_info')}</h3>
                    <DropDownInput
                        title={trans("compare:compare_brand")}
                        className={"flex-grow-1 w-full"}
                        isSecondary={true}
                        onfilter={filterModel}
                        options={[...brands]}
                    />
                    <DropDownInput
                        title={trans("compare:compare_vehicle")}
                        selectedValue={selectedValues.brand}
                        className={"flex-grow-1 w-full"}
                        isSecondary={true}
                        onfilter={filterProductsByGroup}
                        options={[...fProductGroups]}


                    />
                    <DropDownInput
                        title={trans('compare:compare_model')}
                        selectedValue={`${selectedValues.brand}${selectedValues.group}`}
                        className={"flex-grow-1 w-full"}
                        isSecondary={true}
                        options={[...fProducts]}
                        onSelect={(value) => {
                            setSelectedProduct(value);
                        }}
                    />
                    <FormButton name={trans("form:add")} onClick={handleOnAddFromPopup} />
                </div>
            </Popup>
        </Fragment>
    );
}
