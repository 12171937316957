import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Price } from "./__simple/_price";
import ConfirmContext from "../utils/confirmContext";
import { useContext, useEffect, useState } from "react";
import { slugCombine } from "../utils/slugManager";
import { useCompare } from "../utils/compareProvider";
import config from "../configuration/config.json"
import axios from "axios";
function HeaderLandengPage({ Titel, SubTitel, Desc, BtnText, BackOpicity, backImage, viewSocialIcons }) {

    const { t: trans, i18n } = useTranslation(["car", "common", "vehicales", "info"]);
    const languageDir = 'language-' + localStorage.getItem("i18nextLng");

    const lang = i18n.language;
    const context = useContext(ConfirmContext);
    const { onInsert } = useCompare(null);

    // the following function is used to add the card to compare list
    const handleAddToCompare = (value) => {
        onInsert(value);
    };

    const [socials, setSocials] = useState([]);

    useEffect(async () => {
        try {
            const social = await axios.request({
                baseURL: config.controllURL,
                url: lang + "/api/contact/social",
                method: "get",

            })
            setSocials(social.data);
        } catch (error) {
        }

    }, []);
    return (
        <div className=" mb-8 bg-page headerLandeng-car-container p-8 box-shadow-large">
            <div className="headerLandeng-overlay" style={BackOpicity ? { background: "linear-gradient(to " + (lang == "ar" ? "left" : "right") + " , #090d0b 0%, rgba(26, 28, 27, 0.76) " + BackOpicity + "%, rgba(42, 42, 42, 0.13) 100%)" } : {}}>

            </div>
            <div className="headerLandeng-bg"><img src={"data:image/png;base64," + backImage} alt="" /></div>

            <div className="headerLandeng-info  mb-8 pb-8" style={{ height: 100 + "%" }}>

                {
                    viewSocialIcons &&
                    <div className="headerLandeng-info-extra flex-grow-1 ">

                        <div className={"headerLandeng-info-extra-social-links mr-8"}>
                            {
                                socials.map((social) => {
                                    return (
                                        <a target="_blank" rel="noopener" href={social.content} aria-label={social.name}
                                            key={social.name}
                                            className="social-box">
                                            <img src={"data:image/png;base64," + social.imagePath} className={"object-full object-contain"}
                                                alt={social.name} />
                                        </a>
                                    );
                                })
                            }

                        </div>
                    </div>

                }


                <div className={"headerLandeng-info-desc  " + languageDir}>

                    <div className={"headerLandeng-info-text mx-8 lex-grow-2  mx-3 " + languageDir}>
                        <div className={"text-section"}>
                            <span className={" text-line-clamp text-line-clamp-1 text-40 "}>
                                {SubTitel ? SubTitel : " "}
                            </span>
                            <h2 className={" text-line-clamp text-line-clamp-2"}>

                                {Titel ? Titel : ""}
                            </h2>
                            <p className={" text-line-clamp text-line-clamp-5 text-30 text-gray"}>
                                {Desc ? Desc : ""}
                            </p>
                            { }
                        </div>
                        { }
                        <div className={"headerLandeng-info-text-btn my-10"}>

                            {

                                BtnText &&
                                <button
                                    onClick={() => {
                                        // const section = document.querySelector('#search-car');
                                        // // section.scrollIntoView({ behavior: 'smooth', block: 'start' });

                                        var top = document.getElementById( 'search-car' ).offsetTop - 30;

                                        console.log("top>>>>>>>>>>>>>>>>",top);
                                        // window.scrollTo(0, top);
                                        window.scrollTo({
                                            top: top,
                                            left: 0,
                                            behavior: "smooth",
                                          });

                                    }}
                                    className={"bg-gray text-24  py-5"}>{BtnText}</button>
                            }

                        </div>



                    </div>


                </div>









            </div>

        </div>
    );


}

export default HeaderLandengPage;