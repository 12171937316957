import {useEffect , useState } from 'react';
import ErrorLabel from '../../components/error';
export function PhoneInput({name,title,enabled , onChangeValue ,errorLabel,defaultValue,prefix = '+966', length = 9}){
 const [phonevalue,setPhoneValue] = useState();
 let lang = localStorage.getItem("i18nextLng");
 const languageDir = "language-" + lang;
useEffect(()=>{
if(defaultValue!=null&&defaultValue!='')
setPhoneValue(defaultValue);
else
setPhoneValue(prefix);
},[defaultValue]);

function handleChange(event){
    let result = (event.target.validity.valid) ? event.target.value : phonevalue;
    if(!phonevalue.startsWith(prefix))
        result = prefix + phonevalue;
    setPhoneValue(result);
}


function handleUnfocus(){
 if(!phonevalue.startsWith(prefix)) 
  setPhoneValue(prefix);
}

return(
    <div className={"input " + languageDir }>
    <label className={"input-label"} htmlFor={name}>{title}</label>
     <ErrorLabel errorTitle={errorLabel}/>
     <input disabled = {enabled} style={{textAlign:'left', direction:'ltr'}} pattern="\+966[0-9]*" onBlur={(e)=>{handleUnfocus();onChangeValue(phonevalue);}}  maxLength={length + 4}  onInput={(e)=>{ handleChange(e);onChangeValue(phonevalue)}} value ={phonevalue} name={name} className={"input-field bg-white"} type="text"/>
    </div>
);
}
