import { useState } from "react";
import { SearchHeader, SearchResultItem } from "./component/search";
import { SearchResultContainer } from "./component/searchResultContainer";
import config from '../../configuration/config.json';
import axios from 'axios';
import { useTranslation } from "react-i18next";
import { groupBy, includes, mapValues, omit, orderBy } from "lodash";
export const Search = () => {
    let lang = localStorage.getItem("i18nextLng");
    const languageDir = 'language-' + lang;
    const { t: trans } = useTranslation("model", "info");
    const [searchText, setSearchText] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [supportedFilters, setSupportedFilters] = useState(false);
    const [groupsResult, setGroupResult] = useState([]);

    const handleOnSearchClick = (data) => {
        if(searchText?.length == 0){
         setSearchResults(0);
         setGroupResult([]);
        return;
         }
        axios.post(
            config.baseURL + 'func',
            {
                "name": 'GetSearchInfo',
                "values": {
                    "search": `%${searchText}%`
                }
            }
        ).then(response => {
            const responseData = response.data ?? [];
            // filter result as sections
            const { sort, showOptions } = { ...data };
            const tempGroupValues = mapValues(groupBy(responseData, 'Type'), fList => orderBy(fList.map(items => omit(items, 'Type')), ['TitleAr', 'TitleEn'], [sort]));
            let groupValues = {};
            Object.assign(groupValues, tempGroupValues);
            if (showOptions?.length > 0) {
                for (let index = 0; index < Object.keys(tempGroupValues).length; index++) {
                    const key = Object.keys(tempGroupValues)[index];
                    if (!includes(showOptions, parseInt(key))) {
                        delete groupValues[key];
                    }
                }
            }
            let finalResults = [];
            console.log(finalResults);
            //----------------------------------------------------------------------------  
            for (let item in groupValues) {
                const obj = { heading: '', items: [] };
                let title = '';
                switch (parseInt(item)) {
                    case 1:
                        title = trans("global:vehicle");
                        break;
                    case 2:
                        title = trans("global:spare_parts");
                        break;
                    case 3:
                        title = trans("global:news");
                        break;
                    case 4:
                        title = trans("global:jobs");
                        break;
                    case 5:
                        title = trans("global:maintenance_services");
                        break;
                    case 6:
                        title = trans("global:offers");
                        break;
                    case 7:
                        title = trans("global:campaign");
                        break;
                    default:
                        break;
                }
                obj.heading = title;
                obj.items = groupValues[item];
                finalResults.push(obj);
            }
            setSearchResults(responseData);
            setGroupResult(finalResults);
        }).catch(error => {
            console.log('Error once fetching data', error);
            setSearchResults([]);
        });
    };

    return <div className={"container " + languageDir}>

        <SearchHeader onSearch={(value) => {
            setSearchText(value);
        }}
            onSupportFilter={(state) => {
                setSupportedFilters(state);
            }}
            hasFilters={supportedFilters}
            onSearchClick={(data) => { handleOnSearchClick(data) }}
            resultNumber={searchResults?.length}
        />
        <div className="ruler-2 my-10"></div>
        <SearchResultContainer>
            {
                groupsResult.map((item, index) => {
                    return (
                        <div>
                            <h2 className="text-30 text-bold my-4">{item.heading}</h2>
                            {
                                item.items.map(element => {
                                    return (
                                             <SearchResultItem
                                                key={index}
                                                type={Number(element?.SectionType)}
                                                description={element[`${trans("model:description")}`]}
                                                target={element?.GUID}
                                                title={element[`Title${trans("info:language")}`]}
                                                image={element?.Image}
                                                extra={element?.ExtraData}
                                            />
                                        )})
                            }
                        </div>
                    )
                })
            }
        </SearchResultContainer>
    </div>
}
