import {Link} from "react-router-dom";

export function NavCarCard({img, name, link,Year, ...prop}) {
    return (
        <Link {...prop} to={link} onClick={prop.onClick}>
            <div className={"car"}>
                <span className={"text-20 "}>{name}</span>
                <img src={img} alt={name} onError = {e => e.target.style.display = 'none'}/>
            </div>
        </Link>
    );
}