import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { InputField } from "../components/_input/_input";
import { FormButton } from "../components/_input/_button";
import PhoneDropDown from '../components/_input/_phoneDropDown';
import { CheckInput } from "../components/_input/_checkInput";
import { FileInput } from "../components/_input/_fileInput";
import { useEffect, useState } from "react";
import axios from "axios";
import config from '../configuration/config.json';
import { DropDownInput } from "../components/_input/_dropdown";
import crypto from 'crypto-js';
import Joi from "joi";
import { GetMessages, formValidate } from '../utils/validation';
import { getData, saveData, User_Data_Key } from "../utils/localStorageManager";
import { toast } from "react-toastify";
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import SectionHeading from "../components/sectionHeading";
import { includes } from "lodash";
import { useUserFavorite } from "./hooks/useUserFavorite";
export default function SignUp({ onTabChange, onLogin }) {
    let lang = localStorage.getItem("i18nextLng");
    const { init } = useUserFavorite();
    const languageDir = 'language-' + lang;
    const { t: trans } = useTranslation(["common", "form", "inputs", "model", "error", "info", "hints", "error2", "new"]);
    const tabsList = [{
        tab: 1, title: 'sign-in'
    }, {
        tab: 2, title: 'sign-up'
    }];
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [steps, setSteps] = useState(0);
    const [requestForm, setRequestForm] = useState({
        arFirstName: '',
        arMiddleName: '',
        arLastName: '',
        enFirstName: '',
        enMiddleName: '',
        enLastName: '',
        validation: '',
        email: '',
        phoneNumber: '',
        genderID: '',
        password: '',
        identity: '',
        accountType: '',
        commercialIdentity: '',
        commercialIdentityImage: null,
        identityImage: null,
        address: '',
        confirmPassword: ''
    });
    const schema = Joi.object({
        arFirstName: Joi.string().max(50).required().messages(GetMessages()),
        arMiddleName: Joi.string().max(50).required().messages(GetMessages()),
        arLastName: Joi.string().max(50).required().messages(GetMessages()),
        enFirstName: Joi.string().max(50).required().messages(GetMessages()),
        enMiddleName: Joi.string().max(50).required().messages(GetMessages()),
        enLastName: Joi.string().max(50).required().messages(GetMessages()),
        email: Joi.string().email({ minDomainSegments: 2, tlds: { allow: false } }).required().messages(GetMessages()),
        phoneNumber: Joi.string().min(13).required().messages(GetMessages()),
        accountType: Joi.string().required().messages(GetMessages()),
        commercialIdentity: Joi.string().length(10).required().messages(GetMessages()),
        validation: Joi.string().required().messages(GetMessages()),
        password: Joi.string().min(8).max(20).alphanum().required().messages(GetMessages()),
        confirmPassword: Joi.string().valid(Joi.ref('password')).required().messages(GetMessages())
    });
    const [accountTypes, setAccountTypes] = useState([]);
    const [error, setError] = useState({});
    const [selectedPrivacy, setSelectedPrivacy] = useState(false);
    const [confirmValidation, setConfirmValidation] = useState(false);
    const [codeValidation, setCodeValidation] = useState('');
    const [signUpObject, setSignUpObject] = useState({});
    useEffect(() => {
        axios.post(
            config.baseURL + 'func',
            {
                "name": "SiteGetUserAccountTypes"
            }
        ).then(response => {
            console.log("accountType", response.data);
            setAccountTypes(response.data);

        }).catch(err => {
            console.log(err);
        })
    }, []);

    const [isClientAccount, setIsClientAccount] = useState(null);
    const submitRequest = async () => {
        const result = formValidate(schema, requestForm) ?? {};
        setError(result);
        try {
            if (Object.keys(result).length === 0) {
                if (selectedPrivacy) {
                    const md5Password = crypto.MD5(requestForm.password);
                    axios.post(
                        config.controllURL + "api/Administration/" + "SignUp",
                        {
                            "ArFirstName": requestForm.arFirstName.trim(),
                            "ArMiddlename": requestForm.arMiddleName,
                            "ArLastname": requestForm.arLastName.trim(),
                            "EnFirstName": requestForm.enFirstName.trim(),
                            "EnMiddlename": requestForm.enMiddleName.trim(),
                            "EnLastname": requestForm.enLastName.trim(),
                            "Email": requestForm.email,
                            "Phone": requestForm.phoneNumber,
                            "GenderID": requestForm.genderID != '' ? Number(requestForm.genderID) : null,
                            "Password": md5Password.toString(),
                            "Identity": requestForm?.identity ?? null,
                            "Address": requestForm.address,
                            "IdentityImage": requestForm.identityImage,
                            "ConfirmPassword": md5Password.toString()
                        }
                    ).then(response => {
                        if (response.data) {
                            setError({});
                            const { genderID } = { ...requestForm };
                            setRequestForm({
                                arFirstName: '',
                                arMiddleName: '',
                                arLastName: '',
                                enFirstName: '',
                                enMiddleName: '',
                                enLastName: '',
                                email: '',
                                phoneNumber: '',
                                genderID: '',
                                password: '',
                                identity: '',
                                identityImage: null,
                                address: '',
                                confirmPassword: ''
                            });
                            toast.success(trans("common:account-created-successfully"));
                            onTabChange(tabsList[0]);
                        }
                    }).catch(error => {
                        if (error.response && error.response.status === 400) {
                            toast.error(error.response.data[`${trans("model:description3")}`]);
                        }
                    });
                } else {
                    toast.error(trans("common:not-agree"));
                }
            }
        } catch (error) {
            console.log(error);
        }
    };
    const [shortRequestForm, setShortRequestForm] = useState({
        phoneNumber: '',
        password: '',
        identity: '',
        confirmPassword: ''
    });
    const onSuccess = (type, res) => {
        switch (type.toLocaleLowerCase()) {
            case 'google':
                const { givenName, familyName, email } = res.profileObj;
                axios.post(
                    config.controllURL + "api/Administration/OAuthSignUp",
                    {
                        "FirstName": givenName,
                        "LastName": familyName,
                        "Email": email,
                        "AccountType": type,
                    }
                ).then((response) => {
                    saveData(User_Data_Key, response.data, true);
                    saveData('isAuthenticated', true, false);
                    saveData('secretKey', response.data.id.toString().split('_')[0], false);
                    // history.push('/');
                    // window.location.reload();
                    if (onLogin)
                        onLogin();
                    init();
                }).catch((err) => {
                    if (err.response && err.response.status === 400) {
                        toast.error(err.response.data[`${trans("model:description3")}`]);
                    }
                });
                break;

            case 'facebook':
                try {

                    const { name, email: em } = res;
                    const fullNames = name.split(' ');
                    const firstName = fullNames[0];
                    const lastName = fullNames[1];
                    axios.post(
                        config.controllURL + "api/Administration/OAuthSignUp",
                        {
                            "FirstName": firstName,
                            "LastName": lastName,
                            "Email": em,
                            "AccountType": type,
                        }
                    ).then((response) => {
                        saveData(User_Data_Key, response.data, true);
                        saveData('isAuthenticated', true, false);
                        saveData('secretKey', response.data.id.toString().split('_')[0], false);
                        // history.push('/');
                        // window.location.reload();
                        if (onLogin)
                            onLogin();
                        init();
                    }).catch((err) => {
                        if (err.response && err.response.status === 400) {
                            toast.error(err.response.data[`${trans("model:description3")}`]);
                        }
                    });

                } catch (err) {
                    console.log(err);
                }
                break;
        }
    };
    const onFailure = (type, res) => {
        console.log(type);
    };
    const [signUpClicked, setSignUpClicked] = useState({
        clicked: false,
        index: null
    });
    const handleSaveObject = (obj, index) => {
        setSignUpObject(obj);
        setSignUpClicked({
            clicked: true,
            index: index
        });
    };
    const schema2 = Joi.object({
        phoneNumber: Joi.string().length(13).required().messages(GetMessages()),
        identity: Joi.string().min(8).required().messages(GetMessages()),
        password: Joi.string().min(8).max(20).alphanum().required().messages(GetMessages()),
        confirmPassword: Joi.string().valid(Joi.ref('password')).required().messages(GetMessages())
    });
    const externalSubmitRequest = () => {
        const result = formValidate(schema2, shortRequestForm) ?? {};
        setError(result);
        setIsLoading(true);
        if (Object.keys(result).length == 0) {
            axios.post(
                config.baseURL + "details",
                {
                    "Object": "Web_users",
                    "ObjectSettings": {
                        "MetaData": false
                    },
                    "Option": "Column",
                    "Fields": "GUID",
                    "Filters": `where IdentityNumber = '${shortRequestForm.identity}' or phone = '${shortRequestForm.phoneNumber}'`
                }
            ).then((response) => {
                if (response.data.ApiObjectData.length == 0) {
                    setSignUpClicked({
                        index: 0,
                        clicked: false
                    });
                    setError({});
                    localStorage.setItem('externalInfo', JSON.stringify(shortRequestForm));
                    setShortRequestForm({});
                    signUpObject.onClick();
                } else {
                    toast.error(trans("common:existed-data"));
                }
            }).catch((err) => {
                toast.error(trans("error:unknown_failure"));
            });
        }
        setIsLoading(false);
    };
    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    const handleChangeImage = async (event) => {
        const files = event.target.files;
        const val = { ...requestForm };
        if (files.length > 0) {
            let file = files[0];
            const size = file.size;
            const finalCheck = Math.round((size / 1024));
            if (finalCheck >= 4096) {
                toast.error(trans('new:file_size_error'));
                return;
            }
            const fileBase64 = await toBase64(file);
            const finalFile = fileBase64.substr(fileBase64.indexOf(',') + 1, fileBase64.length);
            val.identityImage = finalFile;
        } else {
            val.identityImage = null;
        }
        setRequestForm(val);
    };
    const handleChangeImage2 = async (event) => {
        const files = event.target.files;
        const val = { ...requestForm };
        if (files.length > 0) {
            let file = files[0];
            const size = file.size;
            const finalCheck = Math.round((size / 1024));
            if (finalCheck >= 4096) {
                toast.error(trans('new:file_size_error'));
                return;
            }
            const fileBase64 = await toBase64(file);
            const finalFile = fileBase64.substr(fileBase64.indexOf(',') + 1, fileBase64.length);
            val.commercialIdentityImage = finalFile;
        } else {
            val.commercialIdentityImage = null;
        }
        setRequestForm(val);
    };
    const removeUnspecificError = (errorList, values = []) => {
        let errorResult = {};
        const lowerValues = values.map(item => {
            return item.toLowerCase()
        });
        Object.assign(errorResult, errorList);
        if (errorList) {
            for (let index = 0; index < Object.keys(errorList).length; index++) {
                const key = Object.keys(errorList)[index];
                if (includes(lowerValues, key.toLowerCase())) {
                    continue;
                } else {
                    delete errorResult[key];
                }
            }
        }
        return errorResult;
    };
    const handleNextStep = async () => {
        try {
            const result = formValidate(schema, requestForm) ?? {};
            let specifics;
            switch (steps) {
                case 0:
                    specifics = removeUnspecificError(result, ['phoneNumber']);
                    setError(specifics);
                    var codeValid = false;
                    if (Object.keys(specifics).length === 0) {
                        axios.post(
                            config.controllURL + "api/Administration/SendFreeSms",
                            {
                                phoneNumber: requestForm.phoneNumber
                            }
                        ).then(response => {


                            if (response.data) {
                                setError({});
                                saveData("validationCode", response.data, true)
                                setConfirmValidation(true);
                                codeValid = true;

                                console.log("after validate codeWWW ");
                                setSteps(1);
                            }
                            if (codeValid) {

                                //     console.log("codeValid");
                                //     axios.post(
                                //      config.baseURL + 'func',
                                //     {
                                //           "Name": "Site_SendSmsCodeFormAppFunction",
                                //           "Values": {
                                //           "to": requestForm.phoneNumber
                                //             }
                                //     }
                                // ).then(response1 => {
                                //     if (response1.data) {
                                //         setError({});
                                //         console.log("after validate code ",response1.data[0].Code);
                                //         saveData("validationCode", response1.data[0].Code, true)
                                //         setSteps(1);
                                //     }
                                // }).catch(err => {
                                //     if (err.response1 && err.response1.status === 400) {
                                //         toast.error(err.response1.data[`${trans('model:description3')}`]);
                                //     }
                                //     console.log(err);
                                // })
                                console.log("after validate phoneNumber error");
                            }
                        }).catch(err => {
                            if (err.response && err.response.status === 400) {
                                toast.error(err.response.data[`${trans('model:description3')}`]);
                            }
                            console.log(err);
                        })




                    }
                    break;

                case 1:
                    specifics = removeUnspecificError(result, ['validation']);
                    setError(specifics);

                    if (Object.keys(specifics).length == 0) {

                        var cookies = null;
                        try {

                            cookies = getData("validationCode", true);
                        } catch (err) {
                            console.log(err);
                        }

                        let cookieValue = cookies.replaceAll("\"", "");

                        if (cookieValue == null) {
                            toast.error(trans("error2:validation_code_finish_time_error"));
                            setSteps(0);
                            setError({});
                        } else if (cookieValue == requestForm.validation) {
                            setSteps(2);
                        } else {
                            setSteps(1);
                            toast.error(trans("error2:validation_error"));
                        }

                    }
                    break;

                case 2:
                    let otherProperities = [];
                    const val = { ...requestForm };
                    if (isClientAccount == null) {
                        otherProperities = ['accountType'];
                    } else if (isClientAccount) {
                        val.commercialIdentity = '';
                        val.commercialIdentityImage = null;
                        otherProperities = ['accountType', 'identity'];
                    } else {
                        otherProperities = ['accountType', 'commercialIdentity'];
                        val.identity = '';
                        val.identityImage = null;
                    }
                    setRequestForm(val);
                    specifics = removeUnspecificError(result, [`${lang}FirstName`, `${lang}MiddleName`, `${lang}LastName`, ...otherProperities]);
                    setError(specifics);
                    if (Object.keys(specifics).length == 0) {
                        if (selectedPrivacy) {
                            axios.post(
                                config.controllURL + "api/Administration/" + "SignUp",
                                {
                                    "ArFirstName": requestForm.arFirstName ?? null,
                                    "ArMiddlename": requestForm.arMiddleName ?? null,
                                    "ArLastname": requestForm.arLastName ?? null,
                                    "EnFirstName": requestForm.enFirstName ?? null,
                                    "EnMiddlename": requestForm.enMiddleName ?? null,
                                    "EnLastname": requestForm.enLastName ?? null,
                                    "Phone": requestForm.phoneNumber,
                                    "Identity": requestForm.identity,
                                    "IdentityImage": requestForm.identityImage,
                                    "TradeNo": requestForm.commercialIdentity,
                                    "TradeNoImage": requestForm.commercialIdentityImage,
                                    "CustGroupID": requestForm.accountType,
                                    "IsClient": isClientAccount
                                }
                            ).then(response => {
                                if (response.data) {
                                    setError({});
                                    setRequestForm({
                                        arFirstName: '',
                                        arMiddleName: '',
                                        arLastName: '',
                                        enFirstName: '',
                                        enMiddleName: '',
                                        enLastName: '',
                                        phoneNumber: '',
                                        validation: '',
                                        identity: '',
                                        identityImage: null,
                                        commercialIdentity: '',
                                        commercialIdentityImage: null
                                    });
                                    toast.success(trans("common:account-created-successfully"));
                                    onTabChange(tabsList[0]);
                                }
                            }).catch(error => {
                                if (error.response && error.response.status === 400) {
                                    toast.error(error.response.data[`${trans("model:description3")}`]);
                                }
                            });
                        } else {
                            toast.error(trans("common:not-agree"));
                        }
                    }
                    break;
            }
        } catch (err) {
            console.log(err);
        }
    };

    const getCookie = async (cname) => {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    const getCorrectSection = () => {
        switch (steps) {
            case 0:
                return (
                    <div className="my-5">
                        <PhoneDropDown
                            errorLabel={error?.phoneNumber}
                            onChangeValue={(countryCode, phoneNumber) => {
                                const val = { ...requestForm };
                                val.phoneNumber = `+${countryCode}${phoneNumber}`;
                                setRequestForm(val);
                            }}
                        />
                    </div>
                );
            case 1:
                return (
                    <InputField
                        errorLabel={error?.validation}
                        value={requestForm.validation}
                        onChangeValue={(e) => {
                            const val = { ...requestForm };
                            val.validation = e.target.value;
                            setRequestForm(val);
                        }}
                        placeholder={trans("form:validation-key")}
                    />
                );
            case 2:
                let components;
                if (lang === 'ar') {
                    components = (
                        <>

                            <InputField
                                name='arFirstName'
                                value={requestForm.arFirstName}
                                errorLabel={error?.arFirstName}
                                onChangeValue={(e) => {
                                    const val = { ...requestForm };
                                    val.arFirstName = e.target.value;
                                    setRequestForm(val);
                                }}
                                placeholder={trans("form:first-name")}
                            />
                            <InputField
                                name='arMiddleName'
                                errorLabel={error?.arMiddleName}
                                value={requestForm.arMiddleName}
                                onChangeValue={(e) => {
                                    const val = { ...requestForm };
                                    val.arMiddleName = e.target.value;
                                    setRequestForm(val);
                                }}
                                placeholder={trans("info:middle-name")}
                            />
                            <InputField
                                name='arLastName'
                                value={requestForm.arLastName}
                                onChangeValue={(e) => {
                                    const val = { ...requestForm };
                                    val.arLastName = e.target.value;
                                    setRequestForm(val);
                                }}
                                errorLabel={error?.arLastName}
                                placeholder={trans("info:surname")}
                            />


                        </>
                    )
                }
                else {
                    components = (
                        <>
                            <InputField
                                name='enFirstName'
                                value={requestForm.enFirstName}
                                onChangeValue={(e) => {
                                    const val = { ...requestForm };
                                    val.enFirstName = e.target.value;
                                    setRequestForm(val);
                                }}
                                errorLabel={error?.enFirstName}
                                placeholder={trans("form:first-name")}
                            />
                            <InputField
                                name='enMiddleName'
                                value={requestForm.enMiddleName}
                                onChangeValue={(e) => {
                                    const val = { ...requestForm };
                                    val.enMiddleName = e.target.value;
                                    setRequestForm(val);
                                }}
                                errorLabel={error?.enMiddleName}
                                placeholder={trans("info:middle-name")}
                            />
                            <InputField
                                name='enLastName'
                                value={requestForm.enLastName}
                                onChangeValue={(e) => {
                                    const val = { ...requestForm };
                                    val.enLastName = e.target.value;
                                    setRequestForm(val);
                                }}
                                errorLabel={error?.enLastName}
                                placeholder={trans("info:surname")}
                            />
                        </>
                    )
                }

                return (
                    <div className="flex flex-col nice-gap-3rem">
                        <SectionHeading
                            title={trans("info:account_data")}
                            hasRuler={false}
                        >

                            {
                                components
                            }
                            <DropDownInput
                                title={trans("info:account_type")}
                                placeholder={trans("info:account_type")}
                                errorLabel={error?.accountType}
                                options={[
                                    ...accountTypes.map(item => {
                                        if (lang === 'ar') {
                                            return { text: item.DescriptionAr, value: item.ID, isClient: item.NeedIdentity }
                                        } else {
                                            return { text: item.DescriptionEn, value: item.ID, isClient: item.NeedIdentity }
                                        }
                                    })
                                ]}
                                onfilter={(item) => {
                                    if (item.isClient == undefined) {
                                        setIsClientAccount(null);
                                        return;
                                    }
                                    setIsClientAccount(item.isClient);
                                }}
                                onSelect={(value => {
                                    const val = { ...requestForm };
                                    val.accountType = value;
                                    setRequestForm(val);
                                })}
                            />
                            {isClientAccount != null && isClientAccount == true ?
                                <>
                                    <InputField
                                        length={10}
                                        name='identity'
                                        value={requestForm?.identity}
                                        onChangeValue={(e) => {
                                            const val = { ...requestForm };
                                            val.identity = e.target.value;
                                            setRequestForm(val);
                                        }}
                                        placeholder={trans("form:identity-no")}
                                    />
                                    <FileInput
                                        title={trans("info:identity_image") + ' ' + trans("common:optional")}
                                        mimeType="image/*"
                                        onPicker={async (file) => {
                                            await handleChangeImage(file);
                                        }}
                                    />
                                </>
                                : isClientAccount != null && isClientAccount == false ? <>
                                    <InputField
                                        length={14}
                                        name='identity'
                                        value={requestForm?.commercialIdentity}
                                        onChangeValue={(e) => {
                                            const val = { ...requestForm };
                                            val.commercialIdentity = e.target.value;
                                            setRequestForm(val);
                                        }}
                                        placeholder={trans("info:trade_no")}
                                    />
                                    <FileInput
                                        title={trans("info:trade_image") + ' ' + trans("common:optional")}
                                        mimeType="image/*"
                                        onPicker={async (file) => {
                                            await handleChangeImage2(file);
                                        }}
                                    />
                                </> : null
                            }
                            <CheckInput
                                oncheng={e => {
                                    setSelectedPrivacy(e.checked);
                                }}
                            >
                                <span className='text-inline-block text-size-14'>
                                    {trans("common:agree-on")}
                                </span>
                                <span className='text-inline-block text-white text-size-14'>
                                    {trans("common:policy")}
                                </span>
                            </CheckInput>
                        </SectionHeading>

                    </div>
                );
        }
    };
    return (
        <div className="flex flex-col w-100">
            <h1 className="text-block text-30 text-center text-bold mb-10  ">{trans("common:no-account")}</h1>
            <div className={'popup-content ' + languageDir}>



                {
                    getCorrectSection()
                }


            </div>

            <div className="flex flex-row items-center justify-center nice-flex-space-between nice-gap-1rem">
                <FormButton name={trans("form:next")} onClick={handleNextStep}

                    classes={"w-30 px-10 py-5 text-20 border-sm"}
                />
                {/* <p className='text-center my-5 text-16 text-grey'>{trans("common:or")}</p> */}

                {/* <div className="flex flex-row items-center justify-center nice-gap-1rem">
                    <div className="nice-width-size-40 p-3">
                        <GoogleLogin
                        clientId={`${config.googleClientID}`}
                        render={renderProps => (
                            <Link className='nice-btn nice-btn-white text-12 font-bold text-center pl-14'
                                disabled={renderProps.disabled} onClick={renderProps.onClick}>
                                {trans("form:sign-up-google")}
                                <img src="/assets/icons/google.png" className='absolute absolute-left' alt="google logo" />
                            </Link>
                        )}
                        onSuccess={(e) => onSuccess('Google', e)}
                        onFailure={(e) => onFailure('Google', e)}
                        cookiePolicy={window.location.origin}
                    />
                    </div>
                    <div className="nice-width-size-40 p-3">
                        <FacebookLogin
                        appId={`${config.facebookClientID}`}
                        callback={(e) => onSuccess("Facebook", e)}
                        fields="name,email"
                        cssClass='nice-btn nice-btn-primary pl-14 '
                        textButton={<div className="flex flex-row items-center font-bold text-12 text-white">{trans("form:sign-up-facebook")}<img src="/assets/icons/facebook.svg" className='absolute absolute-left' alt="facebook logo" /></div>}
                        render={renderProps => (
                            <button onClick={renderProps.onClick}>
                            </button>)}
                    />
                    </div>
                </div> */}
                <Link className='text-size-14 mt-5 text-center text-block text-black text-bold border-b border-black'
                    onClick={() => onTabChange(tabsList[0])}>
                    {trans("common:existing-account")}
                </Link>

            </div>
        </div>
    );
}