import { useEffect, useState } from 'react';
import { Map } from "../../../pages/contact/components/map";
import { InputField } from "../../_input/_input";
import { TextArea } from "../../_input/_textArea";
import { FormButton } from "../../_input/_button";
import { useTranslation } from "react-i18next";
import {formValidate } from '../../../utils/validation';
import config from '../../../configuration/config.json';
import { PhoneInput } from "../../../components/_input/_phoneInput";
import PhoneDropDown from "../../../components/_input/_phoneDropDown";
import { DropDownInput } from '../../../components/_input/_dropdown';
import axios from "axios";
import ContactTile from '../../../pages/contact/components/contactTile';
import { toast } from 'react-toastify';



export function NavContact({ ...prop }) {

    const { t: trans } = useTranslation(["common", "form", "info", "inputs"]);
    let lang = localStorage.getItem("i18nextLng");

    const [message, setMessage] = useState({
        fullName: '',
        phone: '',
        email: '',
        subject: '',
        messageContent: ''
    });

    const [branches, setBranches] = useState([]);
    const [contacts, setContacts] = useState([{}]);
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [subjects, setSubjects] = useState([]);
    function rad(x) { return x * Math.PI / 180; }

    function find_closest_marker() {
        navigator.geolocation.getCurrentPosition((position) => {

            const currentPosition = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
            };
            var lat = currentPosition.lat;
            var lng = currentPosition.lng;
            var R = 6371; // radius of earth in km
            var distances = [];
            var closest = -1;
            for (let i = 0; i < branches.length; i++) {
                var mlat = parseFloat(branches.latitude);
                var mlng = parseFloat(branches.longitude);
                var dLat = rad(mlat - lat);
                var dLong = rad(mlng - lng);
                var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                    Math.cos(rad(lat)) * Math.cos(rad(lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
                var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
                var d = R * c;
                distances[i] = d;
                if (closest == -1 || d < distances[closest]) {
                    closest = i;
                }
            }
            setSelectedBranch(branches[closest]);
        });
    }

    useEffect(async () => {
        try {
            const response = await axios.request({
                baseURL: config.controllURL,
                url: lang + "/api/contact/contact/",
                method: "get",
            });
            setBranches(response.data.branches);
            setContacts(response.data.contacts);
            setSubjects(response.data.subjects.map(item => { return { text: item.description, value: item.id } }));

        } catch (error) {
            console.log(error);
        }
    }, []);



    useEffect(async () => {
        find_closest_marker();
    }, [branches]);

    useEffect(async () => {
        if (selectedBranch != null) {
            const allContects = contacts.filter(e =>
                e.branchId == selectedBranch.name
            );
            setContacts(allContects);
        }
    }, [selectedBranch]);


    const schema = prop.schema;

    const [error, setError] = useState({});

    const submitRequest = async (e) => {
        e.preventDefault();


        const result = formValidate(schema, message) ?? {};
        setError(result);
        try {
            if (Object.keys(result).length === 0) {
                console.log(Object.keys(error).length);
                const response = await axios.post(
                    config.baseURL + 'add',
                    {
                        "object": "siteContactMessages",
                        "values": {
                            "name": message.fullName,
                            "email": message.email,
                            "phoneNumber": message.phone,
                            "subject": Number(message.subject),
                            "message": message.messageContent,
                        }
                    }
                );
                if (response.status === 200 || response.status === 201) {
                    const defaultValue = { ...message };
                    setMessage({
                        fullName: '',
                        phone: defaultValue.phone,
                        email: '',
                        subject: '',
                        messageContent: ''
                    });
                    setError({});
                    toast.success(trans("info:message_sent_successfully"));
                }
            }
        } catch (e) {

        }

    }

    return (
        <div className={"nav-mega-menu bg-white box-shadow border-radius  " + prop.className}>
            <div className="my-4 text-20 p-8 border-radius nav-grid-3-cols  nav-grid-contact">
                <div>
                    <form onSubmit={
                        (e) => submitRequest(e)
                    }>
                        <h3 className="nav-heading">
                            {trans("common:contact-us")}
                        </h3>

                        <div>
                            <div className="nav-contact-form mb-8">
                                <InputField
                                    secondary={true}
                                    placeholder={trans("form:name")}
                                    name='fullName'
                                    value={message.fullName}
                                    errorLabel={error?.fullName}
                                    onChangeValue={
                                        (e) => {
                                            const val = { ...message };
                                            val.fullName = e.target.value;
                                            setMessage(val);
                                        }
                                    }
                                />
                                <PhoneDropDown
                                    errorLabel={error?.phone}
                                    onChangeValue={(countryCode, phoneNumber) => {
                                        const val = { ...message };
                                        val.phone = `+${countryCode}${phoneNumber}`;
                                        setMessage(val);
                                    }}
                                />

                                <InputField
                                    placeholder={trans("form:email")}
                                    name='email'
                                    value={message.email}
                                    errorLabel={error?.email}
                                    onChangeValue={
                                        (e) => {
                                            const val = { ...message };
                                            val.email = e.target.value;
                                            setMessage(val);
                                        }
                                    }
                                />
                                <DropDownInput
                                    onSelect={(e) => {
                                        const val = { ...message };
                                        val.subject = String(e);
                                        setMessage(val);
                                    }}
                                    name='subject'
                                    // className={"flex-grow-1"}
                                    defaultOption={{ text: trans("form:subject"), value: 0 }}
                                    options={[...subjects]}
                                    errorLabel={error?.subject}
                                />
                                <TextArea
                                    value={message.messageContent}
                                    placeholder={trans("form:message")}
                                    name='messageContent'
                                    errorLabel={error?.messageContent}
                                    onChangeValue={
                                        (e) => {
                                            console.log(e);
                                            const val = { ...message };
                                            val.messageContent = e;
                                            setMessage(val);
                                        }
                                    }
                                />

                            </div>
                            <FormButton
                                name={trans("form:send")}
                            />
                        </div>
                    </form>
                </div>
                <div className={"nav-grid-middle"}>
                    <h3 className="nav-heading">
                        {trans("common:contact-info")}
                    </h3>

                    <div className="m-8 space-y-6">
                        {
                            contacts?.map((contact, index) => {
                                return (
                                    <ContactTile
                                        key={index}
                                        icon={contact.typeIconPath}
                                        value={contact.contactValue}
                                        type={contact.typeValue}
                                        name={contact.contactTitle ?? contact.typeTitle}
                                    />
                                );
                            })
                        }

                    </div>
                </div>
                <div>
                    <h3 className="nav-heading" onClick={() => console.log(find_closest_marker())}>
                        {trans("common:locations")}
                    </h3>
                    <div className=" nav-contact-map">
                        <Map />
                        <Map multiMarkers={true} markers={branches} />
                    </div>
                </div>
            </div>
        </div>
    );
}