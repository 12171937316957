import {Link} from "react-router-dom";
import {NavWorkHours} from "./components/navWorkHours";
import {NavPartCard} from "./components/navPartCard";
import { useEffect,useState } from "react";
import config from '../../../configuration/config.json';
import axios from "axios";
import _ from 'lodash';
import { useTranslation } from "react-i18next";
import moment from "moment";
export function NavParts({...prop}) {
    const { t: trans } = useTranslation(["common", "form", "model","navbar","info"]);
    //---------------------------------------------------
    const[compaign,setCompaign] = useState([]);
    const[categories,setCategories] = useState([]);
    //---------------------------------------------------
    useEffect(()=>{
    intialCompaign();
    intialEPartsCategories();
    },[]);
    //---------------------------------------------------
    const intialCompaign = ()=>{
    axios.post(
        config.baseURL + "details",
        {
            "object": "Web_compaing",
            "ObjectSettings": {
                "MetaData": false
            },
            "Option":"Column",
            "Fields":"GUID,DescriptionAr,TotalTime,DescriptionEn,DescriptionArTitle,DescriptionEnTitle,image,CreatedDate,DateFrom,DateTo,TotalAmountDiscount"
        }
    ).then(response=>{
    const responseData = response.data.ApiObjectData;
    const responseDataFiltered = _.orderBy(responseData, (e) => {
        return new moment(new Date(e.CreatedDate)).format('YYYY-MM-DD')
    }, ['desc']);

    const newList = responseDataFiltered.map((e, index) => {
            return {
                id: e.GUID,
                image: e.image,
                title: e[`${trans("model:description")}`],
                description: e[`${trans("model:description") + 'Title'}`],
                startDate:e.DateFrom,
                endDate:e.DateTo,
                totalTime:e.TotalTime,
                total:e.TotalAmountDiscount
            }
    });

    const firstTwoOffers = newList.filter((ele,index)=>{
        return index <= 1;
    })
    setCompaign(firstTwoOffers);
    }).catch(error=>{
     console.log(error);
    });
    };
    // fetch the list of categories
    const intialEPartsCategories = ()=>{
        axios.post(
            config.baseURL + "details",
            {
                "Object": "Site_categorySP",
                "Option": "Column",
                "Fields": "GUID,DescriptionAr,DescriptionEn",
                "ObjectSettings": {
                    "MetaDate": false,
                    "Limit":{
                        "Optional":"Top",
                        "Value":5
                    }
                }
            }
        ).then(response => {
         const responseData = response.data.ApiObjectData;
         const responseDataFiltered = responseData.map(cat=>{
             return {id : cat.GUID , description : cat[`${trans("model:description")}`]}
         });
         setCategories(responseDataFiltered);
        }).catch(error=>{
         console.log(error);
        });
    };
    //---------------------------------------------------
    return (
        <div className={"nav-mega-menu bg-white box-shadow border-radius  " + prop.className}>
            <div className="my-4 text-20 p-8 border-radius nav-grid-3-cols">
                <div>
                    <h3 className="nav-heading">
                         {trans("navbar:compaign")}
                    </h3>
                    <div className="nav-maintenance-container nice-flex nice-flex-center">
                      {
                       (compaign != null && compaign.length > 0) ? compaign.map((com,index)=>{
                           return <NavPartCard 
                            key={index}
                            to={'/eparts/compaigndetails/' + com.id}
                            title = {com.title}
                            description = {com.description}
                            image = {com.image}
                            total={com.total}
                            totalTime={com.totalTime}
                            startDate={new Date(com.startDate)}
                            endDate={new Date(com.endDate)}
                            />
                       }):(<div className="nice-flex nice-flex-column nice-flex-center">
                            <img src='/assets/icons/sad.svg' alt="hassan jameel sad"/>
                            <h1 className="text-14 text-bold my-3">{trans("info:no_compaign")}</h1>
                           </div>)
                      }
                    </div>
                </div>
                <div className={"nav-grid-middle"}>
                    <h3 className="nav-heading">
                    {trans("navbar:compaign_category")}
                    </h3>
                    <ul className={"nav-maintenance-links"}>
                    {
                            categories.map((cat,index) => {
                                return (
                                <li key={index}>
                                    <Link to={"/eparts/group/" + cat.id}>{cat.description}</Link>
                                </li>
                                )
                            })
                        }
                       {
                        categories?.length > 4  && <li className={"show-more mt-auto"}><Link to={"/eparts"}>{trans("navbar:show_more") +'...'}</Link></li>
                       } 
                    </ul>
                </div>
                <div>
                    <h3 className="nav-heading">
                    {trans("navbar:work_hours")}
                    </h3>
                    <NavWorkHours department={12}/>
                </div>
            </div>
        </div>
    );
}