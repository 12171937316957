import {useTranslation} from "react-i18next";
export function GetMessages(){
    const {t:trans} = useTranslation(['messages']);
    const messages = {
    "any.required":trans("messages:field_required"),    
    "string.base":trans("messages:type_input_field"),
    "string.email":trans("messages:email_input_field"),
    "string.empty":trans("messages:empty_field"),
    "string.min":trans("messages:min_input_field"),
    "string.max":trans("messages:max_input_field"),
    "string.pattern.base":trans("messages:flag_input_field"),
    "object.base":trans("messages:file_input_field"),
    "binary.max":trans("messages:file_max_input_field"),
    "date.base":trans("messages:date_input_field"),
    "any.only":trans("messages:password-not-matched"),
    "number.base":trans("messages:number_input_field"),
    "number.positive":trans("messages:number_pos_input_field"),
    "number.min":trans("messages:number_min_input_field"),
    "number.max":trans("messages:number_max_input_field"),
    "string.length":trans("messages:flag_length_field")
    }
    return messages;
}
export function formValidate(schema,data){  
const {error,value} =   schema.validate(data,{abortEarly:false});
if(!error) return null;
//alert(JSON.stringify(error.details));
const errors ={};
for(let item of error.details){
    if(item.type != 'object.unknown')
        errors[item.path[0]] = item.message;
}
return errors;
}

export function validateProperty({name,value},schema){
const obj = {[name]:value};
const {error} = schema.validate(obj,{abortEarly:false});
return error ? error.details[0].message : null;
}