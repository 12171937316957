import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Price } from "./__simple/_price";
import { CountDown } from '../pages/eparts/components/countDown';
import config from "../configuration/config.json";
import { Base64 } from 'js-base64';
function Offer({
    img = "/assets/images/offers/1.png",
    name = "اسم العرض هنا",
    type,
    excerpt,
    showSeconds = true,
    slug,
    price,
    endDate,
    startDate,
    totalDays = 0
}) {
    const { t: trans } = useTranslation(["common", "new"]);
    const languageDir = "language-" + localStorage.getItem("i18nextLng");
    return (
        <Link to={(type =="Vehicles"|| type =="المركبات" )? "offers/vehicles/"+slug : "offers/detail/"+slug }>
            <div className={"offer-card border-radius box-shadow " + languageDir} >
                <div className="offer-card-img border-radius">
                    {

                        (img && Base64.isValid(img) == 1 ?

                            <img src={img ? "data:image/png;base64," + img : "/assets/images/default.png"}
                                alt={name} onError = {e => e.target.style.display = 'none'} /> :
                            <img src={img ? config.paymentURL + img : "/assets/images/default.png"}
                                alt={name} onError = {e => e.target.style.display = 'none'} />)
                    }
                </div>
                <div className="offer-card-text">
                    <h3 className={"text-20 font-bold mb-7 p-3  text-line-clamp text-line-clamp-1"}>{name}</h3>
                    <div>
                        <div className="offer-card-counter nice-flex nice-fex-row my-2 nice-align-item-center nice-flex-evenly ">
                            <span className="text-16 text-white offer-card-counter-text">
                                {trans("new:expire_date")}
                            </span>
                            <CountDown showSeconds={showSeconds} Total={totalDays} start={startDate} endDate={endDate} />
                        </div>
                        {price ? <p className={"text-primary font-bold text-16 my-2  mx-2"}>
                            <Price price={price} />
                        </p> :null
                        }
                        <p className={"text-16 p-3 text-line-clamp text-line-clamp-2 offer-card-text-p"}>
                            {excerpt ?excerpt :null}
                        </p>
                    </div>
                </div>
            </div>
        </Link>
    );
}

export default Offer;