function Testimonial({name, content, image}) {

    return (
        <div className={"testimonial-card "}>
            <div className="testimonial-card-img">
                <img className={" border-radius-full box-shadow "} src={image} alt={name} onError = {e => e.target.style.display = 'none'} />
            </div>
            <div className="testimonial-card-text text-center">

                <p className={"text-16 text-height-16"}>
                    {content}
                </p>

                <h4 className={"text-16 text-primary font-bold mb-7"}>{name} </h4>
            </div>
        </div>
    );
}

export default Testimonial;