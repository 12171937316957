import {useEffect, useState} from "react";
import {GoogleMap, LoadScript, Marker, InfoWindow} from "@react-google-maps/api";


export function Map({latitude , longitude , name , content ,  markers = [] , multiMarkers = false}) {
    let googleMapURL = "https://maps.googleapis.com/maps/api/js?key=AIzaSyAN_OIK0AzQyINgni_VzR23kh-ytSgiku0";
    let lang = localStorage.getItem("i18nextLng");


    const [currentPosition, setCurrentPosition] = useState({});
    const [multPositions , setMultiPositions] = useState([]);
    const [toggle , setToggle] = useState(false);


    // function changeMapLanguage(){
    //     return  googleMapURL+=`&language=${lang}`;
    // }

    const success = position => {

        const currentPosition = {
             lat: parseFloat(latitude) ?? position.coords.latitude,
             lng: parseFloat(longitude) ?? position.coords.longitude
        }
        setCurrentPosition(currentPosition);
    };

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(success);
    }, [latitude,longitude])

  

   useEffect(() => {
       if(multiMarkers){
          setMultiPositions(markers.map(e=>(
           {lat:e.latitude , lng:e.longitude}
           )));
       }
    }, [markers,multiMarkers]);

    useEffect(()=>{
        navigator.geolocation.getCurrentPosition((position)=>{
            
            const currentPosition = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
           }
           setCurrentPosition(currentPosition);
        });
    },[]);

   const  handleToggle = ()=>{
    setToggle(!toggle);
    };


    const locations = [
        {
            name: "Location 1",
            location: {
                lat: 26.4007915,
                lng: 50.0110817
            },
        },
        {
            name: "Location 2",
            location: {
                lat: 26.399904,
                lng: 50.013982
            },
        },

    ];

    const mapStyles = {
        height: "100%",
        width: "100%"
    };


    const infoWindowStyle ={
        backgroundColor:'#fff',
        color:'#000',
        textAlign:'center',
        minWidth:'100px',
        fontSize:18,
        fontWeight:'bold'
    };

    const defaultCenter = {
        lat: 41.3851, lng: 2.1734
    }
    const [selected, setSelected] = useState({});

    const onSelect = item => {
        setSelected(item);
    }

    return (

            <GoogleMap
                mapContainerStyle={mapStyles}
                zoom={8}
                center={currentPosition}
                clickableIcons={false}
                options={
                    {
                        panControl: false,
                        mapTypeControl: false,
                        fullscreenControl: false,
                        streetViewControl: false,
                        zoomControl: false,
                    }

                }
            >

                {
                    !multiMarkers &&
                    (
                        <Marker position={currentPosition} key={name??''} onClick={handleToggle}>
                          {
                              toggle &&  <InfoWindow
                              position ={currentPosition}
                              clickable ={true}
                              onCloseClick = {handleToggle}>
                              <div style={infoWindowStyle}>
                               {latitude != '' ? content : 'Me'}
                              </div>
                             </InfoWindow>
                          }
                         </Marker>   
                    )
                }
                {
                   multiMarkers && markers.map((item,index)=>{
                       const {latitude,longitude} = item;
                       const coords = {lat:parseFloat(latitude),lng:parseFloat(longitude)};
                        return (
                            <Marker position={coords} 
                            key = {item?.name}
                            label = {index.toString()}
                            onClick={handleToggle}
                            >
                             {
                              toggle &&  <InfoWindow
                              position ={coords}
                               clickable ={true}
                               onCloseClick = {handleToggle}>
                              <div style={infoWindowStyle}>
                               {item?.content}
                              </div>
                             </InfoWindow>
                          }
                            </Marker>
                        )
                    })
                }
            </GoogleMap>
    )

}

