import {useDispatch} from "react-redux";
import {Fragment, useEffect} from "react";
import {changeIcons} from "../../_redux/slices/navSlice";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";

export default function NotFoundPage_404() {
    const {t: trans} = useTranslation(["messages"]);

    const dispatch = useDispatch();
    useEffect(async () => {

        dispatch(
            changeIcons({iconsBlack: true})
        )

        return () => {
            dispatch(
                changeIcons({iconsBlack: false})
            )
        }
    }, []);
    return (
        <Fragment>
            {
                <Helmet>
                    <meta name="robots" content="noindex"/>
                </Helmet>
            }
            <div className={"container error-page my-32"}>
                <div className="error-text">

                    <div className="error-heading">
                        {trans("messages:page_not_found")}
                    </div>
                    <h1 className={"error-code"}>
                        404
                    </h1>
                    <p className="error-message">
                        {trans("messages:page_not_found_message")}
                    </p>

                </div>

                <div className={'error-image'}>
                    <img src="/assets/svg_background/error.svg" alt=""/>
                </div>
            </div>
        </Fragment>
    );
}