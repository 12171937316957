import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import config from '../../../configuration/config.json';
import Banner from "../../../components/banner";
import axios from "axios";
const ServiceHeaderBanner = ({ defaultBanner = 5, bannerDescriptor = null, to = '', hasRedirect = false }) => {
    const { t: trans, i18n } = useTranslation(['info']);
    const [banner, setBanner] = useState([]);
    let lang = localStorage.getItem('i18nextLng');
    const requsetBanner = async () => {
        let result = [];

        const response = await axios.request({
             baseURL: config.controllURL,
            url: lang + "/api/Maintenance/Banner",
            method: "get",
        });

        console.log('response.data.banner:', response.data.banner);
        result = response.data.banner;

        return result;
    };
    useEffect(async () => {
        let bannerObject;
        try {
            if (bannerDescriptor) {
                bannerObject = (
                    <Banner
                        background={bannerDescriptor.image}
                        title={bannerDescriptor?.title}
                        subtitle={bannerDescriptor?.subTitle}
                        desc={bannerDescriptor?.description}
                        btn={hasRedirect ? {
                            text: trans("info:order-service"),
                            link: to
                        } : null}
                        backOpasity={banner?.backOpasity}
                    />);
            } else {
            const banner = await requsetBanner();
            bannerObject = <Banner
                subtitle={banner?.subtitle}
                title={banner?.title}
                desc={banner?.excerpt}
                background={banner?.imagePath}
                btn={hasRedirect ? {
                    text: trans("info:order-service"),
                    link: to
                } : null}
                backOpasity={banner?.backOpasity}
            />;


            }
            setBanner(bannerObject);
        } catch (error) {
            console.log(error);
        }
    }, []);
    return (
        <>
            {banner}
        </>
    );
};

export default ServiceHeaderBanner;