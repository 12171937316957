import { Map } from "./map";
import ContactList from "./contactList";
import { useEffect, useState } from 'react';

export function MapBanner({ branches, contacts, activebranche }) {

    const [geoLocation, setGeoLocation] = useState({
        location: {
            name: '',
            content: '',
            latitude: '',
            longitude: ''
        }
    });

    function handleChangeLocation(geo) {
        const { latitude, longitude, content, name } = geo;
        const newLocation = { ...geoLocation };
        newLocation.location.latitude = latitude;
        newLocation.location.longitude = longitude;
        newLocation.location.content = content;
        newLocation.location.name = name;
        setGeoLocation(newLocation);
    }

    useEffect(() => {
        try {
            console.log('length', branches.length);
            if (branches.length > 0)
                handleChangeLocation(branches[0]);
        } catch (err) {
        }
    }, [branches]);

    useEffect(() => {
        if (activebranche) {
            handleChangeLocation(activebranche);
        }
    }, [activebranche]);




    return (
        <div className=" mx-10 mb-8 banner-container-map border-radius box-shadow-large">
            <Map longitude={geoLocation.location.longitude} latitude={geoLocation.location.latitude} content={geoLocation.location.content} name={geoLocation.location.name} />
            <div className={"border-radius box-shadow banner-container-map-list bg-white p-8 hide-xs"}>
                {
                    branches?.map((branch, index) => {
                        return (
                            <ContactList
                                key={index}
                                onGeo={(e) => handleChangeLocation(e)}
                                branch={branch}
                                contacts={contacts.filter((contact) => contact.branchId == branch.name)}
                            />
                        );
                    })
                }
            </div>
        </div>
    )

}

