import FooterHeading from "./__simple/_footerHeading";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import config from '../configuration/config.json';
import Joi from 'joi';
import { toast } from "react-toastify";

function Footer() {
    const { t: trans } = useTranslation("common");

    const [brands, setBrands] = useState([]);
    const [socials, setSocials] = useState([]);
    const [email, setEmail] = useState('');
    const submitEmail = async (e) => {
        e.preventDefault();

        const schema = Joi.object({
            email: Joi.string().required().email({ minDomainSegments: 2, tlds: { allow: false } })
        });
        const { error } = schema.validate({ email: email });
        if (error == null) {
            if (!(await isEmailExisted(email))) {
                axios.post(
                    config.baseURL + 'add',
                    {
                        "Object": "siteNewslettterSub",
                        "Values": {
                            "email": email
                        }
                    }
                ).then((response) => {
                    if (response.status == 200) {
                        toast.success(trans("common:email-register-in-news"))
                        setEmail('');
                    }
                });
            } else {
                toast.error(trans("common:email-existed"));
            }
        }
    };

    async function isEmailExisted(email) {
        let isExisted = false;
        try {
            const response = await axios.post(
                config.baseURL + "details",
                {
                    "Object": "siteNewslettterSub",
                    "ObjectSettings": {
                        "MetaData": false
                    },
                    "Filters": `where lower(email) = lower('${email}')`
                }
            );
            isExisted = response.data.ApiObjectData.length > 0;
        } catch (err) {
            console.log(err);
        }
        return isExisted;
    }


    let lang = localStorage.getItem("i18nextLng");

    useEffect(async () => {
        try {
            const res = await axios.request({
                baseURL: config.controllURL,
                url: lang + "/api/brands/brands",
                method: "get",

            })
            setBrands(res.data.brands);
            const social = await axios.request({
                baseURL: config.controllURL,
                url: lang + "/api/contact/social",
                method: "get",

            })
            setSocials(social.data);
        } catch (error) {
        }

    }, []);


    return (
        <footer className="footer">
            <div className="footer-top py-12 container  ">



                <div className="footer-top-links ">
                    <ul className={"bold"}>
                        <li><Link to="/">{trans("home")}</Link></li>
                        {
                            brands.map((brand) => {
                                return (
                                    <li key={brand.brand}><Link to={"/vehicles/" + brand.brand}>{brand.brand}</Link>
                                    </li>
                                )
                            })
                        }
                        {/* <li><Link to="/compare">{trans("compare")}</Link></li> */}
                        <li><Link to="/maintenance">{trans("maintenance-services")}</Link></li>
                        <li><Link to="/finance">{trans("finance")}</Link></li>
                    </ul>
                    <ul className={"normal"}>
                        <li><Link to="/news">{trans("news")}</Link></li>
                        {/*<li><Link to="/NewsPage">{trans("news")}</Link></li>*/}
                        <li><Link to="/about">{trans("about-us")}</Link></li>
                        <li><Link to="/employment">{trans("employment")}</Link></li>
                        <li><Link to="/order-car">{trans('buy-vehicle')}</Link></li>

                    </ul>
                    <ul className={"normal"}>

                    <li><Link to="/hj/advises">{trans("HJ-advises")}</Link></li>
                        <li><Link to="/contact">{trans("locations")}</Link></li>
                        <li><Link to="/contact">{trans('contact-us')}</Link></li>
                        <li><Link to="/websitePolicy">{trans('common:website-policy')}</Link></li>
                        {/* <li><Link to="/agreement">{trans('agreement-Sale-And-Reservation')}</Link></li> */}
                    </ul>
                </div>

                <div className="footer-top-social ">
                    <FooterHeading lang={lang} title={trans('fallow-us')} />
                    <div className={"footer-top-social-links mr-8"}>
                        {
                            socials.map((social) => {
                                return (
                                    <a target="_blank" rel="noopener" href={social.content} aria-label={social.name}
                                        key={social.name}
                                        className="social-box">
                                        <img src={"data:image/png;base64," + social.imagePath} className={"object-full object-contain"}
                                            alt={social.name} />
                                    </a>
                                );
                            })
                        }

                    </div>
                </div>

                <div className="footer-top-newsletter">
                    <FooterHeading lang={lang} title={trans('for-offers-&-news')} />
                    <div>
                        <form onSubmit={
                            (e) => {
                                submitEmail(e);
                            }
                        }>
                            <input required
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value.trim());
                                }}
                                className={"text-16 border-radius"} placeholder={trans('enter-your-email')}
                                type="mail" />
                            <button type='submit' aria-label={"newsletter subscribe"}
                                className={"btn btn-primary border-radius"}>
                                <img src="/assets/icons/email.svg" alt="" />
                            </button>
                        </form>
                    </div>
                </div>

                <div className="footer-top-imgs">
                    <Link to="/" aria-label={"home link"}>
                        <div className={"footer-logo border-radius"}>
                            {

                                lang == "ar" ?
                                    <img src="/assets/logo/logo_AR.png" alt="hassan jameel logo" />
                                    :
                                    <img src="/assets/logo/logo_En.png" alt="hassan jameel logo" />
                            }

                        </div>
                    </Link>
                    <div className={"footer-top-imgs-links "}>
                        <img src="/assets/images/footer/AS.png" alt="app store" />
                        <img src="/assets/images/footer/GP.png" alt="play store" />
                    </div>
                </div>
            </div>
            <div className="footer-bottom flex justify-between items-center py-2 px-6 container-p">
                <div>
                    <a className={"text-16 no-hover"} href="./">® {new Date().getFullYear()} "{trans('HJ-for-cars')}"</a>
                </div>
                <div className={"space-x-2 space-x-reverse"}>
                    <Link className={"text-16"} to="/privacy">{trans('privacy-policy')}</Link>
                    {/* <a className={"text-16"} href="./"> {trans('site-policy')} </a> */}
                </div>
            </div>
        </footer>
    );
}

export default Footer;