import { useTranslation } from "react-i18next";
import { numberFormat } from "../../../utils/currencyFormatter";
const CartDetails = ({ fullTotal, shippingTime, shippingUnitTime, subTotal, shippingCost, totalTax, totalDiscount, sumItemTotal, miniDownPayment }) => {
    const { t: trans, i18n } = useTranslation(["cartFile", "new"]);

    const lang = i18n.language;
    return (
        <>
            <div className="ruler-2"></div>
            {
                shippingTime ?
                    <div className="cart-grid-info-div mb-8">
                        <div className={"text-16 text-gray "}>{trans("cartFile:receive_peroid")}</div>
                        <div className={"text-16 text-gray font-bold"}>{shippingTime} {shippingUnitTime} </div>
                    </div>
                    : null
            }
            {
                shippingCost ?
                    <div className="cart-grid-info-div">
                        <div className={"text-16 text-gray"}>{trans("cartFile:shipping_costs")}</div>
                        <div className={"text-16 text-gray font-bold"}>{numberFormat(shippingCost, lang)}</div>
                    </div>
                    : null

            }
            {
                totalDiscount ?
                    <div className="cart-grid-info-div ">
                        <div className={"text-16 text-gray"}>{trans("cartFile:discount_total")}</div>
                        <div className={"text-16 text-gray font-bold"}>{numberFormat(totalDiscount, lang)}</div>
                    </div> : null
            }


            {
                <div className="cart-grid-info-div border-tb">
                    <div className={"text-16 font-bold text-black"}>{trans("new:total")}&nbsp;<span className="text-14 text-gray mx-1">{trans("new:total_include_tax")}</span></div>
                    <div className={"text-16 text-black font-bold"}>{numberFormat((fullTotal + shippingCost) - totalDiscount, lang)}</div>
                </div>
              

            }



            {
                subTotal &&
                <div className="cart-grid-info-div">
                    <div className={"text-16 text-gray"}>{trans("cartFile:Downpayment")}</div>
                    <div className={"text-16 text-gray font-bold"}>{numberFormat(subTotal, lang)}</div>
                </div>
            }




            {/* <div className="cart-grid-info-div">
                <div className={"text-16 text-gray"}>{trans("cartFile:tax_amount")}</div>
                <div className={"text-16 text-gray font-bold"}>{numberFormat(totalTax)}</div>
            </div> */}


            <div className="cart-grid-info-div border-tb">
                <div className={"text-16 font-bold text-black"}>{trans("new:min_down_payment")}</div>
                <div className={"text-16 text-black font-bold"}>{numberFormat(miniDownPayment, lang)}</div>
            </div>
        </>
    )
};
export default CartDetails;