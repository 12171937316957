import { useState } from "react";

export const EvaluationType = ({ title = "", error = null, countOptions = 6, click, mandatory = false, defultActive =null}) => {
  const [activeItem, setActiveItem] = useState(null);

  const handelClik = (_nomC) => {
    click(_nomC);
  };


  return (
    <article className="serviceEvaluation-section">
      <h3 className="serviceEvaluation-section-title text-20 my-5">
        {title}
        {mandatory && <span className="text-error text-20 text-bold  serviceEvaluation-section-title">{"*"}</span>}
        {error && <span className="text-error text-20 serviceEvaluation-section-title">{error}</span>}
      </h3>
      <div className="flex flex-col">
        {/* <div className="serviceEvaluation-section-map">
          {[...Array(11)].map((num, index) => {
            return (
              <div
                key={`service-evaluation-icons-${index}`}
                className={`serviceEvaluation-section-icons-item`}
              >
                {getActualIcon(index)}
              </div>
            );
          })}
        </div> */}

        <div className="serviceEvaluation-section-map">
          {[...Array(countOptions)].map((num, index) => {
            return (

             
                <div
                  key={`service-evaluation-${index}`}
                  className={`serviceEvaluation-section-map-item ${activeItem != null ? (activeItem == (index / 10) ? "item-active" : "") : (defultActive == (index / 10) ? "item-active" : "")
                    }`}
                  onClick={() => {
                    handelClik((index / 10));
                    setActiveItem((index / 10));
                  }}
                >
                  <span className="text-20">{(index / 10)}</span>
                </div>
            );
          })}
        </div>
      </div>
    </article>
  );
};
