import { Fragment, useContext, useEffect, useState, useCallback } from "react";
import { list, total, update } from 'cart-localstorage'
import Banner from "../../components/banner";
import PageHeading from "../../components/__simple/_pageheading";
import { Cart } from "./component/cart";
import Breadcrumb from "../../components/__simple/breadcrumb";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { toast } from "react-toastify";
import { CartItems } from "./component/CartEpart";
import SectionHeading from "../../components/sectionHeading";
import { DropDownInput } from "../../components/_input/_dropdown";
import config from "../../configuration/config.json";
import { getData, User_Data_Key, saveData } from "../../utils/localStorageManager";
import { CampaignCart } from "./component/campaignCart";
import _, { concat, sum } from "lodash";
import { BouquetCart } from "./component/BouquetCart";
import { RadioInput } from "../../components/_input/_radioInput";
import { ServiceBox } from "./component/ServiceBox";
import BranchesInfo from "./component/BranchInfo";
import CouponControl from "./component/CouponControl";
import SimpleButton from "../../components/_input/SimpleButton";
import PaymentPlatforms from "./component/PaymentsPlatforms";
import CartDetails from "./component/CartDetails";
import { useUserCart } from "../../User/hooks/useUserCart";
import ConfirmContext from "../../utils/confirmContext";
export function CartPage() {

    const { initCartItems, removecartItems, removeAllcartItems } = useUserCart();
    const { t: trans, i18n } = useTranslation(["messages", "cart", "cartFile", "model", "new", "info", "extra"]);
    const [banner, setBanner] = useState({});
    const [currentDeliveryMethod, setcurrentDeliveryMethod] = useState({
        branchMethod: false,
        locationMethod: false
    });
    const thisConfirmContext = useContext(ConfirmContext);
    const [isOpen, setIsOpen] = useState(false);
    const [carErrors, setCarErrors] = useState([]);
    const [develviryType, setDeleviryType] = useState(null);
    const [Shipp, setShipp] = useState(false);
    const GetUrl = config.baseURL + 'details';
    const [Code, setCode] = useState("0000-0000-0000-0000");
    const [UsedCode, setUsedCode] = useState("");
    let lang = i18n.language;
    const languageDir = 'language-' + lang;
    const [CodeInfo, setCodeInfo] = useState([]);
    const [isGetCartData, setIsGetCartData] = useState(false);
    async function DiscountPayment(CodeDisc) {

        const _userCodeDiscount = JSON.parse(window.localStorage.getItem("UsedCodeDiscountID"));




        const Body = {
            "name": "site_FetchInfoCodeDisount",
            "Values": {
                "Code": CodeDisc
            }

        };

        axios.request({
            url: GetUrlfunc,
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {
                setCodeInfo(response.data);
                window.localStorage.setItem('UsedCodeDiscountID', response.data[0].DiscountCartID);
                // console.log("CodeInfo", response.data);
                // console.log("UsedCodeDiscountID", localStorage['UsedCodeDiscountID']);
            })
            .catch(function (error) {
                setCodeInfo([]);
                console.log(error);
                toast.error(" خطأ:قد يكون الكود غير صحيح او تم استخدامه مسبقا");
            });


    };
    const [Branches, setBranches] = useState([]);
    const [branchesDetails, setBranchesDetails] = useState([]);
    const GetUrlfunc = config.baseURL + 'func';
    async function GetBranch() {
        const Body = {
            "name": "site_FetchBranches"
        };
        axios.request({
            url: GetUrlfunc,
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {
                const result = response.data;
                setBranches(result);
                const formatResult = result.map(item => {
                    return {
                        title: concat(item[`${trans("model:description")}`], item.Address ? concat('-', item.Address) : ''),
                        details: item.WorkingHoursFormat,
                        lat: item.Latitude,
                        long: item.Longitude
                    }
                });
                setBranchesDetails(formatResult);
            })
            .catch(function (error) {
                console.log(error);
            });
    };
    async function Discount_change(UsedCount, DiscountCartID, DiscountCartTypeID, ProductModelTypeID, BrandID) {

        const Body = {
            "Object": "Site_CodeDiscontTrans",
            "Values": {
                "UsedCount": UsedCount
            },
            "filters": `where DiscountCartID = ${DiscountCartID} and DiscountCartTypeID = ${DiscountCartTypeID} and  ProductModelTypeID= '${ProductModelTypeID}' and BrandID= ${BrandID}`
        };
        axios.request({
            url: config.baseURL + "update",
            data: Body, method: "PUT", responseType: "json"
        })
            .then(function (response) {

                //   toast.success("success");

            })
            .catch(function (error) {
                console.log(error);
                // toast.error("خطأ:100024");
            });


    };
    async function appLy_discount() {

        CodeInfo.map(a => {

            if (a.CartItemsTypeID === 1)//خصم المركبات
            {

                list().filter(a => a.Type === "car").forEach(function (part, index, list) {
                    if ((list[index].BrandID === list[index].BrandID && list[index].modelTypes === a.ProductModelTypeID) && a.DiscountCartTypeID === 1) {
                        update(list[index].id, "price", list[index].price - (list[index].price * (a.DiscountCartRate / 100)))
                        update(list[index].id, "Discount", list[index].Discount + (list[index].price * (a.DiscountCartRate / 100)))


                    }
                    else if ((list[index].BrandID === list[index].BrandID && list[index].modelTypes === a.ProductModelTypeID) && a.DiscountCartTypeID === 2) {
                        update(list[index].id, "price", list[index].price - (list[index].price * (a.DiscountCartRate / 100)))
                        update(list[index].id, "Discount", list[index].Discount + a.DiscountCartAmount)
                    }
                });

                toast.success("تمة عملية الخصم للمركبات بنجاح", {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }



        });

    };
    const [TotalTax, SetTotalTax] = useState(0)
    const [TotalDiscount, SetTotalDiscount] = useState(0);
    const [SumItemTotal, setSumItemTotal] = useState(0);
    const [SubTotal, setSubTotal] = useState(0);
    const [fullTotal, setfullTotal] = useState(0);
    const [MiniDownPayment, setMiniDownPayment] = useState(0);
    //Accounting for all items 
    function Account() {


        console.log("Account")

        let currentTotalTax = _.sum(list().map((p) => {
            return ((100 / 115) * ((p?.Type?.toLowerCase() == 'car' ? (p.price) : p.price) * p.quantity)) * 0.15;
        }));
        SetTotalTax(currentTotalTax);

        let currentTotalDiscount = _.sum(list().map((p) => {
            return calculateDiscount(p);
        }));
        SetTotalDiscount(currentTotalDiscount);

        let currentSumItemTotal = _.sum(list().map((p) => {
            return (p?.Type?.toLowerCase() == 'car' ? (p.MiniDownPayment ? p.MiniDownPayment : p.price) : p.price) * p.quantity;
        }));
        setSumItemTotal(currentSumItemTotal);
        let currentSumfullTotal = _.sum(list().map((p) => {
            return (p.price) * p.quantity;
        }));

        setfullTotal(currentSumfullTotal);
        let currentSubTotal = _.sum(list().map((p) => {
            return (p?.Type?.toLowerCase() == 'car' ? (p.MiniDownPayment ? p.MiniDownPayment : p.price) : p.price) * p.quantity;
        }));
        setSubTotal(currentSubTotal);


        let currentMiniDownPayment = _.sum(list().map((p) => {
            return (p.MiniDownPayment && p.MiniDownPayment != 0) ? p.MiniDownPayment * p.quantity : (p?.Type?.toLowerCase() == 'car' ? (p.MiniDownPayment ? p.MiniDownPayment : p.price) : p.price) * p.quantity;
        }));
        setMiniDownPayment(currentMiniDownPayment);

    };

    //RequsetBanner
    async function RequsetBanner() {

        try {

            axios.request({
                baseURL: config.controllURL,
                url: lang + "/api/Cart/Cart",
                method: "get",
            }).then(function (response) {


                console.log('response.data.banner:', response.data.banner);
                setBanner(response.data.banner)

            })
                .catch(function (error) {
                    console.log(error);
                });


        } catch (error) {

        }
    }
    //PaymentMAin
    async function PaymentMAin(status, RefrancePayment, CAmount, CID,
        CCode, InfoLocation, InfoBranch, ByLocation, ByBranch,
        IsReservation, IsPayment, PaymentAmount, FinalTotal, AmountShipping,
        SubAmountTotal, TotalTaxAmount, TotalDiscount, Product, UserID, MiniDownPayment, MethodPayment) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const { ipv4 } = await getUserIpAddress();
        var raw = {
            "URL": "https://hassanjameel.com.sa/purchaseMsg/",
            "Lang": lang,
            "Product": Product,
            "UserID": UserID,
            "customerDetails": {
                "IP": ipv4
            },
            "DataAmount": {
                "FinalTotal": FinalTotal,
                "AmountShipping": AmountShipping,
                "SubAmountTotal": SubAmountTotal,
                "TotalTaxAmount": TotalTaxAmount,
                "TotalDiscount": TotalDiscount,
                "MiniDownPayment": MiniDownPayment
            },
            "PaymentAmount": PaymentAmount,
            "TypePaymnet": {
                "IsReservation": IsReservation,
                "IsPayment": IsPayment,
                "PaymnetTypeID": MethodPayment == "Financing" ? "Credit" : "Cash",//Credit - Cash
                "MethodPayment": MethodPayment
            },
            "DeliveryType": {
                "ByBranch": ByBranch,
                "ByLocation": ByLocation
            },
            "DeliveryData": {
                "InfoBranch": InfoBranch,
                "InfoLocation": InfoLocation
            },
            "CobonData": {
                "Code": couponDetail?.coupon?.code ?? null,
                "ID": couponDetail?.coupon?.id ?? null,
                "Amount": CAmount
            },
            "RefrancePayment": RefrancePayment,
            "status": status
        };

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        console.log("Payment", raw);
        axios.request({
            url: config.paymentURL + "/api/Payment/Payment", headers: myHeaders,
            data: raw, method: "POST", responseType: "json"
        }).then(function (response) {
            console.log('response1', response);
            // console.log(response.data.URL_Paytabs);
            console.log('MethodPayment', MethodPayment);
            if (MethodPayment == "Online" || MethodPayment == "Tamara") {

                if (response?.data?.CartID) {

                    saveData('currentCartID', response?.data?.CartID, false);
                }


                if (response?.data?.URL_Paytabs) {


                    try {
                        localStorage.removeItem("__cart");
                        removeAllcartItems();
                    } catch (error) {

                    }



                    window.location.href = response.data.URL_Paytabs;


                } else {

                    // try {
                    //     localStorage.removeItem("__cart");
                    //     removeAllcartItems();
                    // } catch (error) {

                    // }

                    // setShowFinalPopup(true);
                    toast.error(trans("error:unknown_failure"));
                }

            } else {


                try {
                    localStorage.removeItem("__cart");
                    removeAllcartItems();
                } catch (error) {

                }

                window.location.href = "https://hassanjameel.com.sa/purchaseMsg/" + response?.data?.GUID;
                // setShowFinalPopup(true);

            }


            //  window.open(response.data.callback);
            // window.location.href = response.data.callback;
            // window.location.href = (`/${lang}/purchaseMsg?q=${response.data.URL_Paytabs}`)
            //clear();
            //console.log('response1',response);
        }
        ).then((response2) => {
            console.log('response2', response2);
        })
            .catch(error => console.log('error', error));
    }

    useEffect(async () => {
        try {
            await RequsetBanner();
            await GetBranch();
        } catch (error) {
            console.log(error);
        }


    }, []);

    const handlePayment = async (MethodPayment) => {

        const isLogin = getData('isAuthenticated');
        if (isLogin) {

            if (list()?.filter(a => a.Type == "car") > 0 && MethodPayment == 'Tamara') {
                toast.warn(trans("new:cant_use_tamata"));
                return;
            }


            console.log("handlePayment:");
            const cleanData = JSON.parse(getData(User_Data_Key, true));
            console.log("handlePayment:", cleanData);
            const user_id = cleanData.id.toString().split('_')[1];
            if (user_id) {
                if ((!currentDeliveryMethod?.locationMethod) && (!currentDeliveryMethod?.branchMethod)) {
                    toast.warn(trans("new:not_delivery_selected"));
                } else if (currentDeliveryMethod?.locationMethod && (ValueCitie == '' || ValueCitie == null)) {
                    toast.warn(trans("new:not_delivery_selected_loc"));
                } else if (currentDeliveryMethod?.branchMethod && (ValueBranch == '' || ValueBranch == null)) {
                    toast.warn(trans("new:not_delivery_selected_branch"));
                } else {
                    await PaymentMAin(
                        1,
                        null,
                        null,
                        null,
                        null,
                        (currentDeliveryMethod?.locationMethod ?? false) ? ValueCitie : null,
                        (currentDeliveryMethod?.branchMethod) ?? false ? ValueBranch : null,
                        currentDeliveryMethod?.locationMethod ?? false,
                        currentDeliveryMethod?.branchMethod ?? false,
                        false,
                        true,
                        PaymentAmount, SumItemTotal, Shipping.Cost, SubTotal, TotalTax, TotalDiscount,
                        list().map(a => {
                            if (a.Type === "Bouquet")
                                return {
                                    id: a.id,
                                    quantity: a.quantity,
                                    colorID: a.colorID ? a.colorID : null,
                                    Type: 7,
                                    price: a.price,
                                    CartID: null,
                                    VIN: null,
                                    MiniDownPayment: null,
                                    packageId:a.packageId
                                };
                            if (a.Type === "car")
                                return {
                                    id: a.ProductID,
                                    quantity: a.quantity,
                                    colorID: a.colorID ? a.colorID : null,
                                    Type: 1,
                                    price: (a.MiniDownPayment ? a.MiniDownPayment : (a.price > 5000 ? 5000 : a.price)),
                                    CartID: null,
                                    VIN: null,
                                    MiniDownPayment: a.MiniDownPayment ? a.MiniDownPayment : 5000
                                };
                            if (a.Type === "Campaign")
                                return {
                                    id: a.id,
                                    quantity: a.quantity,
                                    colorID: a.colorID ? a.colorID : null,
                                    Type: 4,
                                    price: a.price,
                                    CartID: null,
                                    VIN: null,
                                    MiniDownPayment: a.MiniDownPayment


                                };
                            if (a.Type === "part")
                                return {
                                    id: a.id,
                                    quantity: a.quantity,
                                    colorID: a.colorID ? a.colorID : null,
                                    Type: 2,
                                    price: a.price,
                                    CartID: null,
                                    VIN: null,
                                    MiniDownPayment: a.MiniDownPayment

                                };
                            if (a.Type === "service")
                                return {
                                    id: a.ProductID,
                                    quantity: a.quantity,
                                    colorID: a.colorID ? a.colorID : null,
                                    Type: 3,
                                    price: a.price,
                                    CartID: null,
                                    VIN: a.name,
                                    MiniDownPayment: a.MiniDownPayment
                                };
                        })
                        , parseInt(user_id), MiniDownPayment, MethodPayment);
                }
            } else {
                toast.error(trans("new:login_first"), {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } else {
            thisConfirmContext.onShowRegistrationPup(true);

        }
    };

    const [Shipping, setShipping] = useState({ UnitTime: trans("new:day"), CountTime: 0, Cost: 0 });

    const [ValueCitie, setValueCitie] = useState("");
    const [ValueBranch, setValueBranch] = useState("");

    function getValueBranch(option) {
        setValueBranch(option.value);
    }
    const [Size, SetSize] = useState(0);
    var currentSize = 0;
    const CategoryCar = (Category) => {

        if (Size != currentSize) {
            currentSize = Category;
            SetSize(Category);
        }
    }
    async function CarShippingInfo(Citey, mSize) {
        setValueCitie(Citey.value)
        const Body = {
            "name": "Site_CarShippingInfo",
            "values": {
                "City": `${Citey.value}`,
                "categore": `${mSize}`
            }
        }
        await axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {
                // console.log(response.data.ApiObjectData);
                var currentvalue = { ...Shipping };
                currentvalue.Cost = response.data[0].CostShipping
                currentvalue.UnitTime = response.data[0].Type
                currentvalue.CountTime = response.data[0].TimeDelivary
                setShipping(currentvalue);
                console.log(currentvalue);
                setShipp(true);
            })
            .catch(function (error) {
                console.log(error);
                var currentvalue = { ...Shipping };
                currentvalue.Cost = 0
                currentvalue.UnitTime = trans("new:not_avilable");
                currentvalue.CountTime = null
                setShipping(currentvalue);
                console.log(currentvalue);
            });

    }

    const [PaymentAmount, setPaymentAmount] = useState(0.0);
    const refrechDateCartItems = async () => {
        const data = await initCartItems();

        console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", data);

        setIsGetCartData(true);

        Account();


    }

    useEffect(async () => {

        await refrechDateCartItems();

        await handleFetchCountries();
    }, []);

    // useEffect(async () => {

    //     await refrechDateCartItems();

    // }, [list().length]);
    //-----------------------------------------------
    const [couponDetail, setCouponDetail] = useState(null);
    const [clearCoupon, setClearCoupont] = useState(false);
    const [test, setTest] = useState(list());

    //to apply the coupon discount on the cart
    const applyCoupon = (code) => {
        const _id = getData("secretKey");
        if (code) {
            if (_id) {
                axios.request({
                    baseURL: config.controllURL,
                    url: lang + "/api/cart/CouponDetails",
                    method: "post",
                    data: {
                        "couponCode": code,
                        "id": _id
                    }
                }).then(response => {
                    const responseData = response.data;
                    if (responseData?.items?.length > 0) {
                        setCouponDetail(responseData);
                        setClearCoupont(true);
                    } else {
                        setCouponDetail(null);
                        setClearCoupont(false);
                    }
                }).catch(error => {
                    console.log(error);
                })
            } else {
                toast.error(trans("new:login_first"));
                thisConfirmContext.onShowRegistrationPup(true);
            }
        } else {
            SetTotalDiscount(0);
        }
    };

    // calculate discount
    const calculateDiscount = (itemInfo) => {
        let discountResult = [];
        try {
            if (couponDetail?.items) {
                console.log(itemInfo);
                discountResult = couponDetail?.items.map((item) => {
                    let result = 0;
                    switch (item?.itemType?.toLowerCase()) {
                        case 'car':
                            const { BrandID: brand, groupID, modelTypes, MiniDownPayment, price, quantity } = itemInfo;
                            if (brand == item?.brandID && groupID === item?.productGroupID && modelTypes === item?.productModelTypeID) {
                                result = getDiscountValueByType(item, price, quantity);
                            }
                            break;
                        case 'part': case 'service': case 'bouquet': case 'gift': case 'campaign':
                            result = getDiscountValueByType(item, price, quantity);
                            break;
                    }
                    return result;
                });
            }
        } catch (error) {
            console.log(error);
        }
        return sum(discountResult) ?? 0;
    };

    // apply discount by type
    const getDiscountValueByType = (item, price, quantity) => {
        let result = 0;
        const { discountType, discountAmount, discountRate } = item;
        if (discountType?.toLowerCase() === 'amount') {
            const discountValue = discountAmount * quantity;
            result = discountValue;
        } else if (discountType?.toLowerCase() === 'rate') {
            const priceRateValue = ((price * quantity) * (discountRate / 100));
            result = priceRateValue;
        }
        return result;
    };

    // to get info of current ipv4
    const getUserIpAddress = async () => {
        let currentIpv4Info = {};
        try {
            const response = await axios.get('https://geolocation-db.com/json/');
            if (response.status === 200) {
                const { IPv4, country_code, country_name } = response.data;
                currentIpv4Info.ipv4 = IPv4;
                currentIpv4Info.countryCode = country_code;
                currentIpv4Info.countryName = country_name;
            }
        } catch (error) {
            toast.error(trans("extra:not_connection"))
            console.log(error);
        }
        return currentIpv4Info;
    };


    // to apply discount based on the btn clicked
    useEffect(() => {
        Account();
    }, [clearCoupon]);



    //-------------------------------------------
    const [cities, setCities] = useState([]);
    // to fetch all SAU countries
    const handleFetchCountries = async () => {
        let countries = [];
        try {
            const response = await axios.post(
                config.baseURL + 'func',
                {
                    "Name": "site_ShippingCities"
                }
            );
            if (response.status == 200) {
                countries = response.data.map(item => {
                    return { value: item.ID, text: item[`${trans("model:description")}`] }
                });
            }
        } catch (error) {
            console.log('error in fetching the countries of SAU', error);
        }
        setCities(countries);
    };

    const handleCheckCarsCount = async () => {

        const listError = [];

        const isLogin = getData('isAuthenticated');
        if (isLogin) {

            await list().forEach(async (a, index) => {
                if (a.Type == "car") {

                    // console.log("MMMMMMMMMMMM");
                    const cSN = await getSerialsBuyOnlineCount(a.modelTypeID, a.year, a.colorID);
                    // console.log("MMMMMMMMMMMM", cSN);
                    if (cSN < a.quantity) {
                        listError.push(a.id);
                        // console.log("MMMMMlistErrorMMMMMMM", listError);
                    }




                } else if (a.Type == "service" || a.Type == "Bouquet") {

                    if (!a.Orderdate)
                        listError.push(a.id);

                }
                if ((index + 1) == list().length) {
                    setCarErrors(listError);

                    if (listError.length <= 0) {

                        const isLogin = getData('isAuthenticated');
                        if (isLogin) {
                            setIsOpen(!isOpen);
                        } else {
                            thisConfirmContext.onShowRegistrationPup(true);

                        }
                    } else {
                        setIsGetCartData(false);
                        setTimeout(() => {
                            setIsGetCartData(true);
                        }, 100);

                    }

                }


            });


        } else {
            thisConfirmContext.onShowRegistrationPup(true);

        }



        return listError;

    };
    const getSerialsBuyOnlineCount = async (Type, Model, ColorID) => {
        var count = 0;


        const response = await axios.post(
            config.baseURL + 'func',
            {
                "Name": "siteInvProductSerialsBuyOnlineCount",
                "Values": {
                    "Type": `${Type}`,
                    "Model": `${Model}`,
                    "ColorID": `${ColorID}`
                }
            }
        );


        count = response.data[0].countSN;
        console.log("getSerialsBuyOnlineCount>>>>return>>>>>", count);
        return count;
    };
    return (
        <Fragment>

            <div className="nice-sm-hidden">
                <Banner
                    subtitle={banner?.subtitle}
                    title={banner?.title}
                    desc={banner?.excerpt}
                    background={banner?.imagePath}
                    backOpasity={banner?.backOpasity}
                />
            </div>

            <div className="container my-40">
                <Breadcrumb className={"mt-16 "}
                    links={[
                        {
                            name: trans("cart:cart")
                        }
                    ]}
                />
                {
                    // isGetCartData &&
                    // getCartList()

                    (list() != null && list().length > 0) ?

                        <Fragment>
                            <div className={"nice-row " + languageDir}>
                                <div className="nice-col-md-9 nice-order-lg-last nice-order-md-last nice-order-sm-last nice-order-xl-last ">
                                    {list().filter(a => a.Type === "car").length > 0 && <PageHeading title={trans("new:vehicles")} />}

                                    {
                                        list().filter(a => a.Type === "car").map((data, index) => {
                                            return (
                                                <Cart
                                                    key={index+100}
                                                    productID={data.ProductID}
                                                    slug={data?.slug}
                                                    img={data.image}
                                                    id={data.id}
                                                    brand={lang == "ar" ? data.brand : data.brandEn}
                                                    price={data.price}
                                                    name={lang == "ar" ? data.name : data.nameEn}
                                                    count={data.quantity}
                                                    modelTypes={lang == "ar" ? data.modelTypes : data.modelTypesEn}
                                                    Tax={data.Tax}
                                                    modelTypeID={data.modelTypeID}
                                                    colorID={data.colorId}
                                                    Discount={data.Discount}
                                                    year={data?.year}
                                                    colorName={lang == "ar" ? data?.colorName : data?.colorNameEn}
                                                    onDelete={
                                                        async () => {
                                                            // remove(data.id);
                                                            await removecartItems(data.ProductID, data.colorID, data.modelTypeID, null, data?.id)
                                                            refrechDateCartItems();

                                                        }
                                                    }
                                                    Account={Account}
                                                    CategoryCar={CategoryCar(data?.groupID)}

                                                    carErrorCount={carErrors.includes(data.id)}

                                                />

                                            );
                                        })
                                    }
                                    {/* {list().filter(a => a.Type === "accessories").length > 0 && <PageHeading title={trans("new:accessories")} />}
                                        {
                                            list().filter(a => a.Type === "accessories").map((part, index) => {
                                                return (
            
                                                    <CartItems
                                                        key={index}
                                                        img={part.image}
                                                        id={part.id}
                                                        price={part.price}
                                                        name={part.name}
                                                        showCategor={false}
                                                        count={part.quantity}
                                                        Tax={part.Tax}
                                                        Discount={part.Discount}
                                                        onDelete={
                                                            async () => {
                                                                // remove(part.id);
                                                                await removecartItems(part.id, null, null)
                                                                window.location.reload();
            
                                                            }
                                                        }
                                                        Account={Account}
                                                    />
            
                                                );
                                            })
                                        } */}
                                    { 
                                        list().filter(a => a.Type === "part").length > 0 && 
                                                <PageHeading 
                                                    title={trans("new:spare_parts")} 
                                                /> 
                                    }
                                        
                                        {
                                            list().filter(a => a.Type === "part").map((part, index) => {
                                                return (
            
                                                    <CartItems
                                                        key={index}
                                                        img={part.image}
                                                        id={part.id}
                                                        productNo={part?.ProductID}
                                                        brand={part.brand}
                                                        price={part.price}
                                                        name={part.name}
                                                        count={part.quantity}
                                                        Categior={part.Engine_Groups}
                                                        Tax={part.Tax}
                                                        Discount={part.Discount}
                                                        onDelete={
                                                            async () => {
                                                                // remove(part.id);
                                                                await removecartItems(part?.ProductID, null, null)
                                                                window.location.reload();
            
                                                            }
                                                        }
                                                        Account={Account}
                                                    />
            
                                                );
                                            })
                                        }
                                    {/* {list().filter(a => a.Type === "Campaign").length > 0 && <PageHeading title={trans("new:campaigns")} />}
                                        {
                                            list().filter(a => a.Type === "Campaign").map((c, index) => {
                                                return (
            
                                                    <CampaignCart img={"data:image/png;base64," + c.image}
                                                        id={c.id}
                                                        key={index}
                                                        brand={c.brand}
                                                        price={c.price}
                                                        name={c.name}
                                                        count={c.quantity}
                                                        Categior={c.Engine_Groups}
                                                        Tax={c.Tax}
                                                        Discount={c.Discount}
            
                                                        onDelete={
                                                            async () => {
                                                                // remove(c.id);
                                                                await removecartItems(c?.id, null, null)
                                                                window.location.reload();
            
                                                            }
                                                        }
                                                        Account={Account}
            
            
                                                    />
            
                                                );
                                            })
                                        } */}
                                    {list().filter(a => a.Type === "Bouquet").length > 0 && <PageHeading title={trans("new:packages")} />}
                                    {
                                        list().filter(a => a.Type === "Bouquet").map((c, index) => {
                                            return (

                                                <BouquetCart
                                                    key={index+200}
                                                    img={c.image}
                                                    id={c.id}
                                                    productID={c.ProductID}
                                                    brand={lang == "ar" ? c.brand : c.brandEn}
                                                    price={c.price}
                                                    name={lang == "ar" ? c.name : c.nameEn}
                                                    count={c.quantity}
                                                    Categior={c.Engine_Groups}
                                                    Tax={c.Tax}
                                                    Desc={(lang == "ar" ? c.modelTypes : c.modelTypesEn) + " " + (c?.year ? c?.year : "")}
                                                    Discount={c.Discount}
                                                    packageId={c.packageId}
                                                    Orderdate={c.Orderdate}
                                                    year={c?.year}
                                                    onDelete={
                                                        async () => {
                                                            // remove(c.id);
                                                            await removecartItems(c.ProductID, null, c.modelTypeID, null, c.id, c.packageId)
                                                            refrechDateCartItems();

                                                        }
                                                    }
                                                    Account={Account}

                                                    ErrorDate={carErrors.includes(c.id)}

                                                    refrechDate={() => refrechDateCartItems()}
                                                />

                                            );
                                        })
                                    }
                                    {list().filter(a => a.Type === "service").length > 0 && <PageHeading title={trans("new:maintenance")} />}
                                    {
                                        list().filter(a => a.Type === "service").map((c, index) => {
                                            return (

                                                <ServiceBox
                                                    img={c.image}
                                                    key={index+300}
                                                    id={c.ProductID}
                                                    price={c.price}
                                                    count={c.quantity}
                                                    Orderdate={c.Orderdate}
                                                    productNo={c?.productNo}
                                                    name={lang == "ar" ? c.name : c.nameEn}
                                                    Tax={c.Tax}
                                                    modelTypes={lang == "ar" ? c.modelTypes : c.modelTypesEn}
                                                    Discount={c.Discount}
                                                    modelCode={c?.modelCode}
                                                    onDelete={
                                                        async () => {
                                                            // remove(c.id);
                                                            await removecartItems(c?.ProductID, null, null, c?.modelCode, c.id)
                                                            refrechDateCartItems();

                                                        }
                                                    }
                                                    Account={Account}
                                                    ErrorDate={carErrors.includes(c.id)}

                                                />

                                            );
                                        })
                                    }
                                </div>
                                <div className={"cart-grid-info nice-col-md-3 nice-order-lg-first nice-order-md-first nice-order-sm-first nice-order-xl-first"} >
                                    <div className="py-4">
                                        <SectionHeading
                                            hasRuler={false}
                                            title={trans("extra:choose_receive_way")}
                                        >
                                            <RadioInput
                                                value1={list().filter(a => a.Type === "car").length > 0 ? 1 : null}
                                                value2={2}
                                                name='recieveMethod'
                                                text1={list().filter(a => a.Type === "car").length > 0 ? trans("new:delivery") : null}
                                                text2={trans("new:branch")}
                                                onChangeValue={(value) => {
                                                    const cunrrentvalue = currentDeliveryMethod;
                                                    setDeleviryType(value);
                                                    cunrrentvalue.branchMethod = value === 2 ? true : false;
                                                    cunrrentvalue.locationMethod = value === 1 ? true : false;
                                                    setcurrentDeliveryMethod(cunrrentvalue);
                                                }}
                                            />
                                            {
                                                <>
                                                    {develviryType == 2 && <> <DropDownInput
                                                        title={trans("cartFile:branch")}
                                                        onSelect={(option) => {
                                                        }}
                                                        options={[
                                                            ...Branches.map(A => {
                                                                if (lang === "ar") {
                                                                    return { value: A.SiteID, text: A.DescriptionAr };
                                                                }
                                                                else {
                                                                    return { value: A.SiteID, text: A.DescriptionEn };
                                                                }

                                                            })]}
                                                        onfilter={getValueBranch}
                                                    />

                                                        <BranchesInfo
                                                            values={branchesDetails}
                                                        />

                                                    </>

                                                    }
                                                    {develviryType == 1 && <>
                                                        <DropDownInput
                                                            title={trans("new:city")}
                                                            options={[...cities]}
                                                            onfilter={(Citey) => CarShippingInfo(Citey, Size)}
                                                        />
                                                    </>
                                                    }
                                                </>
                                            }
                                            <div className="my-12">
                                                <CouponControl
                                                    onReset={() => {
                                                        setClearCoupont(false);
                                                        SetTotalDiscount(0);
                                                        setCouponDetail(null);
                                                    }}
                                                    onSubmit={(codeValue) => {
                                                        applyCoupon(codeValue);
                                                    }}
                                                    reset={clearCoupon}
                                                    discountPrice={TotalDiscount}
                                                    couponCode={couponDetail?.coupon?.description}
                                                />




                                            </div>
                                            <CartDetails
                                                shippingTime={Shipping.CountTime}
                                                shippingUnitTime={Shipping.UnitTime}
                                                subTotal={list().filter(a => a.Type == "car").length > 0 ? SubTotal : null}
                                                shippingCost={Shipping.Cost}
                                                totalTax={TotalTax}
                                                totalDiscount={TotalDiscount}
                                                sumItemTotal={SumItemTotal - TotalDiscount}
                                                miniDownPayment={MiniDownPayment}
                                                fullTotal={fullTotal}
                                            />

                                        </SectionHeading>


                                    </div>
                                    <div className="nice-flex nice-flex-row ">

                                        {/* 
                                        <SimpleButton
                                            classes="bg-black nice-flex-1 mb-2"
                                            onClick={() => {

                                            }}
                                            iconPath='/assets/icons/apple-pay.png'
                                        /> */}



                                        <SimpleButton
                                            classes="bg-primary text-white text-20 nice-flex-1 mb-2"
                                            title={trans("cartFile:comlete_buying")}
                                            onClick={async () => {

                                                const mm = await handleCheckCarsCount();
                                                console.log("handleCheckCarsCount>>>>>:", mm);
                                                //  paymentTest();
                                                // Payment(SubTotal.toString());
                                            }}
                                        />


                                    </div>
                                    <PaymentPlatforms
                                        isToggle={isOpen}
                                        title={trans("new:avilable_platforms")}
                                        isFreeze={false}
                                        isTamara={(list()?.filter(a => a.Type == "car")?.length <= 0) ? true : false}
                                        onPaytabsClick={async () => {
                                            // await handlePayment();
                                        }}
                                        onSadadClick={async () => {
                                            // await handlePayment();
                                        }}

                                        onPaymentClick={async (type) => {
                                            await handlePayment(type);
                                        }}
                                    />
                                </div>

                            </div>

                        </Fragment>

                        :

                        <div className={"container flex flex-col justify-center items-center"}>
                            <img src="/assets/svg_background/empty_cart.svg" alt="empty cart" className={"w-1/2 my-8"} />
                            <h2 className="text-primary text-40 my-8">{trans("messages:empty_cart")}</h2>
                        </div>


                }

            </div>

        </Fragment>
    );
}

