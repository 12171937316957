import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PartRating } from "./rate";
import config from '../../../configuration/config.json'
export function RatingSection({ productID }) {
    const { t: trans } = useTranslation(["common", "model"]);
    const [comments, setComments] = useState([]);
    useEffect(() => {
        handleFetchComments();
    }, []);

    // to fetch comments
    const handleFetchComments = () => {
        try {
            if (productID) {
                axios.post(
                    config.baseURL + 'func',
                    {
                        "Name": "SiteFetchSparePartEvaluations",
                        "Values": {
                            "GUID": `${productID}`
                        }
                    }
                ).then(response => {
                    console.log("response.data:",response.data)
                    setComments(response.data);
                }).catch(error => {
                    console.log('rating section has error request', error);
                });
            }
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <>
            {
                comments.map((item, index) => {
                    return (
                    
                    <div key={index} className="part-info-detail-rate container">
                        <div className="part-info-detail-rate-container">
                            <div className={"part-info-detail-rate-img"}>
                                <img className={"object-full object-cover"} 
                                src={item.Logo ? "data:image/png;base64," + item.Logo : '/assets/images/profile.png'}
                                 alt={item[`${trans("model:description")}`]} />
                            </div>
                            <div className={"part-info-detail-rate-text"}>
                                <h4 className={"text-16 text-primary mb-4"}>{item[`${trans("model:description")}`]}</h4>
                                <div className={"mb-4 flex items-center"}>
                                    <PartRating intialValue={item.Stars} readonly={true} />
                                    <time className={"mr-6 text-16 text-gray"}>{item.CreatedDate}</time>
                                </div>
                                <p className={"text-16"}>
                                    {
                                        item.Comment
                                    }
                                </p>
                            </div>
                        </div>
                    </div>
                    )
                })
            }
        </>
    );
}
