import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import { SwiperSlide, Swiper } from 'swiper/react';
import axios from "axios";
import { useEffect, useState } from "react";
import config from '../configuration/config.json';
import { useHistory, useRouteMatch } from "react-router";
import { saveData, User_Data_Key, getData } from "../utils/localStorageManager";
import { forEach } from "lodash";
SwiperCore.use([Pagination, Navigation, Autoplay]);
const AdsBanner = () => {
    const [adsBanners, setAdsBanners] = useState([]);
    const { url } = useRouteMatch();
    const history = useHistory();
    useEffect(() => {
        async function fetchData() {

            var SiteFetchAdsBanners = [];

            // for  (var i = 0; i <= 10; i++) {
            //     try {
            //         let data = [];
            //         data = await JSON.parse(getData("SiteFetchAdsBanners-" + i, true));
            //         console.log("getData SiteFetchAdsBanners", data);
            //         if (data && data.length > 0) {
            //             SiteFetchAdsBanners.push(data);
            //         }
            //     } catch (error) {
            //         break;

            //     }
            // }

            // if (SiteFetchAdsBanners && SiteFetchAdsBanners.length > 0) {
            //     setAdsBanners(SiteFetchAdsBanners);
            // }


            try {
                const response = await axios.post(config.baseURL + 'func',
                    {
                        "Name": "SiteFetchAdsBanners"
                    });

                // if (JSON.stringify(SiteFetchAdsBanners) != JSON.stringify(response.data)) {
                console.log("axios SiteFetchAdsBanners", response.data);

                setAdsBanners(response.data);

                // response.data.map((item,index2)=>{
                //     console.log("SiteFetchAdsBanners-"+index2,  response.data.filter((item,index3) => index2 == index3));
                //     saveData("SiteFetchAdsBanners-"+index2, response.data.filter((item,index3) => index3 == 0), true);

                // })


                // }

            } catch (error) {
                console.log(error);
            }
        }
        fetchData();
    }, []);

    return (
        <>
            {
                adsBanners && (
                    <div className="container">
                        <Swiper
                            slidesPerView={1}
                            resizeObserver={"true"}
                            autoplay={true}
                            loop={false}
                            observer={true}
                        >
                            {
                                adsBanners?.map((item, index) => {
                                    return (
                                        <SwiperSlide key={index}>
                                            <div
                                                className="ads-banner border-radius mb-20" style={{ backgroundImage: `url('${(config.mainURL + item.AdsImage).replaceAll("\\","/")}')` }}
                                                onClick={() => {
                                                    if (item?.HasRedirect && item?.AdsUrl) {
                                                        history.push(`${url}${item?.AdsUrl}`)
                                                    }
                                                }}
                                            >
                                            </div>
                                        </SwiperSlide>
                                    )
                                })
                            }
                        </Swiper>
                    </div>
                )
            }
        </>
    )

};

export default AdsBanner;