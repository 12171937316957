import {Fragment, useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Banner from "../../components/banner";
import {InputField} from "../../components/_input/_input";
import {TextArea} from "../../components/_input/_textArea";
import {FormButton} from "../../components/_input/_button";
import Breadcrumb from "../../components/__simple/breadcrumb";
import {CheckInput} from "../../components/_input/_checkInput";
import axios from "axios";
import { useParams} from "react-router";
import Joi from 'joi';
import { useHistory } from "react-router";
import {GetMessages, formValidate} from '../../utils/validation';
import config from '../../configuration/config.json';
import {toast} from "react-toastify";
import {getCleanSlug} from '../../utils/slugManager';
import { getData, User_Data_Key } from "../../utils/localStorageManager";
import PhoneDropDown from "../../components/_input/_phoneDropDown";

export function OfferOrderPage({offerId}) {
    const {t: trans} = useTranslation(["heading", "maintenance","offers", "form", "inputs", "eparts","common","info","extra"]);
    const [requestForm, setRequestForm] = useState({
        name: '',
        address:'',
        phone: '',
        email: '',
        message: '',
    });
    const [error, setError] = useState({});
    const [selectedPrivacy, setSelectedPrivacy] = useState(false);
    const schema = Joi.object({
        name: Joi.string().required().messages(GetMessages()),
        address: Joi.string().required().messages(GetMessages()),
        email: Joi.string().email({minDomainSegments: 2, tlds: {allow:false}}).required().messages(GetMessages()),
        phone: Joi.string().min(13).required().messages(GetMessages()),
        message: Joi.string().required().messages(GetMessages()),
    });
    const history = useHistory();

    let {slug} = useParams();

    let lang = localStorage.getItem("i18nextLng");
    const languageDir = 'language-' + lang;
    const [banner, setBanner] = useState({});
    const [offer, setOffer] = useState([{text: "", value: ""}]);

    const submitRequest = async () => {
        const result = formValidate(schema, requestForm) ?? {};
        setError(result);
        if (selectedPrivacy){
            try {
                let _userID= null;
                const _isLogin = getData('isAuthenticated');
                if(_isLogin){
                   const _userData =  JSON.parse(getData(User_Data_Key,true));
                   _userID = Number(_userData.id.toString().split('_')[1]);
                }
                if (Object.keys(result).length === 0) {
                    const response = await axios.post(
                        config.baseURL + "add",
                        {
                          "Object":"SiteOfferReservation",
                          "Values":{
                          "UserID":_userID ?? null,
                          "OfferID":offerId,
                          "DescriptionAr": requestForm.name,
                          "DescriptionEn":requestForm.name,
                          "Email":requestForm.email,
                          "Phone":requestForm.phone
                          }
                        }
                    );
                    if (response.status === 200 || response.status === 201) {
                        setError({});
                        const val = {...requestForm};
                        setRequestForm({
                            name: '',
                            address:'',
                            phone: val.phone,
                            email: '',
                            message: '',
                        });
                        toast.success(trans("common:order-request-success"));
                   }
                }
            } catch (e) {
                console.log(e);
            }
        } else {
            toast.error(trans("common:not-agree"));
        }
    }

    ///Finsh_code_hamza
    useEffect(() => {
        callback();
    }, [lang,slug]);

    const callback = useCallback(async()=>{
        try {
            const response = await axios.request({
                 baseURL: config.controllURL,
                url: lang + "/api/offers/detail/" + getCleanSlug(),
                method: "get",
            });

            setBanner(response.data.banner);
            setOffer(response.data.offer);

        } catch (error) {
         console.log(error);
        }
    },[lang,slug]);

    return (
        <Fragment>
            <Banner
                subtitle={banner?.type}
                title={banner?.title}
                desc={banner?.excerpt}
                secondImage={"data:image/png;base64," + banner?.image}
                offerDate={banner?.endDate}
                backOpasity={banner?.backOpasity}
            />
            <div className="container">
                <Breadcrumb className={"mt-16 "}
                            links={[
                                {
                                    name: trans("common:offers"),
                                    to: "/offers"
                                }, {
                                    name: banner.title,
                                    to: "/offers/detail/" + slug
                                },
                                {
                                    name:trans("info:reserve")
                                }
                            ]}
                />
            </div>

            <div className={"container mb-32 " + languageDir}>

                <div className={"grid-2-cols  gap-10 mb-10"}>
                    <div className={"inputs-group flex-grow-1"}>
                        <div>
                            <div className="job-heading">
                                {trans("maintenance:explanation")}
                            </div>
                            <p className={"text-16-children"}>
                                <div dangerouslySetInnerHTML={{__html: offer.content}}/>

                            </p>
                        </div>
                    </div>
                    <div className={"inputs-group flex-grow-1"}>
                        <h2 className="text-20 font-bold mb-6">
                            {trans("form:personal-info")}
                        </h2>
                        <InputField
                            value={requestForm.name}
                            placeholder={trans("form:name")}
                            name='name'
                            errorLabel={error?.name}
                            onChangeValue={
                                (e) => {
                                    const val = {...requestForm};
                                    val.name = e.target.value;
                                    setRequestForm(val);
                                }
                            }
                        />
                        <InputField
                            value={requestForm.address}
                            placeholder={trans("form:address")}
                            name='address'
                            errorLabel={error?.address}
                            onChangeValue={
                                (e) => {
                                    const val = {...requestForm};
                                    val.address = e.target.value;
                                    setRequestForm(val);
                                }
                            }
                        />
                        <PhoneDropDown
                            errorLabel={error?.phone}
                            onChangeValue={
                                (countryCode, phoneNumber) => {
                                    const val = {...requestForm};
                                    val.phone= `+${countryCode}${phoneNumber}`;;
                                    setRequestForm(val);
                                }
                            }
                        />
                        <InputField
                            value={requestForm.email}
                            placeholder={trans("form:email")}
                            name='email'
                            errorLabel={error?.email}
                            onChangeValue={
                                (e) => {
                                    const val = {...requestForm};
                                    val.email = e.target.value;
                                    setRequestForm(val);
                                }
                            }
                        />
                        <TextArea
                            value={requestForm.message}
                            placeholder={trans("form:message")}
                            name='message'
                            errorLabel={error?.message}
                            onChangeValue={
                                (e) => {
                                    const val = {...requestForm};
                                    val.message = e;
                                    setRequestForm(val);
                                }
                            }
                        />


                        <CheckInput oncheng={
                            (e) => {
                                setSelectedPrivacy(e.checked);
                            }
                        }>
                            <div className={"flex"} dangerouslySetInnerHTML={{__html: trans("form:agree_terms")}}/>

                        </CheckInput>

                        <FormButton
                            name={trans("extra:order")}
                            onClick={submitRequest}
                        />

                    </div>
                </div>


            </div>
        </Fragment>
    );
}