import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {Price} from "./__simple/_price";
import Popup from "../User/popup";

function CustomTabbyTamara({price}) {

    const {t: trans, i18n} = useTranslation(["common", "offers", "extra"]);
    let lang = i18n.language;

    const [showPopupPay, setShowPopupPay] = useState(null);


    return (
        <>
            <div className="w-100 my-10 nice-border-top-section">
                <h1 className="text-20  font-bold">{trans("info:buy-now-pay-later")}</h1>
                <div className="  items-cente my-5 grid  grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-2">
                    <div
                        className={' border-radius-sm p-5 box-shadow-sm nice-border-tabby flex-column  w-100  text-center bg-white nice-pointer'}
                        onClick={() => {


                            setShowPopupPay("tabby");
                        }}

                    >
                        <img className="w-30 h-rem-3" src="/assets/icons/tabby.png"/>
                        <div className="w-100 flex my-2 flex-row  gap-1 nice-flex-center">
                            <h3 className="text-20  font-family"> {trans("info:pay-4-interest-free-payments")}
                                <span
                                    className={" text-20 text-bold"}>  {(price / 4) ? <>{(lang == "ar" ? " بقيمة " : " of ")}
                                    <Price price={(price / 4)}/> </> : null} </span>

                            </h3>
                            <img className={"w-10 h-rem-2 " + (lang == "en" ? "rotate-180" : "")}
                                 src="/assets/icons/arrow-right.svg"/>
                        </div>
                    </div>


                    <div
                        className={' border-radius-sm p-5 box-shadow-sm nice-border-tamara flex-column w-100  text-center bg-white nice-pointer'}

                        onClick={() => {


                            setShowPopupPay("tamara");
                        }}
                    >

                        {

                        }
                        <img className="w-30 h-rem-3"
                             src={`/assets/icons/${lang == "ar" ? "Ar-tamara.svg" : "En-tamara.svg"}`}/>


                        <div className="w-100 my-2 flex flex-row  gap-1 nice-flex-center">
                            <h3 className="text-20  font-family"> {trans("info:pay-4-interest-free-payments")}
                                <span className={" text-20 text-bold"}>
                                                        {(price / 4) ? <>{(lang == "ar" ? " بقيمة " : " of ")}
                                                            <Price price={(price / 4)}/> </> : null}
                                                    </span>

                            </h3>
                            {/* <tamara-widget type="tamara-summary" amount='350' inline-type='0' inline-variant='text' config='{"badgePosition":"endLine","showExtraContent":""}' > */}

                            <img className={"w-10 h-rem-2 " + (lang == "en" ? "rotate-180" : "")}
                                 src="/assets/icons/arrow-right.svg" style={{width: "4rem", height: "2rem"}}/>
                            {/* </tamara-widget> */}
                        </div>
                        
                    </div>

                </div>
            </div>


            <Popup show={showPopupPay == null ? false : true}>
                <div className="offer-popup nice-flex nice-flex-center ">
                    <div className={"offer-popup-container p-4   " + (showPopupPay == "tabby" ? "h-80 " : "h-90")}>
                        <div className="offer-popup-container-header">
                            <span className='offer-popup-container-header-close text-block text-gray' onClick={() => {
                                setShowPopupPay(null)
                            }}>&times;</span>
                        </div>

                        {
                            showPopupPay == "tabby" ?
                                // <div className="tamara-faqs ">
                                <iframe className={"h-90 w-100"} src={`https://checkout.tabby.ai/promos/product-page/installments/${lang}/${(price) ? "?price=" + price : "?price=0"}&currency=SAR`}  ></iframe>
                                // </div>
                                :

                                // <div className="tamara-faqs ">
                                <iframe className={"h-90 w-100"} src={`https://cdn.tamara.co/widget-v2/tamara-widget.html?lang=${lang}&widgetType=information-widget&public_key=98ca3489-55a5-4392-9010-671549dbfba2${(price) ? "&amount=" + price : "&amount=0"}&paymentType=installment`} ></iframe>
                            // </div>


                        }


                    </div>
                </div>
            </Popup>
        </>
    );
}

export default CustomTabbyTamara;