import {configureStore, combineReducers} from '@reduxjs/toolkit'

import navReducer from './slices/navSlice';
import cartReducer from './slices/cartSlice';


const entitiesReducer = combineReducers({

    nav: navReducer,
    cart: cartReducer,

});

const reducers = combineReducers({
    entities: entitiesReducer,
});


export default configureStore({
    reducer: reducers,

},)