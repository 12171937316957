import { concat } from "lodash";
// this function is used to format the number based on the contry currency code
export const numberFormat = (number ,lang,showZero,classNameCurrency,displayCurrencyLiter = true, syntax = {code : 'SAR'  , locale : 'en'})=>{
const {locale} = syntax;
const code = lang == 'en' ? ' SAR ' : ' ر.س ';
const suffixCode = (locale == 'en' ? 'IN' : 'EG');
if (number == null || number == undefined || number == 'null' || number === '' || ((number == '0' || number == 0) && showZero == false)){

    return null;
}

if(displayCurrencyLiter) {
    // return concat(Intl.NumberFormat(locale + `-${suffixCode}`).format(number),code);
    return concat(Intl.NumberFormat().format(number), classNameCurrency ? <span key={Math.random} className={classNameCurrency}>{code}</span> : code);
} 

return Intl.NumberFormat().format(number);
// return concat(Intl.NumberFormat().format(number),code);

// return concat(Intl.NumberFormat().format(number), <span className={classNameCurrency}>{code}</span>);
};