import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Fragment } from "react";
import { Price } from "../../../components/__simple/_price";
// import {addToCart} from "../../../_functions/cart";
import { useUserCart } from '../../../User/hooks/useUserCart';

export function CompaignPart({ packageId, compaignId, GUID,
    onChooseCompaign,
    id,
    img,
    name = "إل سي 2020",
    category = '',
    brand = 'تويوتا',
    brandIcon = '',
    brandImage = '',
    salseprice,
    link,
    showDetails = true

}) {
    const { t: trans } = useTranslation("common");
    const { removecartItems, changecartItem } = useUserCart();
    const isToyota = (brand === "تويوتا" || brand === "toyota");
    const image = img ? "data:image/png;base64," + img : "/assets/images/offers/bg.png";
    return (
        <div className={"car-card  overflow-hidden  border-radius m-3"} >
            <Link to={link + "/" + GUID}>
                <div className={"car-card-type " + (isToyota || "lexus")} style={{ zIndex: 10 }}>
                    <div className={"car-card-type-brand " + (isToyota || "bg-lexus")}>
                        <img className={"filter-white pt-1"}
                            src={brandIcon} alt="toyota" />
                        <h4 className={"text-white text-12 pb-1"}>{brand}</h4>
                    </div>
                </div>
                <div className="car-img">

                    <img src={image} alt={name} className={'object-cover object-full '} style={{ objectFit: "cover" }} />

                </div>
            </Link>
            <div className="car-card-info border-radius box-shadow" style={{ height: '35%' }}>
                <div className="slide-info-text-price 30 space-x-4 space-x-reverse">
                    <span className={"text-16 mx-3"}>{trans("info:product_price")}</span>
                    <span className={"price  text-20"}><Price price={salseprice} /></span>
                </div>
                <div className="car-card-info-name">
                    <h3 className={"text-20 font-bold  text-line-clamp text-line-clamp-1"}>{name}</h3>
                    <div className="options-links">
                        <Link to={link + "/" + GUID} className={"text-14 text-primary text-inline-block mx-4"}>عرض</Link>
                        <Link onClick={() => {
                            if (onChooseCompaign) {
                                onChooseCompaign(true, compaignId, packageId);
                            }
                        }}
                            className={"text-14 text-primary text-inline-block"}>اطلب الان</Link>
                        <div onClick={() => {

                            var objectCart =
                            {
                                id: id,
                                name: name,
                                price: salseprice,
                                image: image,
                                Type: "Bouquet",
                            }

                            changecartItem(id, null, null, 1, null,null, objectCart);
                            // addToCart(
                            //     {
                            //         id: id,
                            //         name:name,
                            //         price:salseprice,
                            //         image: image,
                            //         Type:"Bouquet",
                            //     }
                            // )
                        }} className={"text-14 text-primary text-inline-block mx-4"}>شراء</div>
                    </div>

                </div>
                <p className='text-size-12'>
                    {showDetails}
                </p>
            </div>
        </div>

    );
}
