import {Fragment, useEffect, useState} from "react";
import SwiperCore, {Pagination, Navigation, Autoplay, EffectFade, EffectCoverflow, EffectFlip} from "swiper";
import {SwiperSlide, Swiper} from 'swiper/react';
import PageHeading from "../../../components/__simple/_pageheading";
import Offer from "../../../components/offer";
import {FilterButton} from "../../../components/__simple/_filterButton";
import {useTranslation} from "react-i18next";
import { Link } from "react-router-dom";
import {getCleanSlug,slugCombine} from '../../../utils/slugManager';

SwiperCore.use([Pagination, Navigation, Autoplay, EffectFade,]);

export default function HomeOfferSection({offers, filters, type}) {
    const {t: trans} = useTranslation(["common", "inputs",'offers']);

    const [filteredOffers, setFilteredOffers] = useState(offers);
    const [offersFilter, setOfferFilter] = useState(null);

    useEffect(() => {
        setFilteredOffers(offers);
    }, [offers])


    function filterOfferVehicle(offer) {
        setOfferFilter(offer);
        let filterData;
        if (offer != null)
            filterData = offers.filter((off) => {
                return off.type === offer;
            });
        else {
            filterData = offers;
        }
        setFilteredOffers(filterData);
    }

    return (

        <div className=" container mb-36">
            <PageHeading
                fixed={true}
                subtitle={trans("common:offers")}
                title={ trans("offers:"+type) }
            >
                <div className="filter-btns">
                    {
                        filters?.map((filter,index) => {
                            return (
                                <FilterButton key={index} name={filter.content} isActive={offersFilter === filter.content}
                                              onclick={() => filterOfferVehicle(filter.content)}/>
                            )
                        })
                    }
                    <FilterButton name={trans("inputs:all")} isActive={offersFilter === null}
                                  onclick={() => filterOfferVehicle(null)}/>
                </div>
            </PageHeading>
            <Swiper
                spaceBetween={0}
                slidesPerView={2}
                breakpoints={{
                    576: {
                        slidesPerView: 4,
                    },
                }}
                pagination
                className={"mb-16"}
                resizeObserver={"true"}
                autoplay={false}
                loop={false}
                observer={true}
                navigation
            >
                {
                    filteredOffers?.map((offer,index) => {
                        return (
                            <SwiperSlide className={"px-3"} key={index}>
                                <Offer img={offer.image}
                                       name={offer.title}
                                       type={offer.type}
                                       slug={slugCombine(offer.slug,offer.otherLink)}
                                       price={offer.minPrice}
                                       excerpt={offer.excerpt}
                                       totalDays={offer.totalDays}
                                       startDate={offer.startDate}
                                       endDate={offer.endDate}
                                />
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>
            <div className="text-left">
                <Link className={"link-btn-white"} to="/offers">
                    {trans("common:show-more")}
                </Link>
            </div>
        </div>

    );
}