import { Fragment, useEffect, useState, useContext, useCallback, useRef } from "react";
import SwiperCore, { Pagination, Navigation, Autoplay, EffectFade } from "swiper";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { FilterButton } from "../../../components/__simple/_filterButton";
import { DropDownInput } from "../../../components/_input/_dropdown";
import { CarColorPicker } from "../components/colorPicker";
import { CarModel } from "../components/carModel";
import { PageLink } from "../../../components/__simple/_pageLink";
import Offer from "../../../components/offer";
import { CarFeature } from "../components/carFeature";
import Info from "../../../components/_info";
import axios from "axios";
import { useParams, useRouteMatch } from "react-router";
import { useDispatch } from "react-redux";
import config from '../../../configuration/config.json';
import _ from "lodash";
import { toast } from 'react-toastify';
import ImageGallery from "react-image-gallery";
import OverViewContext from "../overviewContext";
import { slugCombine, slugSplitter } from '../../../utils/slugManager';
import { groupBy, mapValues, omit } from "lodash";
import PageHeading from "../../../components/__simple/_pageheading";
SwiperCore.use([Pagination, Navigation, Autoplay, EffectFade]);

export function VehicleOverViewPage({ setSectionActive, mainurl, setIsLoding, currntvehicle }) {
    const { t: trans } = useTranslation(["car", "heading", "vehicales", "model", "navbar", "finance", "inputs", "info"]);
    const [selectedColor, setSelectedColor] = useState(null);
    const dispatch = useDispatch();
    const { slug, group, year, brand } = useParams();
    const [vehicle, setVehicle] = useState({});
    const [models, setModels] = useState([]);
    const [features, setFeatures] = useState([]);
    const [gallery, setGallery] = useState([]);
    const [isLoadingImages, setIsLoadingImages] = useState(false);
    const [vehicleOffers, setVehicleOffers] = useState([]);
    const [innerCarImages, setInnerCarImages] = useState([]);
    const [outerCarImages, setOuterCarImages] = useState([]);
    const [wheels, setWheels] = useState([]);
    const [carColors, setCarColors] = useState([]);
    const [activePart, setActivePart] = useState(0);
    const [carModels, setCarModels] = useState([]);
    const [selectedModel, setSelectedModel] = useState(null);
    const [allImagesCount, setAllImagesCount] = useState(0);
    const [activeColor, setActiveColor] = useState("");
    const [activeModel, setActiveModel] = useState({});
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [activeImage, setActiveImage] = useState(0);
    //---------------------------------------------------------
    const popRef = useRef(null);
    let lang = localStorage.getItem("i18nextLng");
    const { handleChangeOverViewContent } = useContext(OverViewContext);

    useEffect(() => {

        if (currntvehicle && currntvehicle?.productID != vehicle?.productID) {
            setIsLoding(true);
            setActiveModel({});
            setActiveOutColor("");
            setActiveINColor("");
            setSelectedColor("");
            resetColorsValues();
            resetValues();
            setVehicle({});
            setModels([]);
            setFeatures([]);
            setGallery([]);
            setCarModels([]);
            setCarColors([]);
            setINCarColors([]);
            setOUTCarColors([]);





            setTimeout(() => {


                if (currntvehicle?.productID)
                    callback(currntvehicle);


            }, 500)
        }

    }, [currntvehicle]);


    const callback = useCallback(async (currntvehicle) => {

        var curentCars;
        var firstItem = {};

        // try {
        //     const response = await axios.request({
        //         baseURL: config.controllURL,
        //         url: lang + "/api/VehicleOverview/" + slugSplitter(brand) + "/" + slug + "/overview",
        //         method: "get",
        //     });

        //     setVehicle(response.data.vehicle);
        //     firstItem = response.data.vehicle;

        //     await afterChangeDataType(firstItem);




        // } catch (error) {
        //     console.log(error);

        // }

        // setVehicle(currntvehicle);
        firstItem = currntvehicle;

        // await afterChangeDataType(firstItem);
        //---------------------


        try {
            if ("caches" in window) {
                var curl = lang + "/api/VehicleOverview/" + slugSplitter(currntvehicle?.group) + "/" + currntvehicle?.year + "/models";

                // Opening that particular cache
                const cacheStorage = await caches.open("VehicleOverViewPage");

                // Fetching that particular cache data
                const cachedResponse = await cacheStorage.match(
                    curl
                );
                var datacash = await cachedResponse?.json();

                if (datacash && datacash?.length > 0) {
                    setModels(datacash);
                    if (datacash && datacash.length > 0) {

                        afterChangeDataType(datacash[0]);
                        setActiveModel(datacash[0]);
                    }
                    await getCarModels(datacash);
                
                }



            }
        } catch (error) {

        }

        try {
            const responsemodels = await axios.request({
                baseURL: config.controllURL,
                url: lang + "/api/VehicleOverview/" + slugSplitter(currntvehicle?.group) + "/" + currntvehicle?.year + "/models",
                method: "get",
            });

            curentCars = responsemodels.data.models;
            setModels(responsemodels.data.models);
            if (curentCars && curentCars.length > 0 &&activeModel?.productID) {

                afterChangeDataType(curentCars[0]);
                setActiveModel(curentCars[0]);
            }
            await getCarModels(responsemodels.data.models);

      

            const dataResponse = new Response(JSON.stringify(curentCars));

            if ("caches" in window) {
                // Opening given cache and putting our data into it
                caches.open("VehicleOverViewPage").then((cache) => {
                    cache.put(lang + "/api/VehicleOverview/" + slugSplitter(currntvehicle?.group) + "/" + currntvehicle?.year + "/models", dataResponse);

                });
            }

        } catch (error) {
            console.log(error);

        }

        //---------------------
        var getCach = false;
        try {
            if ("caches" in window) {
                // var curl = lang + "/api/VehicleOverview/" + slugSplitter(brand) + "/" + slug + "/overviewextra";
                var curl = lang + "/api/VehicleOverview/" + slugSplitter(brand) + "/" + group + "/" + year + "/overviewextra";

                // Opening that particular cache
                const cacheStorage = await caches.open("VehicleOverViewPage");

                // Fetching that particular cache data
                const cachedResponse = await cacheStorage.match(
                    curl
                );
                var datacash2 = await cachedResponse?.json();

                if (datacash2) {

                    setFeatures(datacash2.features);
                    const filterG = [];

                    datacash2?.gallery?.map((item, index) => {
                        item["original"] = config.mainURL + item.imagePath;
                        item["thumbnail"] = config.mainURL + item.imagePath;
                        item["originalHeight"] = "50";
                        item["originalWidth"] = "50";
                        item["sizes"] = "50";
                        item["fullscreen"] = config.mainURL + item.imagePath;
                        filterG.push(item);


                    });

                    setGallery(filterG);

                    if (setIsLoding) {
                        setIsLoding(false);
                        getCach = true;
                    }

                }



            }
        } catch (error) {

        }

        try {
            const response2 = await axios.request({
                baseURL: config.controllURL,
                // url: lang + "/api/VehicleOverview/" + slugSplitter(brand) + "/" + slug + "/overviewextra",
                url: lang + "/api/VehicleOverview/" + slugSplitter(brand) + "/" + group + "/" + year + "/overviewextra",
                method: "get",
            });

            setFeatures(response2.data.features);
            const filterG = [];

            response2?.data?.gallery?.map((item, index) => {
                item["original"] = config.mainURL + item.imagePath;
                item["thumbnail"] = config.mainURL + item.imagePath;
                item["originalHeight"] = "50";
                item["originalWidth"] = "50";
                item["sizes"] = "50";
                item["fullscreen"] = config.mainURL + item.imagePath;
                filterG.push(item);


            });

            setGallery(filterG);

            if (setIsLoding && getCach == false) {
                setIsLoding(false);
            }


            const dataResponse2 = new Response(JSON.stringify(response2.data));

            if ("caches" in window) {
                // Opening given cache and putting our data into it
                caches.open("VehicleOverViewPage").then((cache) => {
                    // cache.put(lang + "/api/VehicleOverview/" + slugSplitter(brand) + "/" + slug + "/overviewextra", dataResponse2);
                    cache.put(lang + "/api/VehicleOverview/" + slugSplitter(brand) + "/" + group + "/" + year + "/overviewextra", dataResponse2);

                });
            }


        } catch (error) {
            console.log(error);
            if (setIsLoding && getCach == false) {
                setIsLoding(false);
            }

        }





    }, [group]);




    const [cashCarImages, setCashCarImages] = useState({});
    const [innerCarImagesJson, setInnerCarImagesJson] = useState(null);
    const [outerCarImagesJson, setOuterCarImagesJson] = useState(null);
    const fetchCarDesign = async (activeColor, model, partnum = activePart) => {

        if (isLoadingImages == false) {

            setIsLoadingImages(true);
            setFilterCarImages([]);
            var codeColor = partnum == 0 ? activeColor?.split("/")[0] : activeColor?.split("/")[1];
            var codeColorIN = activeColor?.split("/")[1];
            var codeColorOUT = activeColor?.split("/")[0];

            const Namefiled = "frontImage,leftImage,backImage,sideImage,rightImage,mainColorImage"
            const innerImages = [];
            const outerImages = [];
            const wheelsImages = [];

            setAllImagesCount(0);



            // if (partnum == 0) {
            //     setOuterCarImages([]);
            // } else if (partnum == 1) {
            //     setInnerCarImages([]);

            // } else {
            //     setWheels([]);
            // }
            var imagesPart = [];
            const prefix = 'data:image/png;base64,';
            if (model) {
                // console.log("cashCarImages>>>>>>>>>>>>>>>>>>>>>>>>>:", cashCarImages);
                // console.log("cashCarImages222>>>>>>>>>>>>>>>>>>>>>>>>>:", cashCarImages[model + "-part-" + partnum]);
                if (cashCarImages[model + "-part-" + partnum]) {
                    setIsLoadingImages(false);
                    var AllCarImages = cashCarImages[model + "-part-" + partnum];
                    var AllCarImagesIN = cashCarImages[model + "-part-" + 1];
                    var AllCarImagesOUT = cashCarImages[model + "-part-" + 0];

                    // console.log("fetchCarDesign AllCarImagesIN:", AllCarImagesIN);
                    // console.log("fetchCarDesign AllCarImagesOUT:", AllCarImagesOUT);
                    setOuterCarImagesJson(AllCarImagesOUT);
                    setInnerCarImagesJson(AllCarImagesIN);
                    if (AllCarImagesIN) {

                        setInnerCarImages(AllCarImagesIN[codeColorIN]);
                    } else {
                        setInnerCarImages([]);

                    }

                    if (AllCarImagesOUT) {

                        setOuterCarImages(AllCarImagesOUT[codeColorOUT]);
                    } else {
                        setOuterCarImages([]);

                    }






                } else {
                    axios.post(
                        config.baseURL + 'func',
                        {
                            //SiteFetchCarColorImages
                            "Name": "SiteFetchCarColorCodeImages",
                            "Values": {
                                "Id": model,
                                "year": year,
                                // "ColorID": String(codeColor),
                                "ColorID": null,
                                "FieldNameList": null
                            }
                        }
                    ).then(response => {
                        setIsLoadingImages(false);
                        var maincolorimageIndex = 0;
                        if (Array.isArray(response.data)) {
                            const responseData = response.data;
                            // console.log("SiteFetchCarColorCodeImages responseData>>>>>>>>>>>>>>>>>>>>>>>>>:", responseData);
                            const groupResult = mapValues(groupBy(response.data, 'ColorCode'), fList => fList.map(items => omit(items, 'ColorCode')));

                            var allcolor = [...OUTcolorsCode, ...INcolorsCode];


                            allcolor?.map((key, index) => {
                                var colorCodeItems = groupResult[key];
                                if (colorCodeItems?.length > 0) {

                                    if (OUTcolorsCode?.includes(key)) {



                                        var cimage = cashCarImages[model + "-part-" + 0];

                                        if (!cimage) {

                                            cimage = {}
                                        }

                                        cimage[key] = colorCodeItems;
                                        cashCarImages[model + "-part-" + 0] = cimage;
                                        // cashCarImages[model + "-" + key + "-part-" + 0] = colorCodeItems;
                                        if (codeColorOUT == key) {
                                            imagesPart = colorCodeItems;
                                            setOuterCarImages(colorCodeItems);

                                        }

                                    } else if (INcolorsCode?.includes(key)) {

                                        var cimage = cashCarImages[model + "-part-" + 1];

                                        if (!cimage) {

                                            cimage = {}
                                        }

                                        cimage[key] = colorCodeItems;
                                        cashCarImages[model + "-part-" + 1] = cimage;
                                        // cashCarImages[model + "-" + key + "-part-" + 1] = colorCodeItems;
                                        if (codeColorIN == key) {

                                            imagesPart = colorCodeItems;
                                            setInnerCarImages(colorCodeItems);
                                        }


                                    } else {

                                        var cimage = cashCarImages[model + "-part-" + 2];

                                        if (!cimage) {

                                            cimage = {}
                                        }

                                        cimage[key] = colorCodeItems;
                                        cashCarImages[model + "-part-" + 2] = cimage;

                                        // cashCarImages[model + "-" + key + "-part-" + 2] = colorCodeItems;
                                        if (codeColor == key) {

                                            imagesPart = colorCodeItems;
                                            setWheels(colorCodeItems);
                                        }


                                    }
                                }
                            })





                            // console.log("cashCarImages>>>>>>>>>>>>>>>>>>>>>>>>>:", cashCarImages);


                            const imagesCount = imagesPart?.length;
                            setAllImagesCount(imagesCount);

                            var AllCarImagesIN = cashCarImages[model + "-part-" + 1];
                            var AllCarImagesOUT = cashCarImages[model + "-part-" + 0];
                            setOuterCarImagesJson(AllCarImagesOUT);
                            setInnerCarImagesJson(AllCarImagesIN);
                            // console.log("fetchCarDesign AllCarImagesIN:", AllCarImagesIN);
                            // console.log("fetchCarDesign AllCarImagesOUT:", AllCarImagesOUT);

                            if (AllCarImagesIN) {

                                setInnerCarImages(AllCarImagesIN[codeColorIN]);
                            } else {
                                setInnerCarImages([]);

                            }

                            if (AllCarImagesOUT) {

                                setOuterCarImages(AllCarImagesOUT[codeColorOUT]);
                            } else {
                                setOuterCarImages([]);

                            }

                            // setAllCarImages([...outerImages, ...innerImages, ...wheelsImages])
                            setIsLoadingImages(false);




                        }
                    }).catch(error => {
                        console.log(error);
                        setIsLoadingImages(false);
                        cashCarImages[model + "-part-" + partnum] = {};
                    });

                }

            }
        }
    };



    // to change the overview of the vehicle
    const handleRequestChangeOverview = (data, productID) => {
        let result = [];
        const finalItem = {
            name: null,
            isNew: false,
            isHybird: false,
            product: null,
            group: null,
            price: null,
            tax: null,
            discount: 0,
            modelTypes: null
        };
        try {
            if (data) {
                result = data.filter(item => {
                    return String(item.productID) == productID
                });
            } else {
                result = models.filter(item => {
                    return String(item.productID) == productID
                });
            }
            if (result.length > 0) {
                const itemValue = result[0];
                finalItem.modelTypes = itemValue?.type;
                finalItem.isHybird = itemValue?.hybird;
                finalItem.isNew = itemValue?.newCar;
                finalItem.product = itemValue?.productID;
                finalItem.name = itemValue?.name
            }
        } catch (error) {
            console.log(error);
        }
        handleChangeOverViewContent(finalItem);
    };
    // to get the active part of section
    const [filterCarImages, setFilterCarImages] = useState([]);
    const getActivePartSection = async (activePart, thisSelectedColorID = selectedColor?.ColorID, Model = activeModel) => {


        setFilterCarImages([]);
        setActivePart(activePart);
        let result = [];

        if (activePart === 0) {

            if (outerCarImages?.length > 0) {
                result = outerCarImages;

            }

        } else if (activePart === 1) {


            if (innerCarImages?.length > 0) {
                result = innerCarImages;

            }

        } else if (activePart === 2) {

            if (wheels?.length > 0) {
                result = wheels;

            }


        }

        setFilterCarImages(result);
        return result;
    };
    // get the car colors by [id + model]
    const getCarColors = async (id) => {
        let colors = [];
        if (id) {
            try {
                const response = await axios.post(
                    config.baseURL + 'func',
                    {
                        "Name": "site_fetchColorOfTypeWithOutImages",
                        "Values": {
                            "Type": `${id}`,
                        }
                    }
                );
                if (response.status == 200) {
                    colors = response.data;
                }
            } catch (error) {
                console.log(error);
            }
        }
        return colors;
    };

    const [cashColors, setCashColors] = useState({});
    const handleFetchModelAvaliableColors = async (productTypeID, currentColorID, year, thisSelectedColorID = selectedColor?.ColorID) => {
        let _colors = [];


        try {

            if (cashColors[productTypeID]) {
                _colors = cashColors[productTypeID];
                setCarColors(_colors);
                let _default = null;

                _default = _colors.find(item => item.ColorID == thisSelectedColorID) ?? _colors.find(item => item.ColorID == currentColorID);


                setSelectedColor(_default);

            } else {

                const response = await axios.post(
                    config.baseURL + 'func',
                    {
                        "Name": "site_fetchColorOfTypeWithOutImages",
                        "Values": {
                            "Type": productTypeID,
                            "model": year
                        }
                    }
                );

                if (response.status == 200) {

                    var cc = { ...cashColors };
                    cc[productTypeID] = response.data;
                    setCashColors(cc);




                    _colors = response.data;
                    setCarColors(_colors);
                    let _default = null;

                    _default = _colors.find(item => item.ColorID === thisSelectedColorID) ?? _colors.find(item => item.ColorID === currentColorID);
                    if (_default) {
                        // filterPriceoffColor(_default);
                    }

                    setSelectedColor(_default);
                }

            }

        } catch (error) {
            console.log(error);
        }



        return _colors;
    };


    const [INcolors, setINCarColors] = useState([]);
    const [OUTcolors, setOUTCarColors] = useState([]);
    const [INcolorsCode, setINCarColorsCode] = useState([]);
    const [OUTcolorsCode, setOUTCarColorsCode] = useState([]);
    useEffect(() => {

        var currentInColor = [];
        var IN_colors = [];
        var currentOutColor = [];
        var Out_colors = [];

        var jsoncolors = {};
        try {

            carColors?.forEach((item, i) => {

                jsoncolors[item.ColorID] = item;

                if (!currentOutColor.includes(item.ColorID?.split("/")[0])) {

                    Out_colors = [...Out_colors, { ColorDescription: item[lang == "ar" ? `ColorDescriptionAr` : `ColorDescriptionEn`]?.split("/")[0], ColorID: item.ColorID?.split("/")[0], ColorImage: item?.siteOutColorImage }]

                    currentOutColor = [...currentOutColor, item.ColorID?.split("/")[0]]
                }


                if (!currentInColor.includes(item.ColorID?.split("/")[1])) {

                    IN_colors = [...IN_colors, { ColorDescription: item[lang == "ar" ? `ColorDescriptionAr` : `ColorDescriptionEn`]?.split("/")[1], ColorID: item.ColorID?.split("/")[1], ColorImage: item?.siteInColorImage }]

                    currentInColor = [...currentInColor, item.ColorID?.split("/")[1]]
                }


            })


            setOUTCarColorsCode(currentOutColor);
            setINCarColorsCode(currentInColor);
            //---------------

            setINCarColors(IN_colors);
            setOUTCarColors(Out_colors);


        } catch (error) {
            console.log(error);
        }

    }, [carColors]);
    const afterChangeDataType = async (firstItem, thisSelectedColorID = selectedColor?.ColorID, partnum = activePart) => {



        handleRequestChangeOverview(null, firstItem?.productID);
        const colors = await handleFetchModelAvaliableColors(firstItem?.type ?? firstItem?.Type, firstItem?.colorID ?? firstItem?.ColorID, firstItem?.year);


        var currentSelectedColor = colors?.filter(item => item.ColorID == thisSelectedColorID)?.length > 0 ? thisSelectedColorID : colors[0]?.ColorID;

        try {
            if (currentSelectedColor && currentSelectedColor?.split("/")[0] && currentSelectedColor?.split("/")[1]) {
                setActiveOutColor(currentSelectedColor?.split("/")[0]);
                setActiveINColor(currentSelectedColor?.split("/")[1]);

            }
        } catch (error) {
            console.log(error);
        }

        if (firstItem) {
            setCarColors(colors);
            // if (allImagesCount == null)
            fetchCarDesign(currentSelectedColor, firstItem?.productID);


        }

    }


    // to fetch the car models
    const getCarModels = async (allModels) => {
        let models = [];
        try {
            models = allModels?.map((item) => {
                return {
                    text: item.modelDescription, value: item.type
                }
            });
        } catch (error) {
            console.log(error);
        }

        setCarModels(models);


    };
    // to reset all the state objects
    const resetValues = () => {
        setOuterCarImagesJson(null);
        setInnerCarImagesJson(null);
        setAllImagesCount(0);
        setInnerCarImages([]);
        setOuterCarImages([]);
        setWheels([]);
    };

    const resetColorsValues = () => {
        setOUTCarColors([]);
        setINCarColors([]);

    };

    const [activeINColor, setActiveINColor] = useState("");
    const [activeOutColor, setActiveOutColor] = useState("");
    useEffect(() => {

        if (vehicle?.type)
            setActiveModel(vehicle);

        if (vehicle?.colorID)
            setActiveColor(vehicle.colorID);

        if (vehicle?.colorID && vehicle?.colorID?.split("/")[0] && vehicle?.colorID?.split("/")[1]) {
            setActiveOutColor(vehicle?.colorID?.split("/")[0]);
            setActiveINColor(vehicle?.colorID?.split("/")[1]);
        }
    }, [vehicle]);


    useEffect(() => {
        // setActivePart(0);

        if (activeOutColor && activeINColor)
            setSelectedColor(carColors.filter(a => a.ColorID == (activeOutColor + "/" + activeINColor))[0]);



        if (INcolorsCode?.length > 0 && INcolorsCode?.length > 0 && activeOutColor && activeINColor && activeModel?.productID) {


            fetchCarDesign((activeOutColor + "/" + activeINColor), activeModel?.productID);
            // getActivePartSection(activePart);

        }
    }, [activeINColor, activeOutColor, INcolorsCode, OUTcolorsCode, activePart]);

    useEffect(() => {

        // setActivePart(activePart);
        getActivePartSection(activePart);

    }, [outerCarImages, innerCarImages, wheels, activePart]);

    useEffect(() => {


        if (isLoadingImages == false)
            getActivePartSection(activePart);

    }, [isLoadingImages]);


    const handelGetPartImages = (colorID, currentActivePart) => {


    };
    //---------------------------render----------------------------------
    return (
        <Fragment>

            <div className={"flex flex-row justify-center car-overview-filter items-center flex-wrap nice-gap-1rem mb-24"}>

                {
                    outerCarImagesJson &&
                    <FilterButton onclick={() => {
                        if (activePart != 0) {

                            setActivePart(0);
                            setFilterCarImages([]);
                        }

                        // getActivePartSection(0);
                    }} name={trans("car:external-images")} isActive={activePart == 0} />
                }
                {
                    innerCarImagesJson &&
                    <FilterButton onclick={() => {
                        if (activePart != 1) {
                            setActivePart(1);
                            setFilterCarImages([]);
                        }
                        // getActivePartSection(1);
                    }} name={trans("car:internal-images")} isActive={activePart == 1} />
                }
                {
                    wheels?.length > 0 &&
                    <FilterButton onclick={() => {
                        if (activePart != 2) {
                            setActivePart(2);
                            setFilterCarImages([]);
                        }
                        // getActivePartSection(2);
                    }} name={trans("info:wheels")} isActive={activePart == 2} />
                }


            </div>
            {
                // allImagesCount > 0  &&
                <div className="w-1/2 mx-auto car-overview-colorimage">
                    <Swiper
                        className={"slide-car-360 "}
                        spaceBetween={0}
                        slidesPerView={1}
                        navigation
                        resizeObserver={"true"}
                        effect="fade"
                        watchSlidesVisibility
                        watchSlidesProgress
                        autoplay={false}
                        loop={false}
                        observer={true}
                        height={50}
                        autoHeight={false}
                        updateOnWindowResize={true}
                    >
                        {

                            filterCarImages?.map((image, index) => {
                                return (
                                    <SwiperSlide className={"px-3  slide-show "} key={index} >
                                        <img src={config?.mainURL + image?.Image_URL} style={{ maxHeight: '25rem' }} />
                                    </SwiperSlide>
                                )
                            })


                        }
                    </Swiper>
                </div>

            }
            {
                activePart == 0 &&
                <>
                    {

                        <h2 className="text-20 text-gray text-center car-overview-colorname my-5">{selectedColor && (lang == "ar" ? selectedColor?.ColorDescriptionAr?.split("/")[0] : selectedColor?.ColorDescriptionEn?.split("/")[0])}</h2>

                    }
                    <div className={"car-overview-group"}>

                        {/* {
                            OUTcolors.length > 0 &&
                            <h2 className="text-20 text-gray my-5">{trans("car:exterior_color")}:</h2>
                        } */}

                        {
                            OUTcolors.length > 0 && OUTcolors.map((color, index) => {
                                return (
                                    outerCarImagesJson && outerCarImagesJson[color.ColorID] &&
                                    <CarColorPicker
                                        key={index}
                                        urlImageColor={color.ColorImage}
                                        // imageBase64={color.siteOutColorImage}
                                        color={color?.ColorID}
                                        name={color?.ColorDescription}
                                        onClick={() => {
                                            if (color.ColorID != activeOutColor) {
                                                // resetValues();
                                                setSelectedColor([]);
                                                setActiveOutColor(color.ColorID);
                                                // setActiveColor(color.ColorID);
                                                // setSelectedColor(color);
                                                // handleChangeCarColor(color.ColorID);
                                            }

                                        }}
                                        active={activeOutColor} />

                                );
                            })
                        }


                    </div>
                </>


            }

            {
                activePart == 1 &&

                <>

                    {

                        <h2 className="text-20 text-gray text-center car-overview-colorname my-5">{selectedColor && (lang == "ar" ? selectedColor?.ColorDescriptionAr?.split("/")[1] : selectedColor?.ColorDescriptionEn?.split("/")[1])}</h2>

                    }
                    <div className={"car-overview-group"}>

                        {/* {
                            INcolors.length > 0 &&
                            <h2 className="text-20 text-gray my-5">{trans("car:interior_color")}:</h2>
                        } */}

                        {

                            INcolors.length > 0 && INcolors.map((color, index) => {
                                return (
                                    innerCarImagesJson && innerCarImagesJson[color.ColorID] &&
                                    <CarColorPicker
                                        key={index}
                                        urlImageColor={color.ColorImage}
                                        // imageBase64={color.siteOutColorImage}
                                        color={color?.ColorID}
                                        name={color?.ColorDescription}
                                        onClick={() => {
                                            if (color.ColorID != activeINColor) {
                                                // resetValues();
                                                setSelectedColor([]);
                                                setActiveINColor(color.ColorID);
                                                // setActiveColor(color.ColorID);
                                                // setSelectedColor(color);
                                                // handleChangeCarColor(color.ColorID);
                                            }

                                        }}
                                        active={activeINColor} />

                                );
                            })
                        }


                    </div>
                </>


            }


            {/* 
            {

                selectedColor &&
                <h2 className="text-20 text-gray text-center my-5">{selectedColor?.ColorDescriptionAr?.split("/")[1]}</h2>

            } */}
            {
                carModels && carModels?.length > 0 && <div className={"w-1/3 text-center mx-auto mb- 40"}>
                    <p className={"text-16"}>{trans("info:price_note")}</p>
                    <DropDownInput
                        defaultOption={carModels[0]}
                        className={"flex-grow-1"}
                        options={carModels}
                        onSelect={async (value) => {
                            if (value != activeModel.type) {
                                // setActivePart(0);
                                setSelectedColor(null);
                                resetValues();
                                resetColorsValues();
                                // await handleChangeModel(value);


                                var mo = models.filter(a => a.type == value)[0]



                                afterChangeDataType(mo);
                                setActiveModel(mo)
                            }
                            ;
                        }}
                    />
                </div>
            }
            <div className={" my-32 container"}>
                {/* <PageLink
                    className={"container-right mt-20"}
                    title={trans("car:available-models")}
                    link={`${mainurl}/specs`}


                /> */}
                <div className={"mx-10"}>
                    <PageHeading
                        // fixed={true}
                        // subtitle={trans("home:subtitle")}
                        title={trans("car:available-models")}
                    >

                    </PageHeading>
                </div>
                <Swiper
                    className={"car-model-swiper"}
                    slidesPerView={"auto"}
                    pagination
                    resizeObserver={"true"}
                    watchSlidesVisibility
                    watchSlidesProgress
                    autoplay={false}
                    loop={false}
                    observer={true}
                    navigation

                >
                    {
                        models?.map((model, index) => {
                            return (
                                <SwiperSlide key={index}>
                                    <CarModel
                                        group={model?.group}
                                        type={model.type}
                                        brand={model?.brand}
                                        brandID={model?.brandID}
                                        newCar={model.newCar}
                                        hybird={model.hybird}
                                        modelYear={model.year}
                                        productTarget={model.productTarget}
                                        colorID={model.colorID}
                                        productID={model.productID}
                                        backupImage={vehicle.imagePath}
                                        image={model.imagePath}
                                        name={(model["modelDescription"])}
                                        spec={model.note}
                                        price={model.minPrice}
                                        offerIndex={++index}
                                        extraSpecsTo={`${mainurl}/specs/${model?.productTarget}`}
                                        showPric={model.showPric}
                                        buyOnline={model?.buyOnline}
                                    />
                                </SwiperSlide>
                            );
                        })
                    }

                </Swiper>
            </div>
            {
                vehicleOffers?.length > 0 && <div className=" container mb-36">
                    <PageLink
                        title={brand}
                        link={"/offers"}
                    />
                    <Swiper
                        spaceBetween={0}
                        slidesPerView={2}
                        breakpoints={{
                            576: {
                                slidesPerView: 4,
                            },
                        }}
                        className={"mb-16"}
                        resizeObserver={"true"}
                        watchSlidesVisibility
                        watchSlidesProgress
                        autoplay={false}
                        loop={false}
                        observer={true}
                    >
                        {

                            vehicleOffers?.map((item, index) => {
                                return (
                                    <SwiperSlide className={"px-3"} key={index}>
                                        <Offer
                                            img={item.image ? 'data:image/png;base64,' + item.image : '/assets/images/offers/1.png'}
                                            name={item.title}
                                            excerpt={item.description}
                                            slug={item?.slug}
                                            price={item.price}
                                        />
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>

                </div>
            }



            {

                features?.length > 0 && <div className=" container mb-36">
                    <PageLink
                        title={trans("car:features")}
                        link={`${mainurl}/feature`}
                    />

                    <div className={"grid-3-cols gap-2 feature-grid"}>
                        {
                            features?.map((feature, index) => {
                                return (
                                    <CarFeature
                                        key={index}
                                        title={feature.title}
                                        desc={feature.desc}
                                        image={feature.image}
                                    />
                                );
                            })
                        }

                    </div>
                </div>
            }
            {
                gallery?.length > 0 && <div className={"mb-32"}>
                    <PageLink className={"container"}
                        title={trans("car:gallery")}
                        link={`${mainurl}/gallery`}
                    />

                    <div className="simple-gallery">
                        {
                            gallery?.map((img, index) => {
                                return <div
                                    key={index}
                                    className={"simple-gallery-item"}
                                    onClick={(e) => {

                                        setActiveImage(index);

                                        try {
                                            if (popRef) {


                                                popRef.current.toggleFullScreen();
                                                popRef.current.slideToIndex(index)
                                            }

                                        } catch (error) {

                                        }
                                    }}

                                >
                                    <img src={config.mainURL + img.imagePath}
                                        onError={e => e.target.style.display = 'none'}
                                        className={"object-full object-cover"}
                                        alt={"car gellary"}
                                    />

                                    <span className="simple-gallery-item-ovrlay">

                                        <svg class="image-gallery-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <path d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3">

                                            </path>
                                        </svg>
                                    </span>
                                </div>
                            })
                        }

                    </div>
                </div>
            }
            {/* <div className="container mb-32">
                <Info />
            </div> */}


            <div className={""} style={{ opacity: (isFullScreen == true ? 1 : 0), position: (isFullScreen == true ? "relative" : "fixed"), zIndex: (isFullScreen == true ? 999999 : -1) }}>
                <ImageGallery ref={popRef} items={gallery} onScreenChange={(e) => {

                    setIsFullScreen(e);
                }} />
            </div>

        </Fragment >
    );
}