import { useTranslation } from "react-i18next";
import { DropDownInput } from "../../../components/_input/_dropdown";

export function JobFilters({ jobDepartments, jobBranches, departmentFilter, branchFilter, onSearchClick }) {
    const { t: trans } = useTranslation(["form", "new", "employment"]);
    return (
        <div className="container mb-20 bg-white job-filter ">
            <DropDownInput
                onSelect={(e) => {
                }}
                title={trans("new:job")}
                className={"flex-grow-1 job-dropdown"}
                inputName={"job-department"}
                options={
                    jobDepartments ?
                        [
                            ...jobDepartments?.map((job) => {
                                return {
                                    text: job.content,
                                    value: job.name
                                }
                            })
                        ]
                        : []
                }
                isSecondary={true}
                onfilter={departmentFilter}
            />

            <DropDownInput
                onSelect={(e) => {
                }}
                title={trans('employment:branch')}
                className={"flex-grow-1  job-dropdown"}
                options={
                    jobBranches ?
                    [
                    ...jobBranches.map((job) => {
                        return {
                            text: job.content,
                            value: job.name
                        }
                    })
                ]:[]}
                isSecondary={true}
                onfilter={branchFilter}
            />
            <button
                onClick={onSearchClick}
            >
                {trans("form:search")}
            </button>
        </div>


    );
}

