const SimpleButton = ({ title = '', iconPath = null, hint = '', onClick, classes = 'bg-primary' , alt = ""}) => {
    return (
        <div title={hint} className={"nice-style-btn nice-flex-row " + classes}
            onClick={(e) => {
                if (onClick) {
                    onClick(e)
                }
            }}>

            {title}
            {iconPath && <img src={iconPath} alt={title ? title : alt} />}
        </div>
    )
};
export default SimpleButton;