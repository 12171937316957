import React, { useState, useRef, useEffect} from 'react'
import { useTranslation } from 'react-i18next';


export function CountDown({Total,start=0,end=0 , showSeconds = true}) {
    const { t: trans } = useTranslation(["info"]);
    let lang = localStorage.getItem("i18nextLng");
    const languageDir = "language-" + lang;
    // We need ref in this, because we are dealing
    // with JS setInterval to keep track of it and
    // stop it when needed
    const Ref = useRef(null);

    // The state for our timer
    const [timer, setTimer] = useState('00:00:00');


    const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / 1000 / 60 / 60) % 24);
        const days = Math.floor((total / 1000 / 60 / 60/24) % 30);
        const month = Math.floor((total / 1000 / 60 / 60/24/30) %12);
        return {
            total,month,days, hours, minutes, seconds
        };
    }


    const startTimer = (e) => {
        let { total,month,days, hours, minutes, seconds }
            = getTimeRemaining(e);
        if (total >= 0) {
            // update the timer
            // check if less than 10 then we need to 
            // add '0' at the begining of the variable
            const countDownFormat = <div className={'countDown ' + languageDir}>
                {month > 0 && <span className='countDown-part nice-inline-block text-white'>
                    {month}
                    <span className='text-14 nice-inline-block text-white'>
                        {trans("info:month")}
                    </span>

                </span>
                }
                {month > 0 && ':'}
                {
                    days > 0 && <span className='countDown-part nice-inline-block text-white'>
                        <span className='text-white'>
                            {days}
                        </span>
                        <span className='text-14 nice-inline-block text-white'>
                            {trans("info:day")}
                        </span>
                    </span>
                }
                {days > 0 && ':'}
                {
                    hours > 0 && <span className='countDown-part nice-inline-block text-white'>
                        <span className='text-white'>
                            {hours}
                        </span>
                        <span className='text-14 nice-inline-block text-white'>
                            {trans("info:hour")}
                        </span>

                    </span>
                }
                {hours > 0 && ':'}
                {
                    minutes > 0 && <span className='countDown-part nice-inline-block text-white'>
                        <span className='text-white'>
                            {minutes}
                        </span>
                        <span className='text-14 nice-inline-block text-white'>
                            {trans("info:minute")}
                        </span>

                    </span>
                }
                :
                {
                   showSeconds && ( <span className='countDown-part nice-inline-block text-white'>
                        <span className='text-white'>
                            {seconds}
                        </span>
                        <span className='text-14 nice-inline-block text-white'>
                            {trans("info:second")}
                        </span>

                    </span>)
                }
            </div>;
            setTimer(countDownFormat);
            // setTimer((month+"m/ ")+(days+"d / h ")+
            //     (hours > 9 ? hours : '0' + hours) + ':' +
            //     (minutes > 9 ? minutes : '0' + minutes) + ':'
            //     + (seconds > 9 ? seconds : '0' + seconds)
            // )
        }
    }


    const clearTimer = (e) => {

        // If you adjust it you should also need to
        // adjust the Endtime formula we are about
        // to code next   
        setTimer('0d 00:00:00');

        // If you try to remove this line the 
        // updating of timer Variable will be
        // after 1000ms or 1sec
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000)
        Ref.current = id;
    }

    const getDeadTime = () => {
        let deadline = new Date();
        let now= new Date(Date.now());

        // This is where you need to adjust if 
        // you entend to add more time

        deadline.setSeconds(deadline.getSeconds()-now.getSeconds() );
        deadline.setMinutes(deadline.getMinutes()-now.getMinutes() );
        deadline.setHours(deadline.getHours()-now.getHours());
        deadline.setDate(deadline.getDate() +Total); //(end-start));

       
     
       
        return deadline;
    }

    // We can use useEffect so that when the component
    // mount the timer will start as soon as possible

    // We put empty array to act as componentDid
    // mount only
    useEffect(() => {
        clearTimer(getDeadTime());
    }, []);

    // Another way to call the clearTimer() to start
    // the countdown is via action event from the
    // button first we create function to be called
    // by the button
    const onClickReset = () => {
        clearTimer(getDeadTime());
    }

    return (
        <div className="text-18">
            <h2 className='text-white'>{timer}</h2>     
        </div>
    )
}

