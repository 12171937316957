import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { DropDownInput } from "../../../components/_input/_dropdown";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import axios from "axios";
import { InputField } from "../../../components/_input/_input";
import config from '../../../configuration/config.json';
import dropDownResetter from "../../../utils/resetDropDown";
import { reverse } from "lodash";
import { useLocation } from "react-router";
import CustomPopover from "./customPopover";

const PartSearch = forwardRef((
    { search, isIndex = false, isLoadingFuncProp, onChangValuesProp, activePage, passEngineGroups}, ref) => {


    //
    // export function PartSearch({
    //                                search, isIndex = false, isLoadingFuncProp, onChangValuesProp, clearFilterProp
    //                            }) {
    //public verb
    const { t: trans } = useTranslation(["common", "eparts", 'inputs', "info", "new"]);
    const [vehicle, setVehicle] = useState("");
    const [vin, setVin] = useState("hidden");
    const [part, setPart] = useState("hidden");
    const [category, setCategory] = useState("hidden");

    let query = new URLSearchParams(useLocation().search);
    const [brand, setBrand] = useState(null);
    const [group, setGroup] = useState(null);
    const [year, setYear] = useState(null);
    const [Stype, setStype] = useState(null);
    const [queryProduct, setQueryProduct] = useState(query.get("Product") ?? null);


    const [lang, setlang] = useState(localStorage.getItem('i18nextLng'));
    const languageDir = 'language-' + lang;
    const [Brands, setBrands] = useState([]);
    const [ProductGroups, setProductGroups] = useState([]);
    const [ProductModel, setProductModel] = useState([]);
    const [ProductModelTypes, setProductModelTypes] = useState([]);

    const [engineGroups, setEngineGroups] = useState([]);
    const [engineGroupsSup, setEngineGroupsSup] = useState([]);
    const [engineGroupsSup2, setEngineGroupsSup2] = useState([]);

    //values for filter dropdown
    useEffect(() => {

        if (query && Brands.length > 0) {
            setBrand(query.get("brand"));
            setResGroup(query.get("group"));
            setResProductType(query.get("type"));
            setResProductModel(query.get("year"));
            // setQueryProduct (query.get("Product"))
            if (query.get("brand") != null) {
                setTimeout(() => {
                    refreshPage("Vehicle", query.get("brand"), query.get("type"), query.get("group"), query.get("year"));
                }, 1500)
            }
        }
    }, [Brands]);


    const [FProductGroups, setFProductGroups] = useState([]);

    const [FProductModelTypes, setFProductModelTypes] = useState([]);

    //values for filter dropdown

    const [fCatSup1, setoptionCatSup] = useState([{ value: "0", text: "الكل" }]);
    const [fCatSup2, setoptionCatSup2] = useState([{ value: "0", text: "الكل" }]);


    //values for search of Vichals
    // const [brand, setBrand] = useState(0);
    // const [group, setGroup] = useState( null);
    // const [year, setYear] = useState(null);
    // const [Stype, setStype] = useState(null);

    const [CatMain, setCatMain] = useState(null);
    const [SuPCat, setSupCat] = useState(null);
    const [SSuPCat, setSSupCat] = useState(null);

    const [Product, setPro] = useState("0");

    const GetUrl = config.baseURL + 'details';

    // const [inputPro, setInputPro] = useState('');
    const [filterShow, setFilterShow] = useState("")

    function SetProduct(data) {
        setPro(data.target.value);
    }

    const SendRequset = async () => {

        try {

            // if ("caches" in window) {
            //     let url = "Settings";
            //
            //     // Opening that particular cache
            //     const cacheStorage = await caches.open("HJWeb");
            //     ;
            //     // Fetching that particular cache data
            //     const cachedResponse = await cacheStorage.match(
            //         url
            //     );
            //     let data = await cachedResponse?.json();
            //     setBrands(data.brands)
            //     setProductGroups(data?.groups)
            //     setTimeout(setProductModel(reverse(data?.models)), 1000);
            //     setTimeout(setProductModelTypes(data?.productModels), 1000);
            // }

        } catch (error) {
        }

        try {
            axios.request({
                baseURL: '/',
                url: config.controllURL + lang + "/api/Maintenance/Settings",
                method: "get"
            }).then(settingResponse => {

                setBrands(settingResponse?.data?.brands)
                setProductGroups(settingResponse.data?.groups)
                // setTimeout(setProductModel(reverse(settingResponse?.data?.models)), 1000);
                // setTimeout(setProductModelTypes(settingResponse?.data?.productModels), 1000);
                setProductModelTypes(settingResponse?.data?.productModels);
                setProductModel(reverse(settingResponse?.data?.models));

                const data = new Response(JSON.stringify(settingResponse.data));

                if ("caches" in window) {
                    // Opening given cache and putting our data into it
                    caches.open("HJWeb").then((cache) => {
                        cache.put("Settings", data);

                    });
                }

            }).catch(error => {
                console.log(error);

            });


            //---------------------------------------------------------------
        } catch (error) {
            console.log(error);
        }


        const BodyBrands = {
            "object": "InvBrands",
            "ObjectSettings": {
                "MetaData": false
            }
            , "option": "column",
            "Fields": "BrandID,DescriptionAr,DescriptionEn",
            "filters": " where IsEnabled =1"

        };
        const requestBrands = axios.request({
            url: GetUrl,
            data: BodyBrands, method: "POST", responseType: "json"
        });

        const BodyProductGroups = {
            "object": "InvProductGroups",
            "ObjectSettings": {
                "MetaData": false,
            },
            "Option": "Column",
            "Fields": "DescriptionAr,DescriptionEn,ProductGroupID,BrandID"
        };
        const requestProductGroups = axios.request({
            url: GetUrl,
            data: BodyProductGroups, method: "POST", responseType: "json"
        });

        const BodyProductModel = {
            "object": "InvProductModel",
            "ObjectSettings": {
                "MetaData": false
            }
        };
        const requestProductModel = axios.request({
            url: GetUrl,
            data: BodyProductModel, method: "POST", responseType: "json"
        });

        const BodyProductModelTypes = {
            "object": "InvProductModelTypes",
            "ObjectSettings": {
                "MetaData": false
            }
        };
        const requestProductModelTypes = axios.request({
            url: GetUrl,
            data: BodyProductModelTypes, method: "POST", responseType: "json"
        });

        // const BodyEngineGroups = {
        //     "object": "Site_categorySP",
        //     "ObjectSettings": {
        //         "MetaData": false
        //     }
        // };
        const BodyEngineGroups = {
            "name": "fun_Site_categorySP",
            "ObjectSettings": {
                "MetaData": false
            }
        };
        const requestEngineGroups = axios.request({
            url: config.baseURL + "func",
            data: BodyEngineGroups, method: "POST", responseType: "json"
        });

        const BodyEngineGroupsSUP = {
            // "object": "Site_SupCategory",
            "name": "fun_Site_SupCategory",
            "ObjectSettings": {
                "MetaData": false
            }
        };
        const requestEngineGroupsSUP = axios.request({
            url: config.baseURL + "func",
            data: BodyEngineGroupsSUP, method: "POST", responseType: "json"
        });

        const BodyEngineGroupsSUP2 = {
            // "object": "Site_ItemsSupCategory",
            "name": "fun_Site_ItemsSupCategory",
            "ObjectSettings": {
                "MetaData": false
            }
        };
        const requestEngineGroupsSUP2 = axios.request({
            url: config.baseURL + "func",
            data: BodyEngineGroupsSUP2, method: "POST", responseType: "json"
        });


        await axios.all([requestEngineGroups, requestEngineGroupsSUP, requestEngineGroupsSUP2])
            .then(
                axios.spread(
                    function (
                        // Brands, 
                        // ProductGroups,
                        // ProductModel,
                        // ProductModelTypes,
                        EngineGroups,
                        EngineGroupsSup,
                        EngineGroupsSup2
                    ) {

                        //  setTimeout(setBrands(Brands.data.ApiObjectData),1000);
                        //  setTimeout(setProductGroups(ProductGroups.data.ApiObjectData),1000);
                        //  setTimeout(setProductModel(reverse(ProductModel.data.ApiObjectData)),1000);
                        //  setTimeout(setProductModelTypes(ProductModelTypes.data.ApiObjectData),1000);

                        // setTimeout(setEngineGroups(EngineGroups.data.ApiObjectData), 1000);

                        setEngineGroups(EngineGroups?.data);

                        setTimeout(setEngineGroupsSup(EngineGroupsSup?.data), 500);
                        setTimeout(setEngineGroupsSup2(EngineGroupsSup2?.data), 900);

                        if(passEngineGroups){

                            passEngineGroups({
                                EngineGroups:EngineGroups?.data,
                                EngineGroupsSup : EngineGroupsSup?.data,
                                EngineGroupsSup2 :EngineGroupsSup2?.data
                            })
                        }

                    })).catch(function (error) {
                        console.log(error);
                    });

    }

    function filterGroup(option) {


        console.log("filterGroup>>>>>>>>>>>>>>>:", option);
        console.log("brabd>>>>>>>>>>>>>>>:", brand);
        // setBrand(option);
        if (option == '0' || option == '') {
            dropDownResetter(() => {
                setFProductGroups([]);
                setFProductModelTypes([]);
            });
            return;
        }

        setFProductGroups(ProductGroups.filter(a => a.brandID == option));
        console.log("filterGroup>>>>>>>>>>>>>>>:", FProductGroups);
    }

    function filterModelType(option) {
        // setResProductType(null);
        setResProductModel(null);

        setResGroup(option);
        if (option == '0' || option == '') {
            dropDownResetter(() => {
                setFProductModelTypes([]);
            });
            return;
        }
        setFProductModelTypes(ProductModelTypes.filter(a => a.productGroupID == option));
    }

    function filterYear(option) {
        setResProductModel(option.value);
        setIsHeadingBtn(false);
    }

    function filterModel(option) {
        setResProductModel(null); // 2000
        setResProductType(option);

        if (option == '0' || option == '') {
            dropDownResetter(() => {
                setResProductModel(null);
            });
            return;
        }
    }

    function filterCatSup1(option) {
        setCatMain(option);
        setoptionCatSup(engineGroupsSup.filter(a => a.GroupIDMain == option));
        // console.log(filterCatSup1);
    }

    function filterCatSup2(option) {
        setSupCat(option.value);
        setoptionCatSup2(engineGroupsSup2.filter(a => a.GroupIDMainSup1 === option.value));
        // console.log(filterCatSup1);
    }




    //for the search 
    //query select Cat
    let Engine_Groups = CatMain;
    let Engine_GroupsSup = SuPCat;
    let Engine_GroupsSup2 = SSuPCat;

    useEffect(() => {
        // requestBrands(); requestProductGroups();requestProductModel();requestProductModelTypes();

        SendRequset();
        console.log(engineGroups);
    }, []);

    // useEffect(() => {
    // }, [engineGroups]);

    function getResultAll() {
        const Body = {
            // "name": "Site_FeatchPartByAll",
            // "values": {
            //     "search": ''
            // }

            "name": "Site_FeatchPartByTypeVehicle",
            "values": {
                "brand": parseInt(brand),
                "group": resGroup,
                "type": resProductType,
                "year": resProductModel?.toString() ?? null,
                "pageNumber": 1,
                "freeText": "",
                "productNo": "",
                "cat": null,
                "subCat": null,
                "subSubCat": null
            }
        }
        const request = axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        })
        axios.all([request])
            .then(
                axios.spread(
                    function (ProductsWithDetails) {
                        // setTimeout(setItems(ProductsWithDetails.data), 500);
                    })
            )
            .catch(function (error) {
                // setItems([])
                console.log(error);
            });
    }

    function refreshPage(type, brandID = brand, resProductTypeQuery = resProductType, resGroupQuery = resGroup, resProductModelQuery = resProductModel) {
        //   setTimeout(()=>{
        //      window.location.reload(false);
        //  }, 500);
        //  console.log('page to reload')

        if (search) {
            if (type == "Cat") {
                setQueryProduct('');
                search.FunCat(Engine_Groups, Engine_GroupsSup, Engine_GroupsSup2);
            }
            if (type == "Vehicle") {
                setQueryProduct(null);
                search.FunVech(parseInt(brandID), resProductTypeQuery, resGroupQuery, resProductModelQuery);
                // isLoadingFuncProp(true);
            }
            if (type == "ProductID") {
                search.FunPro(Product);
            }
            isLoadingFuncProp(true);


            // console.log("fun search",search)
        }
    }


    function show(section) {


        setVehicle("hidden");
        setVin("hidden");
        setPart("hidden");
        setCategory("hidden");

        switch (section) {
            case "vehicle":
                setVehicle("");
                break;
            case "vin":
                setVin("");
                break;
            case "part":
                setPart("");
                break;
            case "category":
                setCategory("");
                break;
            default:
                setVehicle("");
        }
    }

    function getvalue(e) {
        console.log(e.target.value);
    }

    useEffect(() => {

        if (brand != null && brand != undefined && Brands.length > 0)
            filterGroup(brand)
    }, [brand, Brands]);

    // useEffect(() => {
    //     try {
    //
    //         if (Brands && Brands.length > 0) {
    //
    //             setTimeout(() => {
    //
    //                 var bbr = query.get("brand") ?? null;
    //                 console.log("MMM>>>>>>>>>>>>>>>>:", bbr);
    //                 setBrand(bbr);
    //                 if (bbr) {
    //
    //                     filterGroup(bbr);
    //                     refreshPage("Vehicle", bbr);
    //                 }
    //
    //
    //             }, 1000)
    //
    //         }
    //
    //
    //     } catch (error) {
    //
    //     }
    // }, [Brands]);

    // vehicle
    const [resGroup, setResGroup] = useState(null);
    useEffect(() => {
        if (FProductGroups.length != 0) {
            let resVal = resGroup;
            setResGroup(null);
            setTimeout(() => {
                setResGroup(resVal);
                filterModelType(resVal);
            }, 1000);
        }
    }, [FProductGroups]);

    const [resProductType, setResProductType] = useState(null);
    useEffect(() => {
        if (FProductModelTypes.length != 0) {
            let resVal = resProductType;
            setResProductType(null);
            setTimeout(() => {
                setResProductType(resVal);
            }, 1200);
            // setResProductType(Stype);
        }
    }, [FProductModelTypes]);

    const [resProductModel, setResProductModel] = useState(null);
    useEffect(() => {
        if (ProductModel.length != 0) {
            let resVal = resProductModel;
            setResProductModel(null);
            console.log("resVal: >>>>>>>>>>>>>>>>", resVal);
            setTimeout(() => {
                setResProductModel(resVal);
            }, 1300);
        }
    }, [ProductModel]);
    // end

    // ProductID
    const [resProduct, setResProduct] = useState('');
    useEffect(() => {
        if (Product)
            setResProduct(Product);
    }, [Product]);

    // end

    useEffect(() => {
        let body = {
            "brand": brand,
            'group': resGroup,
            'type': resProductType,
            'year': resProductModel
        };
        if (onChangValuesProp)
            onChangValuesProp(body);
    }, [brand, resGroup, resProductType, resProductModel]);

    const [isHeadingBtn, setIsHeadingBtn] = useState(false);

    // Define the function to be called from the parent
    const clearFiltersFunc = () => {
        console.log('Function called in child component!');
        // Add your functionality here
        setBrand(null);
        setResGroup(null);
        setResProductType(null);
        setResProductModel(null);
        // setYear(null);

        setResProduct(null);

        console.info(queryProduct);
        setQueryProduct(null);
        setPro(null);
        console.info(queryProduct);


        // clearFilterProp = false;
        setBrand(null);
        setIsHeadingBtn(true);

        setCatMain(null);
        setSupCat(null);
        setSSupCat(null);

    };

    // Expose the function to the parent component using useImperativeHandle
    useImperativeHandle(ref, () => ({
        clearFiltersFunc,
    }));

    useEffect(() => {
        if (resProductModel) {
        }
    }, [resProductModel]);

    return (
        <>

            {
                engineGroups.length != 0 && engineGroupsSup.length != 0 && engineGroupsSup2.length != 0 &&

                (
                    <div className="custom-popover" >
                        <div className="container py-5 relative z-40" id="CustomPopover">
                            <CustomPopover listMain={engineGroups} level2={engineGroupsSup} level3={engineGroupsSup2} />
                        </div>
                    </div>
                )
            }

            {/*<div className={"container mb-32 " + languageDir}>*/}
            {/*    <div className={"filter-search-btns-2 mb-8"}>*/}
            {/*        /!*<button*!/*/}
            {/*        /!*    onClick={() => {*!/*/}
            {/*        /!*        show("vehicle");*!/*/}
            {/*        /!*        setFilterShow("vehicle");*!/*/}
            {/*        /!*    }}*!/*/}
            {/*        /!*    className={"text-20 " + vehicle}*!/*/}
            {/*        /!*>*!/*/}
            {/*        /!*    {trans("eparts:vehicle")}*!/*/}
            {/*        /!*</button>*!/*/}

            {/*        /!*<button onClick={() => show("vin")} className={"text-20 " + vin}>{trans("eparts:vin")}</button>*!/*/}

            {/*        /!*<button*!/*/}
            {/*        /!*    onClick={() => {*!/*/}
            {/*        /!*        show("part");*!/*/}
            {/*        /!*        setFilterShow("part");*!/*/}
            {/*        /!*    }}*!/*/}
            {/*        /!*    className={"text-20 " + part}*!/*/}
            {/*        /!*>*!/*/}
            {/*        /!*    {trans("eparts:product_id")}*!/*/}
            {/*        /!*</button>*!/*/}
            {/*        /!*<button*!/*/}
            {/*        /!*    onClick={() => show("category")}*!/*/}
            {/*        /!*    className={"text-20 " + category}*!/*/}
            {/*        /!*>*!/*/}
            {/*        /!*    {trans("eparts:category")}*!/*/}
            {/*        /!*</button>*!/*/}
            {/*    </div>*/}


            {/*    <div className={"filter-search-container "}>*/}

            {/*        /!*{"المركبات"}*!/*/}
            {/*        /!*<div className={"flex items-center " + vehicle}>*!/*/}
            {/*        /!*    <div className="filter-search-grid filter-search-4">*!/*/}


            {/*        /!*        <DropDownInput*!/*/}
            {/*        /!*            className={"flex-grow-1"}*!/*/}
            {/*        /!*            // isLoading={isIndex ? false : Brands.length == 0}*!/*/}
            {/*        /!*            title={trans("info:company")}*!/*/}
            {/*        /!*            selectedValue={brand}*!/*/}
            {/*        /!*            defaultOption={(brand == '0' || brand == null) ? {*!/*/}
            {/*        /!*                text: trans("info:company"),*!/*/}
            {/*        /!*                value: '0'*!/*/}
            {/*        /!*            } : null}*!/*/}
            {/*        /!*            isSecondary={true}*!/*/}
            {/*        /!*            // onfilter={(option) => filterGroup(option.value)}*!/*/}
            {/*        /!*            onfilter={(option) => {*!/*/}
            {/*        /!*                setResProductType(null);*!/*/}
            {/*        /!*                setResProductModel(null);*!/*/}
            {/*        /!*                setResGroup(null);*!/*/}

            {/*        /!*                setBrand(option.value.toString());*!/*/}
            {/*        /!*            }}*!/*/}
            {/*        /!*            isepart={true}*!/*/}
            {/*        /!*            options={[*!/*/}
            {/*        /!*                ...Brands?.map(B => {*!/*/}
            {/*        /!*                    return {value: B.brandID.toString(), text: B.description};*!/*/}


            {/*        /!*                })]}*!/*/}
            {/*        /!*        />*!/*/}

            {/*        /!*        <DropDownInput*!/*/}
            {/*        /!*            // isLoading={isIndex*!/*/}
            {/*        /!*            //     ? false*!/*/}
            {/*        /!*            //     : queryProduct ? false : FProductGroups.length == 0*!/*/}
            {/*        /!*            // }*!/*/}
            {/*        /!*            className={"flex-grow-1"}*!/*/}
            {/*        /!*            title={trans("eparts:vehicle")}*!/*/}
            {/*        /!*            selectedValue={resGroup}*!/*/}
            {/*        /!*            defaultOption={((brand == '0' || brand == null) || (resGroup == '0' || resGroup == null)) ? {*!/*/}
            {/*        /!*                text: trans("eparts:vehicle"),*!/*/}
            {/*        /!*                value: '0'*!/*/}
            {/*        /!*            } : null}*!/*/}
            {/*        /!*            isSecondary={true}*!/*/}
            {/*        /!*            onfilter={(option) => {*!/*/}
            {/*        /!*                setResProductType(null);*!/*/}
            {/*        /!*                setResProductModel(null);*!/*/}

            {/*        /!*                filterModelType(option.value);*!/*/}
            {/*        /!*            }*!/*/}
            {/*        /!*            }*!/*/}
            {/*        /!*            isepart={true}*!/*/}
            {/*        /!*            options={[*!/*/}
            {/*        /!*                ...FProductGroups.map(B => {*!/*/}

            {/*        /!*                    return {value: B.productGroupID, text: B.description};*!/*/}


            {/*        /!*                })]}*!/*/}
            {/*        /!*        />*!/*/}

            {/*        /!*        <DropDownInput*!/*/}
            {/*        /!*            // isLoading={isIndex*!/*/}
            {/*        /!*            //     ? false*!/*/}
            {/*        /!*            //     : queryProduct ? false : FProductGroups.length == 0*!/*/}
            {/*        /!*            // }*!/*/}
            {/*        /!*            className={"flex-grow-1"}*!/*/}
            {/*        /!*            title={trans("eparts:model")}*!/*/}
            {/*        /!*            selectedValue={resProductType}*!/*/}
            {/*        /!*            defaultOption={((brand == '0' || brand == null) || (resGroup == '0' || resGroup == null) || (resProductType == '0' || resProductType == null)) ? {*!/*/}
            {/*        /!*                text: trans("eparts:model"),*!/*/}
            {/*        /!*                value: '0'*!/*/}
            {/*        /!*            } : null}*!/*/}
            {/*        /!*            isSecondary={true}*!/*/}
            {/*        /!*            onfilter={(e) => {*!/*/}
            {/*        /!*                filterModel(e.value)*!/*/}
            {/*        /!*            }}*!/*/}
            {/*        /!*            isepart={true}*!/*/}
            {/*        /!*            options={[*!/*/}
            {/*        /!*                ...FProductModelTypes.map(B => {*!/*/}


            {/*        /!*                    return {value: B.productTypeID, text: B.description};*!/*/}


            {/*        /!*                })]}*!/*/}
            {/*        /!*        />*!/*/}

            {/*        /!*        <DropDownInput*!/*/}
            {/*        /!*            // isLoading={isIndex  ? false : ProductModel.length == 0}*!/*/}
            {/*        /!*            className={"flex-grow-1"}*!/*/}
            {/*        /!*            title={trans("info:created_year")}*!/*/}
            {/*        /!*            selectedValue={resProductModel}*!/*/}
            {/*        /!*            defaultOption={((brand == '0' || brand == null) || (resGroup == '0' || resGroup == null) || (resProductModel == '0' || resProductModel == null)) ? {*!/*/}
            {/*        /!*                text: trans("info:created_year"),*!/*/}
            {/*        /!*                value: '0'*!/*/}
            {/*        /!*            } : null}*!/*/}
            {/*        /!*            isSecondary={true}*!/*/}
            {/*        /!*            onfilter={filterYear}*!/*/}
            {/*        /!*            isepart={true}*!/*/}
            {/*        /!*            options={[*!/*/}
            {/*        /!*                ...ProductModel.map(B => {*!/*/}
            {/*        /!*                    // console.log(parseInt(B.modelID));*!/*/}

            {/*        /!*                    return {value: B.modelID, text: B.modelID};*!/*/}
            {/*        /!*                })*!/*/}

            {/*        /!*                // ...[2015, 2016, 2020, 2030].map(B=>{*!/*/}
            {/*        /!*                //*!/*/}
            {/*        /!*                //     return { value: B, text: B };*!/*/}
            {/*        /!*                // })*!/*/}
            {/*        /!*            ]}*!/*/}

            {/*        /!*        />*!/*/}

            {/*        /!*    </div>*!/*/}

            {/*        /!*    /!*{Stype +" ||&& :"+ group +" ||&&  :"+ year}*!/*!/*/}

            {/*        /!*    {*!/*/}
            {/*        /!*        isHeadingBtn == false && (*!/*/}
            {/*        /!*            resProductType && resGroup && resProductModel && (*!/*/}
            {/*        /!*                <Link*!/*/}
            {/*        /!*                    onClick={() => refreshPage("Vehicle", brand)}*!/*/}
            {/*        /!*                    to={"/eparts/sereach?" + "brand=" + brand + (resGroup ? "&group=" + resGroup : "") + (resProductType ? "&type=" + resProductType : "") + (resProductModel ? "&year=" + resProductModel : "")}*!/*/}
            {/*        /!*                    className={"btn btn-primary text-16 py-3 px-10 mr-4"}*!/*/}
            {/*        /!*                >*!/*/}
            {/*        /!*                    {trans("eparts:search")}*!/*/}
            {/*        /!*                </Link>*!/*/}

            {/*        /!*            )*!/*/}
            {/*        /!*        )*!/*/}
            {/*        /!*    }*!/*/}

            {/*        /!*    {*!/*/}
            {/*        /!*        // isHeadingBtn == true && (*!/*/}
            {/*        /!*        //     Stype && group && year && (*!/*/}
            {/*        /!*        //         <Link*!/*/}
            {/*        /!*        //             onClick={() => refreshPage("Vehicle", brand)}*!/*/}
            {/*        /!*        //             to={"/eparts/sereach?" + "brand=" + brand + (group ? "&group=" + group : "") + (Stype ? "&type=" + Stype : "") + (year ? "&year=" + year : "")}*!/*/}
            {/*        /!*        //             className={"btn btn-primary text-16 py-3 px-10 mr-4"}*!/*/}
            {/*        /!*        //         >*!/*/}
            {/*        /!*        //             {trans("eparts:search")}*!/*/}
            {/*        /!*        //         </Link>*!/*/}
            {/*        /!*        //*!/*/}
            {/*        /!*        //     )*!/*/}
            {/*        /!*        // )*!/*/}
            {/*        /!*    }*!/*/}
            {/*        /!*</div>*!/*/}

            {/*        /!*<div className={"flex items-center"+vin}>*!/*/}
            {/*        /!*    <div className="filter-search-grid filter-search-1">*!/*/}
            {/*        /!*        <InputField*!/*/}
            {/*        /!*            placeholder={trans("eparts:copmany")}*!/*/}
            {/*        /!*        />*!/*/}
            {/*        /!*        *!/*/}
            {/*        /!*    </div>*!/*/}
            {/*        /!*    <Link onClick={refreshPage} to={"/eparts/sereach"} className={"btn btn-primary text-16 py-3 px-10 mr-4"}>{trans("eparts:search")}</Link>*!/*/}
            {/*        /!*</div>*!/*/}

            {/*        /!*{"رقم الصنف"}*!/*/}
            {/*        /!*<div className={"flex items-center " + part}>*!/*/}
            {/*        <div className={"flex items-center "}>*/}
            {/*            <div className="filter-search-grid filter-search-1">*/}

            {/*                <InputField*/}

            {/*                    // placeholder={trans("new:product_no")}*/}
            {/*                    placeholder={trans("info:name-number-search")}*/}
            {/*                    onChangeValue={(e) => {*/}
            {/*                        setQueryProduct(null);*/}
            {/*                        SetProduct(e);*/}
            {/*                    }*/}
            {/*                    }*/}
            {/*                    value={queryProduct}*/}
            {/*                />*/}
            {/*            </div>*/}

            {/*            {*/}
            {/*                resProduct && resProduct != null && resProduct != '' && resProduct != 0 ? (*/}
            {/*                    <Link*/}
            {/*                        onClick={() => refreshPage("ProductID")}*/}
            {/*                        to={"/eparts/sereach?Product=" + resProduct}*/}
            {/*                        className={"btn btn-primary text-16 py-3 px-10 mr-4"}*/}
            {/*                    >*/}
            {/*                        {trans("eparts:search")}*/}
            {/*                    </Link>*/}
            {/*                ) : <></>*/}
            {/*            }*/}
            {/*        </div>*/}
            {/*    </div>*/}

            {/*    /!*{"الفئات"}*!/*/}
            {/*    {*/}
            {/*        isIndex == false && (*/}
            {/*            <div className={"filter-search-container mt-5"}>*/}
            {/*                <div className={"flex items-center "}>*/}
            {/*                    <div className="filter-search-grid filter-search-3">*/}

            {/*                        <DropDownInput*/}
            {/*                            className={"flex-grow-1"}*/}
            {/*                            isSecondary={true}*/}
            {/*                            selectedValue={CatMain}*/}
            {/*                            defaultOption={(CatMain == null && CatMain == '0') ? {*/}
            {/*                                text: trans("info:created_year"),*/}
            {/*                                value: '0'*/}
            {/*                            } : null}*/}
            {/*                            title={trans("eparts:category")}*/}
            {/*                            options={[*/}
            {/*                                ...engineGroups.map(H => {*/}
            {/*                                        if (lang === "ar") {*/}
            {/*                                            return {value: H.GroupIDMain, text: H.DescriptionAr};*/}
            {/*                                        } else {*/}
            {/*                                            return {value: H.GroupIDMain, text: H.DescriptionEn};*/}
            {/*                                        }*/}
            {/*                                    }*/}
            {/*                                )*/}
            {/*                            ]*/}

            {/*                            }*/}
            {/*                            onfilter={(e) => {*/}
            {/*                                filterCatSup1(e.value)*/}
            {/*                            }}*/}
            {/*                        />*/}

            {/*                        <DropDownInput*/}
            {/*                            selectedValue={SuPCat}*/}
            {/*                            defaultOption={(CatMain == null && CatMain == '0') && (SuPCat == null && SuPCat == "0") ? {*/}
            {/*                                text: trans("eparts:Subcategory"),*/}
            {/*                                value: '0'*/}
            {/*                            } : null}*/}
            {/*                            className={"flex-grow-1"}*/}
            {/*                            title={trans("eparts:Subcategory")}*/}
            {/*                            isSecondary={true}*/}
            {/*                            options={[*/}
            {/*                                ...fCatSup1.map(B => {*/}

            {/*                                    if (lang === "ar") {*/}
            {/*                                        return {value: B.GroupIDMainSup1, text: B.DescriptionAr};*/}
            {/*                                    } else {*/}
            {/*                                        return {value: B.GroupIDMainSup1, text: B.DescriptionEn};*/}
            {/*                                    }*/}

            {/*                                })*/}
            {/*                            ]}*/}
            {/*                            onfilter={filterCatSup2}*/}

            {/*                        />*/}

            {/*                        <DropDownInput*/}
            {/*                            // selectedValue={`${CatMain}${SuPCat}`}*/}
            {/*                            className={"flex-grow-1"}*/}
            {/*                            title={trans("eparts:Subcategory2")}*/}
            {/*                            defaultOption={*/}
            {/*                                (CatMain == null && CatMain == '0') &&*/}
            {/*                                (SuPCat == null && SuPCat == "0") &&*/}
            {/*                                (SSuPCat == null && SSuPCat == "0")*/}
            {/*                                    ? {*/}
            {/*                                        text: trans("eparts:Subcategory2"),*/}
            {/*                                        value: '0'*/}
            {/*                                    } : null}*/}
            {/*                            selectedValue={SSuPCat}*/}
            {/*                            isSecondary={true}*/}
            {/*                            options={[*/}
            {/*                                ...fCatSup2.map(B => {*/}

            {/*                                    if (lang === "ar") {*/}
            {/*                                        return {value: B.GroupIDMainSup2, text: B.DescriptionAr};*/}
            {/*                                    } else {*/}
            {/*                                        return {value: B.GroupIDMainSup2, text: B.DescriptionEn};*/}
            {/*                                    }*/}

            {/*                                })]}*/}
            {/*                            onfilter={setCatSup3}*/}
            {/*                        />*/}


            {/*                        {*/}
            {/*                            (Engine_Groups || Engine_GroupsSup || Engine_GroupsSup2) ? (*/}
            {/*                                <Link*/}
            {/*                                    // onClick={() => refreshPage("Cat")}*/}
            {/*                                    // to={"/eparts/sereach?" + "CatMain=" + CatMain + "&SuPCat=" + SuPCat + "&SSuPCat=" + SSuPCat}*/}
            {/*                                    onClick={async () => {*/}
            {/*                                        setCatMain(null);*/}
            {/*                                        setSupCat(null);*/}
            {/*                                        setSSupCat(null);*/}

            {/*                                        if (filterShow.toLowerCase() == 'part') {*/}
            {/*                                            await refreshPage("ProductID");*/}
            {/*                                        } else if (filterShow.toLowerCase() == "vehicle") {*/}
            {/*                                            await refreshPage("Vehicle", brand);*/}
            {/*                                        }*/}

            {/*                                        // await refreshPage("Vehicle",  brand);*/}
            {/*                                        // getResultAll()*/}
            {/*                                    }}*/}
            {/*                                    to={filterShow.toLowerCase() != '' ?*/}
            {/*                                        (*/}
            {/*                                            filterShow.toLowerCase() == 'part'*/}
            {/*                                                ? "/eparts/sereach?Product=" + resProduct*/}
            {/*                                                : "/eparts/sereach?" + "brand=" + brand + (resGroup ? "&group=" + resGroup : "") + (resProductType ? "&type=" + resProductType : "") + (resProductModel ? "&year=" + resProductModel : "")*/}
            {/*                                        ) : "/eparts/sereach"*/}
            {/*                                    }*/}
            {/*                                    className={"btn btn-primary text-16 py-3 px-10 mr-4 "}*/}
            {/*                                >*/}
            {/*                                    /!*{trans("eparts:search")}*!/*/}
            {/*                                    {"جميع الفئات"}*/}
            {/*                                </Link>*/}
            {/*                            ) : <></>*/}
            {/*                        }*/}
            {/*                    </div>*/}


            {/*                </div>*/}
            {/*            </div>*/}
            {/*        )*/}
            {/*    }*/}

            {/*</div>*/}
        </>
    );
}
);
export default PartSearch;
