import { Base64 } from 'js-base64';
import config from '../../../configuration/config.json';
export default function ContactTile({ icon, name, value, type }) {

    return (
        <div className={"contact-tile"}>
            <a target="_blank" href={type != null ? type + value : null} className="contact-tile">
                <div className="contact-tile-icon">
                    <img src={(Base64.isValid(icon) == 1 ? "data:image/png;base64," : config.mainURL) + "" + icon} className={"object-full object-contain"} alt="" />
                </div>
                <div className="contact-tile-info">

                    <div className="contact-tile-key">{name}</div>
                    <div className="contact-tile-value"> {value?.includes("http") ? "" : value}</div>
                   
                </div>
            </a>
        </div>
    );
}