function PageHeading(prop) {
    // const languageDir = 'language-' + localStorage.getItem('i18nextLng');
    const languageDir = '' + localStorage.getItem('i18nextLng');
    return (
        <div className={`page-heading mb-12 inline-block ${ (!prop.fixed ?? false) && languageDir}`}>

            <div className={"page-heading-text"}>
                <div className={"page-heading-flex " +  languageDir}>
                    <p>{prop.subtitle}</p>
                    {prop.children}
                </div>
                <h3 dangerouslySetInnerHTML={{__html: prop.title}}/>
            </div>
            {
                prop.text != null ?
                    <div className={"text-12 pl-6 mt-4"}>{prop.text}</div>
                    : null
            }
        </div>
    );
}

export default PageHeading;
