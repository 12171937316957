import { Link } from "react-router-dom";
import {CountDown} from "../../../../pages/eparts/components/countDown"
import { Price } from "../../../__simple/_price";
import config from "../../../../configuration/config.json";
export function NavPartCard({title,description,image,to,endDate,startDate,totalTime, total}) {
    return (
        <Link to={to} className="nav-box  nav-box-part  w-full ">
            <div className="nav-box-part-date">
            <CountDown Total={totalTime} start={startDate.getDate()}  end={endDate.getDate()}/>
            </div>
            <div className="nav-box-overlay "></div>
            <img src={config.mainURL+ image} alt="service" className="nav-box-img object-cover"/>

            <div className="nav-box-part-text">
                <h2 className={"text-16 text-line-clamp text-line-clamp-1 font-bold mb-2"}>{title}</h2>
                <div className="text-primary text-line-clamp text-16 px-5"><Price price={total}/></div>
                <p className={"text-16 text-line-clamp text-line-clamp-2"}>
                  {description}
                </p>
            </div>
        </Link>
    );
}