
function FooterHeading(prop) {
    return (
        <div className="footer-heading mb-12">

            <div className={"footer-heading-box"}></div>
            <h3 className={"p-2 "+prop.lang}>{prop.title}</h3>
        </div>
    );
}

export default FooterHeading;
