import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import config from "../configuration/config.json";

export function ServiceBox({ key = null, titlebtn, categoryId, small = true, link, img, imgUrl = "", titleEn = " ", subtitleEn = " ", titleAr = " ", subtitleAr = " " }) {
    const [lang, setlang] = useState(localStorage.getItem('i18nextLng'));
    let titel = lang === "en" ? titleEn : titleAr;
    let subtitel = lang === "en" ? subtitleEn : subtitleAr;
    let image = img !== null || img !== 'null' ? "data:image/png;base64," + img : "/assets/images/car-bg.png";

    let imageUrl = (imgUrl !== null && imgUrl != 'null' && imgUrl != '') ? config.controllURL + imgUrl : (lang == "ar" ? "/assets/images/eparts/PARTS_TOYOTA-AR-no-img.png" : "/assets/images/eparts/PARTS_TOYOTA-EN-no-img.png");

    return (
        <Fragment key={key}>
            <a href={link ?? "#"}

                onClick={(e) => {

                    if (link == null || link == undefined)
                        e.preventDefault();

                }}
            >

                <div className={"box  box-service w-full " + (small ? "box-small" : "box-mid")}>
                    <div className="box-overlay"></div>
                    {
                        img && !imgUrl && (
                            <img src={image} alt="service" className="box-img object-cover" />
                        )
                    }
                    {
                        imageUrl && !img && (
                            <img src={imageUrl} alt="service" className="box-img object-cover" />
                        )
                    }
                    {/*<img src={image} alt="service" className="box-img object-cover" />*/}

                    <div className="box-text px-5" style={{ width: '100%' }}>
                        <h2 className={"text-20 mb-8"}>{titel}</h2>
                        <p className={"text-40"}>{subtitel}</p>
                    </div>
                    {

                        link && titlebtn &&
                        <a href={link} className="btn btn-primary text-20 py-2 px-10 box-btn">{titlebtn}</a>
                    }

                </div>
            </a>
        </Fragment>
    );
}