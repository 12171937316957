import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { LikeButton } from "../../components/__simple/_likeButton";
import { Price } from "../../components/__simple/_price";
import config from "../../configuration/config.json";
const FavoriteItem = ({ productTitle, productValue, subProductValue, productImage, productID, price, to, onClick, showPric }) => {
    const { t: trans } = useTranslation(["info"]);
    const imagePath = (productImage) ? config.mainURL + productImage : "/assets/images/eparts/default.png";
    return (
        <div className={"part-card border-radius "}>
            <LikeButton
                favorite={true}
                onToggle={() => {
                    if (onClick) {
                        onClick(productID)
                    }
                }}
            />
            <Link to={to}>
                <div className="part-img">
                    <img src={imagePath} alt={productValue} />
                </div>
            </Link>
            <div className="part-card-info border-radius box-shadow ">
                <Link to={to}>
                    <div className="part-card-info-name mb-2">
                        <h3 className={"text-16 font-bold  text-line-clamp text-line-clamp-1"}>
                            {productTitle && productTitle + ' : '}  {productValue}
                        </h3>
                    </div>
                    <p className={"text-16 mb-4  text-line-clamp text-line-clamp-2"}>{subProductValue}</p>
                    <div className=" mb-4  text-line-clamp text-line-clamp-1">
                        {showPric &&

                            <>
                                <span className={"text-14"}>{trans("info:product_price")}</span>
                                <span className={"text-primary  text-20"}> <Price price={price} /></span>
                            </>
                        }

                    </div>
                </Link>
            </div>

        </div>

    );
};
export default FavoriteItem;
