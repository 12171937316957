import {Link} from "react-router-dom";

function IconText({title, icon, link="./"}) {

    return (
        <Link to={link}>
            <div className={"icon-text inline-block mb-5"}>
                <div className="icon-text-img mb-8 dark-filter-gray">
                    <img src={icon} alt={title}/>
                </div>
                <div className="icon-text-text text-center">


                    <h4 className={"text-24  font-bold mb-7"}>{title}</h4>
                </div>
            </div>
        </Link>
    );
}

export default IconText;