import config from "../../../configuration/config.json"
export function CarColorPicker({ color, active, imageBase64 = null, urlImageColor,name,...prop }) {
    let isActive = (color === active);
    if (imageBase64) {
        return (
            <div {...prop} className={"color-picker-car " + (isActive ? " active ":"")}>
                
                <div className={"color-picker-car-image "} style={{ BackgroundSize: 'cover', backgroundImage: `url("data:image/png;base64,${imageBase64}")` }}>
                </div>

                <div className={(isActive ? "active":"")}>
                    <img hidden={!isActive} src={"/assets/icons/check.svg"} alt={"check"}  onError = {e => e.target.style.display = 'none'}/>
                </div>
            </div>
        );
    } else if (urlImageColor) {
        return (
            <div {...prop} className={"color-picker-car " + (isActive ? " active ":"")}>
                <div className={"color-picker-car-image "} style={{ BackgroundSize: 'cover',  backgroundImage: `url('${(config.mainURL + urlImageColor).replaceAll("\\","/")}')` }}>
                </div>

                <div className={(isActive ? "active":"")}>
                    <img hidden={!isActive} src={"/assets/icons/check.svg"} alt={"check"}  onError = {e => e.target.style.display = 'none'}/>
                </div>
            </div>
        );
    }
    return (
        <div {...prop} className={"color-picker-car " + (isActive ? "active":"")} style={{ background: color, BackgroundSize: 'cover' }}>
            <span className={"color-picker-car-text "} >{color}</span>
            <div className={(isActive ? "active":"")}>
                <img hidden={!isActive} src={"/assets/icons/check.svg"} alt={"check"} onError = {e => e.target.style.display = 'none'} />
            </div>
        </div>
    );
}
