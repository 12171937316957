import {Link} from "react-router-dom";
import Moment from "react-moment";
import { Trans, useTranslation } from "react-i18next";

function News({image, title, date, desc, link, brand}) {
    const {t: trans} = useTranslation(["common"]);
    function tag(brand) {
        if (brand === "toyota" || brand === "تويوتا") {
            return (<span className={" tag tag-toyota "}>{brand}</span>);
        } else {
            return (<span className={" tag tag-lexus "}>{brand}</span>);
        }
    }

    return (
        <Link to={link}>


            <article className={"news-card border-radius box-shadow "}>
                <div className="news-card-img border-radius">
                    <img src={image} alt=""/>
                    {tag(brand)}
                </div>
                <div className="news-card-text">
                    <h3 className={"text-20 font-bold mb-2 text-line-clamp text-line-clamp-1"}>{title}</h3>

                    <div>
                            <Moment className={"text-16 mb-2"} date={date} format={"DD/MM/YYYY"}/>

                        <p className={"text-16  text-line-clamp text-line-clamp-3"}>
                            {desc}
                        </p>

                    </div>
                    <div className={"text-left mt-auto"}>
                        <Link to={link} className={"text-16  text-primary"}>{trans("common:show-more")}</Link>
                    </div>
                </div>
            </article>
        </Link>
    );
}

export default News;