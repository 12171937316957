import { useRef, useState } from "react";
import ContactTile from "./contactTile";

export default function ContactList({ branch, contacts, onGeo }) {
    const height = useRef(null);
    const [divHeight, setHeight] = useState(0);

    function showData() {
        onGeo(branch)
        setHeight(divHeight <= 0 ? height.current.scrollHeight : 0);
    }

    return (
        <div className={"contact-list"}>

            <div onClick={showData} className="contact-list-name">
                <div className={"text-20 font-bold"}>{branch.content}</div>

                <img className={"filter-black w-8 h-8 mr-3 " + (divHeight > 0 && "rotate-180")}
                    src={"/assets/icons/arrow.svg"} alt={"show"} />
            </div>
            <div ref={height} className={"contact-list-info"} style={{ height: divHeight + "px" }}>
                <div className="m-8 space-y-6">

                    {
                        contacts?.map((contact, index) => {
                            return (
                                <ContactTile
                                    key={index}
                                    icon={contact?.typeIconPath}
                                    value={contact?.contactValue}
                                    type={contact?.typeValue}
                                    name={contact?.contactTitle ?? contact.typeTitle}
                                />
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
}