import {add} from "cart-localstorage";
import {toast} from "react-toastify";
import {Translation} from 'react-i18next';


export function addToCart({id,MiniDownPayment=0, name="",nameEn="",modelTypeID, modelCode="",price=1, brand,brandEn,
 image,Tax=0,Discount=0,colorId="",Type,modelTypes="",modelTypesEn,year="",Engine_Groups="",productID="",group,groupEn,BrandID,colorName="",colorNameEn,packageId="",Orderdate=""}) {
    const success = <Translation ns={"messages"}>
        {
            (t, {i18n}) => <p className={"text-20"}>{t('add_to_cart')}</p>
        }
    </Translation>

    const error = <Translation ns={"messages"}>
        {
            (t, {i18n}) => <p className={"text-20"}>{t('unexpected_errer')}</p>
        }
    </Translation>
  
    if (id != null ) {

        var objectCart ={
            id: id,
            name: name,
            nameEn: nameEn,
            price:price, 
            brand: brand, 
            brandEn: brandEn,
            image: image,
            Tax:Tax,
            Discount:Discount,
            Type:Type,
            colorID:colorId,
            BrandID:BrandID,
            colorName:colorName,
            colorNameEn:colorNameEn,
            groupID:group,
            groupEn:groupEn,
            modelTypes:modelTypes,
            modelTypesEn:modelTypesEn,
            year:year,
            modelTypeID:modelTypeID,
            Engine_Groups:Engine_Groups,
            ProductID:productID
            ,MiniDownPayment:MiniDownPayment
            ,modelCode:modelCode
            ,packageId:packageId
            ,Orderdate:Orderdate
            
        }
       console.log("addToCart>>>>>>>>>>>>:",objectCart);
        add(objectCart);

        toast.success(success, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

        try {
            window.dispatchEvent(new Event("storage"));
        } catch (error) {

        }
        
    } else {
        toast.error(<div className={"text-24"}>{error}</div>, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
}