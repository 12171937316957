import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, EffectFade, Navigation, Pagination } from "swiper";
SwiperCore.use([Pagination, Navigation, Autoplay, EffectFade]);

export default function CounterSection({ about }) {
    if (about?.counter3 == null)
        return null;

    return (

        <div className="container mb-32 what-offer" id='aboutUsElmenet'>
            <div className="what-offer-text">
                <h3 className={"text-30 font-bold mb-8"}>{about?.title}</h3>
                <p className={"text-20"}>
                    {about?.desc}
                </p>
            </div>
            <div className="what-offer-boxes">

                <div className="what-offer-box">
                    <h3>{Intl.NumberFormat().format(about?.counter1)}{"+"}</h3>
                    <small>{about?.counterTitle1}</small>
                </div>
                <div className="what-offer-box">
                    <h3>{Intl.NumberFormat().format(about?.counter2)}{"+"}</h3>
                    <small>{about?.counterTitle2}</small>
                </div>
                <div className="what-offer-box">
                    <h3>{Intl.NumberFormat().format(about?.counter3)}{"+"}</h3>
                    <small>{about?.counterTitle3}</small>
                </div>
            </div>

        </div>
    );
}