import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Banner from "../../components/banner"
import config from "../../configuration/config.json";
import { Helmet } from "react-helmet";
export function PrivacyPage() {
    const [banner, setBanner] = useState({});
    const [baseInfo, setBaseInfo] = useState([]);
    const lang = localStorage.getItem("i18nextLng");
    const dir = lang === 'en' ? 'right' : 'left';
    const languageDir = 'language-' + lang;

    const { t: trans } = useTranslation("common");
    const [indexPanel, setIndexPanel] = useState("1000");
    useEffect(async () => {

        console.log("PrivacyPage>>>>>>>>>>>>>>>>>>>>>>>>>>>");
        try {
            const response = await axios.request({
                baseURL: config.controllURL,
                url: lang + "/api/privacy/index",
                method: "get",
            });
            setBanner(response.data.banner);
            setBaseInfo(response.data.baseInfo);
        } catch (error) {
            console.log(error);
        }
    }, []);


    const [seoPageInfo, setSeoPageInfo] = useState({});
    useEffect(async () => {

        var page = "Privacy policy"


        const response = await axios.post(
            config.baseURL + 'func',
            {
                "Name": "SiteFetchSeoPages",
                "Values": {
                    "page": page
                }
            }
        );
        if (response.status == 200) {

            var resdata = response.data[0];

            setSeoPageInfo(resdata);


        }
    }, []);
    return (
        <section className={`privacy ${languageDir}`}>

            {
                seoPageInfo &&

                <Helmet>

                    <meta charSet="utf-8" />
                    {
                        seoPageInfo?.title &&
                        <title>{seoPageInfo?.title}</title>
                    }
                    {
                        seoPageInfo?.link &&
                        <link rel="canonical" href={seoPageInfo?.link} />

                    }
                    {
                        seoPageInfo?.MetaDescription &&
                        <meta name="description" content={seoPageInfo?.MetaDescription} />
                    }
                    {
                        seoPageInfo?.MetaGroups &&
                        <meta name="keywords" content={seoPageInfo?.MetaGroups} />
                    }

                </Helmet>


            }
            <Banner
                subtitle={banner?.subtitle}
                title={banner?.title}
                desc={banner?.excerpt}
                background={banner?.imagePath}
                backOpasity={banner?.backOpasity}
            />
            <img className={`privacy-belt privacy-belt-${dir}`} src="/assets/images/belt.png" alt="belt" />
            <div className="nice-container-fluid ">
                <div className="nice-row">
                    <div className="nice-col-md-8 px-4">




                        {
                            baseInfo &&
                            baseInfo?.map((Agreement, index) => {

                                return (

                                    // <article key={index} className="my-20">
                                    //     <div className="bg-secondary p-4">
                                    //         <h3 className="text-24 font-bold">{Agreement?.intro}</h3>
                                    //     </div>
                                    //     <ul className="text-20 my-20 mx-10 leading-relaxed ">

                                    //         {
                                    //             Agreement?.explaination?.split('\n').map((note, index) => {

                                    //                 return (

                                    //                     // trimEnd(note) ?
                                    //                     <li key={index} className=" text-20 leading-relaxed"  >
                                    //                         <span className="text-20" >{note?.replaceAll("*"," • ")}</span>
                                    //                     </li>
                                    //                     // : null

                                    //                 );
                                    //             })
                                    //         }

                                    //     </ul>
                                    // </article>


                                    <div key={index} className="panel-section panel-white bg-theme-up">
                                        <div className={`panel-content   ${(indexPanel == index) ? "show-panel-info" : ""}`}>
                                            <div className="panel-content-top px-5 bg-secondary p-4"

                                                onClick={(e) => setIndexPanel(indexPanel == index ? "1000" : index)}

                                            >
                                                <h1 className="text-20   text-super-gray font-bold">{Agreement?.intro}</h1>
                                                <img src="/assets/icons/arrow-primary.svg" style={{ width: "3rem" }} />
                                            </div>

                                            <div className="panel-content-body">
                                                <div className="mx-10 my-10">

                                                    {
                                                        Agreement ?
                                                            <div className={"new-lines "}>
                                                                <ul className={"text-20 my-10 mx-10 leading-relaxed"}>
                                                                    {
                                                                        Agreement?.explaination?.split('\n')?.map((item, index) => {

                                                                            return <li className={"text-20 leading-relaxed font-bold"} key={index}>
                                                                                {item?.replaceAll("*", " • ")}
                                                                            </li>
                                                                        })
                                                                    }
                                                                </ul>

                                                            </div>
                                                            : null
                                                    }

                                                </div>

                                            </div>
                                        </div>
                                    </div>


                                );
                            })



                        }

                    </div>
                </div>
            </div>
        </section>
    );
}