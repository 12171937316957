import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {LikeButton} from "../../../components/__simple/_likeButton";
import {Price} from "../../../components/__simple/_price";
import {add, total, list} from 'cart-localstorage'
import {useState} from "react";
import {addToCart} from "../../../_functions/cart";

export function Gift({hasLike = false , ...props}) {
    const {t: trans} = useTranslation(["common", "eparts"]);

    let imagePath = "/assets/images/eparts/defulte.png";


    imagePath = (props.ProductImage) ? "data:image/png;base64," + props.ProductImage : imagePath;
    const [lang, setlang] = useState(localStorage.getItem('i18nextLng'));
    const languageDir = 'language-' + localStorage.getItem('i18nextLng');
    let Description = "Description....";
    Description = lang === "ar" ? props.DescriptionAr : props.DescriptionEn;
    return (

        <div className={"part-card border-radius "}>

            <Link to= {"/eparts/detail/"+props.GUID}>
                <div className="part-img">

                    <img src={imagePath} alt=""/>

                </div>
            </Link>
            <div className={"part-card-info border-radius box-shadow " + languageDir}>
                <Link to={"/eparts/detail/"+props.GUID}>

                    <div className="part-card-info-name mb-2">
                        <h3 className={"text-16 font-bold  text-line-clamp text-line-clamp-1"}>
                            {trans("eparts:product_id")} : {props.ProductID}
                        </h3>
                    </div>
                    <p className={"text-16 mb-4  text-line-clamp text-line-clamp-1"}>{Description}</p>
                    <div className="part-options">
                        <div className="options-price">
                            <h4 className={"text-16 font-bold inline-block text-primary"}>
                                <Price price={props.NetSalePrice}/>
                            </h4>
                        </div>

                    </div>
                </Link>
            </div>

        </div>

    );
}

