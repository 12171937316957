export default function VisionsSection({ visions }) {
    if (visions == null)
        return null;
    let lang = localStorage.getItem("i18nextLng");
    const languageDir = 'language-' + lang;
    return (
        <div className="container mb-32 mt-40 space-y-32">

            {
                visions.map((vision) => {
                    return (
                        <div className="about-box">
                            <h2 className={"about-box-title "}>{vision.name}</h2>
                            <p className={"about-box-desc"}>{vision.content}</p>
                        </div>
                    );
                })
            }
        </div>
    );
}