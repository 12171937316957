import { DropDownInput } from "../../components/_input/_dropdown";
import { DropDownInput2 } from "../../components/_input/_dropdown2";
import { SwiperSlide, Swiper } from 'swiper/react';
import { useCallback, useState, useEffect, useRef } from "react";
import axios from "axios";
import PageHeading from "../../components/__simple/_pageheading";
import { useParams } from "react-router-dom";
import { slugCombine, slugSplitter } from '../../utils/slugManager';
import { useTranslation } from "react-i18next";
import config from '../../configuration/config.json';
import { trimEnd } from "lodash";
import { useHistory } from "react-router";
import Skeleton from 'react-loading-skeleton'
import { Price } from "../../components/__simple/_price";
import { groupBy, mapValues, omit } from "lodash";
import _ from 'lodash';
import Car from "../../components/car";
import { toast } from "react-toastify";
import { useUserCart } from '../../User/hooks/useUserCart';
import { useUserFavorite } from '../../User/hooks/useUserFavorite';
import { saveData } from "../../utils/localStorageManager";
import ImageGallery from "react-image-gallery";
export function CarOverviewPage() {
    const history = useHistory();
    const { slug, sn } = useParams();
    const { t: trans, i18n } = useTranslation(["new", "finance", "inputs", "info", "new"]);
    const [activePart, setActivePart] = useState(0);
    const [Cart, setCart] = useState({ Id: '', colorID: '', colorNameEn: "", modelTypeDescEn: "", groupEn: "", brandEn: "", modelTypeDesc: "", Price: 0, Discount: 0, Tax: 0, ProductID: '', colorName: "", group: "", brand: "", year: "", modelType: "", showPric: false, BrandID: '', MiniDownPayment: 0 });
    const { changecartItem } = useUserCart();
    let lang = i18n.language;
    const [isFullScreen, setIsFullScreen] = useState(false);
    const popGalleryRef = useRef(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingImages, setIsLoadingImages] = useState(false);
    const [isLoadingVehicleOffers, setLoadingVehicleOffers] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [showPanel, setShowPanel] = useState(false);
    const [indexPanel, setIndexPanel] = useState("-1");
    const [activeFilterMenu, setActiveFilterMenu] = useState(0);
    const { isFavoriteItem, changeFavoriteItemState } = useUserFavorite();
    const [scrollSnackUp, setScrollSnackUp] = useState({
        show: false,
        height: '16rem'
    });

    const vehicleFeatures = [{
        title: trans("new:year"),
        fieldName: "year"
    }, {
        title: trans("new:hourse"),
        fieldName: "hp"
    },
        , {
        title: trans("new:number_of_seats"),
        fieldName: "seatsNumber"
    },
        , {
        title: trans("new:cylinders"),
        fieldName: "cylinders"
    },
        , {
        title: trans("new:liter"),
        fieldName: "liter"
    },
    {
        title: trans("new:petrol"),
        fieldName: "petrol"
    }];
    const [activeModel, setActiveModel] = useState("");

    const [activeINColor, setActiveINColor] = useState("");
    const [activeOutColor, setActiveOutColor] = useState("");
    const [vehicle, setVehicle] = useState(null);
    const [features, setFeatures] = useState([]);
    const [extraDetails, setExtraDetails] = useState([]);
    const [models, setModels] = useState([]);
    // const [activeModel, setActiveModels] = useState({});
    const [suggestedVehicles, setSuggestedVehicles] = useState([]);
    const [isLoadingCars, setLoadingCars] = useState(true);
    const [colors, setCarColors] = useState([]);
    const [INcolors, setINCarColors] = useState([]);
    const [OUTcolors, setOUTCarColors] = useState([]);
    const [jsonColors, setJsonColors] = useState({});
    const [innerCarImages, setInnerCarImages] = useState([]);
    const [allCarImages, setAllCarImages] = useState([]);
    const [outerCarImages, setOuterCarImages] = useState([]);
    const [wheels, setWheels] = useState([]);
    const [allImagesCount, setAllImagesCount] = useState(null);
    const [Shipping, setShipping] = useState({ UnitTime: "", CountTime: 0, Cost: 0, DateTimeDelevry: "" });
    const [valueDelevaryCitie, setValueDelevaryCitie] = useState("0");
    const [vehicleOffers, setVehicleOffers] = useState([]);
    const [cities, setCities] = useState([]);
    const [selectedColor, setSelectedColor] = useState(null);
    const [productTypes, setProductTypes] = useState([]);
    const [defaultProductModel, setDefaultProductModel] = useState(null);
    const [cashColors, setCashColors] = useState({});

    const [selectcity, setSelectcity] = useState(null);
    const [selectBranch, setSelectBranch] = useState(null);
    const setCartData = (item) => {

        if (item) {

            var obb =
            {
                id: Cart?.Id,
                Type: 'car',
                colorId: Cart?.colorID
                , name: Cart?.modelTypeDesc
                , nameEn: Cart?.modelTypeDescEn
                , price: Cart?.Price
                , brand: Cart?.brand
                , brandEn: Cart?.brandEn
                , Tax: Cart?.Tax
                , image: Cart?.image
                , Discount: Cart?.Discount
                , productID: Cart?.ProductID
                , group: Cart?.group
                , groupEn: Cart?.group
                , BrandID: Cart?.BrandID
                , modelTypes: Cart?.modelTypeDesc
                , modelTypesEn: Cart?.modelTypeDescEn
                , colorName: Cart?.colorName
                , colorNameEn: Cart?.colorNameEn
                , year: Cart?.year
                , modelTypeID: Cart.modelType
                , MiniDownPayment: Cart?.MiniDownPayment
            }
            console.log("Cart:", Cart);
            changecartItem(Cart.ProductID, Cart.colorID, Cart.modelType, 1, null, null, obb, null, Cart?.year);
        }
    }


    useEffect(() => {

        if (selectedColor) {

            const selectdM = models?.find(item => item?.Type == selectedColor?.Type) ?? null;

            try {

                const currentValues = { ...Cart };
                currentValues.Id = selectedColor?.GUID;
                currentValues.brand = selectedColor["Brand Arabic"];
                currentValues.brandEn = selectedColor["Brand English"];
                currentValues.group = selectedColor["Group Arabic"];
                currentValues.groupEn = selectedColor["Group English"];
                currentValues.colorName = selectedColor["ColorDescriptionAr"];
                currentValues.colorNameEn = selectedColor["ColorDescriptionEn"];
                currentValues.colorID = selectedColor?.ColorID;
                currentValues.Price = selectedColor?.NetSalePrice;
                currentValues.Tax = selectedColor?.TaxAmount;
                currentValues.Discount = selectedColor?.Discount;
                currentValues.ProductID = selectedColor?.ProductID;
                currentValues.BrandID = selectedColor?.BrandID;
                currentValues.image = selectdM?.Image ?? (filterCarImages && filterCarImages[0]?.Image_URL);
                currentValues.modelType = selectedColor?.Type;
                currentValues.modelTypeDesc = selectedColor["Type Arabic"];
                currentValues.modelTypeDescEn = selectedColor["Type English"];
                currentValues.showPric = selectedColor?.showPric;
                currentValues.year = selectedColor?.Model;
                currentValues.MiniDownPayment = selectedColor.MiniDownPayment;
                console.log("useEffect Cart:", currentValues);
                setCart(currentValues);

            } catch (err) {
                //console.log(err);
            }
        }

    }, [selectedColor]);
    useEffect(() => {
        handleFetchCountries();
        GetBranch();

    }, []);


    useEffect(() => {
        callback();
        fetchFinanceSettings();
        initialBanksData();

    }, []);


    const [isLoadingBanks, setLoadingBanks] = useState(true);
    const [banks, setBanks] = useState([]);
    const initialBanksData = (active = true) => {

        setLoadingBanks(true);
        axios.post(
            config.baseURL + "func",
            {
                "name": "SiteFetchActiveBankWithFinanceSettings"
            }
        ).then(response => {
            const result = response.data;
            //console.log("result initialBanksData>>>>>>>>.", result)
            setBanks(result);
            setLoadingBanks(false);
        }).catch(error => {
            //console.log(error);
            setLoadingBanks(false);
        });
    };
    const [financeSettings, setFinanceSettings] = useState(null);
    const [finance, setFinance] = useState({ price: 0, month: 0, first: 0, last: 0, totalPrice: 0 });

    const fetchFinanceSettings = () => {

        // axios.post(
        //     config.baseURL + 'func',
        //     {
        //         "Name": "site_FetchFinanceSettings"
        //     }
        // ).then(response => {
        //     try {
        //         setFinanceSettings(response.data[0])
        //     } catch (error) {

        //     }

        // }).catch(error => {
        //     //console.log(error);
        // });
    };

    useEffect(() => {
        if (banks?.length > 0) {

            const minBank = banks.reduce((min, bank) => {
                const currentValue = bank.FinanceRate + bank.ManagementFees;
                const minValue = min.FinanceRate + min.ManagementFees;
                return currentValue < minValue ? bank : min;
            }, banks[0]);

            setFinanceSettings(minBank)
        }

    }, [banks]);


    const [selectedFinancePeriod, setSelectedFinancePeriod] = useState(60);


    const [minFirstPayPrice, setMinFirstPayPrice] = useState(0);
    const reCountFinanceSettings = (financeSettings, selectedFinancePeriod, currentFirstPayPrice) => {

        try {
            //////console.log('reCountFinanceSettings', financeSettings);
            if (selectedColor && financeSettings) {
                let financeData = { ...finance };
                if (selectedColor?.NetSalePrice && financeSettings?.ManagementFees >= 0) {

                    //selectedFinancePeriod = financeSettings?.MaxFinancePeriod;

                    const firstPay = (financeSettings?.AdvancedPaymentRate / 100) ?? 0;
                    const lastPay = (financeSettings?.LastPaymentRate / 100) ?? 0;
                    const managementFees = (financeSettings?.ManagementFees / 100) ?? 0; // الرسوم الادارية
                    const financeRate = (financeSettings?.FinanceRate / 100) ?? 0; //نسبة الفائدة

                    setMinFirstPayPrice(parseInt(firstPay * selectedColor?.NetSalePrice));

                    const firstPayPrice = currentFirstPayPrice ? currentFirstPayPrice : (firstPay * parseFloat(selectedColor?.NetSalePrice))
                    const lastPayPrice = (lastPay * parseFloat(selectedColor?.NetSalePrice));

                    const financeAmount = (parseFloat(selectedColor?.NetSalePrice) - parseFloat(firstPayPrice));

                    var addAmount = (financeAmount * financeRate) * (selectedFinancePeriod / 12);
                    var managementFeesAmount = 0;
                    if (addAmount && managementFees && managementFees > 0) {
                        // الرسوم الادارية
                        managementFeesAmount = (financeAmount * managementFees);
                        managementFeesAmount = managementFeesAmount + (managementFeesAmount * 0.15);
                        addAmount = addAmount + managementFeesAmount;

                    }

                    const mounthBrach = ((financeAmount + addAmount) - lastPayPrice) / (selectedFinancePeriod - 1)

                    const finalAmount = ((mounthBrach * (selectedFinancePeriod - 1)) + lastPayPrice + firstPayPrice)


                    financeData.period = selectedFinancePeriod;
                    financeData.mangFees = managementFeesAmount;
                    financeData.totalPrice = finalAmount;
                    financeData.first = firstPayPrice;
                    financeData.last = lastPayPrice;
                    financeData.price = mounthBrach;

                    setFinance(financeData);
                }
            }
        } catch (error) {

            ////console.log("error:", error.messages);

        }

    }
    useEffect(() => {
        reCountFinanceSettings(financeSettings, selectedFinancePeriod);

    }, [selectedColor, financeSettings, selectedFinancePeriod]);

    const [isToyota, setisToyota] = useState(false);
    const [isLexus, setisLexus] = useState(false);
    useEffect(() => {

        if (vehicle) {
            setisToyota(vehicle?.brand && (vehicle?.brand.toLowerCase() === "تويوتا" || vehicle?.brand.toLowerCase() === "toyota"));
            setisLexus(vehicle?.brand && (vehicle?.brand.toLowerCase() === "لكزس" || vehicle?.brand.toLowerCase() === "lexus"));
        }

        if (vehicle?.brandID && vehicle?.category && vehicle?.carGroupID)
            GetSuggestedVehicles(vehicle?.brandID, vehicle?.category, vehicle?.carGroupID, vehicle?.slug);

    }, [vehicle]);

    useEffect(() => {
        if (vehicle?.colorID && vehicle?.colorID?.split("/")[0] && vehicle?.colorID?.split("/")[1]) {
            setActiveOutColor(vehicle?.colorID?.split("/")[0]);
            setActiveINColor(vehicle?.colorID?.split("/")[1]);

        }

    }, [vehicle]);

    useEffect(() => {

        if (vehicle && cities && cities?.length > 0)
            CarShippingInfo(cities[0]?.value);

    }, [vehicle, cities]);

    const deliveryTimeAccounting = useCallback(() => {

        try {
            if (Shipping?.CountTime && Shipping?.CountTime !== 0) {
                let dateNow = new Date();

                dateNow.setDate(dateNow.getDate() + Shipping?.CountTime);

                var formatDate = { year: 'numeric', month: '2-digit', day: '2-digit', weekday: "long", hour: '2-digit', hour12: true, minute: '2-digit' };
                if (lang.language === "en") {
                    return dateNow.toLocaleDateString('en-US', formatDate);

                } else {
                    return dateNow.toLocaleDateString('ar-US', formatDate);
                }

            } else {
                return Shipping?.UnitTime;

            }
        } catch (error) {
            //console.log(error);
            return Shipping?.UnitTime;
        }
    }, [Shipping]);


    const handleFetchModelAvaliableColors = async (productTypeID, currentColorID, year, thisSelectedColorID = selectedColor?.ColorID) => {
        let _colors = [];


        try {

            if (cashColors[productTypeID]) {
                _colors = cashColors[productTypeID];
                setCarColors(_colors);
                let _default = null;

                if (selectedColor?.ColorID || currentColorID) {

                    _default = _colors.find(item => item.ColorID === thisSelectedColorID) ?? _colors.find(item => item.ColorID === currentColorID);
                } else {

                    _default = _colors.find(item => item.buyOnline == true);
                    if (!_default)
                        _default = _colors.find(item => item.ColorID === thisSelectedColorID) ?? _colors.find(item => item.ColorID === currentColorID);
                }


                //console.log("_default Color:", _default);
                setSelectedColor(_default);

            } else {

                const response = await axios.post(
                    config.baseURL + 'func',
                    {
                        "Name": "site_fetchColorOfTypeWithOutImages",
                        "Values": {
                            "Type": productTypeID,
                            "model": year
                        }
                    }
                );

                if (response.status == 200) {
                    _colors = response.data?.filter(item => item.buyOnline == 1);

                    var cc = { ...cashColors };
                    cc[productTypeID] = _colors;
                    setCashColors(cc);

                    //console.log("site_fetchColorOfType:", response.data);


                    _colors = _colors;
                    setCarColors(_colors);
                    let _default = null;

                    _default = _colors.find(item => item.ColorID === thisSelectedColorID) ?? _colors.find(item => item.ColorID === currentColorID);
                    if (_default) {
                        // filterPriceoffColor(_default);
                    }

                    //console.log("_default Color:", _default);
                    setSelectedColor(_default);
                }

            }

        } catch (error) {
            //console.log(error);
        }



        return _colors;
    };


    const [cashCarImages, setCashCarImages] = useState({});
    const [innerCarImagesJson, setInnerCarImagesJson] = useState(null);
    const [outerCarImagesJson, setOuterCarImagesJson] = useState(null);
    const fetchCarDesign = async (activeColor, model, partnum = activePart) => {
        FetchCarSNImages(activeModel?.sn);
        if (isLoadingImages == false && OUTcolorsCode && OUTcolorsCode?.length > 0) {

            setIsLoadingImages(true);
            setFilterCarImages([]);
            var codeColor = partnum == 0 ? activeColor?.split("/")[0] : activeColor?.split("/")[1];
            var codeColorIN = activeColor?.split("/")[1];
            var codeColorOUT = activeColor?.split("/")[0];

            setAllImagesCount(0);

            var imagesPart = [];
            const prefix = 'data:image/png;base64,';

            if (model) {

                if (cashCarImages[model + "-part-" + partnum]) {
                    setIsLoadingImages(false);
                    var AllCarImages = cashCarImages[model + "-part-" + partnum];
                    var AllCarImagesIN = cashCarImages[model + "-part-" + 1];
                    var AllCarImagesOUT = cashCarImages[model + "-part-" + 0];

                    setOuterCarImagesJson(AllCarImagesOUT);
                    setInnerCarImagesJson(AllCarImagesIN);
                    if (AllCarImagesIN) {

                        setInnerCarImages(AllCarImagesIN[codeColorIN]);
                    } else {
                        setInnerCarImages([]);

                    }

                    if (AllCarImagesOUT) {

                        setOuterCarImages(AllCarImagesOUT[codeColorOUT]);
                    } else {
                        setOuterCarImages([]);

                    }

                } else {
                    axios.post(
                        config.baseURL + 'func',
                        {
                            //SiteFetchCarColorImages
                            "Name": "SiteFetchCarColorCodeImages",
                            "Values": {
                                "Id": model,
                                "year": vehicle?.year,
                                // "ColorID": String(codeColor),
                                "ColorID": null,
                                "FieldNameList": null
                            }
                        }
                    ).then(response => {
                        setIsLoadingImages(false);
                        var maincolorimageIndex = 0;
                        if (Array.isArray(response.data)) {
                            const responseData = response.data;
                            // //console.log("SiteFetchCarColorCodeImages responseData>>>>>>>>>>>>>>>>>>>>>>>>>:", responseData);
                            const groupResult = mapValues(groupBy(response.data, 'ColorCode'), fList => fList.map(items => omit(items, 'ColorCode')));
                            //console.log("OUTcolorsCode ..............:", OUTcolorsCode);
                            var allcolor = [...OUTcolorsCode, ...INcolorsCode];


                            allcolor?.map((key, index) => {
                                var colorCodeItems = groupResult[key];
                                if (colorCodeItems?.length > 0) {

                                    if (OUTcolorsCode?.includes(key)) {



                                        var cimage = cashCarImages[model + "-part-" + 0];

                                        if (!cimage) {

                                            cimage = {}
                                        }

                                        cimage[key] = colorCodeItems;
                                        cashCarImages[model + "-part-" + 0] = cimage;
                                        // cashCarImages[model + "-" + key + "-part-" + 0] = colorCodeItems;
                                        if (codeColorOUT == key) {
                                            imagesPart = colorCodeItems;
                                            setOuterCarImages(colorCodeItems);

                                        }

                                    } else if (INcolorsCode?.includes(key)) {

                                        var cimage = cashCarImages[model + "-part-" + 1];

                                        if (!cimage) {

                                            cimage = {}
                                        }

                                        cimage[key] = colorCodeItems;
                                        cashCarImages[model + "-part-" + 1] = cimage;
                                        // cashCarImages[model + "-" + key + "-part-" + 1] = colorCodeItems;
                                        if (codeColorIN == key) {

                                            imagesPart = colorCodeItems;
                                            setInnerCarImages(colorCodeItems);
                                        }


                                    } else {

                                        var cimage = cashCarImages[model + "-part-" + 2];

                                        if (!cimage) {

                                            cimage = {}
                                        }

                                        cimage[key] = colorCodeItems;
                                        cashCarImages[model + "-part-" + 2] = cimage;

                                        // cashCarImages[model + "-" + key + "-part-" + 2] = colorCodeItems;
                                        if (codeColor == key) {

                                            imagesPart = colorCodeItems;
                                            setWheels(colorCodeItems);
                                        }


                                    }
                                }
                            })





                            //console.log("cashCarImages>>>>>>>>>>>>>>>>>>>>>>>>>:", cashCarImages);


                            const imagesCount = imagesPart?.length;
                            setAllImagesCount(imagesCount);

                            var AllCarImagesIN = cashCarImages[model + "-part-" + 1];
                            var AllCarImagesOUT = cashCarImages[model + "-part-" + 0];
                            setOuterCarImagesJson(AllCarImagesOUT);
                            setInnerCarImagesJson(AllCarImagesIN);
                            //console.log("fetchCarDesign AllCarImagesIN:", AllCarImagesIN);
                            //console.log("fetchCarDesign AllCarImagesOUT:", AllCarImagesOUT);

                            if (AllCarImagesIN) {

                                setInnerCarImages(AllCarImagesIN[codeColorIN]);
                            } else {
                                setInnerCarImages([]);

                            }

                            if (AllCarImagesOUT) {

                                setOuterCarImages(AllCarImagesOUT[codeColorOUT]);
                            } else {
                                setOuterCarImages([]);

                            }

                            // setAllCarImages([...outerImages, ...innerImages, ...wheelsImages])
                            setIsLoadingImages(false);




                        }
                    }).catch(error => {
                        //console.log(error);
                        setIsLoadingImages(false);
                        cashCarImages[model + "-part-" + partnum] = {};
                    });

                }

            } else {

                setIsLoadingImages(false);
            }
        }
    };

    const [gallery, setGallery] = useState([]);
    const [cachSNImages, setCachSNImages] = useState({});
    async function FetchCarSNImages(SN) {
        try {


            if (cachSNImages && cachSNImages[SN]) {

                const filterG = cachSNImages[SN];
                setGallery(filterG);

                // try {
                //     if (popGalleryRef && gallery && filterG && filterG?.length > 0) {
                //         popGalleryRef.current.toggleFullScreen();
                //     }
                // } catch (error) {

                // }


            } else {
                const response = await axios.post(
                    config.baseURL + 'func',
                    {
                        "Name": "siteFetchCarSNImages",
                        "Values": {
                            "SN": SN
                        }
                    }
                );

                if (response.status == 200) {


                    const filterG = [];

                    response.data?.map((item, index) => {
                        item["original"] = config.mainURL + item.Image;
                        item["thumbnail"] = config.mainURL + item.Image;
                        item["originalHeight"] = "50";
                        item["originalWidth"] = "50";
                        item["sizes"] = "50";
                        item["fullscreen"] = config.mainURL + item.Image;
                        filterG.push(item);


                    });

                    setGallery(filterG);
                    // try {
                    //     if (popGalleryRef && gallery && filterG && filterG?.length > 0) {
                    //         popGalleryRef.current.toggleFullScreen();
                    //     }
                    // } catch (error) {

                    // }


                    var cuCachSNImages = { ...cachSNImages };
                    cuCachSNImages[SN] = filterG;

                    setCachSNImages(cuCachSNImages);


                } else {

                    var cuCachSNImages = { ...cachSNImages };
                    cuCachSNImages[SN] = [];
                    setCachSNImages(cuCachSNImages);
                }

            }

        } catch (error) {
            console.log(error);

            var cuCachSNImages = { ...cachSNImages };
            cuCachSNImages[SN] = [];
            setCachSNImages(cuCachSNImages);

        }
    }
    const afterChangeDataType = async (firstItem, thisSelectedColorID = selectedColor?.ColorID, partnum = activePart) => {
        setActiveOutColor("");
        setActiveINColor("");
        setSelectedColor(null);
        resetColorsValues();
        resetValues();

        const colors = await handleFetchModelAvaliableColors(firstItem?.type ?? firstItem?.Type, firstItem?.ColorID ?? firstItem?.colorID, firstItem?.year);


        var currentSelectedColor = colors?.filter(item => item.ColorID == thisSelectedColorID)?.length > 0 ? thisSelectedColorID : firstItem?.colorID;
        try {
            if (currentSelectedColor && currentSelectedColor?.split("/")[0] && currentSelectedColor?.split("/")[1]) {
                setActiveOutColor(currentSelectedColor?.split("/")[0]);
                setActiveINColor(currentSelectedColor?.split("/")[1]);

            }
        } catch (error) {
            //console.log(error);
        }

        if (firstItem) {
            setCarColors(colors);
            fetchCarDesign(currentSelectedColor, firstItem?.productID);
        }

    }


    const callback = useCallback(async () => {

        setIsLoading(true)

        var curvehicle;
        try {
            await axios.request({
                baseURL: config.controllURL,
                url: config.controllURL + lang + "/api/VehicleOverview/" + null + "/" + slug + "/carDetails",
                // url: lang + "/api/VehicleOverview/" + slugSplitter(brand) + "/" + slug + "/overview",
                method: "get",
            }).then(async function (response) {

                if (response.data.vehicle) {
                    curvehicle = response.data.vehicle;
                    setVehicle(response.data.vehicle);

                }
                setFeatures(response.data.features);
                const firstItem = response.data.vehicle;


                setIsLoading(false)
            }).catch(function (error) {

                setIsLoading(false)
            });


        } catch (error) {

        }

        try {
            const Body = {
                "name": "Fetch_InvProductsEquipments",
                "values": {
                    "GUID": slug
                },
            }
            await axios.request({
                url: config.baseURL + 'func',
                data: Body, method: "POST", responseType: "json"
            })
                .then(function (response) {
                    const groupResult = mapValues(groupBy(response.data, 'ID'), fList => fList.map(items => omit(items, 'ID')));

                    var dataFinal = [];
                    Object.keys(groupResult)?.map((key, index) => {

                        var data = [];
                        var note = "";
                        groupResult[key].forEach((a, i) => {
                            if (lang == "ar") {
                                note = note + a.DescriptionAr + "\n" + a.NoteAr + "\n";
                                a.note = note;
                            } else {
                                note = note + a.DescriptionEn + "\n" + a.NoteEn + "\n";
                                a.note = note;
                            }

                            if (i == (groupResult[key].length - 1))
                                data = [...data, a];

                        })
                        dataFinal = [...dataFinal, ...data];

                    })
                    setExtraDetails(dataFinal);


                })
                .catch(function (error) {


                });
        } catch (error) {


        }


        var curentCars;

        try {
            await axios.request({
                baseURL: '/',
                url: config.controllURL + lang + "/api/VehicleOverview/" + slugSplitter(curvehicle?.group) + "/" + curvehicle?.year + "/models",
                method: "get",
            }).then(async function (response) {
                curentCars = response.data.models?.filter(item => item.buyOnline == 1);

                var _default = curvehicle;
                _default = curentCars.find(item => item.sn == sn) ?? curvehicle;

                if (_default) {

                    setActiveModel(_default);
                    afterChangeDataType(_default, true);
                }

                setModels(curentCars);


            })
                .catch(function (error) {
                    afterChangeDataType(curvehicle, true);

                });


        } catch (error) {
            afterChangeDataType(curvehicle, true);
        }


        try {
            await axios.request({
                baseURL: '/',
                url: config.controllURL + lang + "/api/VehicleOffer/" + slug + "/offers",
                method: "get",
            }).then(async function (response) {

                if (response.data)
                    setVehicleOffers(response.data);

                setLoadingVehicleOffers(false);
            })
                .catch(function (error) {
                    setLoadingVehicleOffers(false);

                });


        } catch (error) {
        }



    }, []);


    const GetSuggestedVehicles = async (brandID, category, carGroupID, slug) => {

        setLoadingCars(true);
        try {
            await axios.request({
                baseURL: '/',
                url: config.controllURL + lang + "/api/vehicles/OnlineVehicles",
                method: "get",
            }).then(async function (response) {

                try {

                    var cc = response?.data?.vehicles?.filter(c => c.sn != sn && c.carGroupID != carGroupID && c.categor == category);
                    setSuggestedVehicles(cc);

                } catch (error) {
                    setLoadingCars(false);
                }
                setLoadingCars(false);
            })
                .catch(function (error) {
                    //console.log(error);
                    setLoadingCars(false);
                });


        } catch (error) {
            //console.log(error);
            setLoadingCars(false);
        }

    }
    async function handelfilterBranches(Citey) {

        setSelectBranch(Citey);
        var bc = Branches.filter((branche) => {
            return branche.city === Citey;
        });
        //console.log('bc:', bc);
        setFilterBranches(bc);

    }

    const [dataShippingInfo, setDataShippingInfo] = useState({ "": [] });
    async function CarShippingInfo(Citey) {

        setSelectcity(Citey)

        if (!dataShippingInfo[Citey]) {

            var currentvalue = { ...Shipping };
            currentvalue.Cost = 0
            // currentvalue.UnitTime = trans("new:not_avilable");
            currentvalue.UnitTime = null;
            currentvalue.CountTime = null
            currentvalue.DateTimeDelevry = ""


            let newData = { ...dataShippingInfo };
            newData[Citey] = currentvalue;
            setDataShippingInfo(newData);

            const Body = {
                "name": "Site_CarShippingInfo",
                "values": {
                    "City": `${Citey}`,
                    "categore": `${vehicle?.carCategories}`
                }
            }
            await axios.request({
                url: config.baseURL + 'func',
                data: Body, method: "POST", responseType: "json"
            })
                .then(function (response) {
                    // //console.log(response.data.ApiObjectData);
                    //console.log('CarShippingInfo response:', response);
                    var currentvalue = { ...Shipping };
                    currentvalue.Cost = response.data[0].CostShipping
                    currentvalue.UnitTime = response.data[0].Type
                    currentvalue.CountTime = response.data[0].TimeDelivary
                    currentvalue.DateTimeDelevry = ""
                    setShipping(currentvalue);
                    //console.log(currentvalue);

                    let newData2 = { ...dataShippingInfo };
                    newData2[Citey] = currentvalue;
                    setDataShippingInfo(newData2);
                })
                .catch(function (error) {
                    //console.log(error);
                    setShipping(currentvalue);
                    //console.log(currentvalue);
                });

        } else {

            //console.log('CarShippingInfo local:', dataShippingInfo[Citey]);
            setShipping(dataShippingInfo[Citey]);

        }
    }

    const handleOnActive = (menuValue) => {
        setActiveFilterMenu(menuValue);
    };

    // to fetch all SAU countries
    const handleFetchCountries = async () => {
        let ShippingCities = [];
        try {
            const response = await axios.post(
                config.baseURL + 'func',
                {
                    "Name": "site_ShippingCities"
                }
            );
            //console.log('site_ShippingCities response', response);
            if (response.status == 200) {

                const desc = lang == "ar" ? "DescriptionAr" : "DescriptionEn";
                ShippingCities = response.data.map(item => {
                    return { value: item.ID, text: item[desc] }
                });
            }
        } catch (error) {
            //console.log('error in fetching the countries of SAU', error);
        }
        setCities(ShippingCities);

    };
    const [brancheCities, setBrancheCities] = useState([]);
    const handleFetchBrancheCities = async (Branches) => {
        let Cities = [];

        let valuesCity = [];
        try {


            let valuesC = [];
            try {
                console.log('setBrancheCities', Branches);
                Branches.map(item => {
                    if (!valuesC.includes(item.city)) {
                        valuesC = [...valuesC, item.city]
                        Cities = [...Cities, { value: item.city, text: item[lang == "ar" ? `CityAr` : `CityEn`] }]
                    }


                });

            } catch (error) {
                console.log('error in fetching the countries of SAU', error);
            }

            console.log('setBrancheCities', Cities);

            setBrancheCities(Cities);
            setFilterBranches(Branches);


        } catch (error) {
            //console.log('error in fetching the countries of SAU', error);
        }
    };


    const [Branches, setBranches] = useState([]);
    const [filterBranches, setFilterBranches] = useState([]);

    async function GetBranch() {

        const GetUrlfunc = config.baseURL + 'func';
        const Body = {
            "name": "site_FetchBranches"
        };
        await axios.request({
            url: GetUrlfunc,
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {

                const result = response.data;
                //console.log('site_FetchBranches response', result);
                setBranches(result);
                handleFetchBrancheCities(result);

            })
            .catch(function (error) {
                //console.log(error);
            });
    }

    const showPanelInfo = (currentIndexPanel) => {

        if (currentIndexPanel != indexPanel) {

            setIndexPanel(currentIndexPanel);
            setShowPanel(true);
        } else {

            setIndexPanel(currentIndexPanel);
            setShowPanel(!showPanel);
        }



    }


    const [INcolorsCode, setINCarColorsCode] = useState([]);
    const [OUTcolorsCode, setOUTCarColorsCode] = useState([]);

    useEffect(() => {

        var currentInColor = [];
        var IN_colors = [];
        var currentOutColor = [];
        var Out_colors = [];

        var jsoncolors = {};
        try {

            colors?.forEach((item, i) => {

                jsoncolors[item.ColorID] = item;

                if (!currentOutColor.includes(item.ColorID?.split("/")[0])) {

                    Out_colors = [...Out_colors, { ColorDescription: item[lang == "ar" ? `ColorDescriptionAr` : `ColorDescriptionEn`]?.split("/")[0], ColorID: item.ColorID?.split("/")[0], ColorImage: item?.siteOutColorImage }]

                    currentOutColor = [...currentOutColor, item.ColorID?.split("/")[0]]
                }


                if (!currentInColor.includes(item.ColorID?.split("/")[1])) {

                    IN_colors = [...IN_colors, { ColorDescription: item[lang == "ar" ? `ColorDescriptionAr` : `ColorDescriptionEn`]?.split("/")[1], ColorID: item.ColorID?.split("/")[1], ColorImage: item?.siteInColorImage }]

                    currentInColor = [...currentInColor, item.ColorID?.split("/")[1]]
                }


            })

            //console.log("IN setOUTCarColorsCode :", currentOutColor);
            setOUTCarColorsCode(currentOutColor);
            setINCarColorsCode(currentInColor);
            //---------------

            setJsonColors(jsoncolors);
            setINCarColors(IN_colors);
            setOUTCarColors(Out_colors);

        } catch (error) {
            //console.log(error);
        }

    }, [colors]);

    useEffect(() => {

        //console.log("useEffect setOUTCarColorsCode >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>:", OUTcolorsCode);

        if (activeOutColor && activeINColor)
            setSelectedColor(colors.filter(a => a.ColorID == (activeOutColor + "/" + activeINColor))[0]);



        if (activeOutColor && activeINColor && activeModel?.productID) {

            fetchCarDesign((activeOutColor + "/" + activeINColor), activeModel?.productID);
        }
    }, [activeINColor, activeOutColor, OUTcolorsCode]);

    useEffect(() => {

        // setActivePart(activePart);
        getActivePartSection(activePart);

    }, [outerCarImages, innerCarImages, wheels, activePart]);

    useEffect(() => {


        if (isLoadingImages == false)
            getActivePartSection(activePart);

    }, [isLoadingImages]);


    // to get the active part of section
    const [filterCarImages, setFilterCarImages] = useState([]);
    const getActivePartSection = async (activePart, thisSelectedColorID = selectedColor?.ColorID, Model = activeModel) => {


        //console.log("IN getActivePartSection :", activePart);
        //console.log("IN outerCarImages :", outerCarImages);
        setFilterCarImages([]);
        setActivePart(activePart);
        let result = [];

        if (activePart === 0) {

            if (outerCarImages?.length > 0) {
                result = outerCarImages;

            }

        } else if (activePart === 1) {


            if (innerCarImages?.length > 0) {
                result = innerCarImages;

            }

        } else if (activePart === 2) {

            if (wheels?.length > 0) {
                result = wheels;

            }


        }
        var allImages = [];

        try {
            allImages = outerCarImages.concat(innerCarImages ?? [], wheels ?? []);
        } catch (error) {
            allImages = outerCarImages;
        }
        console.log(allImages);
        setFilterCarImages(allImages);
        return result;
    };

    // to reset all the state objects
    const resetValues = () => {
        setOuterCarImagesJson(null);
        setInnerCarImagesJson(null);
        setAllImagesCount(0);
        setInnerCarImages([]);
        setOuterCarImages([]);
        setWheels([]);
    };

    const resetColorsValues = () => {
        setOUTCarColors([]);
        setINCarColors([]);

    };
    return (
        <section className="carOverview-page   ">
            <div className="carOverview-page-content grid grid-cols-12 gap-1
                          justify-items-auto mx-30 ">
                <div className="carOverview-page-main  col-span-12 md:col-span-8 sm:col-span-8 lg:col-span-8 xl:col-span-8">
                    {
                        isLoadingImages ? (
                            <>

                                <div className="carOverview-page-header   ">
                                    {/* <BackButton flow={false} className='m-8 nice-absolute' /> */}
                                    <Skeleton animation="wave" count={1} width={"100%"} height={"40rem"} containerClassName={""} className={""} inline={false} />
                                </div>
                            </>

                        ) : (
                            <div className="carOverview-page-header  ">
                                <div className="carOverview-page-header-top">
                                    {
                                        vehicle &&
                                        <div className="flex flex-row">
                                            {
                                                vehicle?.newCar &&
                                                <div className="carOverview-page-header-top-tags bg-info">
                                                    {trans("info:new")}
                                                </div>

                                            }
                                        </div>

                                    }
                                </div>
                                <div className="carOverview-page-header-slider">
                                    <Swiper
                                        className={""}
                                        spaceBetween={0}
                                        slidesPerView={1}
                                        resizeObserver={true}
                                        grabCursor={true}
                                        autoplay={true}
                                        pagination={{
                                            clickable: true
                                        }}
                                        loop={false}
                                        observer={true}
                                    >
                                        {
                                            (filterCarImages && filterCarImages.length > 0) ?
                                                filterCarImages.map((image, index) => {
                                                    return (
                                                        <SwiperSlide key={index}  >

                                                            <img className="carOverview-page-header-img"
                                                                // src={"data:image;base64," + image.DATA}
                                                                src={config.paymentURL + image.Image_URL}
                                                            />

                                                        </SwiperSlide>
                                                    );

                                                })
                                                :
                                                null
                                            // <SwiperSlide key={0}  >

                                            //     <img className="carOverview-page-header-img"
                                            //         src={"/assets/images/default.png"} />

                                            // </SwiperSlide>
                                        }

                                    </Swiper>
                                </div>



                                {
                                    vehicle &&
                                    <div className="carOverview-page-header-actions">

                                        <div className="flex flex-row justify-between w-100 ">

                                            {
                                                activeModel?.sn && cachSNImages && cachSNImages[activeModel?.sn] && cachSNImages[activeModel?.sn].length > 0 ?
                                                    <div className="car-options-links-showImg mt-1 "
                                                        title={"show car images"}

                                                        onClick={(event) => {
                                                            event.stopPropagation();

                                                            FetchCarSNImages(activeModel?.sn);
                                                            try {
                                                                if (popGalleryRef && gallery && gallery?.length > 0) {
                                                                    popGalleryRef.current.toggleFullScreen();
                                                                }
                                                            } catch (error) {

                                                            }
                                                        }}
                                                    >
                                                        {/* <div className="car-options-links-showImg-cover"></div> */}
                                                        <div className={"car-options-links-showImg-btn "}>
                                                            <img className="z-3" src="/assets/icons/camera-svgrepo-com.svg" onError={e => e.target.style.display = 'none'} />
                                                        </div>

                                                        <span className="text-16 text-bold ">{trans("info:Preview")}</span>

                                                    </div>
                                                    :
                                                    <div></div>
                                            }
                                            <div className="carOverview-btn-like"
                                                onClick={() => { changeFavoriteItemState(vehicle?.productID) }}
                                                btn-like
                                            >
                                                <img className="z-3 pt-1" src={`/assets/icons/${Boolean(isFavoriteItem(vehicle?.productID) ?? false) ? 'heart.png' : 'cleanHeart.png'}`} alt="" />
                                            </div>

                                        </div>
                                    </div>
                                }
                            </div>



                        )
                    }
                    <div className="carOverview-page-body">

                        {
                            <div className="hide show-xs ">
                                <div className=" nice-container flex flex-row justify-between">
                                    {
                                        isLoading ?

                                            <Skeleton count={3} borderRadius={"1rem"} width={"70%"} height={"5rem"}
                                                containerClassName={"my-5"} className={"flex my-5"} inline={false} />

                                            : (
                                                vehicle ?
                                                    <>

                                                        <div className=" carOverview-page-body-number flex flex-row nice-gap-1rem my-10">
                                                            <span className="text-24 text-gray"> {trans("new:item_no")}</span>
                                                            <span className="text-24 text-gray">{vehicle?.productNo ? vehicle?.productNo : "########"}</span>
                                                        </div>
                                                        <div className="carOverview-page-body-name">
                                                            <div className="carOverview-page-body-type">
                                                                <div className="carOverview-page-body-type-content nice-gap-1rem">
                                                                    <h2 className="text-24 text-bold">{vehicle?.brand && vehicle?.brand}</h2>
                                                                    {/* <img src={vehicle?.brandIconPath && vehicle?.brandIconPath} /> */}
                                                                    <img className="" src={`/assets/icons/brands/${(isToyota ? "toyota" : "") + (isLexus ? "lexus" : "")}.png`} />
                                                                </div>
                                                            </div>

                                                            <h3 className="text-24 text-gray">{vehicle?.name && vehicle?.name}</h3>
                                                        </div>

                                                    </>
                                                    :
                                                    <div className="container search-line my-40"><h4 className="text-gray text-24 py-2">{trans("new:no_data")}</h4></div>
                                            )
                                    }  </div>

                                <div className="nice-border-top-section"></div>
                            </div>


                        }

                        {

                            isLoading ? (
                                <div className="available-section nice-container nice-border-top-section ">

                                    <Swiper
                                        className={"w-11/12 my-8 nice-apply-theme"}
                                        spaceBetween={10}
                                        slidesPerView={3}
                                        resizeObserver={"true"}
                                        grabCursor={true}
                                        autoplay={false}
                                        loop={false}
                                        observer={true}
                                        touchRatio={1}
                                        initialSlide={2}
                                        centeredSlides={true}
                                    >

                                        {[1, 2, 3, 4]?.map((item, index) => {

                                            return (
                                                <SwiperSlide key={index}>
                                                    <Skeleton count={1} borderRadius={"2rem"}
                                                        width={"100%"} height={"13rem"} containerClassName={""}
                                                        className={""} inline={true} />
                                                </SwiperSlide>

                                            );

                                        })

                                        }

                                    </Swiper>


                                </div>
                            ) : ((models && models.length > 0) &&

                                <div className="available-section nice-container">

                                    <h2 className="text-16 text-bold text-gray my-1">{trans("new:categories")}</h2>

                                    <div className="available-body">


                                        <Swiper
                                            className={"nice-gap-1rem"}
                                            spaceBetween={5}
                                            slidesPerView={2}
                                            breakpoints={{
                                                576: {
                                                    slidesPerView: 3,

                                                },
                                            }}
                                            resizeObserver={true}
                                            grabCursor={true}
                                            initialSlide={models.findIndex((a) => a.type == activeModel)}
                                            autoplay={false}
                                            loop={false}

                                            observer={true}
                                        >

                                            {
                                                models?.map((model, index) => {
                                                    return (

                                                        <SwiperSlide key={index} className={"box-sizing-flex-1"} >
                                                            <div className={"vailable-part bg-theme-up " + (activeModel?.type == model.type ? "border-primary" : "")}

                                                                onClick={() => {

                                                                    if (activeModel?.type != model.type) {
                                                                        afterChangeDataType(model);
                                                                        setActiveModel(model);
                                                                    }

                                                                }}

                                                            >

                                                                {/* <span className="text-20 text-gray my-5"> */}
                                                                {/* {(model?.TotalQtyAVA > 0 && model.showPric) ? trans("new:available_to_buy_online") : trans("new:not_available")}   */}
                                                                {/* </span> */}
                                                                <div className="vailable-type">
                                                                    <span className="text-16">{lang == "ar" ? (model.shortDescription ?? model.modelDescription)?.replace(model?.brand, "")?.replace(model?.group, "") : (model.shortDescriptionEn ?? model.modelDescription)?.replace(model?.brand, "")?.replace(model?.group, "")}</span>

                                                                </div>
                                                            </div>
                                                        </SwiperSlide>

                                                    );

                                                })
                                            }
                                        </Swiper>

                                    </div>


                                </div>
                            )
                        }



                        {


                            isLoading ? (


                                <>
                                    <Skeleton count={1} borderRadius={"1rem"} width={"100%"} height={"40rem"}
                                        containerClassName={"my-5"} className={"flex my-5"} inline={false} />

                                    <Skeleton count={1} borderRadius={"1rem"} width={"100%"} height={"5rem"}
                                        containerClassName={"my-5"} className={"flex my-5"} inline={false} />


                                    <Skeleton count={1} borderRadius={"1rem"} width={"100%"} height={"5rem"}
                                        containerClassName={"my-5"} className={"flex my-5"} inline={false} />

                                    <Skeleton count={1} borderRadius={"1rem"} width={"100%"} height={"40rem"}
                                        containerClassName={"my-5"} className={"flex my-5"} inline={false} />



                                </>
                            ) : (

                                <>


                                    <div className="grid grid-cols-12 nice-container">
                                        {

                                            (OUTcolors && OUTcolors.length > 0) &&
                                            <div className="available-section col-span-12 md:col-span-6 ">

                                                <h2 className="text-16 text-bold  text-gray my-1">{trans("new:exterior_color")}</h2>

                                                <div className="available-body">


                                                    <Swiper
                                                        className={"nice-gap-1rem"}
                                                        spaceBetween={5}
                                                        slidesPerView={2.1}
                                                        breakpoints={{
                                                            576: {
                                                                slidesPerView: 1.5,

                                                            },
                                                        }}
                                                        resizeObserver={true}
                                                        grabCursor={true}
                                                        // initialSlide={OUTcolors.findIndex((a) => a.ColorID == activeOutColor)}
                                                        autoplay={false}
                                                        loop={false}
                                                        observer={true}
                                                    >

                                                        {

                                                            OUTcolors.map((color, index) => {
                                                                return (
                                                                    <SwiperSlide key={index} className="" >
                                                                        <div key={index} className={"vailable-part bg-theme-up " + (activeOutColor == color.ColorID ? "border-primary" : "")}
                                                                            onClick={() => {
                                                                                if (activeOutColor != color.ColorID)
                                                                                    setSelectedColor(null);
                                                                                setActiveOutColor(color.ColorID);
                                                                                // setSelectedColor(color);
                                                                            }}


                                                                        >
                                                                            {/* <span className="text-20 text-gray my-5"> */}
                                                                            {/* {jsonColors[activeOutColor + "/" + activeINColor]?.showPric ? trans("new:available_to_buy_online") : trans("new:not_available")} */}
                                                                            {/* </span> */}
                                                                            <div className="vailable-type">

                                                                                <div className="vailable-type-color-part">
                                                                                    <span className="text-16 text-line-clamp text-line-clamp-2 ">{color?.ColorDescription}</span>

                                                                                    {

                                                                                        color?.ColorImage &&
                                                                                        <div className="vailable-type-color">
                                                                                            <img src={color?.ColorImage ? config.paymentURL + color?.ColorImage : null} ></img>
                                                                                        </div>
                                                                                    }

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </SwiperSlide>

                                                                );

                                                            })
                                                        }
                                                    </Swiper>

                                                </div>


                                            </div>

                                        }

                                        {
                                            (INcolors && INcolors.length > 0) &&
                                            <div className="available-section col-span-12 md:col-span-6 ">

                                                <h2 className="text-16 text-bold text-gray my-1">{trans("new:interior_color")}</h2>

                                                <div className="available-body">


                                                    <Swiper
                                                        className={"nice-gap-1rem"}
                                                        spaceBetween={5}
                                                        slidesPerView={2.1}
                                                        breakpoints={{
                                                            576: {
                                                                slidesPerView: 1.5,

                                                            },
                                                        }}
                                                        resizeObserver={true}
                                                        grabCursor={true}
                                                        // initialSlide={OUTcolors.findIndex((a) => a.ColorID == activeOutColor)}
                                                        autoplay={false}
                                                        loop={false}
                                                        observer={true}
                                                    >

                                                        {

                                                            INcolors.map((color, index) => {
                                                                return (
                                                                    <SwiperSlide key={index}  >
                                                                        <div key={index} className={"vailable-part bg-theme-up " + (activeINColor == color.ColorID ? "border-primary" : "")}
                                                                            onClick={() => {
                                                                                if (activeINColor != color.ColorID)
                                                                                    setSelectedColor(null);
                                                                                setActiveINColor(color.ColorID);
                                                                                // setSelectedColor(color);
                                                                            }}

                                                                        >
                                                                            {/* <span className="text-20 text-gray my-5"> */}
                                                                            {/* {jsonColors[activeOutColor + "/" + activeINColor]?.showPric ? trans("new:available_to_buy_online") : trans("new:not_available")} */}
                                                                            {/* </span> */}
                                                                            <div className="vailable-type">

                                                                                <div className="vailable-type-color-part">
                                                                                    <span className="text-16 text-line-clamp text-line-clamp-2 ">{color?.ColorDescription}</span>

                                                                                    {

                                                                                        color?.ColorImage &&
                                                                                        <div className="vailable-type-color">
                                                                                            <img src={color?.ColorImage ? config.paymentURL + color?.ColorImage : null} ></img>
                                                                                        </div>
                                                                                    }


                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </SwiperSlide>

                                                                );

                                                            })
                                                        }
                                                    </Swiper>

                                                </div>


                                            </div>

                                        }

                                    </div>
                                    <div className="specs-section nice-border-top-section">
                                        <h3 className="text-20  text-super-gray font-bold nice-container">{trans("new:specifications")}</h3>
                                        <div className="specs-wrap mt-5 mb-5 nice-container">

                                            {
                                                vehicleFeatures.map((feature, index) => {
                                                    return (
                                                        vehicle && vehicle[feature.fieldName] &&
                                                        <div key={index} className="specs-wrap-part bg-theme-up">
                                                            <div className="specs-wrap-part-titel">
                                                                <span className="text-16 text-gray">{feature.title}</span>
                                                                <img src="/assets/icons/calendar.svg" />
                                                            </div>
                                                            <div className="specs-wrap-part-contant text-20 text-bold">{vehicle && vehicle[feature.fieldName]}</div>

                                                        </div>
                                                    );
                                                })
                                            }

                                        </div>





                                        {
                                            extraDetails.map((details, index) => {
                                                return (

                                                    (details["note"] && details["note"].split('\n').length > 0 && details["note"].split('\n')[0] != "null" && trimEnd(details["note"]).toString() != "") ?
                                                        <div key={index} className="panel-section mx-5">
                                                            <div className={`panel-content  ${(indexPanel == index & showPanel) ? "show-panel-info" : ""}`}>
                                                                <div className="panel-content-top"

                                                                    onClick={(e) => showPanelInfo(index)}

                                                                >
                                                                    <h1 className="text-20 text-super-gray  font-bold">{lang == "ar" ? details?.sectionAr : details?.sectionEn}</h1>
                                                                    <img src="/assets/icons/arrow.svg" />
                                                                </div>

                                                                <div className="panel-content-body">
                                                                    <ul>
                                                                        {
                                                                            details["note"].split('\n').map((note, index) => {

                                                                                return (

                                                                                    trimEnd(note) ?
                                                                                        <li key={index} className="text-16 text-gray"  >
                                                                                            <span className="text-16 text-gray" >{note}</span>
                                                                                        </li>
                                                                                        : null

                                                                                );
                                                                            })
                                                                        }


                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        null

                                                );
                                            })


                                        }





                                    </div>



                                    {


                                        (selectedColor?.showPric && selectedColor?.buyOnline) &&

                                        <div className="panel-section panel-white bg-theme-up">
                                            <div className={`panel-content   ${(indexPanel == "2000" & showPanel) ? "show-panel-info" : ""}`}>
                                                <div className="panel-content-top nice-container"

                                                    onClick={(e) => showPanelInfo("2000")}

                                                >
                                                    <h1 className="text-20   text-super-gray font-bold">{trans("new:how_buy_online")}</h1>
                                                    <img src="/assets/icons/arrow.svg" />
                                                </div>

                                                <div className="panel-content-body">

                                                    <div className="how-buy-menu container">

                                                        <div className={`how-buy-menu-btn text-16 font-bold  ${(activeFilterMenu === 0) ? 'how-buy-menu-btn-active' : ''} `} onClick={() => handleOnActive(0)}>{trans("new:cash")}</div>

                                                        {

                                                            // (vehicle?.minPrice && financeSettings?.ManagementFees > 0) &&
                                                            <div className={`how-buy-menu-btn text-16 font-bold  ${(activeFilterMenu === 1) ? 'how-buy-menu-btn-active' : ''} `} onClick={() => handleOnActive(1)}>{trans("finance:periods")}</div>

                                                        }
                                                    </div>
                                                    <div className="how-buy-body">

                                                        {
                                                            activeFilterMenu == 0 ?
                                                                <div className="how-buy-body-top">
                                                                    <h1 className="text-24  font-bold">{vehicle?.minPrice && <Price price={parseFloat(vehicle?.minPrice)} />} </h1>
                                                                    <h4 className="text-16  text-gray">{trans("new:Incloding_VAT")}</h4>
                                                                </div>

                                                                :
                                                                <>

                                                                    {
                                                                        // (vehicle?.minPrice && financeSettings?.ManagementFees >= 0) &&

                                                                        <>

                                                                            <div className="how-buy-body-top">

                                                                                <h1 className="text-24  font-bold"> {finance?.price && <Price price={parseFloat(finance?.price.toFixed(2))} />}</h1>
                                                                                <h4 className="text-16  text-gray">{trans("new:price_is_estimated")}</h4>

                                                                            </div>


                                                                            <div className="how-buy-body-footer">
                                                                                <div className="how-buy-body-footer-part">
                                                                                    <h1 className="text-24  text-gray">{trans("finance:installment_duration")}</h1>
                                                                                    <h4 className="text-24  text-gray flex nice-flex-center nice-gap-2rem">

                                                                                        {/* <span className="text-24">{selectedFinancePeriod}</span> */}


                                                                                        <DropDownInput2
                                                                                            // inputName={"delevaryCitie"}
                                                                                            bgNone={true}
                                                                                            fieldTextClass={"text-24 text-bold "}
                                                                                            classDropdownField={" nice-shadow-0  dropdown-field-border-bottom bg-none nice-gap-1rem"}
                                                                                            defaultOption={{ text: 24, value: 24 }}
                                                                                            options={[{ text: 24, value: 24 }, { text: 36, value: 36 }, { text: 48, value: 48 }, { text: 60, value: 60 }]}
                                                                                            onSelect={(v) => setSelectedFinancePeriod(v)}
                                                                                            selectedValue={selectedFinancePeriod}
                                                                                        />



                                                                                        {trans("info:month")}</h4>

                                                                                </div>
                                                                                <div className="how-buy-body-footer-part">
                                                                                    <h1 className="text-24  text-gray"> {trans("new:first_batch")}</h1>
                                                                                    <h4 className="text-24  text-gray">{finance?.first && <Price price={parseFloat(finance?.first.toFixed(2))} classNameCurrency={"text-24 "} />}</h4>
                                                                                </div>
                                                                                <div className="how-buy-body-footer-part">
                                                                                    <h1 className="text-24  text-gray"> {trans("new:last_batch")}</h1>
                                                                                    <h4 className="text-24  text-gray"> {finance?.last && <Price price={parseFloat(finance?.last.toFixed(2))} classNameCurrency={"text-24 "} />}</h4>
                                                                                </div>

                                                                            </div>

                                                                        </>



                                                                    }

                                                                </>

                                                        }

                                                    </div>

                                                </div>
                                            </div>
                                        </div>





                                    }
                                    {
                                        banks?.length > 0 &&
                                        <div className="panel-section panel-white bg-theme-up">
                                            <div className={`panel-content   ${(indexPanel == "3000" & showPanel) ? "show-panel-info" : ""}`}>
                                                <div className="panel-content-top nice-container"

                                                    onClick={(e) => showPanelInfo("3000")}

                                                >
                                                    <h1 className="text-20   text-super-gray font-bold">{trans("finance:finance")}</h1>
                                                    {financeSettings?.Image &&
                                                        <div className="panel-content-top-icon nice-gap-2rem">
                                                            <img className="h-100" src={config.mainURL + financeSettings?.Image} />
                                                        </div>}

                                                    {(financeSettings?.DescriptionAr && !financeSettings?.Image) &&
                                                        <div className="panel-content-top-logo nice-gap-2rem">
                                                            <span className="text-24 text-primary"> {lang == "ar" ? financeSettings.DescriptionAr : financeSettings.DescriptionEn}</span>
                                                        </div>}

                                                    <img src="/assets/icons/arrow.svg" />

                                                </div>

                                                <div className="panel-content-body">
                                                    {
                                                        banks?.map((bank, index) => {

                                                            return (

                                                                <div key={index} className="bank-item text-16 nice-gap-2rem"
                                                                    onClick={() => {

                                                                        var DefaultFinancePeriod =bank.DefaultFinancePeriod ? bank.DefaultFinancePeriod: 60;
                                                                        setFinanceSettings(bank);
                                                                        setSelectedFinancePeriod(DefaultFinancePeriod);
                                                                        reCountFinanceSettings(financeSettings, DefaultFinancePeriod);
                                                                    }}

                                                                >
                                                                    {
                                                                        bank?.Image ?
                                                                            <img src={config.mainURL + bank.Image} />
                                                                            :
                                                                            <img src={null} />
                                                                    }

                                                                    <span className="text-16 text-bold" > {lang == "ar" ? bank.DescriptionAr : bank.DescriptionEn}</span></div>


                                                            );
                                                        })
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    }




                                    {/* <div className="bank-item"><img src="/assets/images/bank.svg" /><span className="text-24 " > بنك الراجحي</span></div>
                                        <div className="bank-item"><img src="/assets/images/bank.svg" /><span className="text-24 " > بنك الراجحي</span></div>
                                        <div className="bank-item"><img src="/assets/images/bank.svg" /><span className="text-24 " > بنك الراجحي</span></div> */}




                                    <div className="carOverview-page-body-delevary nice-border-top-section">
                                        <div className="flex flex-row justify-between p-10">
                                            <article className="flex flex-row nice-gap-2rem items-center">
                                                <img src='/assets/icons/part/shipping.svg' />
                                                <span className="text-20">{trans("new:locations-Send")}</span>
                                            </article>
                                            <article className="flex flex-row nice-gap-2rem items-center">
                                                <img src='/assets/icons/part/safePayment.svg' />
                                                <span className="text-20">{trans("new:safe_pay")}</span>
                                            </article>
                                            <article className="flex flex-row nice-gap-2rem items-center">
                                                <img src='/assets/icons/part/reliability.svg' />
                                                <span className="text-20">{trans("new:trust_product")}</span>
                                            </article>
                                        </div>
                                        <div className="carOverview-page-body-delevary-time">
                                            {
                                                cities && cities?.length > 0 &&
                                                <div className="flex flex-wrap">


                                                    {
                                                        cities && cities?.length > 1 ?
                                                            <>
                                                                <div className="carOverview-page-body-delevary-time-title">
                                                                    <h3 className="text-20">{trans("new:expected_delivery_date")}</h3>
                                                                </div>
                                                                <div className="carOverview-page-body-delevary-time-cities">
                                                                    <DropDownInput
                                                                        inputName={"delevaryCitie"}
                                                                        defaultOption={{ text: trans("new:city"), value: '0' }}
                                                                        options={[...cities]}
                                                                        onSelect={CarShippingInfo}
                                                                        selectedValue={selectcity}
                                                                    />
                                                                </div>
                                                            </>
                                                            :
                                                            cities && cities?.length == 1 &&
                                                            <>
                                                                <div className="carOverview-page-body-delevary-time-title">
                                                                    <h3 className="text-20">{trans("new:delivery")}</h3>
                                                                </div>
                                                                <div className="flex items-center">
                                                                    <h3 className="text-20 text-bold text-primary" >{cities[0].text}</h3>
                                                                </div>
                                                                {
                                                                    deliveryTimeAccounting() &&
                                                                    <div className=" text-20 text-bold carOverview-page-body-delevary-time-value container nice-gap-2rem">
                                                                        <span className="text-20 text-black text-bold">{trans("new:expected_delivery_date")}</span>
                                                                        {deliveryTimeAccounting()}
                                                                    </div>
                                                                }
                                                            </>


                                                    }

                                                </div>

                                            }
                                            {
                                                cities && cities?.length > 1 && deliveryTimeAccounting() &&
                                                <div className=" text-20 text-bold carOverview-page-body-delevary-time-value container nice-gap-2rem">
                                                    <span className="text-20 text-black text-bold">
                                                        {
                                                            cities && cities?.length == 1 ?
                                                                trans("new:expected_delivery_date")
                                                                :
                                                                trans("new:delivery_to_your_site")
                                                        }

                                                    </span>
                                                    {/* {(Shipping?.UnitTime ? Shipping?.UnitTime : "") + " " + (Shipping.CountTime ? Shipping.CountTime : "")} */}
                                                    {deliveryTimeAccounting()}
                                                </div>
                                            }

                                            <div className="text-20 text-bold text-center my-10">
                                                {trans("new:or")}
                                            </div>

                                            {
                                                brancheCities && brancheCities?.length > 0 &&
                                                (
                                                    brancheCities && filterBranches[0] && brancheCities?.length == 1 ?
                                                        <div className="flex flex-row">
                                                            <div className="carOverview-page-body-delevary-time-title">
                                                                <h3 className="text-20">{trans("new:receipt_from")}</h3>
                                                            </div>
                                                            <div className="carOverview-page-body-delevary-time-cities">
                                                                <h3 className="text-20 text-primary text-bold">{filterBranches[0][`${trans("model:description2")}`]}</h3>
                                                            </div>

                                                            <div className="  flex flex-row items-center nice-gap-2rem ">
                                                                <a className="flex flex-row items-center  border-line-b" target='_blank' href={`https://www.google.com/maps/?q=${filterBranches[0].Latitude},${filterBranches[0].Longitude}`}>
                                                                    <span className="text-20  text-bold text-primary">{trans("new:map")}</span>
                                                                    <img src="/assets/icons/location.svg" className="filter-primary nice-width-max-80 nice-height-max-80 object-contain" />
                                                                </a>
                                                            </div>
                                                        </div>

                                                        :
                                                        <>

                                                            <div className="flex flex-row">
                                                                <div className="carOverview-page-body-delevary-time-title">
                                                                    <h3 className="text-20">{trans("new:receipt_from")}</h3>
                                                                </div>
                                                                <div className="carOverview-page-body-delevary-time-cities">
                                                                    <DropDownInput
                                                                        inputName={"brancheCitie"}
                                                                        defaultOption={{ text: trans("new:city"), value: '0' }}
                                                                        options={[...brancheCities]}
                                                                        onSelect={handelfilterBranches}
                                                                        selectedValue={selectBranch}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="my-5 nice-container">
                                                                <Swiper
                                                                    spaceBetween={2}
                                                                    slidesPerView={2}
                                                                    watchSlidesProgress
                                                                    pagination
                                                                    breakpoints={{
                                                                        578: {
                                                                            slidesPerView: 2,
                                                                        },
                                                                    }}
                                                                    resizeObserver={true}
                                                                    observer={true}
                                                                >
                                                                    {
                                                                        filterBranches.slice(0, 1)?.map((Branche, index) => {
                                                                            return (
                                                                                <SwiperSlide key={index}>

                                                                                    <article className="carOverview-page-body-delevary-branch bg-theme-up">
                                                                                        <div className=" my-5 flex flex-row justify-between">
                                                                                            <span className="text-24 text-bold">{Branche?.Address}</span>
                                                                                            <span className="text-24 text-primary border-line-b">{Branche.Enable ? trans("new:avilable1") : trans("new:not_avilable1")}</span>
                                                                                        </div>
                                                                                        <div className=" my-5 flex flex-row justify-between">
                                                                                            <span className="text-24">{trans("new:branch")}</span>
                                                                                            <span className="text-24 ">{Branche[`${trans("model:description2")}`]}</span>
                                                                                        </div>
                                                                                        {
                                                                                            Branche.WorkingHoursFormat &&
                                                                                            <div className="  my-5 flex flex-row justify-between">
                                                                                                <span className="text-24">{trans("new:work_days")}</span>
                                                                                                <span className="text-24 ">{Branche.WorkingHoursFormat}</span>
                                                                                            </div>
                                                                                        }


                                                                                        <div className=" my-5 mt-10  flex flex-row items-center nice-gap-2rem ">
                                                                                            <a className="flex flex-row items-center  border-line-b" target='_blank' href={`https://www.google.com/maps/?q=${Branche.Latitude},${Branche.Longitude}`}>
                                                                                                <span className="text-24  text-bold text-primary">{trans("new:map")}</span>
                                                                                                <img src="/assets/icons/location.svg" className="filter-primary nice-width-max-80 nice-height-max-80 object-contain" />
                                                                                            </a>
                                                                                        </div>
                                                                                        <div className="border-l text-gray"></div>
                                                                                    </article>


                                                                                </SwiperSlide>
                                                                            );
                                                                        })
                                                                    }
                                                                </Swiper>
                                                            </div>
                                                        </>



                                                )


                                            }



                                        </div>
                                    </div>

                                </>
                            )
                        }
                        {
                            suggestedVehicles && suggestedVehicles.length > 0 &&
                            <div className="my-20 nice-container pt-10 nice-border-top-section">
                                {/* <SectionTop title={trans("new:suggested_vehicles")} moreLink="#" /> */}
                                <PageHeading
                                    fixed={true}
                                    title={trans("new:suggested_vehicles")}
                                />

                                <Swiper
                                    spaceBetween={10}
                                    slidesPerView={2}
                                    breakpoints={{
                                        576: {
                                            slidesPerView: 2.5,

                                        },
                                    }}
                                    className={""}
                                    resizeObserver={"true"}
                                    initialSlide={(suggestedVehicles && suggestedVehicles.length > 0) ? ((suggestedVehicles.length / 2)) : 1}
                                // centeredSlides={true}
                                >

                                    {
                                        suggestedVehicles?.map((vehicle, index) => {

                                            return (

                                                <SwiperSlide key={vehicle.productTarget} className={"px-3 box-sizing-flex-1"} >

                                                    <Car
                                                        className={"h-100"}
                                                        group={vehicle.group}
                                                        slug={vehicle.productTarget}
                                                        year={vehicle.year}
                                                        colorID={vehicle.colorID}
                                                        gifts={vehicle.countGifts}
                                                        groupType={vehicle.carGroupID}
                                                        modelType={vehicle.type}
                                                        hybridImage={vehicle.hybridImage}
                                                        brandImage={vehicle.BrandImage}
                                                        discountPrice={vehicle.discount}
                                                        ProductId={vehicle.productID}
                                                        img={vehicle.image}
                                                        name={vehicle.modelDescription + " " + vehicle.year}
                                                        brand={vehicle.brand}
                                                        brandID={vehicle?.brandID}
                                                        category={vehicle.categor}
                                                        minPrice={vehicle.minPrice}
                                                        miniDownPayment={vehicle?.miniDownPayment}
                                                        Tax={vehicle.Tax}
                                                        RateCareDiscount={vehicle.RateCareDiscount}
                                                        newCar={vehicle.newCar}
                                                        hybird={vehicle.hybird}
                                                        desc={vehicle.desc}
                                                        modelTypes={vehicle?.modelDescription?.replace(vehicle?.brand, "")}
                                                        link={"/carOverview/" + vehicle?.sn + "/" + vehicle.productTarget}
                                                        // link={"/vehicles/" + vehicle.productTarget}
                                                        brandIcon={vehicle.brandIcon}
                                                        type={vehicle.type}
                                                        showPric={vehicle.showPric}
                                                        buyOnline={vehicle?.buyOnline}
                                                        goBuyDerict={true}
                                                        colorName={vehicle?.colorName ? vehicle?.colorName : null}
                                                        carData={vehicle}
                                                    // onClickShowSN={(SN) => FetchCarSNImages(vehicle?.sn)}
                                                    // btnShowSN={(cachSNImages && cachSNImages[vehicle?.sn]) ? true : false}
                                                    />
                                                </SwiperSlide>
                                            )

                                        })
                                    }
                                </Swiper>
                            </div>
                        }
                        {/* {


                    <CarOffersSection
                        cars={suggestedVehicles}
                        isLoading={isLoadingCars}
                        title={trans("new:suggested_vehicles")} />
                } */}


                        <div>
                            {

                                <>
                                    {

                                        // (vehicleOffers?.length > 0) &&

                                        // <OffersSection offers={vehicleOffers} titel={trans(`OfferN:offers`) + " " + vehicle?.group} isLoading={isLoadingVehicleOffers} />

                                        // <div className="my-10 nice-apply-theme">
                                        //     {/* <ShowMoreHeading title={trans("new:offers")} to="/" /> */}
                                        //     <div className={`container `}>
                                        //         <SectionTop title={title} moreLink="#" />
                                        //     </div>
                                        //     {

                                        //         vehicleOffers.map((item, index) => {
                                        //             return <VehicleOffer
                                        //                 key={index}
                                        //                 title={item.title}
                                        //                 description={item.description}
                                        //                 image={item.image ? "data:image/png;base64," + item.image : "/assets/images/default.png"}
                                        //                 to="/"
                                        //                 id="0000"
                                        //                 countDown="30 يوم" />
                                        //         })
                                        //     }
                                        // </div>
                                    }
                                </>

                            }

                        </div>
                        {


                            isLoading ? (

                                <Skeleton count={2} borderRadius={"1rem"} width={"100%"} height={"5rem"}
                                    containerClassName={"my-5"} className={"flex my-10"} inline={false} />

                            ) : (
                                <>
                                    {/* <a
                                onClick={(e) => {

                                    e.preventDefault();
                                    downloadPDFLocal(vehicle?.userManual);
                                }}


                                href={`data:application/pdf;base64,` + vehicle?.userManual} download > */}

                                    {
                                        vehicle?.userManual &&
                                        // <a href={config.paymentURL + vehicle?.userManual}   >
                                        <a href={"/" + lang + "/documentViewer"}   >
                                            {/* <embed src={config.paymentURL+'/assets/pdf/test.pdf'} /> */}
                                            {/* <a href={"/downloadpdf/" + vehicle?.userManual} > */}
                                            <div className="my-10 py-10 nice-shadow">

                                                <div className={"nice-rating-btn nice-container flex flex-row justify-between"}
                                                    onClick={() => {

                                                        try {
                                                            saveData("PDFURLOPEN", vehicle?.userManual, true)
                                                            saveData("PDFNAMEOPEN", vehicle?.name.replaceAll(" ", "-") + "_user-manual", true)
                                                        } catch (error) {

                                                        }


                                                    }}>
                                                    <span className="text-24 text-gray font-bold">
                                                        {trans("new:user_guide")}
                                                    </span>

                                                </div>

                                            </div>
                                        </a>


                                    }

                                </>

                            )}

                    </div >


                    <div id="parent-footer"
                        className="eparts-page-footer bg-theme-up show-xs"
                        style={{ height: scrollSnackUp.height }}
                        onTouchEnd={(event) => {

                            if (!event?.target?.id.includes("footer-actions")) {
                                const currentValue = { ...scrollSnackUp };
                                currentValue.show = !currentValue.show;
                                if (currentValue.show) {
                                    currentValue.height = '85rem';
                                } else {
                                    currentValue.height = '16rem';
                                }
                                setScrollSnackUp(currentValue);

                            }

                        }}>

                        {

                            isLoading ? (

                                <Skeleton count={1} borderRadius={"1rem"} width={"100%"} height={"5rem"}
                                    containerClassName={"my-5"} className={"flex my-5"} inline={false} />

                            ) : (


                                ((selectedColor?.buyOnline == true)) ? (
                                    <>


                                        <div className="flex flex-row justify-between">
                                            <div className="flex flex-col justify-center">
                                                {<>
                                                    <h4 className="text-24 text-bold">{trans("new:cash_price")} </h4>
                                                    <div className="flex flex-row gap-1">
                                                        <div className="flex flex-row gap-1">
                                                            <span className="text-24 font-bold text-primary">{(selectedColor?.NetSalePrice) && <Price price={parseFloat(selectedColor?.NetSalePrice)} />}</span>

                                                            {
                                                                finance?.totalPrice ?
                                                                    <span className="text-20 text-gray">
                                                                        {"("}
                                                                        {(finance?.price) && <Price displayCurrencyLiter={false}
                                                                            price={parseFloat(finance?.price.toFixed(2))} />}

                                                                        {" " + trans("new:monthly") + ")"}

                                                                    </span>
                                                                    : null
                                                            }
                                                        </div>
                                                    </div>
                                                </>}
                                            </div>
                                            <div className="eparts-page-footer-actions" id="footer-actions">
                                                <div id="footer-actions-buy" className="text-primary text-24 nice-select-none text-center eparts-page-footer-actions-btn-buy"
                                                    onClick={() => {
                                                        try {

                                                            if (vehicle) {

                                                                history.push(`/order-car?vehicle=${vehicle?.productID}&model=${vehicle.year}&type=${vehicle.type}&color=${selectedColor?.ColorID}`);
                                                            }

                                                        } catch (error) {
                                                            //console.log(error);
                                                        }
                                                    }}




                                                >{trans("info:purchase_order")} </div>
                                                <div id="footer-actions-cart" className="bg-black eparts-page-footer-actions-btn-cart"

                                                    onClick={() => {
                                                        if (Cart) {
                                                            setCartData(Cart);

                                                        }
                                                    }}
                                                ><img id="footer-actions-cart-img" src="/assets/icons/cart.svg" /></div>
                                            </div>
                                        </div >
                                        {
                                            scrollSnackUp.show && <div className="flex flex-col nice-gap-1rem py-5">


                                                {
                                                    finance?.totalPrice ?
                                                        <>
                                                            <p className="text-center text-24 mt-10">
                                                                {trans("new:installment_price")}
                                                            </p>
                                                            <div className="nice-ruler-base bg-secondary"></div>

                                                            <div className="nice-flex nice-flex-column nice-gap-1rem">
                                                                <div className="nice-flex nice-flex-row nice-flex-space-between my-4">
                                                                    <h3 className="text-gray-dark text-16">{trans("finance:finance")}</h3>
                                                                    <p className={"text-primary-dark text-16"}>{lang == "ar" ? financeSettings.DescriptionAr : financeSettings.DescriptionEn}</p>
                                                                </div>
                                                                <div className="nice-flex nice-flex-row nice-flex-space-between my-4">
                                                                    <h3 className="text-gray-dark text-16">{trans("finance:period")}</h3>
                                                                    <p className={"text-primary-dark text-16"}>{finance?.period}</p>
                                                                </div>
                                                                <div className="nice-flex nice-flex-row nice-flex-space-between my-4">
                                                                    <h3 className="text-gray-dark text-16">{trans("finance:management_fees")}</h3>
                                                                    <p className={"text-primary-dark text-16"}><Price price={finance?.mangFees} showZero={true} /></p>
                                                                </div>
                                                                <div className="nice-flex nice-flex-row nice-flex-space-between my-4">
                                                                    <h3 className="text-gray-dark text-16">{trans("finance:monthPayment")}</h3>
                                                                    <p className={"text-primary-dark text-16"}><Price price={finance?.price} showZero={true} /></p>
                                                                </div>
                                                                <div className="nice-flex nice-flex-row nice-flex-space-between my-4">
                                                                    <h3 className="text-gray-dark text-16">{trans("finance:advance_payment")}</h3>
                                                                    <p className={"text-primary-dark text-16"}> <Price price={finance?.first} showZero={true} /></p>
                                                                </div>
                                                                <div className="nice-flex nice-flex-row nice-flex-space-between my-4">
                                                                    <h3 className="text-gray-dark text-16">{trans("finance:lastPayment")}</h3>
                                                                    <p className={"text-primary-dark text-16"}> <Price price={finance?.last} showZero={true} /></p>
                                                                </div>

                                                                <div class="mx-2 my-10 nice-flex nice-flex-row">
                                                                    <img src="/assets/icons/info.svg" class="mx-3 text-inline-block nice-max-icon" />
                                                                    <p class="text-12 text-bold text-gray text-inline-block">{trans("finance:This_calculation_is_estimate")} </p>
                                                                </div>
                                                            </div>

                                                            <div className="nice-ruler-base bg-secondary"></div>

                                                        </>
                                                        : null

                                                }


                                            </div>
                                        }
                                    </>
                                )

                                    :
                                    (

                                        <div className="flex flex-row justify-between">
                                            <div className="flex flex-col justify-center">


                                            </div>


                                            <div id="footer-actions-buy" className="flex flex-col btn-notfound bg-gray-light p-5">
                                                <div id="footer-actions-buy" className="flex flex-row items-center justify-center btn-notfound-available">
                                                    <p id="footer-actions-buy" className="text-center  text-24  text-danger ">
                                                        {trans("new:not_available")}
                                                    </p>
                                                </div>

                                                <div className="border-l-5 my-5 mx-5 text-gray"></div>


                                                <div id="footer-actions-buy" className="flex flex-row basis-2/4 items-center btn-notfound-alert"

                                                    onClick={() => {
                                                        toast.warning(trans("new:Notification_will_be_sent_when_available"));
                                                    }}
                                                >
                                                    <p id="footer-actions-buy" className="text-center text-alert text-24  text-line-clamp   ">
                                                        {trans("new:alert_when_available")}
                                                    </p>
                                                    <img className="  " src="/assets/icons/alert-icon.svg" />
                                                </div>
                                            </div>


                                        </div>
                                    )




                            )

                        }


                    </div >





                </div >

                <div className="carOverview-page-sub pt-20  hide-xs col-span-0  md:col-span-4 sm:col-span-4 lg:col-span-4 xl:col-span-4">

                    {
                        isLoading ?

                            <Skeleton count={3} borderRadius={"1rem"} width={"70%"} height={"5rem"}
                                containerClassName={"my-5"} className={"flex my-5"} inline={false} />

                            : (
                                vehicle ?
                                    <>


                                        <div className="carOverview-page-body-name">
                                            <div className="carOverview-page-body-type">
                                                <div className="carOverview-page-body-type-content nice-gap-1rem">
                                                    <h2 className="text-20 text-bold">{vehicle?.brand && vehicle?.brand}</h2>
                                                    {/* <img src={vehicle?.brandIconPath && vehicle?.brandIconPath} /> */}
                                                    <img className="" src={`/assets/icons/brands/${(isToyota ? "toyota" : "") + (isLexus ? "lexus" : "")}.png`} />
                                                </div>
                                            </div>

                                            <h3 className="text-20 text-gray">{vehicle?.name && vehicle?.name}</h3>
                                        </div>
                                        <div className="nice-container carOverview-page-body-number flex flex-row nice-gap-1rem my-10">
                                            <span className="text-20 text-gray"> {trans("new:item_no")}</span>
                                            <span className="text-20 text-gray">{vehicle?.productNo ? vehicle?.productNo : "########"}</span>
                                        </div>

                                    </>
                                    :
                                    <div className="container search-line my-40"><h4 className="text-gray text-20 py-2">{trans("new:no_data")}</h4></div>
                            )

                    }
                    <div className="nice-container flex flex-col gap-3 justify-between">
                        <div className="flex flex-col justify-center">
                            {<>

                                <div className="nice-flex nice-flex-row nice-flex-space-between mb-10">
                                    <h4 className="text-20">{trans("new:cash_price")} </h4>
                                    <span className="text-20 font-bold text-primary">{(selectedColor?.NetSalePrice) && <Price price={parseFloat(selectedColor?.NetSalePrice)} />}</span>
                                </div>
                                {
                                    finance?.price ?
                                        <p className="text-20 text-center text-gray">
                                            {"( "}

                                            {(finance?.price) && <Price price={parseFloat(finance?.price.toFixed(2))} />}

                                            {" " + trans("new:monthly") + " )"}

                                        </p>
                                        : null
                                }

                                {
                                    financeSettings &&
                                    <div className="nice-flex nice-flex-column nice-gap-0rem px-10">
                                        <div className="nice-flex nice-flex-row nice-flex-space-between my-4">
                                            <h3 className="text-gray-dark text-20">{trans("finance:finance")}</h3>
                                            <p className={"text-primary-dark text-20"}>{lang == "ar" ? financeSettings.DescriptionAr : financeSettings.DescriptionEn}</p>
                                        </div>
                                        <div className="nice-flex nice-flex-row nice-flex-space-between my-4">
                                            <h3 className="text-gray-dark text-20">{trans("finance:period")}</h3>
                                            <p className={"text-primary-dark text-20"}>{finance?.period}</p>
                                        </div>
                                        <div className="nice-flex nice-flex-row nice-flex-space-between my-4">
                                            <h3 className="text-gray-dark text-20">{trans("finance:management_fees")}</h3>
                                            <p className={"text-primary-dark text-20"}><Price price={finance?.mangFees} showZero={true} /></p>
                                        </div>
                                        <div className="nice-flex nice-flex-row nice-flex-space-between my-4">
                                            <h3 className="text-gray-dark text-20">{trans("finance:monthPayment")}</h3>
                                            <p className={"text-primary-dark text-20"}><Price price={finance?.price} showZero={true} /></p>
                                        </div>
                                        <div className="nice-flex nice-flex-row nice-flex-space-between my-4">
                                            <h3 className="text-gray-dark text-20">{trans("finance:advance_payment")}</h3>
                                            <p className={"text-primary-dark text-20"}> <Price price={finance?.first} showZero={true} /></p>
                                        </div>
                                        <div className="nice-flex nice-flex-row nice-flex-space-between my-4">
                                            <h3 className="text-gray-dark text-20">{trans("finance:lastPayment")}</h3>
                                            <p className={"text-primary-dark text-20"}> <Price price={finance?.last} showZero={true} /></p>
                                        </div>

                                        <div class="mx-2 my-10 nice-flex nice-flex-row">
                                            <img src="/assets/icons/info.svg" class="mx-3 text-inline-block nice-max-icon" />
                                            <p class="text-12 text-bold text-gray text-inline-block">{trans("finance:finance_note")} </p>
                                        </div>
                                    </div>
                                }

                            </>}
                        </div>
                        <div className="eparts-page-footer-actions w-100" id="footer-actions">
                            <div id="footer-actions-buy" className="text-primary text-20 nice-select-none text-center eparts-page-footer-actions-btn-buy"
                                onClick={() => {
                                    try {


                                        if (vehicle) {

                                            history.push(`/order-car?vehicle=${vehicle?.productID}&model=${vehicle.year}&type=${vehicle.type}&color=${selectedColor?.ColorID}`);
                                        }

                                    } catch (error) {
                                        //console.log(error);
                                    }
                                }}




                            >{trans("info:purchase_order")} </div>
                            <div id="footer-actions-cart" className="bg-black eparts-page-footer-actions-btn-cart"

                                onClick={() => {
                                    if (Cart) {
                                        setCartData(Cart);
                                    }
                                }}
                            ><img className="z-3" id="footer-actions-cart-img" src="/assets/icons/cart.svg" /></div>
                        </div>
                    </div >

                </div>

                <div className={""} style={{ opacity: (isFullScreen == true ? 1 : 0), position: (isFullScreen == true ? "relative" : "fixed"), zIndex: (isFullScreen == true ? 999999 : -1) }}>
                    <ImageGallery ref={popGalleryRef} items={gallery} onScreenChange={(e) => {

                        setIsFullScreen(e);
                    }} />
                </div>

            </div>
        </section >


    );
}