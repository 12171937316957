import React, {useEffect, useState} from 'react';
import ReactSlider from "react-slider";

function TestEparts({minProp = 5, maxProp  = 100, defaultValueProp= [20,50], minDistanceProp= 10, returnProp, className=''}) {

    const [start, setStart] = useState(minProp);
    const [end, setEnd] = useState(maxProp);

    const funcChange = (val, index) => {
        let currentStart = start;
        let currentEnd = end;
        index === 0 
            ? currentStart = val[index]
            : currentEnd = val[index]

        setStart(currentStart);
        setEnd(currentEnd);
        
        if(returnProp){
            returnProp({"start": currentStart, "end": currentEnd});
        }
        
        return {"start": start, "end": end};
    }

    // useEffect(() => {
    //     // console.info("start: ", start);
    //     // console.info("End:   ", end);
    // }, [start, end]);

    return (
        <>
            <ReactSlider
                className={`horizontal-slider ${className}`}
                thumbClassName="example-thumb"
                trackClassName="example-track"
                defaultValue={defaultValueProp}
                ariaLabel={['Lower thumb', 'Upper thumb']}
                ariaValuetext={state => `Thumb value ${state.valueNow}`}
                renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                pearling
                minDistance={minDistanceProp}
                min={minProp}
                max={maxProp}
                onChange={funcChange}
            />
        </>
    );
}

export default TestEparts;