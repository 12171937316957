import { Fragment, useCallback, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FilterButton } from "../../../components/__simple/_filterButton";
import { useParams } from "react-router";
import axios from "axios";
import { slugSplitter } from "../../../utils/slugManager";
import config from "../../../configuration/config.json";
import ImageGallery from "react-image-gallery";
export function VehicleGalleryPage() {
    const { t: trans, i18n } = useTranslation(["car", "new"]);
    const { slug, brand, group,year } = useParams();
    const [gallery, setGallery] = useState([]);
    const [types, setTypes] = useState([]);
    const [filteredGallery, setFilteredGallery] = useState([]);
    const [filter, setFilter] = useState();
    const [innerCarImages, setInnerCarImages] = useState([]);
    const [outerCarImages, setOuterCarImages] = useState([]);
    const [wheels, setWheels] = useState([]);
    const [activePart, setActivePart] = useState(0);
    const popRef = useRef(null);
    const [activeImage, setActiveImage] = useState(0);
    const [isFullScreen, setIsFullScreen] = useState(false);
    let lang = i18n.language;

    useEffect(() => {
        setFilteredGallery([]);
        setInnerCarImages([]);
        setOuterCarImages([]);
        setWheels([]);

        callback();
    }, [group,year]);

    const callback = useCallback(async () => {
        try {

            const innerImages = [];
            const outerImages = [];
            const wheelsImages = [];
            const response = await axios.request({
                baseURL: config.controllURL,
                url: lang + "/api/VehicleGallery/" + slugSplitter(brand) + "/" + group +"/" + year + "/gallery",
                method: "get",
            });

            setTypes(response.data.types);
            setGallery(response.data.gallery);

            if (Array.isArray(response?.data?.gallery)) {
                const responseData = response.data;
                response?.data?.gallery?.map((item, index) => {
                    item["original"] = config.mainURL + item.imagePath;
                    item["thumbnail"] = config.mainURL + item.imagePath;
                    item["originalHeight"] = "50";
                    item["originalWidth"] = "50";
                    item["sizes"] = "50";
                    item["fullscreen"] = config.mainURL + item.imagePath;
                    if (item?.type?.toLowerCase().includes("inner")) {

                        innerImages.push(item);

                    } else if (item?.type?.toLowerCase().includes("wheel")) {

                        wheelsImages.push(item);
                    } else {

                        outerImages.push(item);

                    }


                });



                setInnerCarImages(innerImages);
                setOuterCarImages(outerImages);
                setWheels(wheelsImages);



            }

        } catch (error) {
            console.log(error);
        }
    }, [group,year, brand]);


    useEffect(() => {
        // setFilteredGallery(gallery)

        const ap = outerCarImages.length > 0 ? 0 : (innerCarImages.length > 0 ? 1 : 2)
        if (wheels.length > 0 || outerCarImages.length > 0 || innerCarImages.length > 0) {
            filterImage(ap);
            setActivePart(ap);
        }


    }, [wheels, outerCarImages, innerCarImages])

    // useEffect(() => {
    //     if (types.length > 0)
    //         setFilter(types[0].name)
    // }, [types])

    function filterImage(filter) {
        // setFilter(filter);

        // let data = gallery.filter((img) => {
        //     return img.type === filter;
        // });
        // console.log("outerImages>>>>>>>>>filterImage>>>>>>>>>>>>>>>>:", filter);
        if (filter == 0) {
            setFilteredGallery(outerCarImages);
        } else if (filter == 1) {
            setFilteredGallery(innerCarImages);
        } else {
            setFilteredGallery(wheels);

        }
        setActiveImage(0);


    }

    return (
        <Fragment>
            <div className={""} style={{ opacity: (isFullScreen == true ? 1 : 0), position: (isFullScreen == true ? "relative" : "fixed"), zIndex: (isFullScreen == true ? 999999 : -1) }}>
                <ImageGallery ref={popRef} items={filteredGallery} onScreenChange={(e) => {

                    setIsFullScreen(e);
                }} />
            </div>
            <div className={"vehicle-filter-group mb-24 mx-auto container "}>
                {/* {
                    types.map(({ name }) => {
                        return (
                            <FilterButton
                                name={name?.toLowerCase().includes("inner") ? trans("new:inner") : (name?.toLowerCase().includes("wheel") ? trans("new:wheels") : trans("new:outer"))}
                                onclick={() => filterImage(name)}
                                isActive={filter === name}
                            />
                        )
                    })
                } */}


                <div className={"flex flex-row justify-center items-center flex-wrap nice-gap-1rem mb-24"}>

                    {
                        outerCarImages?.length > 0 && <FilterButton onclick={() => {
                            setActivePart(0);
                            filterImage(0);

                        }} name={trans("car:external-images")} isActive={activePart == 0} />
                    }
                    {
                        innerCarImages?.length > 0 && <FilterButton onclick={() => {
                            setActivePart(1);
                            filterImage(1);
                        }} name={trans("car:internal-images")} isActive={activePart == 1} />
                    }
                    {
                        wheels?.length > 0 && <FilterButton onclick={() => {
                            setActivePart(2);
                            filterImage(2);
                        }} name={trans("info:wheels")} isActive={activePart == 2} />
                    }


                </div>

            </div>

            <div className={"gallery container mb-32"} >
                {
                    filteredGallery?.map((img, index) => {
                        return <div
                            key={index}
                            className={"gallery-item"}

                            onClick={(e) => {

                                setActiveImage(index);

                                try {
                                    if (popRef) {

                                        // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>:", popRef.current.toggleFullScreen());
                                        popRef.current.toggleFullScreen();
                                        popRef.current.slideToIndex(index)
                                    }

                                } catch (error) {

                                }
                            }}
                        >

                            <img
                                key={img?.type + "-" + index}
                                src={config.mainURL + img.imagePath}
                                onError={e => e.target.style.display = 'none'}
                                className={"object-full object-cover "
                                    // + (filter !== img.type ? "hidden" : "")

                                }

                                alt={img.name} />
                            <span className="gallery-item-ovrlay">
                                <svg class="image-gallery-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                    <path d="M8 3v3a2 2 0 0 1-2 2H3m18 0h-3a2 2 0 0 1-2-2V3m0 18v-3a2 2 0 0 1 2-2h3M3 16h3a2 2 0 0 1 2 2v3">

                                    </path>
                                </svg>

                            </span>
                        </div>
                    })
                }
            </div>
        </Fragment>
    );
}