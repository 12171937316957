import {useRef, useState} from "react";

export default function AdviceTile({key = null ,name, content}) {
    const height = useRef(null);
    const [divHeight, setHeight] = useState(0);

    function showData() {
        setHeight(divHeight <= 0 ? height.current.scrollHeight : 0);
    }

    return (
        <div className={"advise-tile bg-white mb-10"} key={key}>

            <div onClick={showData} className="advise-tile-name">
                <div className={"text-20 font-bold"}>{name}</div>

                <img className={"filter-black w-8 h-8 mr-3 " + (divHeight > 0 && "rotate-180")}
                     src={"/assets/icons/arrow.svg"} alt={"show"}/>
            </div>
            <div ref={height} className={"advise-tile-info "} style={{height: divHeight + "px"}}>
                <div className="m-8 space-y-6 text-20 new-lines">
                    {content}
                </div>
            </div>
        </div>
    );
}