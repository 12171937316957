import config from '../configuration/config.json';
import crypto from 'crypto-js';
export const User_Data_Key = 'USER_DATA_KEY';
export const saveData = (key,value , encryptData = true)=>{
 let transformedValue = value;   
if(encryptData){
    transformedValue =   crypto.AES.encrypt(JSON.stringify(value),config.cryptoKey).toString();
}
window.localStorage.setItem(key,transformedValue);
};
export const getData = (key,descryptData = false)=>{
    let keyData = window.localStorage.getItem(key);
    if(descryptData){
      return crypto.AES.decrypt(keyData, config.cryptoKey).toString(crypto.enc.Utf8);
    }
    return keyData;
}