import { useEffect, useState } from "react";
import axios from "axios";
import config from '../configuration/config.json';
import moment from 'moment';
import { groupBy, mapValues, omit } from "lodash";
import { getData } from "../utils/localStorageManager";
import { DropDownInput } from "../components/_input/_dropdown";
import { useTranslation } from "react-i18next";
export function UserNotificationPage() {
    //siteusernotifications
    const { t: trans } = useTranslation(["info", "model"]);
    const lang = localStorage.getItem('i18nextLng');
    const dir = lang == 'en' ? 'left' : 'right';
    const languageDir = 'language-' + lang;
    const [notifications, setNotifications] = useState([]);
    const [filteredNotification, setFilteredNotification] = useState([]);
    const Filteredcategories = [
        {
            text: trans("info:all"), value: "1"
        },
        {
            text: trans("info:today"), value: "2"
        },
        {
            text: trans("info:newer"), value: "3"
        }
    ];
    useEffect(() => {
        initialNotificationsAsGroup();
    }, []);


    // to fetch all the user offers
    const initialNotificationsAsGroup = () => {
        try {
            const secretKey = getData('secretKey');
            if (secretKey) {
                axios.post(
                    config.baseURL + 'func',
                    {
                        "name": "SiteusernotificationsFunc",
                        "values": {
                            "id": secretKey
                        }
                    }
                ).then(response => {
                    const result = response.data;
                    const groupResult = mapValues(groupBy(result, 'alertType'), fList => fList.map(items => omit(items, 'alertType')));
                    let groupValues = {};
                    Object.assign(groupValues, groupResult);
                    let finalResults = [];
                    //----------------------------------------------------------------------------  
                    for (let item in groupValues) {
                        const obj = { heading: '', items: [] };
                        let title = '';
                        const results = groupValues[item].map(item => item[`${trans("model:description")}`]);
                        if (results?.length > 0) {
                            title = results[0];
                        }
                        obj.heading = title;
                        obj.items = groupValues[item];
                        finalResults.push(obj);
                    }
                    setNotifications(finalResults);
                    setFilteredNotification(finalResults);
                }).catch(error => {
                    console.log(error);
                })
            }
        } catch (error) {
            console.log(error);
        }
    };


    // to filter the notification by type
    const handleFilterNotification = (option) => {
        const type = Number(option);

        let result = [];
        switch (type) {
            case 1:
                result = notifications;
                break;
            case 2:

                for(let parentItem of notifications){          
                    const {heading} = parentItem;
                    const childItemSet = parentItem?.items?.filter(childItem=> {return moment(childItem.createdDate).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')});
                    if(childItemSet?.length > 0){
                        result.push({
                            heading : heading,
                            items:childItemSet
                        });
                    }
                }
                break;
            case 3:
                for(let parentItem of notifications){          
                    const {heading} = parentItem;
                    const childItemSet = parentItem?.items?.filter(childItem=> {return moment(childItem.createdDate).format('YYYY-MM-DD') >= moment().subtract(10, 'days').format('YYYY-MM-DD')});
                    if(childItemSet?.length > 0){
                        result.push({
                            heading : heading,
                            items:childItemSet
                        });
                    }
                }
                break;
        }
        setFilteredNotification(result ?? []);
    };

    return (<>
        <div className={"mx-7 " + languageDir}>
            <div className="nice-row">
                <div className="nice-col-sm-2">
                    <DropDownInput
                        options={Filteredcategories}
                        title={trans("info:filter_By")}
                        onSelect={(option) => {
                            handleFilterNotification(option)
                        }}
                    />
                </div>

            </div>
        </div>
        <div className={"user-notification  " + languageDir}>
            {
                filteredNotification?.map((parentItem, parentIndex) => {
                    return (<article
                    >
                        <h3 className="font-bold text-20 text-black mx-8 mb-8">{parentItem?.heading}</h3>
                        <>
                            <div className={`user-notification-line mb-4  user-notification-line-${dir}`}></div>
                            {
                                parentItem?.items?.map((childItem, childIndex) => {

                                    if (lang === "en") {
                                        return (
                                            <div className={`notification-card notification-card-${dir}`}>
                                                <div className="head">
                                                    <div className="icon primary">
                                                        <img src="/assets/user_profile/icons/notification/icon.svg"
                                                            className="object-contain object-full" alt="notification" />
                                                    </div>
                                                    <div>
                                                        <div className="text-bold text-16 mb-2">{childItem.titleEn}</div>
                                                        <div className="date">{moment(new Date(childItem.createdDate)).format('YYYY-MM-DD (HH:mm:ss A)')}</div>
                                                        <div className="text-14">
                                                            {childItem.contentEn}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        );
                                    } else {
                                        return (
                                            <div className={`notification-card  notification-card-${dir}`}>
                                                <div className="head">
                                                    <div className="icon primary">
                                                        <img src="/assets/user_profile/icons/notification/icon.svg"
                                                            className="object-contain object-full" alt="notification" />
                                                    </div>
                                                    <div>
                                                        <div className="text-bold text-16 mb-2">{childItem.titleAr}</div>
                                                        <div className="date">{moment(new Date(childItem.createdDate)).format('YYYY-MM-DD (HH:mm:ss A)')}</div>
                                                        <div className="text-14">
                                                            {childItem.contentAr}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        );
                                    }
                                })
                            }
                        </>


                    </article>
                    )
                })
            }
        </div>
    </>

    );
}