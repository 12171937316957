import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
export function NavMaintenanceCard({ id, description, price, image, days }) {
    const { t: trans } = useTranslation(["common", "navbar"]);
    return (
        <Link className='nav-box   w-full' to={'/offers/detail/' + id}>
            <div className="nav-box-date">
                <div className={"text-20 "}>{days}</div>
                <div className={"text-12"}>{trans("navbar:day")}</div>
            </div>
            <div className="nav-box-overlay "></div>
            <img src={image} alt="service" className="nav-box-img object-cover" />

            <div className="nav-box-text">
                <h2 className={"text-20 text-line-clamp text-line-clamp-1 font-bold mb-6"}>{description}</h2>
                {
                    price && <p className={"text-16 text-primary font-bold"}>
                        {
                            trans("navbar:offer_start") + price + trans("common:rial")
                        }
                    </p>
                }
            </div>
        </Link>
    );
}