import { useEffect } from "react";
import { useTranslation } from "react-i18next";
export function LikeButton({long, onToggle ,favorite = false, titleStyle= '', btnStyle = '', ...prop}) {
    const {t : trans} = useTranslation('info');
    return (
        <div {...prop}
             onClick = {()=>{
              if(onToggle){
                  onToggle()
              }
             }}
             className={(btnStyle == '' ? "btn-like " : btnStyle + " " ) + (long && "long")}>
            <p className={(titleStyle == '' ? "text-16 " : titleStyle + " ") + (long || "hidden")}>{trans('info:add_favorite')}</p>
            <img src={`/assets/icons/${favorite?'heart.png' : 'cleanHeart.png'}`} alt={"favorite"}/>
        </div>
    );
}