import {Fragment, useEffect, useState} from "react";
import Banner from "../../components/banner";
import SwiperCore, {Autoplay, EffectFade, Navigation, Pagination} from "swiper";
import {WhyHJSection} from "../../sections/whyHJSection";
import Info from "../../components/_info";
import Breadcrumb from "../../components/__simple/breadcrumb";
import {useParams} from "react-router";
import axios from "axios";
import { useTranslation } from "react-i18next";
import config from "../../configuration/config.json";

SwiperCore.use([Pagination, Navigation, Autoplay, EffectFade]);


export function MaintenanceServiceDetailPage() {
    let {ServicId} = useParams();
    const [lang, setlang] = useState(localStorage.getItem('i18nextLng'));
    const langaugeDir = 'language-' + lang;
    const {t:trans} = useTranslation(['info'])
    ///////  تفثاصبل الخدمات الفرعيه
    const GetUrl = config.baseURL + 'details';

    const [CAT, setCAT] = useState([]);

    function RequsetRCAT(catId) {
        const Body = {
            "object": "siteMaintenanceServices",
            "ObjectSettings": {
                "MetaData": false
            },
            "Filters": "where GUID='" + ServicId + "'"


        }
        axios.request({
            url: GetUrl,
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {
                //console.log(response.data.ApiObjectData);
                setTimeout(setCAT(response.data.ApiObjectData), 1000);

            })
            .catch(function (error) {
                console.log(error);
            });


    }

    let title = "";
    let subtitle = "";
    let excerpt = "";
    let categoryId = 0;
    let image = "/assets/images/offers/bg.png";
    let content = "";
    let upDesc = "";
    let upTitle = "";
    let upImage = "/assets/images/offers/bg.png";
    useEffect(() => {

        RequsetRCAT();

    }, [])
    if (CAT.length !== 0) {
        content = lang === "en" ? CAT["0"].contentEn : CAT["0"].contentAr;
        title = lang === "en" ? CAT["0"].titleEn : CAT["0"].titleAr;
        subtitle = lang === "en" ? CAT["0"].subtitleEn : CAT["0"].subtitleAr;
        excerpt = lang === "en" ? CAT["0"].excerptEn : CAT["0"].excerptAr;
        image = CAT["0"].image !== "null" ? "data:image/png;base64," + CAT["0"].image : image;
        upDesc = lang === "en" ? CAT["0"].upDescEn : CAT["0"].upDescAr;
        upTitle = lang === "en" ? CAT["0"].upTitleEn : CAT["0"].upTitleAr;

        upImage = CAT["0"].upImage !== "null" ? "data:image/png;base64," + CAT["0"].upImage : upImage;
        categoryId = CAT["0"].categoryId;

    }
    return (
        <Fragment>

            <Banner
                background={image}
                title={title}
                subtitle={subtitle}
                desc={excerpt}
                btn={{
                    link: "/maintenance/order-service/" + ServicId,
                    text: trans("info:order")
                }}
            />
            <div className="container">
                <Breadcrumb className={"mt-16 "}
                            links={[
                                {
                                    name: trans("info:maintenance"),
                                    to: "/maintenance"
                                },
                                {
                                    name: trans('info:servce'),
                                    to: "/maintenance/service/" + ServicId
                                }, {
                                    name:title
                                }
                            ]}
                />
            </div>
            <div className="container mb-32 -mt-16 ">

                <Info
                    img={upImage}
                    title={upTitle}
                    desd={upDesc}
                />
            </div>


            <div className={"container mb-32 " + langaugeDir }>

                <div className="text-24-children">
                    <div dangerouslySetInnerHTML={{__html: content}}/>
                </div>
            </div>


            <WhyHJSection/>

        </Fragment>
    );
}