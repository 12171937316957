import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {LikeButton} from "../../../components/__simple/_likeButton";
import {PartRating} from "./rate";
import {useEffect, useState} from "react";
import axios from "axios";
import config from "../../../configuration/config.json";
import SimpleCardCar from "../../../components/simpleCardCar";
import {Swiper, SwiperSlide} from "swiper/react";

export function CarSection({guidProductId}, props) {
    const {t: trans} = useTranslation(["common", "eparts"]);
    const [lang, setlang] = useState(localStorage.getItem('i18nextLng'));

    const GetUrl = config.baseURL + 'details';
    const [PWD, setPWD] = useState([]);
    const [PWDT, setPWDT] = useState([]);

    const [compatibilityList, setCompatibilityList] = useState([]);

    function RequsetPISM() {

        const Body = {
            "name": "site_FeatchPartCompatiblity",
            "values": {
                "ProductID": props.productid
            }
        }
        axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        }).then(function (response) {
            setTimeout(setPWD(response.data), 100);

        })
            .catch(function (error) {
                console.log(error);
            });


    }

    function RequsetPIDitals(data) {

        const Body = {
            "name": "site_FeatchPartCompatiblityTrans",
            "values": {
                "ProductID": data.ID.toString()
            }
        }
        axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        }).then(function (response) {
            setTimeout(setPWDT(response.data), 100);
        })
            .catch(function (error) {
                console.log(error);
            });
        return;
    }

    function Compatiblity() {

        PWD.map((pd) => {

                return (
                    <div className="part-info-detail-car-container">
                        <div className={"part-info-detail-car-img"}>
                            <div className="img-flex">
                                <img src={"data:image/png;base64," + pd.Image}/>
                                <div className={"part-info-detail-car-name"}>
                                    <h3 className={"text-20  font-bold"}>{pd.group}</h3>
                                    <small className={"text-16"}>{pd.type}</small>
                                </div>
                            </div>
                        </div>

                        <div>
                            {
                                PWDT.map(p => {
                                        return (
                                            <div className={"part-info-detail-car-detail"}>
                                                <div className={"text-center"}>{p.FromYear}</div>
                                                <div className={"text-center"}> {p.ToYear}</div>
                                                <div>{p.Note}</div>
                                            </div>
                                        )
                                    }
                                )
                            }
                        </div>
                    </div>
                )
            },
            data => RequsetPIDitals(data));
    }

    const getCompatibility = ({guid}) => {
        const body = {
            "name": "Site_FeatchPartCompatiblity",
            "values": {
                "GUID": guid
            }
        };

        axios.request({
            url: config.baseURL + 'func',
            data: body, method: "POST", responseType: "json"
        })
            .then(function (response) {

                if (response?.data && response?.data.length > 0) {
                    setCompatibilityList(response?.data);
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    };

    useEffect(() => {
        // RequsetPISM();

        if (guidProductId) {
            getCompatibility({guid: guidProductId});
        }
    }, [guidProductId]);

    return (
        <>
            {/*<div className="part-info-detail-cars container">*/}
            {/*    <div className="part-info-detail-car-container hide-xs">*/}
            {/*        <div className={"part-info-detail-car-img items-center justify-center"}>*/}
            {/*            <div className="text-16 font-bold text-center">{trans("eparts:model")}</div>*/}
            {/*        </div>*/}
            {/*        <div>*/}
            {/*            <div className={"part-info-detail-car-detail h-full no-b-bottom"}>*/}
            {/*                <div className={"text-center text-16 font-bold"}>{trans("eparts:from-year")}</div>*/}
            {/*                <div className={"text-center text-16 font-bold"}>{trans("eparts:to-year")}</div>*/}
            {/*                <div className={"text-center text-16 font-bold"}>{trans("eparts:note")}</div>*/}
            {/*            </div>*/}

            {/*        </div>*/}
            {/*    </div>*/}
            {/*    {Compatiblity()}*/}
            {/*</div>*/}


            <Swiper
                spaceBetween={1}
                slidesPerView={2}
                breakpoints={{
                    876: {
                        slidesPerView: 6
                    },
                    576: {
                        slidesPerView: 4,
                    },
                }}
                pagination={{clickable: true}}
                className={"mb-16k"}
                resizeObserver={"true"}
                autoplay={false}
                loop={false}
                observer={true}
            >
                {

                    compatibilityList?.map((catValue, catIndex) => (
                        <SwiperSlide className={"px-3"} key={catIndex}>
                            <SimpleCardCar
                                brandID={
                                    catValue?.BrandID == '1'
                                        ? '1'
                                        : catValue?.BrandID == '2'
                                            ? "2"
                                            : "1"
                                }

                                titleAr={catValue?.DescriptionArG}
                                
                                titleEn={catValue?.DescriptionEnG}

                                subTitleListAr={[catValue?.DescriptionAr, catValue?.ProductGroupID]}
                                
                                subTitleListEn={[catValue?.DescriptionEn, catValue?.ProductGroupID]}

                                imageFull={catValue?.ProductImage}

                                // imageFull={"https://appm.hassanjameel.com.sa:8070/assets/UrlImages/1B69E88B-B21D-454C-9A28-7106A7AF6F55super-white.png"}
                            />
                        </SwiperSlide>
                    ))
                }

            </Swiper>
        </>


    );
}
