export function SimpleBanner({ banner = "/assets/images/eparts/2.png", icon, title, subtitle, borderRadius = true }) {
    return (
        <div className="banner-simple">
            <div className={"banner-simple-overlay " + (borderRadius ? " border-radius " : "")}></div>
            <div className="banner-bg banner-simple-bg">
                <img src={banner} alt="" className={"object-full"} />
            </div>

            <div className="banner-simple-text">
                <h3 className={"text-30 font-bold  mb-8  "}>{title}</h3>
                <p className={"text-20 "}>{subtitle}</p>
            </div>

            <div className="banner-simple-img hide-xs">
                {
                    icon &&
                    <img src={icon} className={"object-full object-contain"} alt="" />
                }
            </div>


        </div>
    );
}

