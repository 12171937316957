import { useTranslation } from "react-i18next";
import config from "../../../configuration/config.json";
function PartSlide({ Titel, SubTitel, Desc, BtnText, BackOpicity, backImage,backImageUrl, viewSocialIcons }) {

    const { t: trans, i18n } = useTranslation(["car", "common", "vehicales", "info"]);
    const languageDir = 'language-' + localStorage.getItem("i18nextLng");

    const lang = i18n.language;
    return (
        <div className="  mb-8 bg-page slide-part-container p-8  box-shadow-large">
            <div className="slide-overlay" style={BackOpicity ? { background: "linear-gradient(to " + (lang == "ar" ? "left" : "right") + " , #090d0b 0%, rgba(26, 28, 27, 0.76) " + BackOpicity + "%, rgba(42, 42, 42, 0.13) 100%)" } : { background: 'none' }}>

            </div>
            <div className="slide-bg">
                {
                    backImageUrl && !backImage && (
                        <img src={backImageUrl} alt={Titel.toString()}
                             onError={e => e.target.style.display = 'none'}/>
                    )
                }
                {
                    backImage && !backImageUrl && (
                        <img src={"data:image/png;base64," + backImage} alt=""
                             onError={e => e.target.style.display = 'none'}/>
                    )
                }
            </div>


            <div className="headerLandeng-info  mb-8 pb-8" style={{height: 100 + "%" }}>

                {
                    viewSocialIcons &&
                    <div className="headerLandeng-info-extra flex-grow-1 ">

                        <div className={"headerLandeng-info-extra-social-links mr-8"}>


                        </div>
                    </div>

                }


                <div className={"headerLandeng-info-desc  " + languageDir}>

                    <div className={"headerLandeng-info-text mx-8 lex-grow-2  mx-3 " + languageDir}>
                        <div className={"text-section"}>
                            <span className={" text-line-clamp text-line-clamp-1 text-40 "}>
                                {SubTitel ? SubTitel : " "}
                            </span>
                            <h2 className={" text-line-clamp text-line-clamp-2"}>

                                {Titel ? Titel : ""}
                            </h2>
                            <p className={" text-line-clamp text-line-clamp-5 text-30 text-gray"}>
                                {Desc ? Desc : ""}
                            </p>
                            { }
                        </div>
                        { }
                        <div className={"headerLandeng-info-text-btn my-10"}>

                            {

                                BtnText &&
                                <button
                                    onClick={() => {
                                        // const section = document.querySelector('#search-car');
                                        // // section.scrollIntoView({ behavior: 'smooth', block: 'start' });

                                        var top = document.getElementById('search-car').offsetTop - 30;

                                        console.log("top>>>>>>>>>>>>>>>>", top);
                                        // window.scrollTo(0, top);
                                        window.scrollTo({
                                            top: top,
                                            left: 0,
                                            behavior: "smooth",
                                        });

                                    }}
                                    className={"bg-gray text-24  py-5"}>{BtnText}</button>
                            }

                        </div>



                    </div>


                </div>









            </div>

        </div>
    );


}

export default PartSlide;