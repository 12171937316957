import axios from "axios";
import { groupBy, includes, mapValues, omit } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import config from '../../configuration/config.json';
import { toast } from "react-toastify";
import { Translation } from 'react-i18next';
import { quantity as addQuantityCart, remove, exists, list } from "cart-localstorage";
import { addToCart } from "../../_functions/cart";
import { getData, saveData, User_Data_Key } from "../../utils/localStorageManager";
export const useUserCart = () => {
    const { t: trans, i18n } = useTranslation(['info', 'new']);
    let lang = i18n.language;
    const USER_CART_ITEMS = '__cart';
    const [cartItems, setcartItems] = useState(() => {
        return JSON.parse(getData(USER_CART_ITEMS, false)) ?? []
    });
    const [itemsLength, setItemslength] = useState(cartItems?.length ?? 0);

    useEffect(() => {
        setcartItems(JSON.parse(getData(USER_CART_ITEMS, false)) ?? []);

    }, [itemsLength]);

    const success = <Translation ns={"messages"}>
        {
            (t, { i18n }) => <p className={"text-20"}>{t('add_to_cart')}</p>
        }
    </Translation>

    const error = <Translation ns={"messages"}>
        {
            (t, { i18n }) => <p className={"text-20"}>{t('unexpected_errer')}</p>
        }
    </Translation>


    const initSignIN = async () => {
        try {
            const guid = getData('secretKey');
            console.log('initSignIN guid!!!', guid);
            if (guid) {

                const res = await list().map(async (item, index) => {



                    await changecartItem(item?.ProductID, item?.colorID, item?.modelTypeID, item?.quantity, item?.modelCode, item?.packageId, null, item?.Orderdate, item?.year, false);




                    if (index + 1 == list().length) {
                        await init();

                    }
                })

                if (list().length == 0) {
                    await init();

                }


                console.log('after changecartItem goo to >>>>>>>>>:');

            } else {
                // toast.error(trans("info:login_first"));
                await init();
            }
        } catch (error) {
            console.log('sorry there is an error happened !!!', error);
            await init();
        }
    };
    // to initailize the user favorite items
    const init = async () => {

        console.log('init >>>>>>>>>>>.:');
        try {
            const guid = getData('secretKey');
            console.log('guid!!!', guid);
            if (guid) {
                await axios.post(
                    config.baseURL + 'func',
                    {
                        "Name": "SiteFetchUserCartItems",
                        "Values": {
                            "UserID": String(guid)
                        }
                    }
                ).then(async response => {
                    console.log('SiteFetchUserCartItems response:', response);
                    var _products = response.data.map(item => {
                        return {
                            id: item?.ProductID + (item?.colorID ? "-" + item?.colorID : "") + (item?.Type ? "-" + item?.Type : "") + (item?.modelCode ? "-" + item?.modelCode : "") + (item?.packageId ? "-" + item?.packageId : ""),
                            name: item?.DescriptionAr,
                            nameEn: item?.DescriptionEn,
                            price: item?.SalesPrice,
                            brand: item?.BrandDescriptionAr,
                            brandEn: item?.BrandDescriptionEn,
                            group: item?.Group,
                            year: item?.year,
                            groupEn: item?.["Group English"],
                            image: item?.ProductImage,
                            Tax: 0,
                            year: item?.year,
                            Discount: item?.SalesPriceDiscount,
                            Type: item?.packageId ? "Bouquet" : (item?.ItemType == "SpareParts" ? "part" : (item?.ItemType == "MaintenanceService" ? "service" : item?.ItemType)),
                            colorId: item?.colorID,
                            colorID: item?.colorID,
                            colorName: item?.colorNameAr,
                            colorNameEn: item?.colorNameEn,
                            BrandID: item?.BrandID,
                            groupID: item?.CarGroupID,
                            modelTypes: item?.modelTypesAr,
                            modelTypesEn: item?.modelTypesEn,
                            engineGroups: 0,
                            modelTypeID: item?.Type,
                            productNo: item?.ProductNo,
                            ProductID: item?.ProductID,
                            quantity: item?.QTY ? item?.QTY : 1
                            , modelCode: item?.modelCode
                            , packageId: item?.packageId,
                            MiniDownPayment: item?.MiniDownPayment,
                            Orderdate: item?.Orderdate

                        }

                    });


                    saveData(USER_CART_ITEMS, JSON.stringify(_products), false);
                    console.log('USER_CART_ITEMS !!!', _products);
                    setcartItems(_products);

                    try {
                        window.dispatchEvent(new Event("storage"));
                    } catch (error) {

                    }

                }).catch(err => {
                    console.log(err);
                    saveData(USER_CART_ITEMS, JSON.stringify([]), false);
                    console.log('USER_CART_ITEMS !!!', []);
                    setcartItems([]);
                    try {
                        window.dispatchEvent(new Event("storage"));
                    } catch (error) {

                    }
                });
            } else {
                // toast.error(trans("info:login_first"));
            }
        } catch (error) {
            console.log('sorry there is an error happened !!!', error);
        }

    };
    // to check if the item is favorite or not
    const isCartItem = (item) => {
        try {
            return includes(cartItems, item);
        } catch (error) {
            console.log('Has an error when checking the favorite item,', error);
        }
    };
    // to change the state of the favorite item [add-delete of found]
    const changecartItem = async (productID, colorID = null, modelTypeID = null, quantity = 1, modelCode = null, packageId = null, objectProduct = {}, Orderdate = null, year = null, isGetInitData = true) => {
        let returnValue = null;
        try {
            const isLogin = getData('isAuthenticated');
            if (isLogin) {

                const { id } = JSON.parse(getData(User_Data_Key, true));
                const userID = Number(id.toString().split('_')[1]);
                console.log("userID:", userID);

                console.log("ProductID:", productID);
                console.log("quantity:", quantity);
                var colorIDSelect = colorID ? ` and colorID='${colorID}' ` : " ";
                var modelTypeIDSelect = modelTypeID ? ` and modelTypeID='${modelTypeID}' ` : " ";
                var modelCodeSelect = modelCode ? ` and modelCode='${modelCode}' ` : " ";
                var packageIdSelect = packageId ? ` and packageId='${packageId}' ` : " ";
                var yearSelect = year ? ` and year='${year}' ` : " ";
                const itemsDetails = await cartItemsDetails(userID, productID, colorID, modelTypeID, modelCode, packageId);


                if (itemsDetails && itemsDetails?.length > 0) {

                    var newQTY = (quantity + itemsDetails[0]?.QTY) ? (quantity + itemsDetails[0]?.QTY) : 1;

                    newQTY = newQTY > 0 ? newQTY : 1;

                    returnValue = await axios.put(
                        config.baseURL + 'update',
                        {
                            "Object": "WebUserCart",
                            "values": {
                                "QTY": newQTY
                            }
                            ,
                            "Filters": `where WebUserID = '${userID}' and ProductID = '${productID}' ${colorIDSelect} ${modelTypeIDSelect} ${modelCodeSelect} ${yearSelect}  ${packageIdSelect} `
                        }
                    ).then(async response => {
                        console.log("response update:", response);

                        if (quantity > 0 && isGetInitData == true)
                            toast.success(success, {
                                position: "bottom-left",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });

                        //----------------------
                        if (isGetInitData == true)
                            return await init();

                    }).catch(err => {
                        console.log(err);


                        if (isGetInitData == true) {

                            toast.error(<div className={"text-24"}>{error}</div>, {
                                position: "bottom-left",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }


                    });
                } else {
                    returnValue = await axios.post(
                        config.baseURL + 'add',
                        {
                            "Object": "WebUserCart",
                            "values": {
                                "WebUserID": userID,
                                "ProductID": productID,
                                "ColorID": colorID,
                                "modelTypeID": modelTypeID,
                                "QTY": quantity,
                                "modelCode": modelCode,
                                "year":year,
                                "packageId": packageId ? packageId + "" : null,
                                "Orderdate": Orderdate,
                            }
                        }
                    ).then(async response => {
                        console.log("response add:", response);


                        if (isGetInitData == true) {

                            toast.success(success, {
                                position: "bottom-left",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }


                        //--------------------------
                        if (isGetInitData == true)
                            return await init();

                    }).catch(err => {
                        console.log("catch add WebUserCart>>:", err);

                        if (isGetInitData == true) {
                            toast.error(<div className={"text-24"}>{error}</div>, {
                                position: "bottom-left",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }

                    });
                }
            } else {
                // toast.error(trans("info:login_first"));
                console.log("add cart objectProduct:", objectProduct);
                if (objectProduct?.id && exists(objectProduct?.id)) {
                    console.log("addQuantityCart", objectProduct);
                    addQuantityCart(objectProduct?.id, quantity);

                    toast.success(success, {
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                } else {
                    console.log("addToCart");
                    addToCart(objectProduct);
                }



            }
        } catch (err) {
            console.log(err);

        }



        console.log("returnValue:", returnValue);
        return returnValue;
    };


    const removecartItems = async (productID, colorID = null, modelTypeID = null, modelCode = null, cartID, packageId = null) => {

        let returnValue = null;
        try {
            const isLogin = getData('isAuthenticated');
            if (isLogin) {

                const { id } = JSON.parse(getData(User_Data_Key, true));
                const userID = Number(id.toString().split('_')[1]);
                console.log("userID:", userID);

                console.log("ProductID:", productID);
                var colorIDSelect = colorID ? ` and colorID='${colorID}' ` : " ";
                var modelTypeIDSelect = modelTypeID ? ` and modelTypeID='${modelTypeID}' ` : " ";
                var modelCodeSelect = modelCode ? ` and modelCode='${modelCode}' ` : " ";
                var packageIdSelect = packageId ? ` and packageId='${packageId}' ` : " ";
                returnValue = await axios.request({
                    url: config.baseURL + 'delete',
                    data: {
                        "Object": "WebUserCart",
                        "Filters": `where WebUserID = '${userID}' and ProductID = '${productID}'  ${colorIDSelect}  ${modelTypeIDSelect} ${modelCodeSelect} ${packageIdSelect} `
                    },
                    method: "delete"
                    , responseType: "json"
                }).then(async response => {
                    console.log("response update:", response);


                    return await init();

                }).catch(err => {
                    console.log(err);

                });

            } else {
                // toast.error(trans("info:login_first"));
                remove(cartID ? cartID : productID);
            }
        } catch (err) {
            console.log(err);

        }

        console.log("returnValue:", returnValue);
        return returnValue;
    };


    const removeAllcartItems = async () => {

        let returnValue = null;
        try {
            const isLogin = getData('isAuthenticated');
            if (isLogin) {
                const { id } = JSON.parse(getData(User_Data_Key, true));
                const userID = Number(id.toString().split('_')[1]);
                console.log("userID:", userID);

                returnValue = await axios.request({
                    url: config.baseURL + 'delete',
                    data: {
                        "Object": "WebUserCart",
                        "Filters": `where WebUserID = '${userID}' `
                    },
                    method: "delete"
                    , responseType: "json"
                }).then(async response => {
                    console.log("response update:", response);


                    return await init();

                }).catch(err => {
                    console.log(err);

                });

            } else {
                toast.error(trans("info:login_first"));
            }
        } catch (err) {
            console.log(err);

        }

        console.log("returnValue:", returnValue);
        return returnValue;
    };

    // to fetch favorite items with details
    const cartItemsDetails = async (userID, productID, colorID = null, modelTypeID = null, modelCode = null, packageId = null) => {

        console.log('cartItemsDetails!!', productID);
        var colorIDSelect = colorID ? ` and colorID='${colorID}' ` : " ";
        var modelTypeIDSelect = modelTypeID ? ` and modelTypeID='${modelTypeID}' ` : " ";
        var modelCodeSelect = modelCode ? ` and modelCode='${modelCode}' ` : " ";
        var packageIdSelect = packageId ? ` and packageId='${packageId}' ` : " ";
        var result = [];
        await axios.request({
            url: config.baseURL + 'details',
            data: {
                "Object": "WebUserCart",
                "ObjectSettings": {
                    "MetaData": false
                },
                "Option": "Column",
                "Fields": " * ",

                "Filters": `where WebUserID = '${userID}' and ProductID = '${productID}'  ${colorIDSelect}  ${modelTypeIDSelect}  ${modelCodeSelect} ${packageIdSelect}    `
            },
            method: "post"
            , responseType: "json"
        }).then(response => {
            console.log("response delete:", response);
            result = response?.data?.ApiObjectData;

        }).catch(err => {
            console.log(err);

        });


        console.log('cartItemsDetails result !!', result);
        return result;

    };
    return { initCartItems: init, isCartItem, changecartItem, cartItemsDetails, removecartItems, removeAllcartItems, initSignIN };
};

