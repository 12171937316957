import axios from "axios";
import { useEffect, useState } from "react";
import { saveData, getData } from "./localStorageManager";
import config from '../configuration/config.json';
import {  find, forEach, includes, without, findIndex } from "lodash";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
export const useCompare = (vehicleID = null, maxColumns = 5) => {
    const { t: trans} = useTranslation(["model","compare"]);
    const COMPARE_KEY = "COMPARE_KEY";
    const COMPARE_FORMAT_KEY = "COMPARE_FORMAT_KEY";
    const [properities, setProperities] = useState(() => {
        return JSON.parse(getData(COMPARE_KEY)) ?? []
    });
    const [formatProperities, setFormatProperities] = useState(() => {
        return JSON.parse(getData(COMPARE_FORMAT_KEY)) ?? []
    });
    // the following fun is used to fetch the veh properities and reorder them as table
    const requestData = (vehID) => {
        if (vehID) {
            const currentValues = JSON.parse(getData(COMPARE_KEY)) ?? [];
            const currentFormatValues = [];
            if (currentValues?.length < maxColumns) {
                axios.post(
                    config.baseURL + 'func',
                    {
                        "name": "SiteGetVehicleProperities",
                        "values": {
                            "vehicleID": vehID
                        }
                    }
                ).then(response => {
                    const responseData = response.data;
                    if (responseData?.length > 0) {
                        const _image = responseData[0].Image != null ?   responseData[0].Image : null;
                        const _id = responseData[0].ProductID;

                        const customizedResult = responseData.map((item) => {
                            return {
                                key: {
                                    PropertyID: item.PropertyID,
                                    DescriptionAr: item.ProperityDescriptionAr,
                                    DescriptionEn: item.ProperityDescriptionEn,
                                },
                                value: {
                                    DescriptionAr: item.DescriptionAr,
                                    DescriptionEn: item.DescriptionEn,
                                    ProductID: item.ProductID,
                                    PropertyID: item.PropertyID,
                                }
                            }
                        });

                        const vehObject = {
                            ID: _id,
                            Image: _image,
                            Values: customizedResult
                        };

                        const _isNotFound = find(currentValues, (ele) => { return ele.ID === vehObject.ID }) == undefined || null ? true : false;

                        if (_isNotFound) {
                            // to check and reorder the keys and values 
                            currentValues.push(vehObject);
                            const _tempArray = [];
                            for (let index = 0; index < currentValues.length; index++) {
                                forEach(currentValues[index].Values, (value) => {
                                    const _propertyObjectValue = value?.key;
                                    const _propertyValue = value?.key?.PropertyID;
                                    const _props = _tempArray.map(item => {
                                        return item.PropertyID
                                    });
                                    if (!includes(_props, _propertyValue)) {
                                        _tempArray.push(_propertyObjectValue);
                                    }
                                });
                            }
                            const customizedVehicalesGroup = [];
                            // the following code is to order keys and values according to above array
                            for (let index = 0; index < currentValues.length; index++) {
                                const { ID, Image, Values } = currentValues[index];
                                const customizedObj = { ID, Image };
                                const customizedObjValues = [];
                                forEach(without(_tempArray, undefined, null), (value) => {
                                    const _valueIndex = findIndex(Values, (item) => { return item.key?.PropertyID == value?.PropertyID });
                                    if (_valueIndex != -1) {
                                        customizedObjValues.push(
                                            {
                                                ...Values[_valueIndex]
                                            }
                                        );
                                    } else {
                                        customizedObjValues.push(
                                            {
                                                key: value,
                                                value: null
                                            }
                                        );
                                    }
                                });
                                const _tempValues = { ...customizedObj };
                                _tempValues.Values = customizedObjValues;
                                customizedVehicalesGroup.push(_tempValues);
                            }
                            // formatting the values
                            for (let index = 0; index < without(_tempArray, undefined, null).length; index++) {
                                const _currentValue = without(_tempArray, undefined, null)[index];
                                _currentValue.Values = [];
                                for (let index2 = 0; index2 < customizedVehicalesGroup.length; index2++) {

                                    for (let index3 = 0; index3 < customizedVehicalesGroup[index2].Values.length; index3++) {
                                        const _v = customizedVehicalesGroup[index2].Values[index3];
                                        if (_currentValue.PropertyID === _v?.key?.PropertyID) {
                                            _currentValue.Values.push(_v?.value != null ? {DescriptionAr :_v?.value?.DescriptionAr , DescriptionEn:_v?.value?.DescriptionEn} : null);
                                            break;
                                        }
                                    }
                                }
                                currentFormatValues.push(_currentValue);
                            }
                            saveData(COMPARE_FORMAT_KEY, JSON.stringify(currentFormatValues), false);
                            setFormatProperities(currentFormatValues);
                            saveData(COMPARE_KEY, JSON.stringify(customizedVehicalesGroup), false);
                            setProperities(JSON.parse(getData(COMPARE_KEY, false)));
                            toast.success( trans('compare:compare_added_success'));

                        }else{
                            toast.info(trans('compare:compare_added_info'))
                        }
                    }
                }).catch(error => {
                    toast.warning(trans('compare:compare_no_properities_found'))
                });
            }else{
                toast.error(trans('compare:compare_added_error'));
            }
        }
    };
    // the following fun is used to delete the item from list by [index && vehID]
    const onDeleteItem = (position, value) => {
        const currentValues = JSON.parse(getData(COMPARE_KEY));
        const currentFormatValues = JSON.parse(getData(COMPARE_FORMAT_KEY));
        if(currentValues.length == 1){
            window.localStorage.removeItem(COMPARE_KEY);
            window.localStorage.removeItem(COMPARE_FORMAT_KEY);
            setProperities([]);
            setFormatProperities([]);
            return;
        }
        const _newCurrentValues = currentValues.filter((item) => {
           return   item.ID !== value
        });
       for (let index = 0; index < currentFormatValues.length; index++) {
        delete currentFormatValues[index].Values[position];
       }
       saveData(COMPARE_KEY, JSON.stringify(_newCurrentValues), false);
       saveData(COMPARE_FORMAT_KEY, JSON.stringify(currentFormatValues), false);
       setProperities(_newCurrentValues);
       setFormatProperities(currentFormatValues);
    };
    //--------------------------------------
    useEffect(() => {
        requestData(vehicleID);
    }, [vehicleID, maxColumns, properities]);
    return { properities, formatProperities, onInsert: requestData ,onDeleteItem};
};
