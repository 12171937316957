import { Fragment } from "react";
import { useTranslation } from "react-i18next";
const BrandImage = ({brand})=>{
const {t:trans} = useTranslation(["finance"]);    
const isLexus = (brand == trans("finance:brand_lexus").toLowerCase());
return(  
 <Fragment>   
<div className={"finance-car-card-type " + ( isLexus ? "lexus" : "toyota")}>
    <div className={"finance-car-card-type-brand bg-" + (isLexus ? "lexus" : "toyota")}>
        <img className={"filter-white pt-1"}
            src={"/assets/brands/" + (isLexus? "lexus.svg" : "toyota.svg")} alt="toyota" />
        <h4 className={"text-white text-12 pb-1"}>{(isLexus ? trans("finance:brand_lexus") : trans("finance:brand_toyota"))}</h4>
    </div>
</div>
    <div className="car-img-bg">
    <img className={"object-full"} src= {"/assets/brands/" + (isLexus? "lexus.png" : "toyota.png") }  alt=""/>
</div>
</Fragment>  
);
};
export default BrandImage;