import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

export function PageLink({title, link, className}) {
    const {t: trans} = useTranslation("common")
    return (
        <div className={" mb-16 flex justify-between items-center " + className}>
            <h3 className={"text-20 font-bold"}>{title}</h3>
            <Link className={"btn btn-shadow btn-white btn-show-more"} to={link}>
                {trans("show-more")}
            </Link>
        </div>
    );
}
