import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {LikeButton} from "../../../components/__simple/_likeButton";
import {Price} from "../../../components/__simple/_price";
import {useState} from "react";
// import { addToCart } from "../../../_functions/cart";
import _ from "lodash";
import {useUserFavorite} from '../../../User/hooks/useUserFavorite';
import config from "../../../configuration/config.json";
import {useUserCart} from '../../../User/hooks/useUserCart';

export function Part(props) {
    const {t: trans} = useTranslation(["common", "eparts", "info"]);
    const {changeFavoriteItemState, isFavoriteItem} = useUserFavorite();
    const {removecartItems, changecartItem} = useUserCart();

    function getpric(SalesPriceBefor) {
        if (SalesPriceBefor > 0) {
            return (
                <div className="slide-info-text-price 30 space-x-4 space-x-reverse">
                    <span className={"text-14 mx-1"}>{trans("info:product_price")}</span>
                    <span className={"price  text-20 mx-2"}><Price price={props.NetSalePrice}/></span>
                    <span className={"old-price  text-20 mx-3"}>{props.SalesPriceBefor}{trans("common:rial")}</span>
                </div>);
        } else {
            return (
                <div className="slide-info-text-price 30 space-x-4 space-x-reverse">
                    <span className={"text-14 mx-3"}>{trans("info:product_price")}</span>
                    <span className={"price  text-20"}><Price price={props.NetSalePrice}/></span>
                </div>);
        }
    }

    const [lang, setlang] = useState(localStorage.getItem('i18nextLng'));
    const languageDir = 'language-' + localStorage.getItem('i18nextLng');
    let Description = "Description....";

    Description = lang === "ar" ? props.DescriptionAr : props.DescriptionEn;
    let imagePath = lang == "ar" ? "/assets/images/eparts/PARTS_TOYOTA-AR-no-img.png" : "/assets/images/eparts/PARTS_TOYOTA-EN-no-img.png";
    imagePath = (props.ProductImage) ? config.mainURL + props.ProductImage : imagePath;
    return (

        <div className={"part-card border-radius "}>

            <LikeButton
                favorite={Boolean(isFavoriteItem(props.ProductID))}
                onToggle={() => {
                    changeFavoriteItemState(props.ProductID)
                }}
            />
            <Link to={"/eparts/detail/" + props.GUID}>
                <div className="part-img">
                    <img src={imagePath} alt="def"
                         loading={"lazy"}
                         onError={(e) => e.target.style.display = "none"}
                    />
                </div>
            </Link>
            <div className={"part-card-info border-radius box-shadow " + languageDir}>
                <div
                    onClick={() => {

                        var objectCart =
                            {
                                id: props?.ProductID,
                                productID: props?.ProductID,
                                name: props?.DescriptionAr,
                                nameEn: props?.DescriptionEn,
                                price: props?.NetSalePrice,
                                image: imagePath,
                                Type: "part",
                            }

                        changecartItem(props?.ProductID, null, null, 1, null, null, objectCart);
                        // addToCart(
                        //     {
                        //         id: props.ProductID,
                        //         name: props.DescriptionAr,
                        //         price: props.NetSalePrice,
                        //         image: imagePath,
                        //         Type: "part",
                        //     })
                    }}
                    className="part-card-info-cart text-16 btn btn-primary">
                    {trans("add-to-cart")}
                    <img className={"inline filter-white size-20 pr-1"}
                         src="/assets/icons/cart.svg"
                         alt={trans("buy")}
                         loading={"lazy"}
                         onError={(e) => e.target.style.display = "none"}
                    />
                </div>

                <Link to={"/eparts/detail/" + props.GUID}>

                    <div className="part-card-info-name mb-2">
                        <h3 className={"text-16 font-bold  text-line-clamp text-line-clamp-1"}>
                            {trans("eparts:product_id")} : {props.ProductNo}
                        </h3>
                    </div>
                    <p className={"text-16 mb-4  text-line-clamp text-line-clamp-1"}>{Description}</p>
                    <div className="part-options">
                        <div className="options-price">
                            {getpric(props.SalesPriceBefor)}
                        </div>

                    </div>
                </Link>
            </div>

        </div>

    );
}

