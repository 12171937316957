import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import Popup from "../../User/popup";
import config from '../../configuration/config.json';
import { DropDownInput } from "./_dropdown";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import {slugCombine} from '../../utils/slugManager';
const AdvancedSearch = ({onSearchClick , show = false}) => {
    const { t: trans } = useTranslation(["navbar", "model"]);
    const sections = [
        { text: trans("navbar:cars"), value: '1' },
        { text: trans("navbar:spare_parts"), value: '2' },
        { text: trans("navbar:offer"), value: '3' },
        { text: trans("navbar:compaigns"), value: '4' }
    ];
    const popRef = useRef(null);
    const searchRef = useRef(null);
    const history = useHistory();
    //----------------------------------------------
    const [searchInput, setSearchInput] = useState({description:'',value:''});
    const [isOpened, setIsOpened] = useState(false);
    const [section, setSection] = useState('');
    const [searchResult, setSearchResult] = useState([]);
    const [filteredSearchResult, setFilteredSearchResult] = useState([]);
    const [showDetailsBox, setShowDetailsBox] = useState('');
    const [targetPage,setTargetPage] = useState(''); 
    const lang = localStorage.getItem('i18nextLng');
    const languageDir = 'language-' + lang;
  
    //-----------------------------------------------
    useEffect(() => {
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [show]);

    // to close the popup from outside
    const handleClickOutside = (event) => {
        if (popRef.current && popRef.current.classList.contains(event.target.classList[0]) && show === true) {
            onSearchClick(false);
            setSearchInput({
                description:'',
                value:''
            });
            setIsOpened(false);
        }
    };
    // fire when the client clicks on the search btn
    const handleSearchBtn = () => {
       const result = {...searchInput};
       if(result.value && targetPage !== ''){
           history.push(targetPage + result.value)
           onSearchClick(false);
       }else{
           toast.error(trans("navbar:no_search_result"))
       }
    };
    // filtering the result when the client change the text value
    const handleSearchChange = (event) => {
        const value = event.target.value;
        let filter = [];
        const prevousValue = {...searchInput};
        prevousValue.description = value;
        setSearchInput(prevousValue);

        if (sections != '') {
           filter = searchResult.filter((item) => item.source.toLowerCase().includes(value?.toLowerCase() ?? ''));
        }

        setFilteredSearchResult(filter);
        if (filter && filter?.length > 0)
            setIsOpened(true);
        else
            setIsOpened(false);
    };
    // choose the option from the searching result
    const handleChooseOption = (option) => {
        if (option) {
            setSearchInput({
                description:option.description,
                value:option.value
            });
        }
        searchRef.current.focus();
        setIsOpened(false);
    };
    // select the section and populate the list
    const handleSearchResult = async (option) => {
        const descriptionModel = trans("model:description");
        let result = [];
        let target = '';
        if (option == null || option == '' || option == '0') {
            setSearchInput({description:'',value:''});
            setIsOpened(false);
            setTargetPage(target);
            return;
        }
        try {
            switch (Number(option)) {
                case 1:
                    result = await handleRequest('SiteVehiclesAdvancedSearch');
                    result = result.map((item) => {
                        return {
                            uniqueID: String(item.GUID), source: [item[`${trans("model:brandDescription")}`], item[`${trans("model:description")}`],
                            item[`${trans("model:groupDescription")}`], String(item.Model)].join(), description: item[`${trans("model:description")}`],
                            value: item[`${trans("navbar:slug")}`] ? (slugCombine(item.BrandAr,item.BrandEn) + '/' + slugCombine(item.slugAr,item.slugEn))  : null
                        }
                    });
                    target = '/vehicles/';
                    break;
                case 2:
                    result = await handleRequest('SiteSparePartsAdvancedSearch');
                    result = result.map((item)=>{
                         return {
                             description:item[`${trans("model:description")}`],source:[item[`${trans("model:description")}`],item[`${trans("navbar:category")}`],
                             String(item.Discount), item.Price , item.Weight , item.Length , item.Height , item.Width ].join(),
                             value:String(item.ID)
                         }  
                    });
                    target = '/eparts/detail/';
                    break;
                case 3:
                     result = await handleRequest('SiteOffersAdvancedSearch');
                     result = result.map((item)=>{
                           return {
                               description:item[`${descriptionModel}`],source:[item[`${descriptionModel}`],item.Price,
                               item[`Small${descriptionModel}`],item[`Offer${descriptionModel}`],item[`Terms${descriptionModel}`]].join(),
                               value: slugCombine(item.slugAr,item.slugEn)
                           }
                     });
                     target = '/offers/detail/'
                    break;
                case 4:
                    result = await handleRequest('SiteCompainsAdvancedSearch');
                    result = result.map((item)=>{
                     return {
                        description:item[`${descriptionModel}`],source:[item.Model,item.Price,item.Discount,
                        item[`Small${descriptionModel}`],item[`${trans("model:brandDescription")}`],
                        ,item[`Com${descriptionModel}`]].join(),
                        value:item.ID
                     }
                    });
                    target = '/eparts/compaigndetails/';
                    break;
            }
            setTargetPage(target);
            setSearchResult(result);
        } catch (error) {
            console.log(error);
        }
    };
    // it's used to send http request to fetch the result
    const handleRequest = async (options) => {
        let responseResult = [];
        try {
            const response = await axios.post(
                config.baseURL + "func",
                {
                    "Name": `${options}`
                }
            );
            responseResult = response.data;
        } catch (error) {
            console.log(error);
        }
        return responseResult;
    };
    // to show the more details about the item
    const handleShowDetails = (event, id) => {
        event.stopPropagation();
        setShowDetailsBox(id);
    };
    // to close the details box about the item
    const handleCloseDetailsBox = (event) => {
        event.stopPropagation();
        setShowDetailsBox('');
    };
    //--------------------------------------------------
    return (
        
        <Popup show={show}>
            <div ref={popRef} className="advanced">
                <div className="advanced-search-box">
                    <div className="advanced-search-box-container">
                        <div className="advanced-search-box-container-item-r bg-primary search-btn" onClick={handleSearchBtn} 
                        >
                            <span className="search-icon">
                                <img src="/assets/icons/search.svg" />
                            </span>
                        </div>
                        <div className="advanced-search-box-container-item-c bg-secondary ">
                            <input type='search'
                                ref={searchRef}
                                value={searchInput.description}
                                onKeyPress={(event)=>{
                                    if(event.code === 'Enter'){
                                        handleSearchBtn();
                                    }
                                }}
                                onChange={handleSearchChange}
                                className={`search-input p-5 text-size-18r  ${languageDir}`}
                                placeholder={trans("navbar:search_place_holder")} />
                        </div>
                        <div className={`advanced-search-box-container-item-l drop-box ${languageDir}`}>
                            <DropDownInput
                                options={[{ text: trans("navbar:select_section"), value: '0' }, ...sections]}
                                styles={{
                                    borderStyle: 'none'
                                }}
                                onSelect={(e) => {
                                    setSection(e);
                                    handleSearchResult(e);
                                }
                                }
                            />
                        </div>
                    </div>
                </div>
                {

                    isOpened && (<div className={`advanced-suggestions ${languageDir}`}>
                        <ul>
                            {
                                filteredSearchResult.length > 0 && filteredSearchResult.map((item, index) => {
                                    return (
                                        <li key={index} onClick={() => handleChooseOption(item)}>
                                            {item?.description}
                                            {/* {
                                                (showDetailsBox == item.uniqueID) && <div className="suggestions-flow-box"
                                                    onClick={(event) => handleCloseDetailsBox(event)}>
                                                    تفاصيل اكثر
                                                </div>
                                            }
                                            <div className="suggestion-btn" onClick={(event) => handleShowDetails(event, item.uniqueID)}>
                                                تفاصيل
                                            </div> */}
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                    )

                }
            </div>

        </Popup>
    );

};

export default AdvancedSearch;