// to build the object share component
export const  shareMaker = (url,title,hashTag = '#Hassen Jameel') =>{
let baseUrl = /*window.location.host*/ 'https://hassanjameel.com.sa/' + '/' + localStorage.getItem("i18nextLng");   
const shareObject = {
    url:(baseUrl + url),
    title:title,
    hashTag:hashTag
}; 
if(url)
return shareObject;

return null;
}