import ErrorLabel from '../../components/error';
import { useEffect, useState } from "react";
export function RadioInputDynamic({ name, title, selectValue, groupName, ListValues, errorLabel, onChangeValue,  disabled = false }) {
    let lang = localStorage.getItem("i18nextLng");
    const languageDir = "language-" + lang;
    const [radioSelectValue, setRadioSelectValue] = useState(selectValue);
    useEffect(() => {
        setRadioSelectValue(selectValue);
    }, [selectValue]);
    return (
        <div className={"input " + languageDir}>
            <label className={"input-label"} htmlFor={name}>{title}</label>
            <ErrorLabel errorTitle={errorLabel} />
            <div>
                {ListValues?.map((item, index) => (
                    item && (
                        <label key={index} className={"input-radio"}>
                            <input
                               disabled={disabled}
                                type="radio"
                                checked={radioSelectValue != null && radioSelectValue != undefined && radioSelectValue == item?.value}
                                radioGroup={groupName}
                                onChange={() => { onChangeValue(item?.value); setRadioSelectValue(item?.value); }}
                                value={item?.value}
                                name={name}
                                className={""}
                            />
                            {item?.text}
                        </label>
                    )
                ))}
            </div>
        </div>
    );
}
