import {Fragment, useState} from "react";
import {Link} from "react-router-dom";

export function SubServiceBox({linkTxt="عرض التفاصيل",small = true, link,img,titleEn="not set title",subtitleEn="not set subtitle",titleAr="غيار المحركات",subtitleAr="غيار المحركات"}) {
    const [lang,setlang]=useState( localStorage.getItem('i18nextLng'));
    let {titel}=lang==="en"?titleEn:titleAr;
    let image = "/assets/images/car-bg.png";
    image = img!== "null" ? "data:image/png;base64," +img :image;
    return (
        <Fragment>
            <Link to={link}>
                <div className={"box-shadow box  box-h-18 w-full " }>
                    <img src={image} alt="service" className="box-img object-cover"/>
                </div>
                <div className="text-center">
                    <h2 className={"text-20 font-bold mt-4 mb-2 text-line-clamp text-line-clamp-1"}>{titel}</h2>
                    <Link to={link} className="text-primary text-16 py-2 px-10 box-btn0">{linkTxt}</Link>
                </div>
            </Link>
        </Fragment>
    );
}