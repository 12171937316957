import React, {Fragment, useEffect, useRef, useState} from 'react'
import {Swiper, SwiperSlide} from "swiper/react";
import {Link} from "react-router-dom";
import config from "../../../configuration/config.json";

const CustomPopover = ({listMain, level2, level3}) => {
    const [lang, setLang] = useState(localStorage.getItem('i18nextLng'));

    const [isShowIndex, setIsShowIndex] = useState(null);

    const [CatMain, setCatMain] = useState(null);
    const [SuPCat, setSupCat] = useState(null);
    const [SSuPCat, setSSupCat] = useState(null);

    const [fCatSup1, setoptionCatSup] = useState([]);
    const [fCatSup2, setoptionCatSup2] = useState([]);

    const dropdownRef = useRef(null);
    const show = ({val}) => {
        setIsShowIndex(null);
        setIsShowIndex(val);
    };

    const [imageTitle, setImageTitle] = useState(null);
    const [imageTitleLevel1, setImageTitleLevel1] = useState(null);
    const [imageTitleLevel2, setImageTitleLevel2] = useState(null);


    // Function to handle click outside of dropdown
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !dropdownRef.current.parentNode.contains(event.target)) {
            // Click occurred outside of dropdown, close it
            setIsShowIndex(null);
        }
    };

    function filterCatSup1(option) {
        setCatMain(option);
        setSupCat(null);
        var filter = level2.filter(a => a.GroupIDMain == option);
        setoptionCatSup(level2.filter(a => a.GroupIDMain == option));
    }

    function filterCatSup2(option) {
        setSupCat(option);
        var filter = level3.filter(a => a.GroupIDMainSup1 === option.GroupIDMainSup1);
        setoptionCatSup2(level3.filter(a => a.GroupIDMainSup1 === option.GroupIDMainSup1));
        console.log("filter", filter);
    }

    function adjustDropdownPosition(button, drId, indexP) {
        const dropdown = document.getElementById(drId);

        var customPopover = document.getElementById("CustomPopover");
        // console.log("CustomPopover:offsetLeft",customPopover.target.offsetLeft)

        const {right, left} = customPopover.getBoundingClientRect();
        console.log('CustomPopover:offsetRight:', right);
        console.log('CustomPopover:offsetLeft:', left);
        // console.log("CustomPopover:offsetLeft",customPopover.target.offsetLeft)

        console.log("dropdown:", dropdown);

        const dropdownContent = document.getElementById(`dropdown-content-${indexP}`);
        console.info("dropdownContent", dropdownContent)
        const rect = dropdownContent.getBoundingClientRect();
        const rightSpace = right - rect.right;
        const leftSpace = left - rect.left;

        console.log("rect.right ", rect.right);
        console.log("rect.left ", rect.left);

        console.log("rightSpace ", rightSpace);
        console.log("leftSpace ", leftSpace);
        if (rightSpace < -1) {
            dropdownContent.style.left = `${rightSpace}px`;
        }

        if (leftSpace > 1) {
            // Dropdown is beyond right edge, move it left
            dropdownContent.style.right = `${leftSpace * -1}px`;
        }
    }
    
    const funcSetImage= ({typeLevel})=>{
        try {
            switch (typeLevel) {
                case "level1": 
                    setImageTitle(imageTitleLevel1);
                    break; 
                case "level2":
                    setImageTitle(imageTitleLevel2);
                    break;
                default:
                    setImageTitle("/assets/images/car-bg.png");
                    break;
            }
        }catch (ex) {
            console.log("funcSetImage:catch:>>>",ex);
        }
    };

    // Add event listener when component mounts
    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            // Cleanup: Remove event listener when component unmounts
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        // (showNav === "vehicle" || "hidden")
        <div ref={dropdownRef} key={"CustomPopover" + 1}>
            <Swiper
                slidesPerView={"auto"}
                // breakpoints={{
                //     576: {
                //         slidesPerView: 7,
                //     },
                //     900: {
                //         slidesPerView: 10,
                //     }
                //
                // }}
                spaceBetween={20}
                // centeredSlides={true}
                resizeObserver={"true"}
                watchSlidesVisibility
                watchSlidesProgress
                autoplay={false}
                loop={false}

            >

                {listMain.map((val, i) => {
                    return (
                        <SwiperSlide
                            className={"swiper-max-content"}
                        >

                            <div className="relative text-center rounded-md" id={`dr-${i}`}>

                                <button type="button" onClick={(e) => {
                                    show({val: i});
                                    filterCatSup1(val.GroupIDMain);
                                    setImageTitle(val.ImageUrl);

                                    setTimeout(() => {
                                        // getPops(e);
                                        adjustDropdownPosition(e, `dr-${i}`, i);
                                    }, 10)

                                }}
                                        className="inline-flex items-center text-gray-900 focus:border-green-900 rounded-lg py-2  underline-offset-8"
                                        aria-expanded="false"
                                >
                                        <span
                                            className={`text-4xl sm:text-3xl md:text-4xl lg:text-3xl xl:text-3xl hover:text-green-500  ${isShowIndex == i ? 'text-green-500' : ''}`}>
                                            {lang.toLowerCase() == "en" ? val?.DescriptionEn : val?.DescriptionAr}
                                        </span>
                                </button>


                                {
                                    (
                                        <div id={`dropdown-content-${i}`}
                                             className={` custom-popover-dropdown absolute z-40 mt-10 w-screen max-w-max -translate-x-1/2 px-8 ${isShowIndex == i ? ' ' : '  hidden '}`}>
                                            <div
                                                className="nice-relative max-w-3xl flex-auto overflow-hidden rounded-3xl bg-white text-20 leading-6 shadow-xl  ring-green-800">
                                                <div className="p-4">
                                                    <div
                                                        className="group relative flex items-start gap-x-6 rounded-lg p-4 hover:bg-gray-50">
                                                        <div className="w-2/3" style={{textAlign: "start"}}>

                                                            <ul className="list-none">
                                                                {
                                                                    <>
                                                                        <div
                                                                            className={`${SuPCat != null ? 'hidden' : ''}`}>
                                                                            <li className='underline mb-4 sm:text-4xl md:text-4xl lg:text-3xl xl:text-2xl text-3xl font-bold hover:text-green-500 cursor-pointer'>
                                                                                <Link
                                                                                    onClick={() => {
                                                                                        setIsShowIndex(null);
                                                                                    }}
                                                                                    to={"/eparts/sereach?" + "CatMain=" + CatMain + "&SuPCat=" + null + "&SSuPCat=" + null}
                                                                                    className="text-gray-900 my-3 cursor-pointer text-3xl sm:text-4xl lg:text-2xl hover:text-green-500"
                                                                                >
                                                                                    {lang.toLowerCase() == "en" ? val.DescriptionEn : val.DescriptionAr}
                                                                                </Link>
                                                                            </li>
                                                                            {
                                                                                fCatSup1.map((value, index) => {
                                                                                    return (
                                                                                        <li key={index}
                                                                                            className=" text-gray-900 my-5 cursor-pointer text-3xl sm:text-4xl lg:text-2xl hover:text-green-500"
                                                                                            onClick={() => {
                                                                                                filterCatSup2(value);
                                                                                                if(value.ImageUrl!= undefined && value.ImageUrl != null && value.ImageUrl!= ""  ){
                                                                                                    setImageTitle(value.ImageUrl);
                                                                                                }
                                                                                                // setImageTitle("https://appm.hassanjameel.com.sa:8070\assets\UrlImages\DBA63181-11EB-43AA-BE95-C38569099784rx.png")
                                                                                            }}
                                                                                        > {lang.toLowerCase() == "en" ? value.DescriptionEn : value.DescriptionAr}
                                                                                            {/*<span className="absolute inset-0"></span>*/}
                                                                                        </li>
                                                                                    );
                                                                                })
                                                                            }
                                                                        </div>
                                                                        <div
                                                                            className={`${SuPCat == null ? 'hidden' : ''}`}>
                                                                            <li key={-2} className=''>
                                                                                <div
                                                                                    className="flex gap-y-3 gap-x-4 ">
                                                                                    <button
                                                                                        onClick={() => setSupCat(null)}
                                                                                        className="w-12 h-12 flex items-center justify-center bg-gray-400 hover:bg-green-400 text-white font-bold rounded-full">
                                                                                        <img
                                                                                            className="w-8 h-8 rotate-180 justify-self-center"
                                                                                            src="/assets/icons/arrow-light.svg"
                                                                                            alt="show"/>
                                                                                    </button>

                                                                                    <div
                                                                                        className="grow h-14 underline mb-4 sm:text-4xl md:text-4xl lg:text-3xl xl:text-2xl text-3xl font-bold hover:text-green-500 cursor-pointer">
                                                                                        <Link
                                                                                            onClick={() => setIsShowIndex(null)}
                                                                                            to={"/eparts/sereach?" + "CatMain=" + CatMain + "&SuPCat=" + SuPCat?.GroupIDMainSup1 + "&SSuPCat=" + null}
                                                                                            className="text-gray-900 my-3 cursor-pointer text-3xl sm:text-4xl lg:text-2xl hover:text-green-500"
                                                                                        >
                                                                                            {lang.toLowerCase() == "en" ? SuPCat?.DescriptionEn : SuPCat?.DescriptionAr}
                                                                                        </Link>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                            {
                                                                                fCatSup2.map((valLevel2, indexLevel2) => {
                                                                                    return (
                                                                                        <li key={indexLevel2}
                                                                                            className="my-5 cursor-pointer">
                                                                                            <Link
                                                                                                onClick={() => setIsShowIndex(null)}
                                                                                                to={"/eparts/sereach?" + "CatMain=" + CatMain + "&SuPCat=" + SuPCat?.GroupIDMainSup1 + "&SSuPCat=" + valLevel2.GroupIDMainSup2}
                                                                                                className="text-gray-900 my-3 cursor-pointer text-3xl sm:text-4xl lg:text-2xl hover:text-green-500"
                                                                                            >
                                                                                                {lang.toLowerCase() == "en" ? valLevel2?.DescriptionEn : valLevel2?.DescriptionAr}
                                                                                            </Link>
                                                                                        </li>
                                                                                    );
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </>
                                                                }
                                                            </ul>
                                                        </div>
                                                        <div
                                                            className="w-1/2 flex-none bg-gray-50 group-hover:bg-white">
                                                            {
                                                                imageTitle && (
                                                                    <img
                                                                        src={ config.controllURL + imageTitle}
                                                                        alt={"alt"} className=""
                                                                    />
                                                                )
                                                            }
                                                            {
                                                                !imageTitle && (
                                                                    <img
                                                                        src="/assets/images/default.png"
                                                                        alt={"alt"} className=""
                                                                    />
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }

                            </div>

                        </SwiperSlide>
                    );

                })}

            </Swiper>
        </div>
    )
}

export default CustomPopover;