import { useEffect, useState } from "react";
import SectionHeading from "../components/sectionHeading";
import { useTranslation } from "react-i18next";
import { useUserFavorite } from "./hooks/useUserFavorite";
import FavoriteItem from "./components/favoriteItem";
import {slugCombine} from '../utils/slugManager';
export const FavoritePage = () => {
    const { t: trans } = useTranslation(['global', 'model']);
    const {favoriteItemsDetails,changeFavoriteItemState} = useUserFavorite();
    const [favoriteGroups,setFavoriteGroups]  = useState([]);
    const [freshFavoriteGroups,setFreshFavoriteGroups] = useState([]);
    useEffect(async ()=>{
    const data = await favoriteItemsDetails(true); 
    setFavoriteGroups(data);
    setFreshFavoriteGroups(data);
    },[]);
    const urlBilder = (type,value)=>{
     let urlValue = '';
     switch(type.toLowerCase()){
         case 'car':
          urlValue = `/vehicles/${slugCombine(value.BrandDescriptionAr,value.BrandDescriptionEn)}/${value.GUID}`;
         break;
         case 'spare':
         urlValue = `/eparts/detail/${value.GUID}`;
         break;
     }
     return urlValue;
    };
    const handleDeleteItem = (type,item)=>{
     changeFavoriteItemState(item);
     let tempData = favoriteGroups;
     let tempData2 = favoriteGroups;
     let parentArray = [];
     for (let index = 0 ; index < tempData?.length ; index++) {
       const parentValue = {...tempData2[index]};  
      if(parentValue.type == type){ 
         if(parentValue.items.length == 0){
           delete tempData2[index];
         }else{
            const innerItems = parentValue.items.filter(childItem=> { return childItem.ProductID !== item});
            if(innerItems?.length == 0){
                delete tempData2[index];
                return;
            }
            parentValue.items = innerItems;
            parentArray.push(parentValue);
         }
      }else{
        parentArray.push(parentValue); 
      }
     }
     setFavoriteGroups(parentArray);
     setFreshFavoriteGroups(parentArray);
    };
    return( 
    <>
        {
            freshFavoriteGroups.map((parentItem, index) => {
                return <>
                    <SectionHeading
                        key={index}
                        hasRuler={false}
                        title={parentItem.heading}
                    >
                        <div className="nice-row my-8">
                            {
                             parentItem?.items?.map((childItem,index)=>{
                              return (
                                <div key={index} className="nice-col-md-3 nice-col-sm-6 mt-4 my-5">
                                <FavoriteItem 
                                onClick={(value)=>{handleDeleteItem(parentItem.type,value)}}
                                productID={childItem.ProductID} 
                                productTitle={parentItem.type == 'car'? '':trans("new:product_no")}
                                productValue = {parentItem.type == 'car'? childItem[`Title${trans("model:description")}`] : childItem?.ProductNo}
                                subProductValue={childItem[`${trans("model:description")}`]}
                                productImage ={childItem.ProductImage}
                                price={childItem.SalesPrice}
                                to={urlBilder(parentItem.type,childItem)}
                                showPric ={parentItem?.showPric}
                                />    
                             </div>
                              )
                             })
                            }
                        </div>
    
                    </SectionHeading>
                </>
            })
        }
    </>)
    
};