import config from "../../../configuration/config.json";
import { Base64 } from 'js-base64';
export default function ContactTile({ icon, name, value, type }) {

    return (
        <div className={"contact-tile"}>

            <div className="contact-tile-icon">
                <img src={(Base64.isValid(icon) == 1 ? "data:image/png;base64,":config.mainURL )+""+icon} className={"object-full object-contain"} alt="" />
            </div>
            <div className="contact-tile-info">

                <div className="contact-tile-key">{name}</div>
                <a href={type != null ? type + value : null} className="contact-tile-value">{value}</a>
            </div>
        </div>
    );
}