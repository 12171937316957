import Rating from "react-rating";
export function PartRating({onChange , readonly = false , intialValue = 0}) {
    return (
        <Rating
            initialRating={intialValue}
            readonly = {readonly}
            className={"rating"}
            direction={"rtl"}
            onChange = {(value)=>{
                if(onChange){
                    onChange(value);
                }
            }}
            emptySymbol={<img src="/assets/icons/star-empty.svg" className="icon"/>}
            fullSymbol={<img src="/assets/icons/star-full.svg" className="icon"/>}
        />
    );
}