import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {CarBanner} from "../components/carBanner";
import axios from "axios";
import {Route, Switch, useLocation, useParams, useRouteMatch} from "react-router";
import {useDispatch} from "react-redux";
import {VehicleOfferPage} from "./offers";
import {VehicleSpecsPage} from "./specs";
import {VehicleFeaturePage} from "./features";
import {VehicleGalleryPage} from "./gallery";
import {VehicleOverViewPage} from "./overview";
import ConfirmContext from "../../../utils/confirmContext";
import {useContext, useCallback} from "react";
import OverViewContext from "../overviewContext";
import config from "../../../configuration/config.json";
import {slugSplitter} from '../../../utils/slugManager';
import {Helmet} from "react-helmet";

export function VehicleDetailPage() {
    const {t: trans} = useTranslation(["heading", "info", "vehicales"]);
    let {path, url} = useRouteMatch();
    const dispatch = useDispatch();
    const {slug, brand, section, modelslug, group, year} = useParams();
    const [vehicle, setVehicle] = useState({});
    const [isLoding, setIsLoding] = useState(true);
    const [sectionActive, setSectionActive] = useState("public");
    let lang = localStorage.getItem("i18nextLng");
    const context = useContext(ConfirmContext);
    const [vehicleInfo, setVehicleInfo] = useState({
        name: null,
        isNew: false,
        isHybird: false,
        product: null,
        group: null,
        price: null,
        tax: null,
        discount: 0,
        modelTypes: null
    });


    const location = useLocation();
    const query = new URLSearchParams(location.search);

    const handleChangeOverViewContent = (data) => {
        setVehicleInfo(data);
    };

    const callback = useCallback(async () => {
        try {
            setVehicle(null)


            try {
                if ("caches" in window) {
                    // var curl = lang + "/api/VehicleOverview/" + 'يارس' + "/" + '2024' + "/overview";
                    const curl = lang + "/api/VehicleOverview/" + group + "/" + year + "/overview";

                    // Opening that particular cache
                    const cacheStorage = await caches.open("VehicleDetailPage");

                    // Fetching that particular cache data
                    const cachedResponse = await cacheStorage.match(
                        curl
                    );
                    var datacash = await cachedResponse?.json();

                    if (datacash) {

                        setVehicle(datacash)
                    }


                }
            } catch (error) {

            }


            const response = await axios.request({
                baseURL: config.controllURL,
                // url: lang + "/api/VehicleOverview/" + 'YARIS' + "/" + '2024'  + "/overview",
                url: lang + "/api/VehicleOverview/" + group + "/" + year + "/overview",
                method: "get",
            })
            setVehicle(response.data.vehicle)

            const dataResponse = new Response(JSON.stringify(response.data.vehicle));

            if ("caches" in window) {
                // Opening given cache and putting our data into it
                caches.open("VehicleDetailPage").then((cache) => {
                    // cache.put(lang + "/api/VehicleOverview/" + slugSplitter(brand) + "/" + slug + "/overview", dataResponse);
                    cache.put(lang + "/api/VehicleOverview/" + group + "/" + year + "/overview", dataResponse);

                });
            }

        } catch (error) {
            console.log(error);
        }
    }, [group]);

    const handleSetSectionActive = (section) => {
        setSectionActive(section);
    };


    useEffect(() => {

        console.log("section>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>:", section);
        if (section)
            setSectionActive(section);
    }, [section]);

    useEffect(() => {
        callback();
    }, [group]);

    const [seoPageInfo, setSeoPageInfo] = useState({});
    const siteFetchSeoCars = async () => {
        const response = await axios.post(
            config.baseURL + 'func',
            {
                "Name": "SiteFetchSeoCars",
                "Values": {
                    "GroupID": group,
                    "Year": year
                }
            }
        );

        if (response.status == 200) {
            const resData = response.data[0];
            setSeoPageInfo(resData);
        }
    }

    useEffect(() => {
        siteFetchSeoCars()
    }, [group, year]);


    return (
      <>
          { seoPageInfo &&
              <Helmet>
                  <meta charSet="utf-8" />
                  {
                      seoPageInfo?.title &&
                      <title>{lang.toLowerCase() == 'ar' ?  seoPageInfo?.title : seoPageInfo?.titleEn?? seoPageInfo?.title }</title>
                  }
                  {
                      seoPageInfo?.link &&
                      <link rel="canonical" href={`${window.location.origin}${location.pathname}${location.search}`} />

                  }
                  {
                      seoPageInfo?.MetaDescription &&
                      <meta name="description" content={lang.toLowerCase() == 'ar'? seoPageInfo?.MetaDescription : seoPageInfo?.MetaDescriptionEn ?? seoPageInfo?.MetaDescription } />
                  }
                  {
                      seoPageInfo?.MetaGroups &&
                      <meta name="keywords" content={lang.toLowerCase() == 'ar'? seoPageInfo?.MetaGroups : seoPageInfo?.MetaGroupsEn ?? seoPageInfo?.MetaGroups } />
                  }
              </Helmet>
          }
          
          <OverViewContext.Provider value={{vehicleInfo, handleChangeOverViewContent}}>
              <CarBanner
                  pdf={vehicle?.userManual}
                  url={"/vehicles/" + slugSplitter(brand) + "/" + group + "/" + year}
                  breadcrumb={sectionActive ? trans(`vehicales:veh-d-home-${sectionActive}`) : trans('vehicales:veh-d-home-public')}
                  bg={vehicle?.backgroundPath}
                  name={vehicle?.name}
                  brand={vehicle?.brand}
                  desc={vehicle?.desc}
                  hasOffers={vehicle?.hasOffers}
                  logo={vehicle?.logo}
                  siteDesc={vehicle?.siteDesc}
                  setSectionActive={(section) => {

                      handleSetSectionActive(section);

                  }}
              />
              {/* <Switch>
                <Route exact path={path} component={VehicleOverViewPage} />
                <Route path={`${path}/offers`} component={VehicleOfferPage} />
                <Route path={`${path}/specs`} component={VehicleSpecsPage} />
                <Route path={`${path}/feature`} component={VehicleFeaturePage} />
                <Route path={`${path}/gallery`} component={VehicleGalleryPage} />
            </Switch> */}

              {
                  <>
                      <div className={" "} style={{display: (sectionActive == "public") ? "block" : "none"}}>
                          <VehicleOverViewPage
                              mainurl={"/vehicles/" + slugSplitter(brand) + "/" + group + "/" + year} /////
                              setIsLoding={(v) => setIsLoding(v)}
                              setSectionActive={(section) => {

                                  handleSetSectionActive(section);

                              }}
                              currntvehicle={vehicle}
                          />
                      </div>

                      {

                          isLoding == false &&
                          <>
                              {/* <div className={" "} style={{ display: (sectionActive == "offers") ? "block" : "none" }}>
                                <VehicleOfferPage />
                            </div> */}
                              <div className={" "} style={{display: (sectionActive == "specs") ? "block" : "none"}}>
                                  <VehicleSpecsPage
                                      modelslug={modelslug}

                                      vehicle={vehicle}
                                  />
                              </div>
                              <div className={" "} style={{display: (sectionActive == "feature") ? "block" : "none"}}>
                                  <VehicleFeaturePage/>
                              </div>
                              <div className={" "} style={{display: (sectionActive == "gallery") ? "block" : "none"}}>
                                  <VehicleGalleryPage group={group} year={year}/>
                              </div>

                          </>
                      }

                  </>
              }
          </OverViewContext.Provider>
      </>
    );
}