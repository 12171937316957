import { useEffect, useState, useRef,useCallback } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Popup from "../../User/popup";
import { DropDownInput } from "../../components/_input/_dropdown";
import { TextArea } from "../../components/_input/_textArea";
import { DateInput3 } from "../../components/_input/_dateInput_3";
import { InputField } from "../../components/_input/_input";
import { Route, Switch, useRouteMatch } from "react-router";
import PhoneDropDown from "../../components/_input/_phoneDropDown";
import config from '../../configuration/config.json';
import MainSericePage from '../maintenance/subpages/mainServicePage';
import MaintenanceContext from "./maintenanceContext";
import SubMainServicePage from "./subpages/subMainServicePage";
import SubServicePage from "./subpages/subServicePage";
import ServicePage from "./subpages/servicePage";
import { ServiceReservation } from "./serviceReservation";
import { ServiceReservationByID } from "./serviceReservationByID";
import { ProtectionAndShading } from "./protectionAndShading";
import DatePicker from 'react-datepicker';
import { useHistory, useLocation } from "react-router";
import { GetMessages, formValidate } from '../../utils/validation';
import Joi from "joi";
import { useUserCart } from '../../User/hooks/useUserCart';
import { getData, User_Data_Key } from "../../utils/localStorageManager";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
export function MaintenancePage() {
    const { t: trans, i18n } = useTranslation(["info", "eparts"]);
    const { path } = useRouteMatch();
    let lang = i18n.language;
    const [showFinalPopup, setShowFinalPopup] = useState(false);
    const popRef = useRef(null);
    const [resOrderID, setResOrderID] = useState(null);
    const history = useHistory();
    const { changecartItem } = useUserCart();
    const [stepNum, setStepNum] = useState(1);
    const [dataFullNum, setDataFullNum] = useState(0);
    const [ServicesDataJson, setServiceDataJson] = useState({});
    const location = useLocation();

    useEffect(() => {

        function handleClickOutside(event) {
            if (popRef.current && !popRef.current.contains(event.target) && showFinalPopup === true) {
                setShowFinalPopup(false);
                setResOrderID(null);
                if (location.pathname)
                    history.push(location.pathname);

            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showFinalPopup]);


    //--------------------------------------------------
    const [maintenanceObj, setMaintenanceObj] = useState({
        showPopup: false, type: "Notset", main: "", sub1: "", sub2: ""
    });
    const [Defult, setDefult] = useState({
        showPopup: false, type: "Notset", main: "", sub1: "", sub2: ""
    });

    const [infoPrice, setinfoPrice] = useState([{ Price: 0 }]);
    const [isLogin, setLogin] = useState(false);
    const [isSendeng, setIsSendeng] = useState(false);
    const [requestForm, setRequestForm] = useState({
        lastName: '',
        firstName: '',
        phone: '',
        email: '',
        subject: '',
        date: '',
        time: '',
        messageContent: '',
        myCarID: '',
        SiteID: null,
    });
    const LoginSchema = Joi.object({
        date: Joi.date().required().messages(GetMessages()),
        time: Joi.date().required().messages(GetMessages()),
        SerciveID: Joi.string().required().messages(GetMessages()),
        SiteID: Joi.string().required().messages(GetMessages()),
    });
    const notLoginSchema = Joi.object({
        date: Joi.date().required().messages(GetMessages()),
        time: Joi.date().required().messages(GetMessages()),
        SiteID: Joi.string().required().messages(GetMessages()),
        SerciveID: Joi.string().required().messages(GetMessages()),
        firstName: Joi.string().required().messages(GetMessages()),
        lastName: Joi.string().required().messages(GetMessages()),
        phone: Joi.string().required().messages(GetMessages()),
        year: Joi.string().required().messages(GetMessages())
    });


    const [filteredSettings, setFilteredSettings] = useState({
        brands: [],
        groups: [],
        models: [],
        productModels: []
    });


    const [settings, setSettings] = useState({
        brands: [],
        groups: [],
        models: [],
        productModels: []
    });
    const [brand, setBrand] = useState(null);
    const [type, setType] = useState(null);
    const [groupData, setGroupData] = useState([]);
    const [group, setGroup] = useState(null);
    const [vModelCode, setVModelCode] = useState([]);
    const [year, setYear] = useState(null);
    const handleFilteredModels = (option, vYear = year) => {

        setGroup(null);
        setType(null);
        setFilteredSettings({ ...filteredSettings, models: [], productModels: [] });
        setGroup(option);
        var fModels = [];
        try {

            const currentgroupData = settings?.groups?.filter(a => a.brandID == brand && a.productGroupID == option && a.Year == vYear);
            const ListTreeGroups = (currentgroupData && currentgroupData?.length > 0) ? currentgroupData[0].ListTreeGroups : option;

            // Split the comma-separated string into an array
            const listVArray = ListTreeGroups.split(',');

            // Assuming settings.productModels is an array of objects with properties productGroupID and Model
            fModels = settings?.productModels?.filter(a =>
                listVArray.includes(a.productGroupID) && a.Model == vYear
            );
        } catch {

            fModels = settings?.productModels?.filter(a => a.productGroupID == option && a.Model == vYear);
        }





        setFilteredSettings({ ...filteredSettings, productModels: fModels });

    };

    const handelSelectBrand = (option, vYear = year) => {
        setBrand(null);
        setType(null);
        setGroup(null);

        setFilteredSettings({ ...settings, groups: [], models: [], productModels: [] });
        setGroupData([]);
        setBrand(option);


        const fGroups = settings?.groups?.filter(a => a.brandID == option && a.Year == vYear);

        setFilteredSettings({ ...settings, groups: fGroups, models: [], productModels: [] });
        setGroupData(fGroups);

    };


    useEffect(() => {


        // && filteredSettings?.groups?.length == 0
        if (settings?.brands && settings?.brands.length > 0 && Defult?.first != true
        ) {

            if (Defult?.year) {
                setYear(Defult?.year)
                const val = { ...requestForm };
                val.year = Defult?.year;
                setRequestForm(val);
            }

            if (Defult?.brand)
                handelSelectBrand(Defult?.brand, Defult?.year);
        }

    }, [Defult, settings]);

    const [fullDatetime, setFullDatetime] = useState(null);
    useEffect(() => {

        try {

            if (requestForm?.date) {

                var md = new Date(requestForm?.date);
                try {
                    if (requestForm?.time) {

                        var Hours = requestForm?.time.split(":");

                        md.setHours(Hours[0], Hours[1], 0);
                    }
                } catch (error) {

                }

                // var datetime =  new moment(md).format('yyyy-MM-DD HH:mm:ss')
                var datetime = md.toLocaleDateString("en-GB", { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).replaceAll(',', '').replaceAll('/', '-');
                setFullDatetime(datetime);
                console.log();
            }

        } catch (error) {
        }


    }, [requestForm]);

    useEffect(() => {
        if (settings?.brands && settings?.brands.length > 0 && filteredSettings?.groups?.length > 0 && Defult?.first != true) {

            setDefult({ ...Defult, first: true });
            if (Defult?.group)
                handleFilteredModels(Defult?.group);
            // if (Defult?.year) {
            //     setYear(Defult?.year)
            //     const val = { ...requestForm };
            //     val.year = Defult?.year;
            //     setRequestForm(val);
            // }

            if (Defult?.modelType) {
                setType(Defult?.modelType);
            }
        }
    }, [filteredSettings]);


    useEffect(() => {
        getMainUserData();
    }, []);
    const getMainUserData = async () => {
        let data = {};

        try {
            data = JSON.parse(getData(User_Data_Key, true));
        } catch (error) {

        }
        try {

            if (getData('isAuthenticated'))
                setLogin(true);
        } catch (err) {
            //////console.log(err);
            setLogin(false);
        }



        return data;
    };

    const fetchSettings = async () => {
        try {

            if ("caches" in window) {
                var curl = "Settings-" + lang;

                // Opening that particular cache
                const cacheStorage = await caches.open("HJWeb");

                // Fetching that particular cache data
                const cachedResponse = await cacheStorage.match(
                    curl
                );
                var settingResponseCash = await cachedResponse?.json();

                const currentSettings = { ...settings };
                currentSettings.brands = settingResponseCash?.brands;
                currentSettings.groups = settingResponseCash?.groups;
                currentSettings.models = settingResponseCash?.models;
                currentSettings.productModels = settingResponseCash?.productModels;
                setSettings(currentSettings);

                const fGroups = currentSettings?.groups?.filter(a => a.brandID == brand);
                setGroupData(fGroups);
                //---------------------------------------------------------------
                let currentvModelCode = {};
                currentSettings?.productModels?.map((item) => {
                    if (item.ModelCode) {
                        currentvModelCode[item.productTypeID] = item.ModelCode;
                    }
                })
                setVModelCode(currentvModelCode);
            }


            const settingResponse = await axios.request({
                baseURL: '/',
                url: config.controllURL + lang + "/api/Maintenance/Settings",
                method: "get"
            });

            const currentSettings = { ...settings };
            currentSettings.brands = settingResponse.data?.brands;
            currentSettings.groups = settingResponse.data?.groups;
            currentSettings.models = settingResponse.data?.models;
            currentSettings.productModels = settingResponse.data?.productModels;

            setSettings(currentSettings);

            const fGroups = currentSettings?.groups?.filter(a => a.brandID == brand);
            setGroupData(fGroups);
            //---------------------------------------------------------------

            let currentvModelCode = {};
            currentSettings?.productModels?.map((item) => {
                currentvModelCode[item.productTypeID] = item.ModelCode

            })

            //////console.log("currentvModelCode>>>>>>>>>", currentvModelCode);
            setVModelCode(currentvModelCode);

            const data = new Response(JSON.stringify(settingResponse.data));

            if ("caches" in window) {
                // Opening given cache and putting our data into it
                caches.open("HJWeb").then((cache) => {
                    cache.put("Settings-" + lang, data);

                });
            }

        } catch (error) {
            //////console.log(error);
        }

    };

  
    const handleServiceOrder = useCallback(async (data) => {

        try {
            data['firstFetchPrice'] = null;
            data['first'] = null;


            var serviceOptions = data.serviceOptions;
            var objectservicesJON = {};
            if (serviceOptions && serviceOptions.length > 0 && data.sub2) {
                objectservicesJON[`${data.sub2}`] = serviceOptions;
                setServiceDataJson(objectservicesJON);
            }
            setSerciveID(null);

            setRequestForm({
                lastName: null,
                firstName: null,
                phone: null,
                email: null,
                subject: null,
                date: null,
                time: null,
                messageContent: null,
                myCarID: null,
            });

            setStepNum(1);
            setDataFullNum(0);

            setselsub0({ ID: "0", desAr: "اختيار  قيمة", desEn: "chose" });
            setService([]);
            setinfoPrice([{ Price: 0 }]);

            var value = { ...maintenanceObj, ...data };
            if (data?.main == '') {

                data['main'] = null;
            }
            if (data?.sub1 == '') {

                data['sub1'] = null;
            }
            if (data?.sub2 == '') {

                data['sub2'] = null;


            }

            setMainServiceID(data?.main);
            setSubServiceID(data?.sub1);
            setTypeServiceID(data?.sub2);
            setDefult(data);

            if (getData('isAuthenticated') && data?.modelType == null) {
                GetVehicales();

            } else {

                fetchSettings();
            }

            fetchMainService(data);
            GetBranch();

            setSubService1([]);
            setSubService2([]);
            setService([]);

            if (value.type === "order") {

                setselMain({ ID: "0", desAr: "اختيار قيمة", desEn: "chose" });
                setselsub1({ ID: "0", desAr: "اختيار  قيمة", desEn: "chose" });
                setselsub2({ ID: "0", desAr: "اختيار  قيمة", desEn: "chose" });

            }
            setTimeout(() => {
                if (value.type === 'main') {
                    var main = { value: data.main, sub1: data.sub1 }
                    fetchSubService(data);
                    setselsub2({ ID: "0", desAr: "اختيار  قيمة", desEn: "chose" });

                } else if (value.type === 'sub1') {
                    var main = { value: data.main }
                    fetchSubService(data);
                    var sub1 = { value: data.sub1, ServiceID: data?.ServiceID }
                    fetchSubService2(data);

                } else if (value.type == 'sub2') {
        
                    var main = { value: data.main }
                    fetchSubService(data);
                    var sub1 = { value: data.sub1, ServiceID: data?.ServiceID }
                    fetchSubService2(data,objectservicesJON);
                    var sub2 = { value: data.sub2, ServiceID: data?.ServiceID }
                   
                    fetchService(sub2, null, objectservicesJON);


                }
                setTimeout(() => { setMaintenanceObj(value) }, 1000);

            }, 0,[ServicesDataJson]);
        } catch (e) {
            console.error(e)

        }





   
    }, [ServicesDataJson]);
    const [Note, setNote] = useState("");

    const [MainServic, setMainServic] = useState([]);
    async function fetchMainService(opiton) {

        try {
            if ("caches" in window) {
                var curl = "site_fetchMainService_Req";

                // Opening that particular cache
                const cacheStorage = await caches.open("HJWeb");

                // Fetching that particular cache data
                const cachedResponse = await cacheStorage.match(
                    curl
                );
                var datacash = await cachedResponse?.json();
                if (datacash && datacash?.length > 0) {

                    setTimeout(setMainServic(datacash), 1000);
                    if (opiton.main)
                        datacash?.map((B) => {
                            if (B.MainServiceID === opiton.main) {
                                const v = selMain;
                                v.desAr = B.DescriptionAr;
                                v.desEn = B.DescriptionEn;
                                v.ID = B.MainServiceID;
                                // setselMain(v);
                                ////////console.log(v);
                            }
                        })
                }



            }
        } catch (error) {

        }

        const Body = {
            "name": "site_fetchMainService_Req"
        }

        axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {
                // //////console.log(response.data.ApiObjectData);
                setTimeout(setMainServic(response.data), 1000);
                if (opiton.main)
                    response.data.map((B) => {
                        if (B.MainServiceID === opiton.main) {
                            const v = selMain;
                            v.desAr = B.DescriptionAr;
                            v.desEn = B.DescriptionEn;
                            v.ID = B.MainServiceID;
                            // setselMain(v);
                            ////////console.log(v);
                        }
                    })

                const data = new Response(JSON.stringify(response.data));

                if ("caches" in window) {
                    // Opening given cache and putting our data into it
                    caches.open("HJWeb").then((cache) => {
                        cache.put("site_fetchMainService_Req", data);

                    });
                }


            })
            .catch(function (error) {
                //////console.log(error);
            });



    }
    const [SubService1, setSubService1] = useState([]);
    async function fetchSubService(option) {


        setselsub1({ ID: "0", desAr: "اختيار  قيمة", desEn: "chose" });
        setselsub2({ ID: "0", desAr: "اختيار  قيمة", desEn: "chose" });
        setselsub0({ ID: "0", desAr: "اختيار  قيمة", desEn: "chose" });
        setSubService1([]);
        setSubService2([]);
        setService([]);
        setinfoPrice([{ Price: 0 }]);
        var SID = option.main ? option.main : option.value;

        // if (SID != "9ceb8b65-14a4-4a61-9223-a06ae0db53cf") {


        if ("caches" in window) {
            var curl = "site_fetchMntc_SubService1-" + SID;

            // Opening that particular cache
            const cacheStorage = await caches.open("HJWeb");

            // Fetching that particular cache data
            const cachedResponse = await cacheStorage.match(
                curl
            );
            var settingResponseCash = await cachedResponse?.json();

            if (settingResponseCash && settingResponseCash?.length > 0) {
                setTimeout(setSubService1(settingResponseCash), 1000);

                if (settingResponseCash.length == 1 || SID == "9ceb8b65-14a4-4a61-9223-a06ae0db53cf") {

                    ////console.log("settingResponseCash>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>:", settingResponseCash);
                    if (SID == "9ceb8b65-14a4-4a61-9223-a06ae0db53cf" && settingResponseCash && settingResponseCash[1]) {
                        setFirestOptionService1(settingResponseCash[0])
                    } else {

                        setFirestOptionService1(settingResponseCash[0])
                    }
                }
                if (option.sub1)
                    settingResponseCash?.map((B) => {
                        if (B.SubService1ID === option.sub1) {
                            const v = selsub1;
                            v.desAr = B.DescriptionAr;
                            v.desEn = B.DescriptionEn;
                            v.ID = B.SubService1ID;
                            setselsub1(v);

                        }
                    }
                    )
            }



        }

        const Body = {
            "name": "site_fetchMntc_SubService1",
            "values": {
                "MainID": option.main ? option.main : option.value
            }
        }

        axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {
                // //////console.log(response.data.ApiObjectData);
                setTimeout(setSubService1(response.data), 1000);

                if (response?.data.length == 1 || SID == "9ceb8b65-14a4-4a61-9223-a06ae0db53cf") {

                    if (SID == "9ceb8b65-14a4-4a61-9223-a06ae0db53cf") {
                        setFirestOptionService1(response?.data[0])
                    } else {

                        setFirestOptionService1(response?.data[0])
                    }
                }
                if (option.sub1)
                    response.data.map((B) => {
                        if (B.SubService1ID === option.sub1) {
                            const v = selsub1;
                            v.desAr = B.DescriptionAr;
                            v.desEn = B.DescriptionEn;
                            v.ID = B.SubService1ID;
                            setselsub1(v);

                        }
                    }
                    )


                const data = new Response(JSON.stringify(response.data));

                if ("caches" in window) {
                    // Opening given cache and putting our data into it
                    caches.open("HJWeb").then((cache) => {
                        cache.put("site_fetchMntc_SubService1-" + SID, data);

                    });
                }



            })
            .catch(function (error) {
                //////console.log(error);
            });


        // }
    }
    const [SubService2, setSubService2] = useState([]);
    async function fetchSubService2(option,objectservicesJON) {


        setselsub2({ ID: "0", desAr: "اختيار  قيمة", desEn: "chose" });
        setselsub0({ ID: "0", desAr: "اختيار  قيمة", desEn: "chose" });
        setSubService2([]);
        setService([]);
        setinfoPrice([{ Price: 0 }]);

        var getFirestOption = false;

        var SID = option.sub1 ? option.sub1 : option.value;

        //////console.log("fetchSubService2PPPPPPPPPPPPPPPPPPPPPPPPPP?",SID);
        if ("caches" in window) {
            var curl = "site_fetchMntc_SubService2-" + SID;

            // Opening that particular cache
            const cacheStorage = await caches.open("HJWeb");

            // Fetching that particular cache data
            const cachedResponse = await cacheStorage.match(
                curl
            );
            var settingResponseCash = await cachedResponse?.json();

            if (settingResponseCash && settingResponseCash?.length > 0) {
                setTimeout(setSubService2(settingResponseCash), 100);
                if (settingResponseCash?.length == 1 || SID == "a4df3008-2236-4455-854b-da6ddd592364" || SID == "22d24d95-04cd-4c88-88ec-317d940231e0"
                    || SID == "619b5424-1a20-4e01-950a-a8d87f9f1c7e") {
                    getFirestOption = true;


                    if (SID == "a4df3008-2236-4455-854b-da6ddd592364" || SID == "22d24d95-04cd-4c88-88ec-317d940231e0" || SID == "619b5424-1a20-4e01-950a-a8d87f9f1c7e") {
                        setFirestOption(settingResponseCash[1], SID, option?.SerciveID,objectservicesJON)

                    } else {

                        setFirestOption(settingResponseCash[0], SID, option?.SerciveID,objectservicesJON)
                    }
                }

                if (option.sub2)
                    settingResponseCash?.map((B) => {
                        if (B.SubService2ID === option.sub2) {
                            const v = selsub2;
                            v.desAr = B.DescriptionAr;
                            v.desEn = B.DescriptionEn;
                            v.ID = B.SubService2ID;
                            setselsub2(v);
                            ////////console.log(v);
                        }
                    })
            }



        }
        const Body = {
            "name": "site_fetchMntc_SubService2",
            "values": {
                "SubID": option.sub1 ? option.sub1 : option.value
            }
        }

        axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {

                setTimeout(setSubService2(response.data), 100);
                if (response?.data.length == 1 || SID == "a4df3008-2236-4455-854b-da6ddd592364" || SID == "22d24d95-04cd-4c88-88ec-317d940231e0"
                    || SID == "619b5424-1a20-4e01-950a-a8d87f9f1c7e") {

                    if (getFirestOption == false) {


                        if (SID == "a4df3008-2236-4455-854b-da6ddd592364" || SID == "22d24d95-04cd-4c88-88ec-317d940231e0"
                            || SID == "619b5424-1a20-4e01-950a-a8d87f9f1c7e") {
                            setFirestOption(response?.data[1], SID, option?.SerciveID,objectservicesJON)

                        } else {

                            setFirestOption(response?.data[0], SID, option?.SerciveID,objectservicesJON)
                        }

                    }

                }

                if (option.sub2)
                    response.data.map((B) => {
                        if (B.SubService2ID === option.sub2) {
                            const v = selsub2;
                            v.desAr = B.DescriptionAr;
                            v.desEn = B.DescriptionEn;
                            v.ID = B.SubService2ID;
                            setselsub2(v);
                            ////////console.log(v);
                        }
                    })

                const data = new Response(JSON.stringify(response.data));

                if ("caches" in window) {
                    // Opening given cache and putting our data into it
                    caches.open("HJWeb").then((cache) => {
                        cache.put("site_fetchMntc_SubService2-" + SID, data);

                    });
                }

            })
            .catch(function (error) {
                //////console.log(error);
            });



    }
  
    const [Service, setService] = useState([]);
    const [lodingService, setLodingService] = useState(false);
    async function fetchService(option, subServiceID, objectservicesJON = ServicesDataJson) {
        setselsub0({ ID: "0", desAr: "اختيار  قيمة", desEn: "chose" });
        setService([]);
        setinfoPrice([{ Price: 0 }]);

        setLodingService(true);

        if (objectservicesJON[option.value]) {

            var responseData = objectservicesJON[option.value];
            setTimeout(setService(responseData), 1000);
            if (responseData.length == 1 || subServiceID == "a4df3008-2236-4455-854b-da6ddd592364" || subServiceID == "22d24d95-04cd-4c88-88ec-317d940231e0"
                || subServiceID == "619b5424-1a20-4e01-950a-a8d87f9f1c7e") {
                setSerciveID(responseData[0].ServiceID);
                const val = { ...requestForm };
                val.SerciveID = responseData[0].ServiceID;
                setRequestForm(val);

            }

            if (option?.ServiceID) {
                setSerciveID(option?.ServiceID)
                const val = { ...requestForm };
                val.SerciveID = option?.ServiceID;
                setRequestForm(val);
                responseData.map((B) => {
                    if (B.ServiceID === option?.ServiceID) {
                        const v = selsub0;
                        v.desAr = B.ServiceNameAR;
                        v.desEn = B.ServiceName;
                        v.ID = B.ServiceID;
                        setselsub0(v);
                        ////////console.log(v);
                    }
                })
            }


            setLodingService(false);
        } else {
            const Body = {
                "name": "site_FetchServices",
                "values": {
                    "GUID": option.value,
                    "ModelCode": null
                    // "ModelCode": modelCode
                }
            }

            axios.request({
                url: config.baseURL + 'func',
                data: Body, method: "POST", responseType: "json"
            })
                .then(function (response) {
                    // //////console.log(response.data.ApiObjectData);
                    setTimeout(setService(response.data), 1000);
                    if (response?.data.length == 1 || subServiceID == "a4df3008-2236-4455-854b-da6ddd592364" || subServiceID == "22d24d95-04cd-4c88-88ec-317d940231e0"
                        || subServiceID == "619b5424-1a20-4e01-950a-a8d87f9f1c7e") {

                        setSerciveID(response?.data[0].ServiceID);

                        const val = { ...requestForm };
                        val.SerciveID = response?.data[0].ServiceID;
                        setRequestForm(val);
                        ////console.log("response?.data[0]?>>>>>>>>>>>>>>>>>>>>>>>>>>", response?.data[0])
                        //console.log("setRequestForm?>>>>>>>>>>>>>>>>>>>>>>>>>>", val)
                    }

                    if (option?.ServiceID) {
                        setSerciveID(option?.ServiceID)
                        const val = { ...requestForm };
                        val.SerciveID = option?.ServiceID;
                        setRequestForm(val);
                        response.data.map((B) => {
                            if (B.ServiceID === option?.ServiceID) {
                                const v = selsub0;
                                v.desAr = B.ServiceNameAR;
                                v.desEn = B.ServiceName;
                                v.ID = B.ServiceID;
                                setselsub0(v);
                                ////////console.log(v);
                            }
                        })
                    }


                    setLodingService(false);

                })
                .catch(function (error) {

                    setLodingService(false);
                    //////console.log(error);
                });

        }

    }
    const [Branches, setBranches] = useState([]);

    let [modelCode, setModelCode] = useState("");
    let [VIN, setVIN] = useState("");

    const [SerciveID, setSerciveID] = useState(null);
    const [MainServiceID, setMainServiceID] = useState(null);
    const [subServiceID, setSubServiceID] = useState(null);
    const [TypeServiceID, setTypeServiceID] = useState(null);
    async function FetchPrice(modelC, SID) {

        ////console.log("FetchPrice>>>>>>>>>:", modelC)
        setinfoPrice([{ Price: 0 }]);
        if (!modelC) {
            return;
        }
        if (!SID) return;



        const Body = {
            "name": "Site_fetchPriceService",
            "values": {
                "ModelCode": modelC,
                "ServicID": SID
            }
        }

        axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {
                // //////console.log(response.data.ApiObjectData);

                setinfoPrice(response.data);

            })
            .catch(function (error) {
                setinfoPrice([{ Price: 0 }]);

                //////console.log(error);
            });


    }

    const checkDataFullNum = async () => {


        if (((isLogin && requestForm?.VIN) || type) && SerciveID && requestForm?.date && requestForm?.time && requestForm?.SiteID && (isLogin || (requestForm?.firstName && requestForm?.lastName && requestForm?.phone))) {
            setDataFullNum(2);
            return 2;
        } else if (((isLogin && requestForm?.VIN) || type) && SerciveID) {
            setDataFullNum(1);
            return 2;
        } else {
            setDataFullNum(0);
            return 0;
        }


    }

    const checkErrors = () => {
        var thisSchema = null;

        if (isLogin) {

            thisSchema = LoginSchema;
        } else {
            thisSchema = notLoginSchema;

        }
        const result = formValidate(thisSchema, requestForm) ?? {};

        ////console.log("formValidate>>>>>>>>>>>>>>:", result);
        setError(result);


    }
    const [error, setError] = useState({});
    function SendResreve(VIN, ServecID, UserID, Amount) {

        var thisSchema = null;

        if (isLogin) {

            thisSchema = LoginSchema;
        } else {
            thisSchema = notLoginSchema;

        }
        const val = { ...requestForm };
        val.year = year;
        setRequestForm(val);
        const result = formValidate(thisSchema, val) ?? {};

        setError(result);
        if (Object.keys(result).length == 0 && isSendeng == false) {
            setIsSendeng(true);
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = {
                "VIN": VIN,
                "Model": type,
                "CarType": modelCode,
                "UserID": UserID ?? "",
                "Year": year,
                "TotalAmount": Amount ? Amount : 0,
                "RequiredServicesList": ServecID,
                "Note": Note,
                "MeterReading": requestForm?.meterReading,
                "FirstName": requestForm?.firstName,
                "LastName": requestForm?.lastName,
                "phone": requestForm?.phone,
                "StoreID": requestForm?.SiteID,
                "Email": requestForm?.email,
                "Orderdate": moment(requestForm?.date).format('YYYY-MM-DD'),
                "OrderTime": moment(requestForm?.time).format('HH:mm:ss'),
                // "PaymentMethodID": paymentType == 1 ? "Credit Card" : (paymentType == 2 ? "Sadad" : (paymentType == 3 ? "Installment" : "CashByBranch")),
            };


            axios.request({
                url: config.controllURL + "api/Service/ServiceRequestAdd", headers: myHeaders,
                data: raw, method: "POST", responseType: "json"
            }).then(function (response) {
                //////console.log("Service", response.data)
                setStepNum(1);
                setDataFullNum(0);

                setSerciveID(null);
                setselsub0({ ID: "0", desAr: "اختيار  قيمة", desEn: "chose" });
                setService([]);
                setinfoPrice([{ Price: 0 }]);

                setRequestForm({
                    lastName: '',
                    firstName: '',
                    phone: '',
                    email: '',
                    subject: '',
                    date: '',
                    time: '',
                    messageContent: '',
                    myCarID: '',
                    SiteID: null,
                });

                setMaintenanceObj({
                    showPopup: false, type: "Notset", main: "", sub1: "", sub2: ""
                });
                //console.log("OrderID", response?.data)
                if (response?.data?.OrderID)
                    setResOrderID(response?.data?.OrderID);
                setShowFinalPopup(true);
                toast.success(trans("info:reservation_completed_successfully"));

            }
            ).then((response) => {
                //////console.log('Error', response);
                setIsSendeng(false);
            })
                .catch(error => {
                    //////console.log('error', error);
                    setIsSendeng(false);
                }



                );

        }
    }



    const GetUrlfunc = config.baseURL + 'func';
    async function GetBranch() {

        const Body = {
            "name": "site_FetchMaintenanceBranches"
        };

        await axios.request({
            url: GetUrlfunc,
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {

                setBranches(response.data);


            })
            .catch(function (error) {
                //////console.log(error);
            });


    }

    useEffect(() => {
        if (Branches && Branches?.length > 0 && requestForm?.SerciveID && requestForm?.SerciveID != '' && !requestForm?.SiteID) {


            if (Branches?.length == 1) {
                const val = { ...requestForm };
                val.SiteID = Branches[0]?.SiteID;
                setRequestForm(val);
            }

        }

    }, [Branches, requestForm]);

    const [Vehicales, setVehicales] = useState([]);


    async function GetVehicales() {
        try {
            const isLogin = getData('isAuthenticated')
            if (!isLogin) {
                setVehicales([]);
                toast.error(trans("info:login_to_get_my_cars"));
                return;
            }
            const cleanData = JSON.parse(getData(User_Data_Key, true));

            if (!cleanData.custID) {
                setVehicales([]);
                toast.error(trans("info:approveAccount"));
                return;
            }

            const Body = {
                "name": "site_fetchMyVehical",
                "values": {
                    "CustID": Number(cleanData.custID)
                }
            };
            await axios.request({
                url: GetUrlfunc,
                data: Body, method: "POST", responseType: "json"
            })
                .then(function (response) {

                    setVehicales(response.data);
                })
                .catch(function (error) {

                    toast.error(trans("info:pleas_add_your_car"));
                    //////console.log(error);
                });
        } catch (error) {
            //////console.log(error);
        }



    }
    //selected option 
    const [selMain, setselMain] = useState({ ID: "0", desAr: "اختيار قيمة", desEn: "chose" });
    const [selsub1, setselsub1] = useState({ ID: "0", desAr: "اختيار  قيمة", desEn: "chose" });
    const [selsub2, setselsub2] = useState({ ID: "0", desAr: "اختيار  قيمة", desEn: "chose" });
    const [selsub0, setselsub0] = useState({ ID: "0", desAr: "اختيار  قيمة", desEn: "chose" });


    function setFirestOption(option, SID, currentServiceID = SerciveID,objectservicesJON=ServicesDataJson) {
        fetchService({ value: option.SubService2ID, text: lang === 'ar' ? option.DescriptionAr : option.DescriptionEn, currentServiceID }, SID,objectservicesJON);

        setTypeServiceID(option.SubService2ID);
    }


    function setFirestOptionService1(option) {
        fetchSubService2({ value: option.SubService1ID, text: lang === 'ar' ? option.DescriptionAr : option.DescriptionEn });
        setSubServiceID(option.SubService1ID);
    }


    function setFirestOptionService(option) {
        setSerciveID(option.ServiceID);
    }

    useEffect(() => {
        checkDataFullNum();
    }, [requestForm, SerciveID, year, type, isLogin]);



    useEffect(() => {
        if (vModelCode != null && SerciveID && Defult?.firstFetchPrice != true) {
            setDefult({ ...Defult, firstFetchPrice: true });
            if (Defult?.modelType) {
                if (vModelCode[Defult?.modelType] && SerciveID)
                    FetchPrice(vModelCode[Defult?.modelType], SerciveID);
            }



        }

    }, [vModelCode, SerciveID]);

    const [seoPageInfo, setSeoPageInfo] = useState({});
    useEffect(async () => {

        var page = "maintenance services"


        const response = await axios.post(
            config.baseURL + 'func',
            {
                "Name": "SiteFetchSeoPages",
                "Values": {
                    "page": page
                }
            }
        );
        if (response.status == 200) {

            var resdata = response.data[0];

            setSeoPageInfo(resdata);


        }
    }, []);

    const [listTimeAvailable, setListTimeAvailable] = useState([]);
    // const [maxTime, setMaxTime] = useState(0);
    // const [minTime, setMinTime] = useState(0);

    const webAvailableReshours = async (date = '') => {
        const Body = {
            "name": "web_AvailableReshours",
            "values": {
                "Resdate": date
            }
        }

        await axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {
                console.log("web_AvailableReshours:response", response);
                if (response.status >= 200 && response.status < 299) {
                    console.log("web_AvailableReshours:response", response.statusText);
                    if (response?.data && response?.data.length != 0) {
                        response?.data.forEach((val, index) => {

                            setListTimeAvailable(_lstTime => [..._lstTime, parseInt(val?.AvailableHour)]);
                        });

                    }
                }
            })
            .catch(function (error) {
                console.log("web_AvailableReshours:Error", error);
                toast.warn(trans("form:please-choose-another-date"));
            });
    }

    // useEffect(() => {
    //
    //     debugger
    //     setMaxTime(Math.max(...listTimeAvailable));
    //     console.log("setMaxTime(Math.max(...listTimeAvailable))", setMaxTime(Math.max(...listTimeAvailable)));
    //     setMinTime(Math.min(...listTimeAvailable));
    //     console.log("setMaxTime(Math.min(...listTimeAvailable))", setMaxTime(Math.min(...listTimeAvailable)));
    //
    // }, [listTimeAvailable]);

    const maxTime = Math.max(...listTimeAvailable);
    const minTime = Math.min(...listTimeAvailable);




    return (
        <MaintenanceContext.Provider value={{ maintenanceObj, handleServiceOrder }}>

            {
                seoPageInfo &&

                <Helmet>

                    <meta charSet="utf-8" />
                    {
                        seoPageInfo?.title &&
                        <title>{seoPageInfo?.title}</title>
                    }
                    {
                        seoPageInfo?.link &&
                        <link rel="canonical" href={seoPageInfo?.link} />

                    }
                    {
                        seoPageInfo?.MetaDescription &&
                        <meta name="description" content={seoPageInfo?.MetaDescription} />
                    }
                    {
                        seoPageInfo?.MetaGroups &&
                        <meta name="keywords" content={seoPageInfo?.MetaGroups} />
                    }

                </Helmet>


            }


            <Switch>
                <Route exact path={path} component={MainSericePage} />
                <Route exact path={`${path}/serviceReservation/:PVIN/:PServiceID`} component={ServiceReservation} />
                <Route exact path={`${path}/serviceReservation/:PServiceID`} component={ServiceReservationByID} />
                <Route exact path={`${path}/protectionAndShading/:PVIN/:PUser`} component={ProtectionAndShading} />
                <Route exact path={`${path}/protectionAndShading/:PVIN/`} component={ProtectionAndShading} />
                <Route exact path={`${path}/:PGroupID/:PYear/:PSerciveID`} component={MainSericePage} />
                <Route exact path={`${path}/subMainService/:ID`} component={SubMainServicePage} />
                <Route exact path={`${path}/subMainService/subService/:ID`} component={SubServicePage} />
                <Route exact path={`${path}/subMainService/subService/service/:ID`} component={ServicePage} />
            </Switch>
            <Popup show={maintenanceObj.showPopup}>
                <div className="mainteance-popup nice-flex nice-flex-center">
                    <div className="mainteance-popup-container p-4">
                        <div className="mainteance-popup-container-header">
                            <h1 className="text-balck text-bold text-24 text-center">{trans("info:request-maintenance-service")}</h1>
                            <span className='mainteance-popup-container-header-close text-block text-gray' onClick={() => {
                                setMaintenanceObj({ showPopup: false })
                            }}>&times;</span>


                        </div>

                        <div className="customStepper">
                            <div className={"customStepper-part " + (dataFullNum > 0 ? "active" : " ")}>
                                {/* <span className="customStepper-part-titel"><li>{"1"}</li>{'بيانات المركبة'}</span> */}
                                <div className={"customStepper-part-connector  "}></div>
                            </div>
                            <div className={"customStepper-part " + (dataFullNum > 1 ? "active" : " ")}>
                                {/* <span className="customStepper-part-titel"><li>{"2"}</li>{'بيانات الخدمة'}</span> */}
                                <div className={"customStepper-part-connector  "}></div>
                            </div>

                            {/* <div className={"customStepper-part " + (dataFullNum > 2 ? "active" : " ")}>
                                <span className="customStepper-part-titel"><li>{"3"}</li>{'البيانات الشخصية'}</span>
                                <div className={"customStepper-part-connector  "}></div>
                            </div> */}

                        </div>
                        <div className="ruler-2 mb-3"></div>
                        <div className="my-8 px-8 flex flex-col nice-gap-3rem mainteance-popup-container-body">







                            {
                                stepNum == 1 &&
                                <>

                                    {
                                        !isLogin || Defult?.modelType ?
                                            <>



                                                <div className="flex flex-row grid-2-cols nice-gap-2rem ">
                                                    <div className="basis2/4 grow">
                                                        <DropDownInput

                                                            defaultOption={(brand == '0' || brand == null) ? { text: trans("info:company"), value: '0' } : null}
                                                            options={settings?.brands?.map(item => {
                                                                return { text: item?.description, value: item?.brandID }
                                                            })}
                                                            placeholder={trans("info:company")}
                                                            selectedValue={brand}
                                                            onSelect={handelSelectBrand}
                                                        />
                                                    </div>

                                                    <div className="basis2/4 grow">
                                                        <DropDownInput
                                                            errorLabel={error?.year}
                                                            defaultOption={{
                                                                text: trans("info:created_year"),
                                                                value: '0'
                                                            }}
                                                            placeholder={trans("info:created_year")}
                                                            // options={settings?.models?.map(item => {
                                                            //     return { text: item?.modelID, value: item?.modelID }
                                                            // })}

                                                            options={
                                                                settings?.models?.filter(
                                                                    item => item?.modelID?.startsWith('20')).map(
                                                                        item => ({
                                                                            text: item?.modelID,
                                                                            value: item?.modelID
                                                                        })
                                                                    )
                                                            }

                                                            selectedValue={year}
                                                            onSelect={(value) => {
                                                                //////console.log("models:", value)

                                                                setYear(value);

                                                                const val = { ...requestForm };
                                                                val.year = value;
                                                                setRequestForm(val);
                                                                handelSelectBrand(brand, value);
                                                                // handleFilteredModels(group,value);
                                                            }}
                                                        />
                                                    </div>


                                                </div>

                                                <div className="flex flex-row grid-2-cols nice-gap-2rem ">

                                                    <div className="basis2/4 grow">
                                                        <DropDownInput
                                                            defaultOption={((brand == '0' || brand == null) || (group == '0' || group == null)) ? {
                                                                text: trans("eparts:vehicle"),
                                                                value: '0'
                                                            } : null}
                                                            placeholder={trans("eparts:vehicle")}
                                                            options={filteredSettings?.groups?.map(item => {
                                                                return { text: item?.description, value: item?.productGroupID }
                                                            })}
                                                            selectedValue={group}
                                                            onSelect={handleFilteredModels}
                                                        />
                                                    </div>
                                                    <div className="basis2/4 grow">
                                                        <DropDownInput

                                                            defaultOption={((brand == '0' || brand == null) || (group == '0' || group == null)) ? {
                                                                text: trans("eparts:model"),
                                                                value: '0'
                                                            } : null}
                                                            placeholder={trans("eparts:model")}
                                                            errorLabel={requestForm?.type}
                                                            options={filteredSettings?.productModels?.map(item => {
                                                                return { text: item?.description, value: item?.productTypeID }
                                                            })}
                                                            selectedValue={type}
                                                            onSelect={(value) => {
                                                                //////console.log("setType:", value)
                                                                setType(value);
                                                                //////console.log("vModelCode[value]:", vModelCode[value])
                                                                setModelCode(vModelCode[value]);
                                                                FetchPrice(vModelCode[value], SerciveID);

                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <DropDownInput

                                                title={trans("info:Vehicle-must-be-selected")}
                                                errorLabel={error?.VIN}
                                                selectedValue={requestForm?.VIN}
                                                options={[
                                                    ...Vehicales.map(B => {
                                                        return { value: B.SN, text: lang == 'ar' ? B.carAr : B.carEn, ModelCode: B.ModelCode, year: B.Model, type: B.Type };
                                                    })]}
                                                onfilter={(option) => {
                                                    setModelCode(option.ModelCode);
                                                    setVIN(option.value);
                                                    setYear(option.year);
                                                    setType(option.type);
                                                    FetchPrice(option.ModelCode, SerciveID);

                                                    const val = { ...requestForm };
                                                    val.VIN = option.value;
                                                    setRequestForm(val);
                                                }}
                                                isepart={true}
                                            />
                                    }

                                    {


                                        <div className="flex flex-row grid-3-cols nice-gap-1rem mb-40">
                                            <DropDownInput
                                                options={[
                                                    ...MainServic.map(B => {
                                                        return { value: B.MainServiceID, text: lang === 'ar' ? B.DescriptionAr : B.DescriptionEn };
                                                    })]}
                                                onfilter={(option) => {
                                                    setMainServiceID(null);
                                                    setSubServiceID(null);
                                                    setTypeServiceID(null);
                                                    setSerciveID(null);
                                                    fetchSubService(option);
                                                    setMainServiceID(option.value);
                                                }
                                                }
                                                defaultOption={{ value: selMain.ID, text: trans("info:main_service") }}
                                                selectedValue={MainServiceID}
                                                isepart={true}
                                            />

                                            {
                                                (SubService1?.length > 1 && MainServiceID != "9ceb8b65-14a4-4a61-9223-a06ae0db53cf") ?

                                                    <DropDownInput

                                                        options={[
                                                            ...SubService1.map(B => {

                                                                return ({ value: B.SubService1ID, text: lang === 'ar' ? (B.DescriptionAr ? B.DescriptionAr : B.DescriptionEn) : B.DescriptionEn });


                                                            })]}
                                                        onfilter={(option) => {
                                                            setSubServiceID(null);
                                                            setTypeServiceID(null);
                                                            setSerciveID(null);
                                                            fetchSubService2(option);
                                                            setSubServiceID(option.value);
                                                        }}
                                                        isepart={true}
                                                        selectedValue={subServiceID}
                                                        defaultOption={{ value: selsub1.ID, text: trans("info:sub_main_service") }}
                                                    />
                                                    : null

                                            }


                                            {
                                                (SubService2?.length > 1 && MainServiceID != "9ceb8b65-14a4-4a61-9223-a06ae0db53cf" && subServiceID != "a4df3008-2236-4455-854b-da6ddd592364" && subServiceID != "619b5424-1a20-4e01-950a-a8d87f9f1c7e") ?
                                                    <DropDownInput

                                                        options={[
                                                            ...SubService2.map(B => {
                                                                return { value: B.SubService2ID, text: lang === 'ar' ? (B.DescriptionAr ? B.DescriptionAr : B.DescriptionEn) : B.DescriptionEn };
                                                            })]}
                                                        onfilter={(option) => {
                                                            setTypeServiceID(null);
                                                            setSerciveID(null);
                                                            fetchService(option, subServiceID);
                                                            setTypeServiceID(option.value);

                                                        }}

                                                        isepart={true}
                                                        selectedValue={TypeServiceID}
                                                        defaultOption={{ value: selsub2.ID, text: trans("info:main_service_type") }}


                                                    />
                                                    : null


                                            }
                                            {

                                                (MainServiceID != "9ceb8b65-14a4-4a61-9223-a06ae0db53cf" && subServiceID != "a4df3008-2236-4455-854b-da6ddd592364" && subServiceID != "619b5424-1a20-4e01-950a-a8d87f9f1c7e") ?
                                                    <DropDownInput
                                                        options={[
                                                            ...Service.map(B => {
                                                                return { value: B.ServiceID, text: lang === 'ar' ? B.ServiceNameAR : B.ServiceName };
                                                            })]}
                                                        errorLabel={error?.SerciveID}
                                                        onfilter={(option) => {

                                                            const val = { ...requestForm };
                                                            val.SerciveID = option.value;
                                                            setRequestForm(val);

                                                            setSerciveID(null);
                                                            setSerciveID(option.value);
                                                            FetchPrice(modelCode, option.value);

                                                        }}
                                                        isepart={true}
                                                        isLoading={lodingService}
                                                        selectedValue={SerciveID ? SerciveID : requestForm?.SerciveID}
                                                        defaultOption={{ value: selsub0.ID, text: trans("info:servce") }}

                                                    />

                                                    :
                                                    null
                                            }


                                        </div>
                                    }

                                    {/*السعر */}
                                    {/* { 
                                        // infoPrice[0].Price ?
                                        <div className={""}>
                                            <div className="nice-flex nice-flex-space ">
                                                <p className={`text-16 text-bold my-4 px-3 border-section-${lang == 'en' ? 'left' : 'right'}`}>{trans("info:price")}</p>

                                            </div>

                                            {
                                                <div className="mx-2 mb-5 ">
                                                    <img src='/assets/icons/info.svg' className="mx-3 text-inline-block nice-max-icon" />
                                                    <p className="text-12 text-bold text-gray text-inline-block">{trans("info:service_price_head_message")}</p>
                                                </div>
                                            }
                                            {
                                                <div className='p-4 mt-4 text-30 text-primary'>
                                                    {
                                                        infoPrice[0] && <Price price={infoPrice[0].Price} />
                                                    }

                                                </div>
                                            }
                                        </div>
                                        // : null
                                    } */}

                                </>
                            }

                            {
                                // stepNum == 2 &&
                                <>


                                    {
                                        stepNum == 2 &&
                                        <>
                                            {

                                                !isLogin &&
                                                <>
                                                    <div className="flex flex-row grid-2-cols nice-gap-2rem ">
                                                        <>
                                                            <div className="basis-2/4 grow">

                                                                <InputField

                                                                    errorLabel={error?.firstName}
                                                                    placeholder={trans("form:first-name")}
                                                                    value={requestForm.firstName}
                                                                    length={255}
                                                                    onChangeValue={
                                                                        (e) => {
                                                                            const val = { ...requestForm };
                                                                            val.firstName = e.target.value;
                                                                            setRequestForm(val);
                                                                        }
                                                                    }
                                                                />
                                                            </div>
                                                        </>
                                                        <>

                                                            <div className="basis-2/4 grow">
                                                                <InputField

                                                                    errorLabel={error?.lastName}
                                                                    placeholder={trans("info:surname")}
                                                                    value={requestForm.lastName}
                                                                    length={255}
                                                                    onChangeValue={
                                                                        (e) => {
                                                                            const val = { ...requestForm };
                                                                            val.lastName = e.target.value;
                                                                            setRequestForm(val);
                                                                        }
                                                                    }
                                                                />
                                                            </div>
                                                        </>
                                                    </div>

                                                    <div className="flex flex-row grid-2-cols nice-gap-2rem ">
                                                        <div className="grow">
                                                            <PhoneDropDown
                                                                title={trans("form:phone")}
                                                                errorLabel={error?.phone}
                                                                value={requestForm.phone}
                                                                onChangeValue={(countryCode, phoneNumber) => {
                                                                    const val = { ...requestForm };
                                                                    val.phone = phoneNumber ? `+${countryCode}${phoneNumber}` : null;
                                                                    val.codephone = `+${countryCode}`;
                                                                    setRequestForm(val);
                                                                }}

                                                                defaultValue={requestForm?.phone?.replace(`${requestForm?.codephone}`, '')}
                                                            />
                                                        </div>

                                                        <div className="grow">
                                                            <InputField

                                                                type='email'
                                                                value={requestForm.email}
                                                                onChangeValue={(e) => {
                                                                    const val = { ...requestForm };
                                                                    val.email = e.target.value;
                                                                    setRequestForm(val);
                                                                }}
                                                                errorLabel={error?.email}
                                                                placeholder={trans("form:email")}
                                                                name='email'
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className=" grow">

                                                        <InputField
                                                            errorLabel={error?.VIN}
                                                            placeholder={trans("form:structure-no") + '(VIN)'}
                                                            onChangeValue={(e) => {
                                                                const val = { ...requestForm };
                                                                val.VIN = e.target.value;
                                                                setRequestForm(val);
                                                                setVIN(e.target.value);



                                                            }} />
                                                    </div>


                                                </>
                                            }



                                            <div className="flex flex-row grid-2-cols nice-gap-2rem ">


                                                <div className="grow">
                                                    <div className="basis-2/4 grow">

                                                        <DatePicker
                                                            // showIcon
                                                            isClearable={!!requestForm.date}
                                                            // showTimeSelect
                                                            // closeOnScroll={true}
                                                            // popperContainer={({ children }) => (
                                                            //     <div style={{ width: '300px' }}>{children}</div>
                                                            //   )}

                                                            className=" w-100"
                                                            // popperPlacement="top-end"
                                                            // icon={"fa fa-calendar"}
                                                            selected={requestForm.date}
                                                            onChange={async (value) => {


                                                                const val = { ...requestForm };
                                                                val.date = value;
                                                                val.time = null;
                                                                setRequestForm(val);
                                                                setListTimeAvailable([]);

                                                                if (val.date) {
                                                                    await webAvailableReshours(moment(value).format('yyyy-MM-DD'));
                                                                }
                                                            }}
                                                            filterDate={(date) => {
                                                                const today = new Date();
                                                                const yesterday = new Date(today);
                                                                yesterday.setDate(today.getDate() - 1);
                                                                const day = date.getDay();
                                                                return (day === 0 || day === 1 || day === 2 || day === 3 || day === 4 || day === 6)
                                                                    && (new Date(date).setHours(0, 0, 0) > yesterday.setHours(0, 0, 0)); // Sunday (0), Saturday (6)

                                                            }}
                                                            placeholderText={trans("form:maintenance-date")}
                                                            dateFormat="yyyy-MM-dd"
                                                            customInput={<DateInput3
                                                                value={moment(requestForm.date).format('yyyy-MM-DD')}
                                                            />}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="grow">
                                                    <div className="basis-2/4 grow">
                                                        <DatePicker
                                                            //     excludeTimes={
                                                            //     listTimeAvailable.map((val, index) => {
                                                            //         const time = new Date();
                                                            //         time.setHours(val, 0, 0);
                                                            //         return time;
                                                            //     })
                                                            // }

                                                            includeTimes={listTimeAvailable.map((val) => {
                                                                const time = new Date();
                                                                let res;

                                                                if (requestForm.date && new Date(requestForm.date).toDateString() === new Date().toDateString()) {
                                                                    if (val > new Date().getHours()) {
                                                                        res = time.setHours(val, 0, 0);
                                                                    }
                                                                } else if (requestForm.date && new Date(requestForm.date) > new Date()) {
                                                                    res = time.setHours(val, 0, 0);
                                                                } else {
                                                                    res = time.setHours(val, 0, 0);
                                                                }

                                                                return res;
                                                            })}
                                                            isClearable={!!requestForm.time}
                                                            selected={requestForm.time}
                                                            onChange={(value) => {

                                                                const val = { ...requestForm };
                                                                val.time = value;
                                                                setRequestForm(val);
                                                            }}
                                                            className="w-100"
                                                            // filterTime={(time) => {
                                                            //     // Define your custom logic to disable specific times
                                                            //     // For example, disable times before 9:00 AM and after 5:00 PM
                                                            //     const startTime = new Date().setHours(8, 0, 0); // 9:00 AM
                                                            //     const endTime = new Date().setHours(18, 0, 0); // 5:00 PM
                                                            //     return time < startTime || time > endTime;
                                                            //   }}
                                                            placeholderText={trans("form:maintenance-time")}
                                                            showTimeSelect
                                                            showTimeSelectOnly
                                                            timeIntervals={60}
                                                            timeInputLabel={trans("form:maintenance-time")}
                                                            // minTime={
                                                            //     (requestForm.date && new Date(requestForm.date).getDate() > new Date().getDate()) 
                                                            //         ? new Date().setHours(8, 0, 0) 
                                                            //         :(new Date().getHours() > 19 
                                                            //             ? new Date().setHours(18, 55, 0) 
                                                            //             : new Date().setHours(new Date().getHours(), new Date().getMinutes(), 0) ) }
                                                            // maxTime={new Date().setHours(18, 59, 0)}
                                                            timeCaption={trans("form:maintenance-time")}
                                                            // withPortal
                                                            dateFormat="hh:mm aa"
                                                            // showTimeInput
                                                            customInput={<DateInput3
                                                            //  value={requestForm.time} 

                                                            />}

                                                        // customTimeInput={<DateInput3 />}
                                                        />
                                                    </div>
                                                </div>

                                                {/* <div className="grow">
                                                    <div className="basis-2/4 grow">

                                                        <DateInput
                                                            extraClasses={"border-none  text-30  border-radius-sm "}
                                                            errorLabel={error?.date}
                                                            type={"date"}
                                                            placeholder={trans("form:maintenance-date")}
                                                            value={requestForm.date}
                                                            length={255}
                                                            onSelect={
                                                                (e) => {
                                                                    //////console.log(">>>>>>>>>>>>>:", e.target.value);
                                                                    if (e.target.value >= (new Date().toJSON().slice(0, 10)) || !e.target.value) {
                                                                        const val = { ...requestForm };
                                                                        val.date = e.target.value;
                                                                        setRequestForm(val);
                                                                    }
                                                                }
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="grow">
                                                    <div className="basis-2/4 grow">

                                                        <DateInput
                                                            extraClasses={"border-none  text-30  border-radius-sm "}
                                                            errorLabel={error?.time}
                                                            type={"time"}
                                                            placeholder={trans("form:maintenance-time")}
                                                            value={requestForm.time}
                                                            length={255}
                                                            onSelect={
                                                                (e) => {
                                                                    const val = { ...requestForm };
                                                                    val.time = e.target.value;
                                                                    setRequestForm(val);
                                                                }
                                                            }
                                                        />
                                                    </div>
                                                </div> */}
                                            </div>
                                            <div className="flex flex-row grid-2-cols nice-gap-2rem ">


                                                <div className="basis2/4 grow">

                                                    <InputField
                                                        errorLabel={error?.meterReading}

                                                        placeholder={trans("info:meter-reading")}
                                                        type='number'
                                                        value={requestForm?.meterReading}
                                                        onChangeValue={(e) => {
                                                            const val = { ...requestForm };
                                                            val.meterReading = e.target.value;
                                                            setRequestForm(val);

                                                        }} />
                                                </div>

                                                <DropDownInput
                                                    // title={trans("info:branch")}
                                                    errorLabel={error?.SiteID}
                                                    options={[
                                                        ...Branches.map(B => {
                                                            return { value: B.SiteID, text: lang === 'ar' ? B.DescriptionAr : B.DescriptionEn };
                                                        })]}
                                                    selectedValue={Branches?.length == 1 ? Branches[0]?.SiteID : requestForm?.SiteID}
                                                    onSelect={(value) => {

                                                        //////console.log("onSelect:", value);
                                                        const val = { ...requestForm };
                                                        val.SiteID = value;
                                                        setRequestForm(val);
                                                    }}

                                                />
                                            </div>







                                            <TextArea
                                                value={Note}
                                                row={3}
                                                placeholder={trans("info:notes")}
                                                onChangeValue={(data) => {
                                                    setNote(data);
                                                }}
                                            />

                                        </>

                                    }



                                </>
                            }

                        </div>
                        <div className="ruler-2 mb-3"></div>
                        {

                            <>
                                <div className="flex nice-justifiy-end gap-2 py-10 px-5">


                                    {

                                        <div className=" mb-1 ">

                                            {
                                                stepNum > 1 &&
                                                <div className="nice-bg-btn bg-black text-white text-20  p-5 " onClick={() => {
                                                    if (stepNum > 1)
                                                        setStepNum(stepNum - 1);
                                                }}>


                                                    {trans("info:back")}


                                                    <div className={" mx-2 " + (lang == "ar" ? "  " : " rotate-180 ")}>

                                                        <svg xmlns="http://www.w3.org/2000/svg" height="20" width="25" viewBox="0 0 448 512" >
                                                            <path fill="#3bd461" strokeWidth="2" d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
                                                        </svg>



                                                    </div>
                                                </div>
                                            }

                                        </div>
                                    }

                                    {

                                        <div className=" mb-1 ">

                                            {
                                                stepNum < 2 &&
                                                <div className="nice-bg-btn bg-primary text-white text-20 p-5 " onClick={async () => {

                                                    const num = await checkDataFullNum();

                                                    if (num >= (stepNum)) {

                                                        if (stepNum < 2)
                                                            setStepNum(stepNum + 1);

                                                        if (Object.keys(error).length > 0) {
                                                            setError({});

                                                        }
                                                    } else {
                                                        checkErrors();
                                                    }

                                                }}>
                                                    {trans("form:next")}
                                                    <div className={" mx-2 " + (lang == "ar" ? " rotate-180 " : "")}>

                                                        <svg xmlns="http://www.w3.org/2000/svg" height="20" width="25" viewBox="0 0 448 512" >
                                                            <path fill="#ffffff" strokeWidth="2" d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
                                                        </svg>

                                                    </div>


                                                </div>
                                            }

                                        </div>
                                    }



                                </div>

                                {
                                    dataFullNum >= 2 &&
                                    <div className="nice-row py-10 px-5 nice-flex-center ">
                                        <div className="nice-col-md-6 mb-5">
                                            <div className="nice-bg-btn bg-primary text-white text-24" onClick={() => {

                                                var cleanData = {};
                                                try {
                                                    cleanData = JSON.parse(getData(User_Data_Key, true));
                                                } catch (e) {


                                                }

                                                if (isSendeng == false)
                                                    SendResreve(VIN, SerciveID, cleanData?.custID, infoPrice[0].Price);

                                            }}>
                                                {trans("info:reserve")}

                                                {
                                                    isSendeng && <span className='mx-2 loading-btn '>
                                                        <img src='/assets/icons/loading.svg' width='25' height='25' />
                                                    </span>
                                                }

                                            </div>
                                        </div>
                                        {/* <div className="nice-col-md-6 mb-5">
                                            <div className={"nice-bg-btn  text-24 text-white gap-3r " + (infoPrice[0].Price ? "bg-black" : "bg-gray")} onClick={() => {

                                                if (infoPrice[0].Price) {

                                                    setIsSendeng(true);


                                                    var brandDesc = settings?.brands?.filter(a => a.brandID == brand)[0];
                                                    var groupDesc = settings?.groups?.filter(a => a.productGroupID == group)[0];
                                                    var typeDesc = settings?.productModels?.filter(a => a.productTypeID == type)[0];
                                                    var ServiceDesc = Service?.filter(a => a.ServiceID == SerciveID)[0];
                                                    var objectCart =
                                                    {
                                                        id: modelCode + '-' + SerciveID,
                                                        productID: SerciveID,
                                                        name: ServiceDesc.ServiceNameAR,
                                                        nameEn: ServiceDesc.ServiceName,
                                                        // image :img,
                                                        modelTypeID: VIN,
                                                        modelCode: modelCode,
                                                        price: infoPrice[0].Price,
                                                        Type: "service",
                                                        brand: brandDesc?.descriptionAr
                                                        , brandEn: brandDesc?.descriptionEn
                                                        , group: groupDesc?.descriptionAr
                                                        , groupEn: groupDesc?.descriptionen
                                                        , BrandID: brand
                                                        , modelTypes: typeDesc?.descriptionAr
                                                        , modelTypesEn: typeDesc?.descriptionEn
                                                        ,Orderdate :fullDatetime


                                                    }
                                                    changecartItem(SerciveID, null, VIN, 1, modelCode, null,objectCart,fullDatetime );


                                                    setTimeout(() => {


                                                        setIsSendeng(false);


                                                        setSerciveID(null);
                                                        setselsub0({ ID: "0", desAr: "اختيار  قيمة", desEn: "chose" });
                                                        setService([]);
                                                        setinfoPrice([{ Price: 0 }]);
                                                        setRequestForm({
                                                            lastName: '',
                                                            firstName: '',
                                                            phone: '',
                                                            email: '',
                                                            subject: '',
                                                            date: '',
                                                            time: '',
                                                            messageContent: '',
                                                            myCarID: '',
                                                            SiteID: null,
                                                        });
                                                        setStepNum(1);
                                                        setDataFullNum(0);

                                                        setMaintenanceObj({
                                                            showPopup: false, type: "Notset", main: "", sub1: "", sub2: ""
                                                        });


                                                    }, 1000);


                                                } else {
                                                    toast.warning(trans("info:can_reserve_service"));
                                                    setIsSendeng(false);

                                                }
                                            }
                                            }>
                                                {trans("info:add_to_cart")} <img src="/assets/icons/cart.svg" className="filtter-primary" />

                                                {
                                                    isSendeng && <span className='mx-2 loading-btn '>
                                                        <img src='/assets/icons/loading.svg' width='25' height='25' />
                                                    </span>
                                                }
                                            </div>
                                        </div> */}
                                    </div>
                                }

                            </>
                        }

                    </div>
                </div>
            </Popup>


            <Popup show={showFinalPopup}>
                <div className="w-full h-full flex justify-center items-center" >
                    <div className="cart-finalized" ref={popRef}>
                        <div className="cart-finalized-container flex flex-col">

                            <div className="cart-finalized-container-img flex flex-col">
                                <img src="/assets/images/finalized.svg" />
                            </div>

                            <div className="flex flex-wrap justify-center">
                                <span className="text-20 font-bold text-black mx-2 unselectable ">{trans("new:thank_to_choose")}</span>
                                <span className="text-20 font-bold text-primary mx-2"> {trans("new:HJ_cars")}</span>
                            </div>

                            {
                                <>
                                    <div className="flex flex-row justify-center">
                                        <span className="text-20 font-bold  mx-2"> {trans("new:The_appointment_has_been_booked")}</span>
                                    </div>
                                    <div className="flex flex-row justify-center">
                                        <span className="text-20 font-bold  mx-2"> {trans("new:You-will-be-contacted")}</span>
                                    </div>

                                    {

                                        resOrderID &&
                                        <div className="flex flex-row justify-center cart-finalized-code"
                                            onClick={() => {

                                                navigator.clipboard.writeText(resOrderID);
                                            }}

                                        >

                                            <span className="text-20 font-bold text-white  mx-2"> {trans("new:Reservation_number") + " :"}</span>
                                            <span className="text-20 font-bold text-white  mx-2">{resOrderID && resOrderID}</span>
                                            <img src="/assets/icons/copy.svg" alt='' />
                                        </div>
                                    }

                                </>

                            }



                            <div className="flex flex-center flex-col  mt-5 pt-5 w-80">
                                <div className=" mb-5 w-100">
                                    <div className="nice-sm-btn cart-notification-go  bg-primary text-white text-16" onClick={() => {

                                        setShowFinalPopup(false);
                                        setResOrderID(null);
                                        if (location.pathname)
                                            history.push(location.pathname);

                                    }}>
                                        {trans("cartFile:Continue_browsing")}
                                    </div>
                                </div>
                            </div>

                        </div>


                    </div>


                </div>
            </Popup>

        </MaintenanceContext.Provider >
    );
}