import axios from "axios";
import {useContext, useEffect, useState} from "react"
import {useTranslation} from "react-i18next";
import {useRouteMatch,useParams} from "react-router";
import Breadcrumb from "../../../components/__simple/breadcrumb";
import PageHeading from "../../../components/__simple/_pageheading"
import {AboutHJSection} from "../../../sections/aboutHJ";
import {TestimonialSection} from "../../../sections/testimonialSection";
import {WhyHJSection} from "../../../sections/whyHJSection";
import {MaintenanceServiceBox, ServiceOrderButton} from "../components/maintenance";
import ServiceHeaderBanner from "../components/serviceHeaderBanner";
import MaintenanceContext from "../maintenanceContext";
import {DropDownInput2 as DropDownInput} from "../../../components/_input/_dropdown2";
// import { DropDownInput } from "../../../components/_input/_dropdown";
import {Price} from "../../../components/__simple/_price";
import config from "../../../configuration/config.json";
import Offer from "../../../components/offer";
import {SwiperSlide, Swiper} from 'swiper/react';
import {Link} from "react-router-dom";
import {slugCombine} from '../../../utils/slugManager';

const MainServicePage = ({}) => {

    let {PGroupID,PYear,PSerciveID} = useParams();
    const [maintenanceInfo, setMaintenanceInfo] = useState({});

    const {t: trans, i18n} = useTranslation(["info", "navbar"]);
    const {handleServiceOrder} = useContext(MaintenanceContext);
    const {path} = useRouteMatch();
    const languageDir = 'language-' + i18n.language;
    const lang = i18n.language;
    const [MainService, setMainService] = useState([]);
    const [indexPanel, setIndexPanel] = useState("1000");
    
    const [ServiceOptions, setServiceOptions] = useState([]);
    const [Service, setService] = useState([]);
    const [lodingService, setLodingService] = useState(false);
    const [settings, setSettings] = useState({
        brands: [],
        groups: [],

        models: [],
        productModels: []
    });
    const [filteredSettings, setFilteredSettings] = useState({
        brands: [],
        groups: [],
        models: [],
        productModels: []
    });
    const [firstByLink22, setFirstByLink22] = useState(true);
    useEffect(() => {

        if(PGroupID && PYear && PSerciveID && settings && settings?.groups?.length > 0 && firstByLink22 ==true){
            setFirstByLink22(false);
           // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>:",PGroupID);
           // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>:",PYear);
           // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>:",PSerciveID);
            const ttGroup =settings?.groups?.filter(a => a.productGroupID == PGroupID);
            if(ttGroup && ttGroup?.length > 0){

                const PGroupData = ttGroup[0];

                setYear(PGroupData?.Year);

                const val = {...requestForm};
                val.year = PGroupData?.Year;
                setRequestForm(val);
                handelSelectBrand(PGroupData.brandID, PGroupData?.Year);
           
            }

        }

    }, [PGroupID,PYear,PSerciveID,settings])

    const [firstByLink, setFirstByLink] = useState(true);
    useEffect(() => {
        if(PGroupID && PYear && PSerciveID && settings && filteredSettings?.groups?.length > 0 && firstByLink ==true ){
            setFirstByLink(false);
            const ttGroup =settings?.groups?.filter(a => a.productGroupID == PGroupID);
            if(ttGroup && ttGroup?.length > 0){
                const PGroupData = ttGroup[0];
                    handleFilteredModels(PGroupData.productGroupID);

            }

        }

    }, [filteredSettings,firstByLink])

    const [firstByLink33, setFirstByLink33] = useState(true);
    useEffect(() => {
        if(PGroupID && PYear && PSerciveID && settings && filteredSettings?.groups?.length > 0 && ServiceOptions?.length > 0 && firstByLink33 ==true ){
            setFirstByLink33(false);

                    const val = {...requestForm};
                    val.SerciveID = PSerciveID;
                    setRequestForm(val);

                    setSerciveID(null);
                    //console.log("setSerciveID>>>>>>>>>>>>>>>>>>>>>>>:", option.value);
                  
                    FetchPrice(modelCode, PSerciveID);
                    setSerciveID(PSerciveID);

                    handleServiceOrder({
                        showPopup: true,
                        type: "sub2",
                        main: "0a5e145a-bf3f-4a8e-aa7b-d8f0a1e0d9b0",
                        sub1: "eb199b7e-55be-4ac8-b465-9a110b415ca8",
                        sub2: "2b995873-d703-47d8-9f58-f1df7f1e3979",
                        ServiceID: PSerciveID,
                        brand: brand,
                        group: group,
                        year: year,
                        modelType: type,
                        serviceOptions:Service

                    });
        }

    }, [ServiceOptions])
    async function fetchMainService() {

        try {
            if ("caches" in window) {
                var curl = "site_fetchMainService_Req";

                // Opening that particular cache
                const cacheStorage = await caches.open("MainServicePage");

                // Fetching that particular cache data
                const cachedResponse = await cacheStorage.match(
                    curl
                );
                var datacash = await cachedResponse?.json();
                if (datacash && datacash?.length > 0) {
                    setTimeout(() => {
                        setMainService(datacash)
                    }, 1000);

                }


            }
        } catch (error) {

        }
        const Body = {
            "name": "site_fetchMainService"
        }

        axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {
                //console.log("setMainService:", response.data);
                setTimeout(() => {
                    setMainService(response.data)
                }, 1000);


                const dataResponse = new Response(JSON.stringify(response.data));

                if ("caches" in window) {
                    // Opening given cache and putting our data into it
                    caches.open("MainServicePage").then((cache) => {
                        cache.put("site_fetchMainService_Req", dataResponse);

                    });
                }


            })
            .catch(function (error) {
                //console.log(error);
            });


    }

    useEffect(async () => {

        try {

            const response = await axios.request({
                baseURL: config.controllURL,
                url: i18n.language + "/api/maintenance/index",
                method: "get",
            });
            setMaintenanceInfo(response.data);
        } catch (error) {
            //console.log(error);
        }
    }, []);
    useEffect(() => {
        fetchMainService();
        intialOffers();

    }, [])


    const [maintenanceOffers, setMaintenanceOffers] = useState([]);
    // to intialize the offers
    const intialOffers = async () => {
        await axios.request({
            baseURL: config.controllURL,
            url: lang + "/api/Maintenance/MaintenanceOffers",
            method: "get",
        }).then(response => {

            setMaintenanceOffers(response.data);

        }).catch(error => {
            //console.log(error);
        });

    };


    let [modelCode, setModelCode] = useState("");
    const [error, setError] = useState({});
    const [requestForm, setRequestForm] = useState({
        lastName: '',
        firstName: '',
        phone: '',
        email: '',
        subject: '',
        date: '',
        time: '',
        messageContent: '',
        myCarID: '',
        SiteID: null,
    });


    useEffect(() => {

        fetchSettings();
        // fetchService();

    }, [])
    const fetchSettings = async () => {


        try {

            if ("caches" in window) {
                var curl = "Settings-" + lang;

                // Opening that particular cache
                const cacheStorage = await caches.open("HJWeb");

                // Fetching that particular cache data
                const cachedResponse = await cacheStorage.match(
                    curl
                );
                var settingResponseCash = await cachedResponse?.json();

                const currentSettings = {...settings};
                currentSettings.brands = settingResponseCash?.brands;
                currentSettings.groups = settingResponseCash?.groups;
                currentSettings.models = settingResponseCash?.models;
                currentSettings.productModels = settingResponseCash?.productModels;
                setSettings(currentSettings);

                const fGroups = currentSettings?.groups?.filter(a => a.brandID == brand);
                setGroupData(fGroups);
                //---------------------------------------------------------------

                let currentvModelCode = {};
                currentSettings?.productModels?.map((item) => {

                    if (item.ModelCode)
                        currentvModelCode[item.productGroupID] = item.ModelCode;

                })

               // console.log("currentvModelCode>>>>>>>:", currentvModelCode);
                setVModelCode(currentvModelCode);

            }

            const settingResponse = await axios.request({
                baseURL: '/',
                url: config.controllURL + lang + "/api/Maintenance/Settings",
                method: "get"
            });

            const currentSettings = {...settings};
            currentSettings.brands = settingResponse.data?.brands;
            currentSettings.groups = settingResponse.data?.groups;
            currentSettings.models = settingResponse.data?.models;
            currentSettings.productModels = settingResponse.data?.productModels;
            setSettings(currentSettings);

            const fGroups = currentSettings?.groups?.filter(a => a.brandID == brand);
            setGroupData(fGroups);
            //---------------------------------------------------------------

            let currentvModelCode = {};
            currentSettings?.productModels?.map((item) => {
                if (item.ModelCode)
                    currentvModelCode[item.productGroupID] = item.ModelCode;

            })

            ////console.log("currentvModelCode>>>>>>>>>", currentvModelCode);
            setVModelCode(currentvModelCode);

            const data = new Response(JSON.stringify(settingResponse.data));

            if ("caches" in window) {
                // Opening given cache and putting our data into it
                caches.open("HJWeb").then((cache) => {
                    cache.put("Settings-" + lang, data);

                });
            }

        } catch (error) {
            ////console.log(error);
        }

    };
  

    const [brand, setBrand] = useState(null);
    const [type, setType] = useState(null);
    const [groupData, setGroupData] = useState([]);
    const [group, setGroup] = useState(null);
    const [vModelCode, setVModelCode] = useState([]);
    const [year, setYear] = useState(null);
    const handleFilteredModels = (option,vYear=year) => {

        setModelCode(vModelCode[option]);

        // FetchPrice(vModelCode[option], SerciveID);

        fetchService(vModelCode[option]);

        setGroup(null);
        setType(null);
        setFilteredSettings({...filteredSettings, models: [], productModels: []});
        setGroup(option);

        var fModels =[];
        try{
            const currentgroupData  =settings?.groups?.filter(a => a.brandID ==brand && a.productGroupID == option && a.Year ==vYear);
            const ListTreeGroups =(currentgroupData && currentgroupData?.length > 0 )? currentgroupData[0].ListTreeGroups :option;
            // Split the comma-separated string into an array
            const listVArray = ListTreeGroups.split(',');

            // Assuming settings.productModels is an array of objects with properties productGroupID and Model
            fModels =   settings?.productModels?.filter(a => 
                listVArray.includes(a.productGroupID) && a.Model == vYear
            );
        }catch{

             fModels = settings?.productModels?.filter(a => a.productGroupID == option && a.Model ==vYear);
        }

       // console.log("handleFilteredModels fModels:", fModels);
        setFilteredSettings({...filteredSettings, productModels: fModels});

        if (fModels && fModels.length > 0)
            setType(fModels[0]?.productTypeID)

    };

    const handelSelectBrand = (option, year) => {
        setBrand(null);
        setGroup(null);
        setType(null);
        setSerciveID(null);
        setService([]);
        setinfoPrice([{Price: 0}]);
        setFilteredSettings({...settings, groups: [], models: [], productModels: []});
        setGroupData([]);
        setBrand(option);


        const fGroups = settings?.groups?.filter(a => a.brandID == option && a.Year ==year );
  
        setFilteredSettings({...settings, groups: fGroups});
        setGroupData(fGroups);

    };
    const [infoPrice, setinfoPrice] = useState([{Price: 0}]);
    const [SerciveID, setSerciveID] = useState(null);

    async function FetchPrice(modelC, SID) {

       // console.log("FetchPrice>>>>>>>>>:", modelC)
        setinfoPrice([{Price: 0}]);
        if (!modelC) {
            return;
        }
        if (!SID) return;


        const Body = {
            "name": "Site_fetchPriceService",
            "values": {
                "ModelCode": modelC,
                "ServicID": SID
            }
        }

        axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {
                // ////console.log(response.data.ApiObjectData);

                setinfoPrice(response.data);

                const data = new Response(JSON.stringify(response.data));


            })
            .catch(function (error) {
                setinfoPrice([{Price: 0}]);

                ////console.log(error);
            });


    }


    async function fetchService(mc) {

        var option = "2b995873-d703-47d8-9f58-f1df7f1e3979";
        setService([]);
        setServiceOptions([]);
        setinfoPrice([{Price: 0}]);
        setSerciveID(null);
        setLodingService(true);

        //console.log("fetchService?>>>>>>>>>>>>>>>>>>>>>>>>>>", option);

        const Body = {
            "name": "site_FetchServices",
            "values": {
                "GUID": option,
                // "ModelCode": mc
                "ModelCode": null
            }
        }

        axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {
                // ////console.log(response.data.ApiObjectData);

                var vv = response.data?.map(B => {
                    return {value: B.ServiceID, text: lang === 'ar' ? B.ServiceNameAR : B.ServiceName};
                });
                setServiceOptions(vv);
                setService(response.data);
                if (response?.data.length == 1) {

                    setSerciveID(response?.data[0].ServiceID);
                    FetchPrice(modelCode, response?.data[0].ServiceID);
                }


                setLodingService(false);


            })
            .catch(function (error) {

                setLodingService(false);
                setService([]);
                setServiceOptions([]);
                setinfoPrice([{Price: 0}]);
                setSerciveID(null);
                ////console.log(error);
            });


    }

    return (
        <>
            <ServiceHeaderBanner/>
            <div className="container">
                <Breadcrumb className={"mt-16 "}
                            links={[
                                {
                                    name: trans("info:maintenance")
                                }
                            ]}
                />
            </div>


            <div className=" container mb-36">

                <PageHeading
                    title={trans("info:Periodic-maintenance")}
                />

                <div className="flex flex-row grid-4-cols nice-gap-2rem ">
                    <div className="basis2/4 grow">
                        <DropDownInput

                            defaultOption={(brand == '0' || brand == null) ? {
                                text: trans("info:company"),
                                value: '0'
                            } : null}
                            options={settings?.brands?.map(item => {
                                return {text: item?.description, value: item?.brandID}
                            })}
                            selectedValue={brand}
                            onSelect={(value) => handelSelectBrand(value, requestForm?.year)}
                        />
                    </div>

                    <div className="basis2/4 grow">
                        <DropDownInput
                            errorLabel={error?.year}
                            defaultOption={{
                                text: trans("info:created_year"),
                                value: '0'
                            }}
                            // options={settings?.models?.map(item => {
                            //     return { text: item?.modelID, value: item?.modelID }
                            // })}

                            options={
                                settings?.models?.filter(
                                    item => item?.modelID?.startsWith('20')).map(
                                    item => ({
                                        text: item?.modelID,
                                        value: item?.modelID
                                    })
                                )
                            }

                            selectedValue={year}
                            onSelect={(value) => {
                                ////console.log("models:", value)

                                setYear(value);

                                const val = {...requestForm};
                                val.year = value;
                                setRequestForm(val);
                                handelSelectBrand(brand, value);
                            }}
                        />
                    </div>

                    <div className="basis2/4 grow">
                        <DropDownInput

                            defaultOption={((brand == '0' || brand == null) || (group == '0' || group == null)) ? {
                                text: trans("eparts:vehicle"),
                                value: '0'
                            } : null}
                            options={filteredSettings?.groups?.map(item => {
                                return {text: item?.description, value: item?.productGroupID}
                            })}
                            selectedValue={group}
                            onSelect={handleFilteredModels}
                        />
                    </div>

                    <div className="basis2/4 grow">
                        <DropDownInput
                            options={ServiceOptions}
                            errorLabel={error?.SerciveID}
                            onfilter={(option) => {

                                const val = {...requestForm};
                                val.SerciveID = option.value;
                                setRequestForm(val);

                                setSerciveID(null);
                                //console.log("setSerciveID>>>>>>>>>>>>>>>>>>>>>>>:", option.value);
                                setSerciveID(option.value);
                                FetchPrice(modelCode, option.value);

                            }}
                            isepart={true}
                            isLoading={lodingService}
                            selectedValue={SerciveID}
                            defaultOption={SerciveID == null || SerciveID == '0' ? {
                                value: '0',
                                text: trans("info:servce")
                            } : null}

                        />
                    </div>


                </div>


                <div className="panel-section panel-white bg-theme-up w-100 ">
                    <div
                        className={`panel-content  flex flex-col  nice-shadow-1 border-radius-sm   ${(indexPanel == "2000") ? "show-panel-info" : ""}`}>
                        <div className="panel-content-top px-10 nice-shadow-0">
                            {
                                // infoPrice[0].Price ?
                                <div className={""}>
                                    {/* السعر */}
                                    {/* <div className="nice-flex-row  ">
                                        <div className="nice-flex nice-flex-space ">
                                            <p className={`text-16 text-bold my-4 px-3 border-section-${lang == 'en' ? 'left' : 'right'}`}>{trans("info:price")}{":"}</p>

                                        </div>

                                        <div className=' text-30 text-primary flex flex-row gap-2 '>
                                            {
                                                infoPrice[0] && <Price price={infoPrice[0].Price} className={"white-space-pre"} />
                                            }



                                        </div>
                                    </div>
                                    {
                                        <div className="mx-2 mb-5 ">
                                            <img src='/assets/icons/info.svg' className="mx-3 text-inline-block nice-max-icon" />
                                            <p className="text-12 text-bold text-gray text-inline-block">{trans("info:service_price_head_message")}</p>
                                        </div>
                                    } */}
                                </div>
                                // : null
                            }


                            {
                                SerciveID ?
                                    <div className=" mb-1 ">
                                        <div className="nice-bg-btn bg-primary text-white text-24 px-20"
                                             onClick={(e) => {
                                                 e.preventDefault();
                                                 handleServiceOrder({
                                                     showPopup: true,
                                                     type: "sub2",
                                                     main: "0a5e145a-bf3f-4a8e-aa7b-d8f0a1e0d9b0",
                                                     sub1: "eb199b7e-55be-4ac8-b465-9a110b415ca8",
                                                     sub2: "2b995873-d703-47d8-9f58-f1df7f1e3979",
                                                     ServiceID: SerciveID,
                                                     brand: brand,
                                                     group: group,
                                                     year: year,
                                                     modelType: type,
                                                     serviceOptions:Service


                                                 });

                                             }}>
                                            {trans("info:reserve")} {trans("info:servce")}
                                        </div>
                                    </div>
                                    : null
                            }

                        </div>
                        {
                            indexPanel != "2000" ?
                                <div className='z-whole-pupup-s   py-2 px-10  nice-shadow-0 '>
                                    {
                                        Service && Service.find(a => a.ServiceID == SerciveID) && Service.find(a => a.ServiceID == SerciveID)[lang == "ar" ? "ServiceDetails" : "ServiceDetailsEn"] &&
                                        <>
                                            <ul className='text-30 text-line-clamp text-line-clamp-2 list-bullet'>

                                                {
                                                    Service.find(a => a.ServiceID == SerciveID)[lang == "ar" ? "ServiceDetails" : "ServiceDetailsEn"]?.replaceAll("\\n", "\n")?.split('\n')?.map((note, index) => {
                                                        return (

                                                            note &&
                                                            <li key={index} className=' text-25 text-gray'>
                                                                <span className="text-24 text-gray">{note}</span>
                                                            </li>
                                                        );
                                                    })
                                                }


                                            </ul>

                                            <span className="mx-10 text-30 text-bold text-primary">{"..."}</span>


                                        </>

                                    }

                                </div>
                                : null

                        }


                        <div className="panel-content-body  nice-shadow-0 ">
                            <div className="mx-10 my-10">

                                {
                                    Service && Service.find(a => a.ServiceID == SerciveID) && Service.find(a => a.ServiceID == SerciveID)[lang == "ar" ? "ServiceDetails" : "ServiceDetailsEn"] &&
                                    <>
                                        <ul className='text-30  list-bullet'>

                                            {
                                                Service.find(a => a.ServiceID == SerciveID)[lang == "ar" ? "ServiceDetails" : "ServiceDetailsEn"]?.replaceAll("\\n", "\n")?.split('\n')?.map((note, index) => {
                                                    return (

                                                        note &&
                                                        <li key={index} className=' text-20 text-gray'>
                                                            <span className="text-24 text-gray">{note}</span>
                                                        </li>
                                                    );
                                                })
                                            }
                                        </ul>
                                    </>

                                }

                            </div>
                        </div>

                        <div
                            className='flex flex-row nice-flex-center mb-5 w-100'


                            onClick={(e) => setIndexPanel(indexPanel == "2000" ? "000" : "2000")}
                        >

                            <img src="/assets/icons/arrow.svg"
                                 className={"" + (indexPanel == "2000" ? " rotate-180 " : "  ")}/>
                        </div>
                    </div>


                </div>
            </div>


            <AboutHJSection/>
            {
                maintenanceInfo &&
                <div className={"container mb-32 what-offer " + languageDir}>
                    <div className="what-offer-text">
                        <h3 className={"text-30 font-bold mb-8"}>{maintenanceInfo?.title ?? ''}</h3>
                        <p className={"text-20"}>
                            {maintenanceInfo?.desc ?? ''}
                        </p>
                    </div>
                    <div className="what-offer-boxes">
                        {maintenanceInfo && <>
                            {maintenanceInfo?.image1Path && <div className="what-offer-box overflow-hidden">
                                <img className={"object-full object-cover"}
                                     src={"data:image/png;base64," + maintenanceInfo.image1Path}
                                     alt={maintenanceInfo.title}/>
                            </div>
                            }
                            {maintenanceInfo?.image2Path && <div className="what-offer-box overflow-hidden">
                                <img className={"object-full object-cover"}
                                     src={"data:image/png;base64," + maintenanceInfo.image2Path}
                                     alt={maintenanceInfo.title}/>
                            </div>
                            }
                            {maintenanceInfo?.image3Path && <div className="what-offer-box overflow-hidden">
                                <img className={"object-full object-cover"}
                                     src={"data:image/png;base64," + maintenanceInfo.image3Path}
                                     alt={maintenanceInfo.title}/>
                            </div>
                            }
                        </>
                        }
                    </div>
                </div>
            }

            <div className={"container mb-32 " + languageDir}>
                <PageHeading
                    title={trans("info:main_services")}
                />
                <div className="">
                    {/* {

                        MainService && MainService?.length > 0 &&
                        <div className="nice-col-md-3 my-3">
                            <ServiceOrderButton
                                title={trans("info:main_service_order")}
                                onClick={(status) => {
                                    handleServiceOrder({
                                        showPopup: status,
                                        type: 'order', main: "", sub1: "", sub2: ""

                                    });
                                }}
                            />
                        </div>
                    } */}

                    <Swiper
                        spaceBetween={0}
                        slidesPerView={2}
                        breakpoints={{
                            576: {
                                slidesPerView: 4,
                            },
                        }}
                        pagination
                        centeredSlides={true}
                        initialSlide={1}
                        className={"mb-16"}
                        resizeObserver={"true"}
                        autoplay={false}
                        loop={false}
                        observer={true}
                    >
                        {
                            MainService?.map((item, index) => {
                                return (
                                    <SwiperSlide className={"px-3"} key={index}>
                                        <div
                                            key={index} className=" mb-12">
                                            <MaintenanceServiceBox
                                                image={item.img ? `url('${(config.mainURL + item.img.replaceAll("\\", "/"))}')` : `url('/assets/images/defult.png')`}
                                                to={(item?.MainServiceID == "0a5e145a-bf3f-4a8e-aa7b-d8f0a1e0d9b0" && item?.SubService1ID == null) ? `${path}/` + item.path : "#"}
                                                title={lang === 'ar' ? item.DescriptionAr : item.DescriptionEn}
                                                minPrice={50000}
                                                maxPrice={100000}
                                                onServiceOrder={() => {
                                                    //                   //console.log(item.Level);
                                                    handleServiceOrder({
                                                        showPopup: true,

                                                        type: item.Level,
                                                        main: item.MainServiceID,
                                                        sub1: item.SubService1ID,
                                                        sub2: item.SubService2ID

                                                    });
                                                }}
                                            />
                                        </div>
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                </div>
            </div>


            <div className=" container mb-36">

                <h3 className=" text-30 text-bold my-10">
                    {
                        trans("navbar:man_offer")
                    }
                </h3>
                <Swiper
                    spaceBetween={0}
                    slidesPerView={2}
                    breakpoints={{
                        576: {
                            slidesPerView: 4,
                        },
                    }}
                    pagination
                    className={"mb-16"}
                    resizeObserver={"true"}
                    autoplay={false}
                    loop={false}
                    observer={true}
                    navigation
                >
                    {
                        maintenanceOffers && maintenanceOffers?.length > 0 &&
                        maintenanceOffers?.map((offer, index) => {
                            return (
                                <SwiperSlide className={"px-3"} key={index}>
                                    <Offer img={offer.image}
                                           name={offer.title}
                                           type={offer.type}
                                           slug={slugCombine(offer.slug, offer.otherLink)}
                                           price={offer.minPrice}
                                           excerpt={offer.excerpt}
                                           totalDays={offer.totalDays}
                                           startDate={offer.startDate}
                                           endDate={offer.endDate}
                                    />
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
                {/* <div className="text-left">
                    <Link className={"link-btn-white"} to="/offers">
                        {trans("common:show-more")}
                    </Link>
                </div> */}
            </div>
            <WhyHJSection/>
            <TestimonialSection/>
        </>
    );
}
export default MainServicePage;