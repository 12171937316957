import { useEffect, useRef, useState } from "react";
import { quantity } from 'cart-localstorage'
import { Price } from "../../../components/__simple/_price";
import axios from "axios";
import config from "../../../configuration/config.json";
import { LikeButton } from "../../../components/__simple/_likeButton";
import { useTranslation } from "react-i18next";
import { useUserFavorite } from "../../../User/hooks/useUserFavorite";
import _ from "lodash";
import { useUserCart } from '../../../User/hooks/useUserCart';
import { getData } from "../../../utils/localStorageManager";
export function CartItems(
    { Account, img, brand, name, price, count, id, favorites, onFavoriteItemsChange, onDelete, Categior, Discount, Tax, Pay, favoriteItems, showCategor = true, productNo }
) {
    const { t: trans } = useTranslation(["cartFile", "new"]);
    const { isFavoriteItem, changeFavoriteItemState } = useUserFavorite();
    const [counter, setCounter] = useState(count);
    const { removecartItems, changecartItem } = useUserCart();
    const [lang, setlang] = useState(localStorage.getItem('i18nextLng'));
    const specRef = useRef(null);
    const [specHeight, setHeight] = useState(0);

    const countF = async (num) => {
        if (num === -1 && counter > 1)
            setCounter(counter + num);
        else if (num === 1 && counter < 100)
            setCounter(counter + num);
        if (num === -1 && counter === 1) {
            // onDelete();

            return;
        }
        console.log("part id:" + id);

        const isLogin = getData('isAuthenticated');
        if (isLogin) {
            const resultchangecartItem = await changecartItem(id, null, null, num, null);
            Account()
        } else {
            quantity(id, num);
            Account()
        }
      

    }

    function showData() {

        setHeight(specHeight <= 0 ? specRef.current.scrollHeight : 0);
    }

    const [isFavorite, setIsFavorite] = useState(false);
    const [tempFavorites, setTempFavorites] = useState(favoriteItems);

    const [CartInfo, setCartInfo] = useState(null);
    async function PartCartInfo() {

        const Body = {
            "name": "Site_FeatchCartPartInfo",
            "values": {
                "ProductID": `${id}`
            }
        }
        await axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {

                var CuurentValue = {};
                CuurentValue.Brand = lang === "ar" ? response.data[0].BrandAr : response.data[0].BrandEn;
                CuurentValue.category = lang === "ar" ? response.data[0].categoryAr : response.data[0].categoryEn;
                CuurentValue.Description = lang === "ar" ? response.data[0].DescriptionAr : response.data[0].DescriptionEn;

                // CuurentValue.img = response.data[0].img ? "data:image/png;base64," + response.data[0].img : "/assets/images/eparts/default.png";
                CuurentValue.img = response.data[0].img 
                    ? "data:image/png;base64," + response.data[0].img 
                    : lang.toLowerCase() === "ar" ?  "/assets/images/eparts/PARTS_TOYOTA-AR-no-img.png" : "/assets/images/eparts/PARTS_TOYOTA-EN-no-img.png";

                CuurentValue.ProductNo = response.data[0].ProductNo;

                console.log("CuurentValue>>>>>>>>>>>>>>>>>>>>>>>>:",CuurentValue);
                setCartInfo(CuurentValue);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        //handleFavoriteState();
        PartCartInfo();
    }, []);

    return (<div>

        <div className={"cart-card p-8 mb-8"}>
            <div className={"bg-secondary border-radius p-10 "}>
                <img className={"object-full product-cart-image object-contain"} 
                     alt={productNo.toString()} 
                     src={CartInfo?.img ?? "/assets/images/eparts/PARTS_TOYOTA-AR-no-img.png" } />
            </div>
            <div className="cart-detail">
                <div className="nice-flex nice-flex-row">
                    <div className="nice-flex-1 nice-gap-3rem nice-flex nice-flex-column">
                        <div className="cart-detail-container">
                            <h3 className={"text-16"}>{trans("new:category_no")}</h3>
                            <p className={"text-16 font-bold"}>{productNo}</p>
                        </div>
                        {
                            showCategor && <div className="cart-detail-container">
                                <h3 className={"text-16"}>{trans("new:category")}</h3>
                                <p className={"text-16 font-bold"}>{CartInfo?.category}</p>
                            </div>
                        }

                        <div className="cart-detail-container">
                            <h3 className={"text-16"}>{trans("new:description")}</h3>
                            <p className={"text-16 font-bold"}>{CartInfo?.Description}</p>
                        </div>
                    </div>
                    <div className="nice-flex nice-flex-column nice-gap-2rem">
                        <div className="nice-flex nice-flex-row nice-justifiy-end nice-align-item-center">
                            <button onClick={onDelete} className={`cart-delete cart-delete-${lang == 'en' ? 'right' : 'left'}`}>
                                <img src={"/assets/icons/trash.svg"} alt={"minus"} />
                            </button>
                            <LikeButton long={true}
                                favorite={Boolean(isFavoriteItem(id))}
                                onToggle={() => { changeFavoriteItemState(id) }}
                            />
                        </div>
                        <div style={{ display: "flex", flexFlow: "column nowrap" }}>
                            <div className={"flex items-center"}>
                                <div className={"text-12 ml-4"}>{trans("new:single_price")}</div>
                                <div className={"text-20 font-bold mx-3 text-primary"}><Price price={price} /></div>
                            </div>
                            <div className={"flex items-center"}>
                                <div className={"text-12 ml-4"}>{trans("cartFile:discount_rate")}</div>
                                <div className={"text-20 font-bold mx-3 text-primary"}>{Discount}%</div>
                            </div>
                        </div>
                        <div>
                            <div className={"cart-counter"}>
                                <h3 className={"cart-counter-title text-12"}>{trans("cartFile:amount")}</h3>
                                <div className="nice-flex nice-flex-row ">
                                    <button onClick={() => countF(+1)} className="cart-counter-btn">
                                        <img src={"/assets/icons/plus.svg"} alt={"plus"} />
                                    </button>
                                    <div className={"cart-counter-num text-20 font-bold"}>{counter}</div>
                                    <button onClick={() => countF(-1)} className="cart-counter-btn">
                                        <img src={"/assets/icons/minus.svg"} alt={"minus"} />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}
