import { useEffect } from "react";
import { useState } from "react";
import {NavWorkHours} from "./components/navWorkHours";
import config from '../../../configuration/config.json';
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useHistory } from "react-router";
export function NavAbout({...prop}) {
    const { t: trans } = useTranslation("navbar");
    const aboutList = [
    {description:trans("navbar:about_hj"),to:'whyHjElement'},
    {description:trans("navbar:info_about_us"),to:'aboutUsElmenet'},
    {description:trans("navbar:about_rewards"),to:'rewardsElement'},
    {description:trans("navbar:about_what_our_cutomers_says"),to:'customersSaysElement'},
    {description:trans("navbar:about_principle"),to:'workPricipleElement'},
    ];
   const history = useHistory();
   //----------------------------------------------------
   const [aboutOwner,setAboutOwner] = useState({
       title:'',
       description:''
   });
   //---------------------------------------------------
   useEffect(()=>{
   intial();
   },[]);
   //---to intialize the hj description from request----
   const intial = ()=>{
    axios.post(
        config.baseURL + 'details',
        {
         "Object":"siteAboutPage",
         "Option":"Column",
         "Fields":"whatTitleAr,whatDescAr,whatTitleEn,whatDescEn",
         "ObjectSettings":{
             "MetaData":false
         }
        }
    ).then(response=>{
     const responseData = response.data.ApiObjectData;
     if(responseData.length > 0){
         const responseResult = responseData[0];
         const finalResult = {...aboutOwner};
         finalResult.title = responseResult[`${trans("navbar:about_title_model")}`];
         finalResult.description = responseResult[`${trans("navbar:about_desc_model")}`];
         setAboutOwner(finalResult);
     }
    }).catch(error=>{
    console.log(error);
    });
   };
   //--- to navigate to about section elements
   const navigateToElement = (elementID) =>{
    history.push('/about');
    try{
    setTimeout(()=>{
        document.getElementById(elementID).scrollIntoView()
    },250);
    }catch(error){
        console.log(error);
    }
   };

    return (
        <div className={"nav-mega-menu bg-white box-shadow border-radius  " + prop.className}>
            <div className="my-4 text-20 p-8 border-radius nav-grid-3-cols">
                <div>
                    <h3 className="nav-heading">
                       {aboutOwner?.title}
                    </h3>
                    <div className="nav-about-container">
                        <div className="nav-about-container-box"></div>
                        <p className={"text-20 nav-about-container-text"}> 
                        {aboutOwner?.description}
                        </p>
                    </div>
                </div>
                <div className={"nav-grid-middle"}>
                    <h3 className="nav-heading">
                       {trans("navbar:info_about_us")}
                    </h3>
                    <ul className={"nav-maintenance-links"}>
                      {
                          aboutList.map((ele,index)=>{
                             return (<li key={index}>
                                  <a style={{cursor:'pointer'}} onClick={()=>navigateToElement(ele.to)}>
                                      {ele.description}
                                 </a>
                             </li>)
                          })
                      }
                    </ul>
                </div>
                <div>
                    <h3 className="nav-heading">
                       {trans("navbar:work_hours")}
                    </h3>
                    <NavWorkHours department={12}/>
                </div>
            </div>
        </div>
    );
}