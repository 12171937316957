import axios from "axios";
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useParams, useRouteMatch } from "react-router"
import SwiperCore, { Pagination, Navigation, Autoplay, EffectFade } from "swiper";
import { Swiper } from 'swiper/react';
import PageHeading from "../../../components/__simple/_pageheading"
import { WhyHJSection } from "../../../sections/whyHJSection";
import MainSubServiceOffer from "../components/mainSubServiceOffer";
import { MaintenanceServiceBox } from "../components/maintenance";
import ServiceBanner from "../components/serviceBanner";
import ServicesList from "../components/servicesList";
import MaintenanceContext from "../maintenanceContext";
import ServiceFeatureList from "../components/serviceFeature";
import Breadcrumb from "../../../components/__simple/breadcrumb";
import ServiceHeaderBanner from "../components/serviceHeaderBanner";
import config from "../../../configuration/config.json";
SwiperCore.use([Pagination, Navigation, Autoplay, EffectFade]);
const SubMainServicePage = () => {
    const { t: trans, i18n } = useTranslation(["info", "common"]);
    const { handleServiceOrder } = useContext(MaintenanceContext);
    const { path } = useRouteMatch()
    const languageDir = 'language-' + i18n.language;
    let { ID } = useParams();
    const [SubService, setSubService] = useState([]);
    function fetchSubService(IDMain) {
        const Body = {
            "name": "site_fetchMntc_SubService1",
            "values": {
                "MainID": IDMain
            }
        }

        axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {
                // console.log(response.data.ApiObjectData);
                setTimeout(setSubService(response.data), 1000);

            })
            .catch(function (error) {
                console.log(error);
            });



    }

    const [MainInfo, setMainInfo] = useState([]);
    function fetchMainInfo(IDMain) {
        const Body = {
            "name": "site_fetchMainService_Info",
            "values": {
                "Main": IDMain
            }
        }

        axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {
                // console.log(response.data.ApiObjectData);
                setTimeout(setMainInfo(response.data), 1000);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [Special, setSpecial] = useState([]);
    function fetchFetchMaintenanceSpecial() {
        const Body = {
            "name": "Site_FetchMaintenanceSpecial"
        }

        axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {
                // console.log(response.data.ApiObjectData);
                setTimeout(setSpecial(response.data), 1000);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const [lang, setlang] = useState(localStorage.getItem('i18nextLng'));
    useEffect(() => {
        fetchSubService(ID); fetchFetchMaintenanceSpecial();
        setTimeout(() => { fetchMainInfo(ID) }, 100);
    }, []);
    return (
        <>
            {

                MainInfo.map((p) => {

                    return (<ServiceHeaderBanner
                        bannerDescriptor={{ image: p?.banner, subTitle: '', title: lang === "en" ? p.DescriptionEn : p.DescriptionAr }}
                        hasRedirect={false}
                    />)
                })
            }

            <div className="container">
                <Breadcrumb className={"mt-16 "}
                    links={[
                        {
                            name: trans("info:maintenance"),
                            to: '/maintenance'
                        },
                        {
                            name: trans("info:main_sub_main_service"),
                            to: `${path}`
                        }
                    ]}
                />
            </div>
            <div className={"container mb-32 " + languageDir}>
                <PageHeading
                    title={trans("info:main_sub_main_service")}
                />
                <div className="nice-row">
                    {
                        SubService.map(item => {
                            return <div className="nice-col-md-3 nice-col-sm-6 nice-col-6 mb-12">
                                <MaintenanceServiceBox
                                    image={item.img ? `url('${(config.mainURL + item.img.replaceAll("\\", "/"))}')` : `url('/assets/images/defult.png')`}
                                    to={"#"}
                                    title={lang === 'ar' ? item.DescriptionAr : item.DescriptionEn}
                                    maxPrice={100000}
                                    minPrice={50000}
                                    onServiceOrder={() => {
                                        console.log(item.Level);
                                        handleServiceOrder({
                                            showPopup: true,
                                            type: item.Level, main: item.MainServiceID, sub1: item.SubService1ID, sub2: item.SubService2ID

                                        });
                                    }}
                                />
                            </div>
                        })
                    }
                </div>

                {
                    Special && Special.length > 0 &&
                    <ServiceFeatureList
                        title={trans("info:main_feature")}
                        values={Special.map((Sp) => { return { title: lang === "ar" ? Sp.titleAr : Sp.titleEn, image: "data:image/png;base64," + Sp.icon } })}
                    />

                }


            </div>
            <WhyHJSection />
        </>
    );
}
export default SubMainServicePage;