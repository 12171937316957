import PageHeading from "../components/__simple/_pageheading";

import WhyHJBox from "../components/__simple/_whyBox";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import config from "../configuration/config.json";

export function WhyHJSection({ secondTheme = false, subtitle, title }) {
    const { t: trans } = useTranslation(["eparts", "info"]);
    let lang = localStorage.getItem("i18nextLng");
    const languageDir = 'language-' + lang;
    const [whyInfo, setWhyInfo] = useState([]);
    useEffect(async () => {
        try {


            try {

                if ("caches" in window) {
                    let url = "WhyHj-"+lang;
    
                        // Opening that particular cache
                        const cacheStorage = await caches.open("HJWeb");
                        // Fetching that particular cache data
                        const cachedResponse = await cacheStorage.match(
                            url
                        );
                        let data = await cachedResponse?.json();
                        setWhyInfo(data.data);
                   
                }
    
            } catch (error) {
            }


            const response = await axios.request({
                baseURL: config.controllURL,
                url: lang + "/api/sections/WhyHj",
                method: "get",
            });
            setWhyInfo(response.data)

            const data = new Response(JSON.stringify(response));

            if ("caches" in window) {
                // Opening given cache and putting our data into it
                caches.open("HJWeb").then((cache) => {
                    cache.put("WhyHj-"+lang, data);

                });
            }
        } catch (error) {

        }

    }, []);

    return (

        <div className={`  mb-32 ${languageDir}`} id='whyHjElement'>
            <div className={"container"}>
                <PageHeading
                    subtitle={subtitle ? subtitle: trans("info:HJ")}
                    title={title ?title : trans("eparts:WhyHj")}
                />
            </div>
            <div className={secondTheme ? "bg-secondary-light" : ""}>
                <div className="why-container container ">
                    {
                        whyInfo.map((item,index) => {
                            return (
                                <WhyHJBox
                                    key={index}
                                    icon={"data:image/image;base64," + item.imagePath}
                                    title={item.name}
                                    subtitle={item.content}
                                    secondTheme={secondTheme}
                                />
                            );
                        })
                    }

                </div>
            </div>
        </div>
    );
}