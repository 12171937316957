import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {Price} from "../../../components/__simple/_price";
import {useState} from "react";
// import {addToCart} from "../../../_functions/cart";
import { useUserCart } from '../../../User/hooks/useUserCart';

export function Item({hasLike = false , ...props}) {
    const {t: trans} = useTranslation(["common", "eparts"]);
    let imagePath = "/assets/images/eparts/defulte.png";

    const { removecartItems, changecartItem } = useUserCart();
    imagePath = (props.ProductImage) ? "data:image/png;base64," + props.ProductImage : imagePath;
    const [lang, setlang] = useState(localStorage.getItem('i18nextLng'));
    const languageDir = 'language-' + localStorage.getItem('i18nextLng');
    let Description = "Description....";
    Description = lang === "ar" ? props.DescriptionAr : props.DescriptionEn;
    return (

        <div className={"part-card border-radius "}>

            <Link to= {"/eparts/detail/"+props.GUID}>
                <div className="part-img">

                    <img src={imagePath} alt=""/>

                </div>
            </Link>
            <div className={"part-card-info border-radius box-shadow " + languageDir}>
                <div

                    onClick={() => {

                        var objectCart =
                             {
                                id: props?.ProductID,
                                name: props?.DescriptionAr,
                                price: props?.NetSalePrice,
                                image: imagePath,                
                                Type:"accessories",
                                Engine_Groups:props?.Engine_Groups
                            }
                        changecartItem(props?.ProductID, null, null, 1,null,null,objectCart);
                        // addToCart(
                        //     {
                        //         id: props.ProductID,
                        //         name: props.DescriptionAr,
                        //         price: props.NetSalePrice,
                        //         image: imagePath,                
                        //         Type:"accessories",
                        //         Engine_Groups:props.Engine_Groups
                        //     }
                        // )
                        window.location.reload();

                    }}
                    className="part-card-info-cart text-16 btn btn-primary">
                    {trans("add-to-cart")}
                    <img className={"inline filter-white size-20 pr-1"} src="/assets/icons/cart.svg"
                         alt={trans("buy")}/>
                </div>

                <Link to={"/eparts/detail/"+props.GUID}>

                    <div className="part-card-info-name mb-2">
                        <h3 className={"text-16 font-bold  text-line-clamp text-line-clamp-1"}>
                            {trans("eparts:product_id")} : {props.ProductID}
                        </h3>
                    </div>
                    <p className={"text-16 mb-4  text-line-clamp text-line-clamp-1"}>{Description}</p>
                    <div className="part-options">
                        <div className="options-price">
                            <h4 className={"text-16 font-bold inline-block text-primary"}>
                                <Price price={props.NetSalePrice}/>
                            </h4>
                        </div>

                    </div>
                </Link>
            </div>

        </div>

    );
}

