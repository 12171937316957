import { Fragment, useCallback, useEffect, useState } from "react";
import SwiperCore, { Pagination, Navigation, Autoplay, EffectFade } from "swiper";
import PageHeading from "../../components/__simple/_pageheading";
import { useTranslation } from "react-i18next";
import { CarSecondary } from "./components/carSecondary";
import { Swiper, SwiperSlide } from "swiper/react";
import Car from "../../components/car";
import { FilterButton } from "../../components/__simple/_filterButton";
import { OfferSections } from "../../sections/offersSection";
import Breadcrumb from "../../components/__simple/breadcrumb";
import InfoCircle from "../../components/__simple/_infoCirclejsx";
import Info from "../../components/_info";
import { useDispatch } from "react-redux";
import { changeIcons } from "../../_redux/slices/navSlice";
import { useParams } from "react-router";
import axios from "axios";
import AdvancedSearch from "../../components/_input/advancedSearch";
import { Link } from "react-router-dom";
import { slugSplitter, slugCombine } from "../../utils/slugManager";
import config from "../../configuration/config.json";
import { Helmet } from "react-helmet";
SwiperCore.use([Pagination, Navigation, Autoplay, EffectFade]);

export function VehiclePage() {
    const { t: trans, i18n } = useTranslation(["inputs", "heading", "vehicales", "info"]);
    const [brandInfo, setBrandInfo] = useState({});
    const [vehicles, setVehicles] = useState([]);
    const [filteredVehicles, setFilteredVehicles] = useState(vehicles);
    const [vehiclesFilter, setVehiclesFilter] = useState(null);
    const [Featured, setFeatured] = useState([]);
    const GetUrl = config.baseURL + 'details';
    const [filters, setFilters] = useState([]);
    const [offerVehicle, setOfferVehicle] = useState([]);
    const dispatch = useDispatch();
    const [searchPopup, setSearchPopup] = useState(false);
    let { brand } = useParams();
    const [CarCategories, setCarCategories] = useState([]);
    const [seoPageInfo, setSeoPageInfo] = useState({});
    let lang = i18n.language;
    const languageDir = 'language-' + lang;

    function RequsetFeatured() {
        const Body = {
            "object": "SiteVehicleSpecial",
            "ObjectSettings": { "MetaData": false }
        };

        axios.request({
            url: GetUrl,
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {
                setFeatured(response.data.ApiObjectData);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        callback();
    }, [brand]);


    const callback = useCallback(async () => {
        try {
            setFilteredVehicles([]);
            setVehicles([]);
            setOfferVehicle([]);
            setFilters([]);
            setBrandInfo({});


            try {

                try {

                    if ("caches" in window) {
                        var curl = "site_FetchCarCategories";

                        // Opening that particular cache
                        const cacheStorage = await caches.open("HJWeb");
                        // Fetching that particular cache data
                        const cachedResponse = await cacheStorage.match(
                            curl
                        );
                        let data = await cachedResponse?.json();
                        setCarCategories(data)

                    }

                } catch (error) {
                }


                const response = await axios.post(
                    config.baseURL + 'func',
                    {
                        "Name": "site_FetchCarCategories"
                    }
                );
                if (response.status == 200) {

                    setCarCategories(response.data)
                    const data44 = new Response(JSON.stringify(response.data));

                    if ("caches" in window) {
                        // Opening given cache and putting our data into it
                        caches.open("HJWeb").then((cache) => {
                            cache.put("site_FetchCarCategories", data44);

                        });
                    }


                }
            } catch (error) {
                console.log('error in fetching the countries of SAU', error);
            }


            const res = await axios.request({
                baseURL: config.controllURL,
                url: lang + "/api/home/vehicles/" + slugSplitter(brand),
                method: "get",
            });

            setVehicles(res.data.vehicles);
            const brands = await axios.request({
                baseURL: config.controllURL,
                url: lang + "/api/vehicles/brand/" + slugSplitter(brand),
                method: "get",
            });
            const homeExtraRes = await axios.request({
                baseURL: config.controllURL,
                url: lang + "/api/home/HomeExtra",
                method: "get",
            });
            setOfferVehicle(homeExtraRes.data.offerVehicle);

            const allCategories = brands?.data?.categories;
            var filterHasVehicals = brands?.data?.categories;
            try {


                filterHasVehicals = allCategories?.filter(f => (res?.data?.vehicles?.filter(c => (c?.category == f?.category))).length > 0);


            } catch (error) {

            }

            console.log("filterHasVehicals:", filterHasVehicals);
            setFilters(filterHasVehicals);
            setBrandInfo(brands?.data?.brandInfo);

        } catch (error) {
            console.log(error);
        }
        RequsetFeatured();
    }, [brand]);

    // to change the icons color on the navbar
    useEffect(() => {
        dispatch(
            changeIcons({ iconsBlack: true })
        )

        return () => {
            dispatch(
                changeIcons({ iconsBlack: false })
            )
        }
    }, []);

    useEffect(() => {

        if (vehicles && vehicles.length > 0)
            filterVehicles(vehiclesFilter);
    }, [vehicles]);

    function filterVehicles(category) {
        setVehiclesFilter(category);
        let filterData;
        if (category != null)
            filterData = vehicles.filter((car) => {
                return car.category === category;
            });
        else {
            filterData = vehicles;
        }
        setFilteredVehicles(filterData);
        console.log("filteredVehicles", filteredVehicles);
    }


    useEffect(async () => {

        var page = ""

        if (brand?.includes("Toyota")) {

            page = "Toyota cars"
        } else {
            page = "Lexus Cars"

        };
        const response = await axios.post(
            config.baseURL + 'func',
            {
                "Name": "SiteFetchSeoPages",
                "Values": {
                    "page": page
                }
            }
        );
        if (response.status == 200) {

            var resdata = response.data[0];

            setSeoPageInfo(resdata);


        }
    }, [brand]);

    return (
        <Fragment>

            {
                seoPageInfo &&

                <Helmet>
                    <meta charSet="utf-8" />
                    {
                        seoPageInfo?.title &&
                        <title>{lang.toLowerCase() == 'ar' ?  seoPageInfo?.title : seoPageInfo?.titleEn?? seoPageInfo?.title }</title>
                    }
                    {
                        seoPageInfo?.link &&
                        <link rel="canonical" href={seoPageInfo?.link} />

                    }
                    {
                        seoPageInfo?.MetaDescription &&
                        <meta name="description" content={lang.toLowerCase() == 'ar'? seoPageInfo?.MetaDescription : seoPageInfo?.MetaDescriptionEn ?? seoPageInfo?.MetaDescription } />
                    }
                    {
                        seoPageInfo?.MetaGroups &&
                        <meta name="keywords" content={lang.toLowerCase() == 'ar'? seoPageInfo?.MetaGroups : seoPageInfo?.MetaGroupsEn ?? seoPageInfo?.MetaGroups } />
                    }
                </Helmet>
            }

            <div className="container mt-xs " >
                <Breadcrumb className={"mt-16 "}
                    links={[
                        {
                            name: brandInfo?.name,
                        }
                    ]}
                />
            </div>
            <div className="container mb-32 ">
                <PageHeading
                    fixed={true}
                    subtitle={brandInfo?.name && trans("vehicales:showroom").replace("{brand}", brandInfo?.name)}
                    title={brandInfo?.name && trans("vehicales:showroom_desc").replace("{brand}", brandInfo?.name)}
                />
                {/* <div className={"slide-car-secondary-container my-20 " + languageDir}>
                    <Swiper
                        className={"slide-car-secondary"}
                        spaceBetween={40}
                        slidesPerView={1}
                        breakpoints={{
                            576: {
                                slidesPerView: 3,
                            },
                        }}
                        initialSlide={1}
                        navigation
                        centeredSlides={true}

                        resizeObserver={"true"}
                    >
                        {
                            vehicles?.map((vehicle, index) => {
                                if (vehicle.showOnHomePage && parseFloat(vehicle.minPrice) != 0) {
                                    return (
                                        <SwiperSlide
                                            key={index}
                                        >
                                            <CarSecondary
                                                qty={vehicle.qty}
                                                type={vehicle.type}
                                                slug={vehicle.slug}
                                                year={vehicle.year}
                                                colorID={vehicle.colorID}
                                                id={vehicle.productId}
                                                img={vehicle.image}
                                                title={vehicle.name}
                                                brand={vehicle.brand}
                                                brandID={vehicle?.brandID}
                                                category={vehicle.category}
                                                minPrice={vehicle.minPrice}
                                                Tax={vehicle.Tax}
                                                RateCareDiscount={vehicle.RateCareDiscount}
                                                newCar={vehicle.newCar}
                                                hybird={vehicle.hybird}
                                                desc={vehicle.desc}
                                                group={vehicle.group}
                                                modelTypes={vehicle.modelTypes}
                                                showPric={vehicle.showPric}
                                                buyOnline={vehicle?.buyOnline}
                                                link={"/vehicles/" + slugCombine(vehicle?.brand, vehicle?.otherBrand) + "/" + vehicle.slug}
                                            />
                                        </SwiperSlide>
                                    );
                                }
                            })
                        }

                    </Swiper>
                </div> */}

                {/* <div className={"mb-20 vehicle-brand " + languageDir}>

                    <div className={" vehicle-brand-name " + (brandInfo.id == "1" ? "text-toyota" : "text-lexus")}>
                        {brandInfo.name}
                    </div>
                    <div className={"vehicle-brand-search"}>
                        <h3 className={"text-16 mb-6 text-gray"}>{trans("info:advance_search_note")}</h3>
                        <Link to='/search' className={"px-6 py-2 inline-flex items-center  justify-between box-shadow bg-white border-radius-full"}>
                            <span className={"text-16 font-bold pl-4"}>{trans("info:advance_note")}</span>
                            <img className={"size-40"} src="/assets/icons/car-search.svg" alt=""/>
                        </Link>
                    </div>
                </div> */}


                <div className={" mb-32 mt-32 " + languageDir}>
                    <div className={"vehicle-filter-group start mb-24 mx-auto"}>

                        <FilterButton
                            key={-1}
                            onclick={() => filterVehicles(null)}
                            name={trans("inputs:all")} isActive={vehiclesFilter === null} />

                        {
                            // filters?.map(({ category }) => {
                            //     return (
                            //         <FilterButton
                            //             onclick={() => filterVehicles(category)}
                            //             name={category} isActive={vehiclesFilter === category} />
                            //     );
                            // })

                            CarCategories && filters && CarCategories.length > 0 && filters?.length > 0 &&
                            CarCategories?.map((item, index) => {

                                if (item && item[lang == "ar" ? "NameAr" : "NameEn"] && filters.filter(a => a.category == item[lang == "ar" ? "NameAr" : "NameEn"])?.length > 0) {
                                    return (
                                        <FilterButton
                                            key={index}
                                            onclick={() => filterVehicles(item[lang == "ar" ? "NameAr" : "NameEn"])}
                                            name={item[lang == "ar" ? "NameAr" : "NameEn"]} isActive={vehiclesFilter == item[lang == "ar" ? "NameAr" : "NameEn"]} />);
                                } else { return null }
                            })

                        }

                    </div>
                    <div className="nice-row mb-16 vehicle-gap">
                        {
                            filteredVehicles?.map((vehicle, index) => {
                                // if (parseFloat(vehicle.minPrice) != '0')
                                return (
                                    <div key={index} className="nice-col-lg-3 nice-col-md-4 nice-col-sm-6 my-20">
                                        <Car
                                            key={index}
                                            group={vehicle.group}
                                            slug={vehicle.slug}
                                            year={vehicle.year}
                                            colorID={vehicle.colorID}
                                            gifts={vehicle.countGifts}
                                            groupType={vehicle.carGroupID}
                                            modelType={vehicle.type}
                                            hybridImage={vehicle.hybridImage}
                                            brandImage={vehicle.BrandImage}
                                            discountPrice={vehicle.discount}
                                            ProductId={vehicle.productId}
                                            img={vehicle.image}
                                            name={vehicle.name}
                                            brand={vehicle.brand}
                                            brandID={vehicle?.brandID}
                                            category={vehicle.category}
                                            minPrice={vehicle.minPrice}
                                            Tax={vehicle.Tax}
                                            RateCareDiscount={vehicle.RateCareDiscount}
                                            newCar={vehicle.newCar}
                                            hybird={vehicle.hybird}
                                            desc={vehicle.desc}
                                            modelTypes={vehicle.modelTypes}
                                            link={"/vehicles/" + slugCombine(vehicle.brand, vehicle.otherBrand) + "/" + vehicle.group + "/" + vehicle.year}
                                            brandIcon={vehicle.brandIcon}
                                            type={vehicle.type}
                                            showPric={vehicle.showPric}
                                            buyOnline={vehicle?.buyOnline}
                                        />
                                    </div>
                                )
                            })
                        }
                    </div>

                </div>

            </div>
            <div className="container mb-32">
                <Info />
            </div>
            {
                offerVehicle &&
                <OfferSections title={trans("info:vehicle_offers")} offersList={offerVehicle} />
            }
            <div className={"container mb-32 " + languageDir}>
                <div className="mx-20 flex flex-row justify-center flex-wrap items-center nice-gap-1rem">

                    {Featured?.map(a => (

                        <InfoCircle
                            icon={"data:image/image;base64," + a.icon}
                            titleAr={a.titleAr}
                            titleEn={a.titleEn}
                        />
                    ))}


                </div>
            </div>
        </Fragment>
    );
}