import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import axios from "axios";
import config from "../../configuration/config.json";
import { User_Data_Key, getData, saveData } from "../../utils/localStorageManager";
function Navlink({ title, to, icon, state, handelonClick, ...props }) {
  return (
    <NavLink {...props} to={to} className={"sidebar-link " + state}
      onClick={() => {
        if (handelonClick)
          handelonClick();
      }}>
      <img src={"/assets/user_profile/icons/" + icon} alt={title} />
      <div className={"text-16 link-text"}>{title}</div>
    </NavLink>
  );
}

export default function UserNav({ url }) {
  const defaultProfileImage = '/assets/images/profile.jpg';
  const { t: trans } = useTranslation(["common", "model", "info", "user", "home"]);
  const lang = localStorage.getItem("i18nextLng");
  const pageDir = 'language-' + lang;
  const handleLayout = () => {
    const isLagout = window.confirm(trans("common:lagout-confirm"));
    if (isLagout) {
      window.localStorage.clear();
      saveData("i18nextLng", lang, false);
      deleteAllCookies();
      window.location = '/';
    }
  };
  const [showItems, setShowItems] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [names, setNames] = useState('');
  const [imageProfile, setImageProfile] = useState(defaultProfileImage);
  const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays);
    return blob;
  };

  useEffect(() => {
    try {
      const _temp = JSON.parse(getData(User_Data_Key, true));
      setUserInfo(_temp);
      const firstName = _temp[`${lang}FirstName`];
      const lastName = _temp[`${lang}LastName`];
      let finalResult = trans("info:no_set_names");
      if (firstName)
        finalResult = firstName + ' ' + lastName;
      setNames(finalResult);
    } catch (error) {
      console.log(error);
    }


    intialUserData();
  }, []);
  const intialUserData = async () => {


    try {
      const userKey = getData('secretKey');
      console.log(">>>>>>>>>>>>>>>>>GetUserImage>>>>>>>>userKey>>>>>>>>>>:", userKey)
      const settingsResponse = await axios.request({
        baseURL: config.controllURL,
        url: lang + "/api/user/GetUserImage",
        method: "get",
        params: {
          ID: userKey
        },
      });

      if (settingsResponse.status === 200) {
        const imgeUser = settingsResponse?.data?.userImage?.Logo;

        if (imgeUser) {
          setImageProfile(URL.createObjectURL(b64toBlob(imgeUser)));
        } else {
          setImageProfile(defaultProfileImage);
        }
      }

    } catch (err) {
      console.log(err);
    }
  }

  const deleteAllCookies = () => {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
      document.cookie = name + "=;max-age=-1";
    }

  };
  return (
    <>
      {

        <div className="user-sidebar-button mb-10">
          <div className="bg-black border-radius-sm " onClick={() => { setShowItems(!showItems) }}>
            <img src="/assets/icons/menu.svg" className="" />
          </div>
        </div>

      }
      <div className={`user-sidebar  ${pageDir} ${showItems ? " user-sidebar-show " : " user-sidebar-hidden"}`}>
        <div className={`user-sidebar-header `}>

          <div className="user-sidebar-header-phone mb-10">
            {
              <div className="user-sidebar-header-phone-icon">
                <div onClick={() => { setShowItems(!showItems) }} className="text-black  nice-flex nice-flex-center nice-pointer user-page-content-phone-items-icon-cancel">
                  &times;
                </div>
              </div>
            }
          </div>
          <div className={`user-sidebar-header-items nice-border-top-section `}>
            <div className="logout flex " onClick={handleLayout}>
              <img src="/assets/user_profile/icons/logout.svg" alt="logout" />
              <span className="text-20 text-error mx-10">{trans("user:logout")}</span>
            </div>
            <div className="user-profile-box nice-border-top-section pt-5">
              <div className='user-profile-box-image' style={{ backgroundImage: `url('${imageProfile}')` }}>
              </div>
              <h3 className={"profile-name ellipsis"} title={names}>{names}</h3>
            </div>

            <div className="user-sidebar-nav nice-border-top-section pt-5">
              <Navlink exact to={`${url}`} title={trans("common:home")} icon={"home.svg"} handelonClick={()=>setShowItems(false)} />
              <Navlink to={`${url}/profile`} title={trans("user:profile")} icon={"user.svg"} handelonClick={()=>setShowItems(false)} />
              <Navlink to={`${url}/notifications`} title={trans("user:notifications")} icon={"notification.svg"} handelonClick={()=>setShowItems(false)} />
              <Navlink to={`${url}/vehicles`} title={trans("common:vehicle")} icon={"car.svg"}handelonClick={()=>setShowItems(false)} />
              <Navlink to={`${url}/favorite`} title={trans("user:favorite")} icon={"favorite.svg"}handelonClick={()=>setShowItems(false)} />
              <Navlink to={`${url}/offers`} title={trans("home:offers")} icon={"offer.svg"} handelonClick={()=>setShowItems(false)} />
              <Navlink to={`${url}/myOrders`} title={trans("user:myOrders")} icon={"history.svg"} handelonClick={()=>setShowItems(false)} />
              <Navlink to={`${url}/myBookings`} title={trans("info:my-bookings")} icon={"offer.svg"} handelonClick={()=>setShowItems(false)} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}