import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { User_Data_Key } from "../../utils/localStorageManager";
const PhoneMenu = ({ url }) => {
    const history = useHistory();
    const [activeItem, setActiveItem] = useState(0);
    const [showItems, setShowItems] = useState(false);
    const { t: trans } = useTranslation("common");
    const [items, setItems] = useState([
        { path: "/assets/user_profile/icons/home.svg", title: 'home', to: '/' },
        { path: "/assets/user_profile/icons/user.svg", title: 'profile', to: '/profile' },
        { path: "/assets/user_profile/icons/notification.svg", title: 'notification', to: '/notifications' },
        { path: "/assets/user_profile/icons/car.svg", title: 'car', to: '/vehicles' },
        { path: "/assets/user_profile/icons/offer.svg", title: 'offer', to: '/offers' },
        { path: "/assets/user_profile/icons/favorite.svg", title: 'favorite', to: '/favorite' },
        { path: "/assets/user_profile/icons/history.svg", title: 'history', to: '/myOrders' }
    ]);
    const handleActiveItem = (to, index) => {
        setActiveItem(index);
        history.push(`${url}${to}`);
    };
    const handleSwicth = (state) => {
        setShowItems(state);
    };

    const deleteAllCookies = () => {
        var cookies = document.cookie.split(";");
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf("=");
            var name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
            document.cookie = name + "=;max-age=-1";
        }

    };

    const handleLayout = () => {
        const isLagout = window.confirm(trans("common:lagout-confirm"));
        if (isLagout) {
            window.localStorage.removeItem(User_Data_Key);
            window.localStorage.removeItem('isAuthenticated');
            window.localStorage.removeItem('secretKey');
            deleteAllCookies();
            window.location = '/';
        }
    };
    return (
        <div className={"user-page-content"}>
            <div className="user-page-content-phone">
                {!showItems && <div className="user-page-content-phone-menu" onClick={() => { handleSwicth(true) }}>
                    <img src="/assets/icons/menu.svg" className="filter-white" />
                </div>
                }
                {showItems && <div className="user-page-content-phone-items pb-8">
                    <div className="user-page-content-phone-items-icon">
                        <div onClick={() => { handleSwicth(false) }} className="text-white icon-8 nice-flex nice-flex-center nice-pointer user-page-content-phone-items-icon-cancel">
                            &times;
                        </div>
                    </div>
                    <div className="user-page-content-phone-items-icon">

                        <div className="user-page-content-phone-items-icon nice-flex my-2 filter-white nice-flex-center nice-pointer" onClick={handleLayout}>
                            <img src="/assets/user_profile/icons/logout.svg" alt="logout" />
                        </div>
                        <div className="nice-ruler nice-ruler-white"></div>
                    </div>
                    {
                        items.map((item, index) => {
                            return (
                                <div onClick={() => { handleActiveItem(item.to, index) }} key={index} className={`user-page-content-phone-items-icon nice-flex my-2 nice-flex-center nice-pointer                              
                                 ${index == activeItem && 'filter-primary'}`}>
                                    <img src={item.path} className='filter-white' alt={item.title} />
                                </div>
                            )
                        })
                    }
                </div>
                }
            </div>
        </div>
    );
};

export default PhoneMenu;