import axios from "axios";
import { groupBy, includes, mapValues, omit } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import config from '../../configuration/config.json';
import { toast } from "react-toastify";
import { useUserCart } from './useUserCart';
import { getData, saveData, User_Data_Key } from "../../utils/localStorageManager";
export const useUserFavorite = () => {
    const { t: trans } = useTranslation(['info', 'new']);
    const { initCartItems,initSignIN } = useUserCart();
    const USER_FAVORITE_ITEMS = 'USER_FAVORITE_ITEMS';
    const [favoriteItems, setFavoriteItems] = useState(() => {
        return JSON.parse(getData(USER_FAVORITE_ITEMS, false)) ?? []
    });
    const [itemsLength, setItemslength] = useState(favoriteItems?.length ?? 0);

    useEffect(() => {
        setFavoriteItems(JSON.parse(getData(USER_FAVORITE_ITEMS, false)) ?? []);
    }, [itemsLength]);

    // to initailize the user favorite items
    const init = () => {

        try {
            initSignIN();
        } catch (error) {
            console.log('sorry there is an error happened !!!', error);
        }
        try {
            const userID = getData('secretKey');
            if (userID) {
                axios.post(
                    config.baseURL + 'func',
                    {
                        "Name": "SiteFetchUserFavoriteSpareParts",
                        "Values": {
                            "UserID": String(userID)
                        }
                    }
                ).then(response => {

                    const _products = response.data.map(item => {
                        return item.ProductID
                    });
                    saveData(USER_FAVORITE_ITEMS, JSON.stringify(_products), false);
                    setFavoriteItems(_products);
                }).catch(err => {
                    console.log(err);
                });
            } else {
                toast.error(trans("info:login_first"));
            }
        } catch (error) {
            console.log('sorry there is an error happened !!!', error);
        }
    };
    // to check if the item is favorite or not
    const isFavoriteItem = (item) => {
        try {
            return includes(favoriteItems, item);
        } catch (error) {
            console.log('Has an error when checking the favorite item,', error);
        }
    };
    // to change the state of the favorite item [add-delete of found]
    const changeFavoriteItemState = (item) => {
        try {
            const isLogin = getData('isAuthenticated');
            if (isLogin) {
                const { id } = JSON.parse(getData(User_Data_Key, true));
                const userID = Number(id.toString().split('_')[1]);
                const isFavorite = isFavoriteItem(item);
                if (isFavorite) {
                    axios.request({
                        url: config.baseURL + 'delete',
                        data: {
                            "Object": "WebUserFavorites",
                            "Filters": `where WebUserID = ${userID} and ProductID = '${item}'`
                        },
                        method: "delete"
                        , responseType: "json"
                    }).then(response => {
                        const currentItems = JSON.parse(getData(USER_FAVORITE_ITEMS, false));
                        const newItems = currentItems.filter(value => {
                            return value !== item;
                        });
                        saveData(USER_FAVORITE_ITEMS, JSON.stringify(newItems), false);
                        setFavoriteItems(newItems);
                        setItemslength(newItems?.length);
                    }).catch(err => {
                        console.log(err);
                    });
                } else {
                    axios.post(
                        config.baseURL + 'add',
                        {
                            "Object": "WebUserFavorites",
                            "values": {
                                "webuserID": userID,
                                "productID": item
                            }
                        }
                    ).then(response => {
                        const currentItems = JSON.parse(getData(USER_FAVORITE_ITEMS, false));
                        currentItems.push(item);
                        saveData(USER_FAVORITE_ITEMS, JSON.stringify(currentItems), false);
                        setFavoriteItems(currentItems);
                        setItemslength(currentItems?.length);
                    }).catch(err => {
                        console.log(err);
                    });
                }
            } else {
                toast.error(trans("info:login_first"));
            }
        } catch (err) {
            console.log(err);
        }
    };
    // to fetch favorite items with details
    const favoriteItemsDetails = async (withGroup = false) => {
        let result = [];
        try {
            const userID = getData('secretKey');
            if (userID) {
                const response = await axios.post(
                    config.baseURL + 'func',
                    {
                        "Name": "SiteFetchUserFavoriteSpareParts",
                        "Values": {
                            "UserID": String(userID)
                        }
                    }
                );
                if (response.status == 200) {
                    const _data = response.data;
                    if (withGroup) {
                        const groupResult = mapValues(groupBy(_data, 'ItemType'), fList => fList.map(items => omit(items, 'ItemType')));
                        let groupValues = {};
                        Object.assign(groupValues, groupResult);
                        let finalResults = [];
                        for (let item in groupValues) {
                            const obj = { heading: '', items: [] };
                            let title = '';
                            let type = '';
                            const result = item;
                            switch (result.toLowerCase()) {
                                case 'spareparts':
                                    title = trans("new:favorite_spare_parts");
                                    type = 'spare';
                                    break;

                                case 'car':
                                    title = trans("new:favorite_cars");
                                    type = 'car';
                                    break;
                            }
                            obj.heading = title;
                            obj.type = type;
                            obj.items = groupValues[item];
                            finalResults.push(obj);
                        }
                        result = finalResults;
                    } else {
                        result = _data;
                    }
                }
            } else {
                toast.error('لم يتم تسجيل الدخول !!');
            }
        } catch (error) {
            console.log('sorry there is an error happened !!!', error);
        }
        return result;
    };
    return { init, isFavoriteItem, changeFavoriteItemState, favoriteItemsDetails };
};

