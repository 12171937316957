import { useRef, useState } from "react";
import { quantity } from 'cart-localstorage'
import { Price } from "../../../components/__simple/_price";
import axios from "axios";
import { SwiperSlide, Swiper } from 'swiper/react';
import SwiperCore, { Pagination, Navigation, Autoplay, EffectFade } from "swiper";
import { useTranslation } from "react-i18next";
import { Item } from "./Item";
import { Gift } from "./gift";
import { useUserFavorite } from "../../../User/hooks/useUserFavorite";
import config from "../../../configuration/config.json";
import { LikeButton } from "../../../components/__simple/_likeButton";
import _ from "lodash";
import { Base64 } from 'js-base64';
import { useUserCart } from '../../../User/hooks/useUserCart';
import { toast } from "react-toastify";
import { getData } from "../../../utils/localStorageManager";
SwiperCore.use([Pagination, Navigation, Autoplay, EffectFade]);
export function Cart(
    { slug, img, brand, name, price, count, id, onDelete, modelTypes,
        Tax, Discount, Pay, Account, colorID, CategoryCar, productID, year, modelTypeID, colorName, modelCode, carErrorCount }
) {
    const { isFavoriteItem, changeFavoriteItemState } = useUserFavorite();
    const { removecartItems, changecartItem } = useUserCart();
    const [LodingDelate, setLodingDelate] = useState(false);
    const { t: trans } = useTranslation(["cartFile", "info"]);
    const GetUrl = config.baseURL + 'details';
    const [counter, setCounter] = useState(count);
    const [Accessories, setAccessories] = useState([]);
    const [GiftS, setGifts] = useState([]);
    let lang = localStorage.getItem('i18nextLng');
    const specRef = useRef(null);
    // to show and hide the accessories box
    const [hasAccessories, setHasAccessories] = useState(false);
    const [hasGift, setHasGift] = useState(false);
    const [CartInfo, setCartInfo] = useState({ id: "", brand: "", modelTypes: "", Color: "", CountGifts: 0, MinPrice: 0, Discount: 0, img: "", CategoryCar: 0 });
    //----------------------------------------------------------
    const countF = async (num) => {

        const countSN = await getSerialsBuyOnlineCount(modelTypeID, year, colorID);

        console.log("countSN>>>>>>>>>>>>>>>>>>>>>>:", countSN);
        console.log("num>>>>>>>>>>>>>>>>>>>>>>:", num);
        if (countSN == null || (num != -1 && countSN != null && (countSN < (counter + num)))) {

            toast.warn(trans("cartFile:quantity_is_not_available"), {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }


        if (num == -1 && counter > 1)
            setCounter(counter + num);
        else if (num == 1 && counter < 100)
            setCounter(counter + num);
        if (num == -1 && counter == 1) {
            // onDelete();

            return;
        }
        const isLogin = getData('isAuthenticated');
        if (isLogin) {
            const resultchangecartItem = await changecartItem(productID, colorID, modelTypes, num, modelCode);
            Account()
        } else {
            quantity(id, num);
            Account()
        }


        console.log(">>>>>>>>>countF>>>>>>>>>>>>>:");


    }

    const getSerialsBuyOnlineCount = async (Type, Model, ColorID) => {
        var count = 0;


        const response = await axios.post(
            config.baseURL + 'func',
            {
                "Name": "siteInvProductSerialsBuyOnlineCount",
                "Values": {
                    "Type": `${Type}`,
                    "Model": `${Model}`,
                    "ColorID": `${ColorID}`
                }
            }
        );


        count = response.data[0].countSN;
        console.log("getSerialsBuyOnlineCount>>>>return>>>>>", count);
        return count;
    };

    //SiteAccessories_Cart;
    async function CarCartInfo() {
        const Body = {
            "name": "Site_CarCartInfoAr",
            "values": {
                "slug": `${slug}`, "colorId": `${colorID}`
            }
        }
        await axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {
                var CuurentValue = CartInfo;
                CuurentValue.brand = response.data[0].Brand
                CuurentValue.modelTypes = response.data[0].ModelTypes
                CuurentValue.MinPrice = response.data[0].MinPrice
                CuurentValue.Discount = response.data[0].Discount
                CuurentValue.CountGifts = response.data[0].CountGifts
                CuurentValue.Color = response.data[0].Color
                CuurentValue.year = response.data[0].Model
                CuurentValue.img = response.data[0].img ? response.data[0].img : "/assets/images/eparts/default.png";
                CuurentValue.CategoryCar = response.data[0].CategoryCar
                setCartInfo(CuurentValue)
                CategoryCar(CuurentValue.CategoryCar)
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    async function Accessories_Car(ProductsId) {
        const Body = {
            "name": "Site_Accessories_CartCar",
            "values": {
                "ProductID": `${ProductsId}`
            }
        }

        await axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {
                // console.log(response.data);
                setAccessories(response.data);


            })
            .catch(function (error) {
                console.log(error);
            });
    }
    async function Gifts_Car(ProductsId) {
        const Body = {
            "name": "Site_Gifts_CartCar",
            "values": {
                "ProductID": `${ProductsId}`
            }
        }

        await axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {
                // console.log(response.data);
                setGifts(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useState(() => {
        if (id) {
            CarCartInfo();
            // Accessories_Car(id);
            // Gifts_Car(id);
        }
    }, []);




    return (
        <div className={" " + (carErrorCount ? " cart-card-error " : "")}>

            {

                (carErrorCount == true) &&
                <div className="card-error-lable"><span>{trans("cartFile:quantity_is_not_available")}</span></div>
            }
            <div className={"cart-card p-8 mb-8 "} >
                <div className={"bg-secondary border-radius p-10"}>

                    {
                        (Base64.isValid(img) == 1 ?

                            <img className={"object-full product-cart-image object-contain"} src={img ? "data:image/png;base64," + img : "/assets/images/default.png"}
                                alt={""} /> :
                            <img className={"object-full product-cart-image object-contain"} src={img ? config.mainURL + img : "/assets/images/default.png"}
                                alt={""} />)

                    }

                    {/* <img className={"object-full product-cart-image object-contain"} src={CartInfo.img} /> */}
                </div>
                <div className="cart-detail">
                    <div className="nice-flex nice-flex-row">
                        <div className="nice-flex-1 nice-gap-3rem nice-flex nice-flex-column">
                            <div className="cart-detail-container">
                                <h3 className={"text-16"}>{trans("cartFile:trade")}</h3>
                                <p className={"text-16 font-bold"}>{brand}</p>
                            </div>
                            <div className="cart-detail-container">
                                <h3 className={"text-16"}>{trans("cartFile:vehicle_type")}</h3>
                                <p className={"text-16 font-bold"}>{modelTypes}</p>
                            </div>
                            <div className="cart-detail-container">
                                <h3 className={"text-16"}>{trans("info:vehicle_year")}</h3>
                                <p className={"text-16 font-bold"}>{year}</p>
                            </div>
                            <div className="cart-detail-container ">
                                <h3 className={"text-16"}>{trans("cartFile:color")}</h3>
                                <p className={"text-16 font-bold flex items-center "}>
                                    <span className={"p-8 bg-toyota box-shadow-sm border-radius ml-4 hidden"}></span>{colorName}
                                </p>
                            </div>
                        </div>
                        <div className="nice-flex nice-flex-column nice-gap-2rem ">
                            <div className="nice-flex nice-flex-row nice-justifiy-end nice-align-item-center">
                                {

                                    LodingDelate ?
                                        <span className='mx-2 loading-btn '>
                                            <img src='/assets/icons/loading-dark.svg' color="#121212" width='24' height='24' />
                                        </span>

                                        : <button onClick={() => {

                                            onDelete();
                                            setLodingDelate(true);


                                        }} className={`cart-delete cart-delete-${lang == 'en' ? 'right' : 'left'}`}>
                                            <img src={"/assets/icons/trash.svg"} alt={"minus"} />
                                        </button>
                                }
                                <LikeButton long={true}
                                    favorite={Boolean(isFavoriteItem(productID))}
                                    onToggle={() => { changeFavoriteItemState(productID) }}
                                />
                            </div>
                            <div style={{ display: "flex", flexFlow: "column nowrap" }}>
                                <div className={"flex items-center"}>
                                    <div className={"text-12 ml-4"}>{trans("cartFile:product_price")}</div>
                                    <div className={"text-20 mx-3 font-bold text-primary"}><Price price={price} /></div>
                                </div>
                                {
                                    Discount ?
                                        <div className={"flex items-center"}>
                                            <div className={"text-12 ml-4"}>{trans("cartFile:discount_amount")}</div>
                                            <div className={"text-20 mx-3 font-bold text-primary"}><Price price={Discount} /></div>
                                        </div>
                                        : null
                                }

                                {
                                    Discount ?
                                        <div className={"flex items-center"}>
                                            <div className={"text-12 ml-4"}>{trans("cartFile:discount_rate")}</div>
                                            <div className={"text-20 mx-3 font-bold text-primary"}>%{(Discount / (price + Discount)) * 100}</div>
                                        </div>
                                        : null
                                }

                            </div>
                            <div>
                                <div className={"cart-counter"}>
                                    <h3 className={"cart-counter-title text-12"}>{trans("cartFile:amount")}</h3>
                                    <div className="nice-flex nice-flex-row ">
                                        <button onClick={() => countF(+1)} className="cart-counter-btn">
                                            <img src={"/assets/icons/plus.svg"} alt={"plus"} />
                                        </button>
                                        <div className={"cart-counter-num text-20 font-bold"}>{counter}</div>
                                        <button onClick={() => countF(-1)} className="cart-counter-btn">
                                            <img src={"/assets/icons/minus.svg"} alt={"minus"} />
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>



                </div>
                {/* <button onClick={onDelete} className={`cart-delete cart-delete-${lang == 'en' ? 'right' : 'left'}`}>
                <img src={"/assets/icons/trash.svg"} alt={"minus"} />
            </button> */}
                <div className="cart-groups">
                    {Accessories?.length > 0 &&
                        <a onClick={() => {
                            setHasGift(false);
                            setHasAccessories(!hasAccessories);

                        }} className={"cart-groups-btn text-16 font-bold flex nice-pointer "}>
                            {trans("cartFile:accessories")}
                            <img className={"filter-white mx-3 " + (hasAccessories && "rotate-180")} src={"/assets/icons/arrow.svg"} alt={"show"} />
                        </a>
                    }
                    {GiftS?.length > 0 &&
                        <a onClick={() => {
                            setHasAccessories(false);
                            setHasGift(!hasGift);

                        }} className={"cart-groups-btn text-16 font-bold flex nice-pointer "}>
                            {trans("cartFile:gifts")}
                            <img className={"filter-white mx-3 " + (CartInfo.CountGifts > 0 && "rotate-180")} src={"/assets/icons/arrow.svg"} alt={"show"} />
                        </a>}
                </div>
            </div>

            {hasAccessories && <div className={"cart-additional mb-8 p-5"}>
                <div className="m-4">
                    {
                        <Swiper
                            style={{ width: '100%' }}
                            spaceBetween={0}
                            slidesPerView={2}
                            breakpoints={{
                                576: {
                                    slidesPerView: 4,
                                },
                            }}
                            resizeObserver={"true"}
                            autoplay={false}
                            loop={false}
                            observer={true}
                        >



                            {Accessories.map(P => (
                                <SwiperSlide className={"px-3"} key={P.ProductID}>
                                    <Item Engine_Groups={P.CategiorAr} Discount={P.DiscountAmount} Tax={P.Percentage} GUID={P.GUID} Weight={P.Weight} height={P.height} width={P.width} length={P.length} ProductImage={P.Img} DescriptionEn={P.DescriptionEn} DescriptionAr={P.DescriptionAr} NetSalePrice={P.SalesPrice} ProductID={P.ProductID} />
                                </SwiperSlide>
                            ))}

                        </Swiper>
                        // Accessories.map((Acc)=>{
                        //    return( <div className="cart-additional-container ">
                        //        <img className={"img"} src={"data:image/png;base64,"+Acc.ProductImage} alt=""/>
                        //        <div className={"cart-additional-title mt-2"} title={Acc.DescriptionAr}>{Acc.DescriptionAr}</div>
                        //        <img className={"cart-additional-info"} src={"/assets/icons/info-primary.svg"}
                        //             alt={"info"}/>
                        //    </div>); 
                        // })
                    }



                </div>
            </div>
            }

            {hasGift && <div className={"cart-additional mb-8 p-5"}>
                <div className="m-4">
                    {
                        <Swiper
                            style={{ width: '100%' }}
                            spaceBetween={0}
                            slidesPerView={2}
                            breakpoints={{
                                576: {
                                    slidesPerView: 4,
                                },
                            }}
                            resizeObserver={"true"}
                            autoplay={false}
                            loop={false}
                            observer={true}
                        >



                            {GiftS.map(P => (
                                <SwiperSlide className={"px-3"} key={P.ProductID}>
                                    <Gift Engine_Groups={P.CategiorAr} Discount={P.DiscountAmount} Tax={P.Percentage} GUID={P.GUID} Weight={P.Weight} height={P.height} width={P.width} length={P.length} ProductImage={P.ProductImage} DescriptionEn={P.DescriptionEn} DescriptionAr={P.DescriptionAr} NetSalePrice={P.NetSalePrice} ProductID={P.ProductID} />
                                </SwiperSlide>
                            ))}

                        </Swiper>
                        //Accessories.map((Acc)=>{
                        //    return( <div className="cart-additional-container ">
                        //        <img className={"img"} src={"data:image/png;base64,"+Acc.ProductImage} alt=""/>
                        //        <div className={"cart-additional-title mt-2"} title={Acc.DescriptionAr}>{Acc.DescriptionAr}</div>
                        //        <img className={"cart-additional-info"} src={"/assets/icons/info-primary.svg"}
                        //             alt={"info"}/>
                        //    </div>); 
                        // })
                    }



                </div>
            </div>
            }
        </div>
    );
}
