import {Fragment, useEffect, useState} from "react";
import Banner from "../../components/banner";
import SwiperCore, {Autoplay, EffectFade, Navigation, Pagination} from "swiper";
import {TestimonialSection} from "../../sections/testimonialSection";
import {WhyHJSection} from "../../sections/whyHJSection";
import {AboutHJSection} from "../../sections/aboutHJ";
import {ServiceBox} from "../../components/serviceBox";
import PageHeading from "../../components/__simple/_pageheading";
import Info from "../../components/_info";
import {OfferSections} from "../../sections/offersSection";
import {SimpleBanner} from "../../components/__simple/_simpleBanner";
import {SubServiceBox} from "./components/subServiceBox";
import InfoCircle from "../../components/__simple/_infoCirclejsx";
import Breadcrumb from "../../components/__simple/breadcrumb";
import {useParams} from "react-router";
import axios from "axios";
import {useTranslation} from "react-i18next";
import Offer from "../../components/offer";
import config from "../../configuration/config.json";

SwiperCore.use([Pagination, Navigation, Autoplay, EffectFade]);

export function MaintenanceServicePage() {
    let {CATid} = useParams();
    const [offers, setOffers] = useState([]);
    const {t: trans} = useTranslation(["maintenance", "home"]);
    const [lang, setlang] = useState(localStorage.getItem('i18nextLng'));
    const languageDir = 'language-' + lang;
    let content = "";
    let title = "";
    let subtitle = "";
    let excerpt = "";
    let upDesc = "";
    let upTitle = "";
    let categoryId = 0;
    let image = "/assets/images/offers/bg.png";
    let upImage = "/assets/images/offers/bg.png";
    const GetUrl = config.baseURL + 'details';
    const [CAT, setCAT] = useState([]);

    function RequsetCAT() {
        const Body = {
            "object": "siteMaintenanceCategories",
            "ObjectSettings": {"MetaData": false},
            "Filters": "where GUID='" + CATid + "'"
        };

        axios.request({
            url: GetUrl,
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {
                //console.log(response.data.ApiObjectData);
                setTimeout(setCAT(response.data.ApiObjectData), 1000);
                RequsetRCAT(response.data.ApiObjectData["0"].categoryId);


            })
            .catch(function (error) {
                console.log(error);
            });


    }

    const [Featured, setFeatured] = useState([]);

    function RequsetFeatured() {
        const Body = {
            "object": "SiteMaintenanceSpecial",
            "ObjectSettings": {"MetaData": false}
        };

        axios.request({
            url: GetUrl,
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {
                //console.log(response.data.ApiObjectData);
                setTimeout(setFeatured(response.data.ApiObjectData), 1000);


            })
            .catch(function (error) {
                console.log(error);
            });


    }

    ///////الخدمات الفرعيه
    const [RCAT, setRCAT] = useState([]);

    function RequsetRCAT(catId) {
        const Body = {
            "object": "siteMaintenanceServices",
            "ObjectSettings": {
                "MetaData": false

            },
            "Filters": "where categoryId=" + catId


        }
        axios.request({
            url: GetUrl,
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {
                //console.log(response.data.ApiObjectData);
                setTimeout(setRCAT(response.data.ApiObjectData), 1000);

            })
            .catch(function (error) {
                console.log(error);
            });


    }

    useEffect(async () => {

        RequsetCAT();
        RequsetFeatured();


    }, [])
    useEffect(async () => {
        try {
            const response = await axios.request({
                 baseURL: config.controllURL,
                url: lang + "/api/maintenance/offers/" + RCAT[0].categoryId,
                method: "get",
            });

            setOffers(response.data);

        } catch (error) {

        }
    }, [RCAT])

    // console.log(ٌCAT);
    if (CAT.length !== 0) {
        content = lang === "en" ? CAT["0"].contentEn : CAT["0"].contentAr;
        title = lang === "en" ? CAT["0"].titleEn : CAT["0"].titleAr;
        subtitle = lang === "en" ? CAT["0"].subtitleEn : CAT["0"].subtitleAr;
        excerpt = lang === "en" ? CAT["0"].excerptEn : CAT["0"].excerptAr;
        upDesc = lang === "en" ? CAT["0"].upDescEn : CAT["0"].upDescAr;
        upTitle = lang === "en" ? CAT["0"].upTitleEn : CAT["0"].upTitleAr;
        image = CAT["0"].image !== "null" ? "data:image/png;base64," + CAT["0"].image : image;
        upImage = CAT["0"].upImage !== "null" ? "data:image/png;base64," + CAT["0"].upImage : upImage;
        categoryId = CAT["0"].categoryId;
        console.log("content", content);
        console.log("title", title);
        console.log("subtitle", subtitle);
        console.log("excerpt", excerpt);
        console.log("categoryId", categoryId);
        console.log("CAT", CAT);

    }

    return (
        <Fragment >

            <Banner
                background={image}
                title={title}
                subtitle={subtitle}
                desc={excerpt}
            />
            <div className="container">
                <Breadcrumb className={"mt-16 "}
                            links={[
                                {
                                    name: trans("maintenance:maintenance"),
                                    to: "/maintenance"
                                }, {
                                    name: trans("maintenance:servce")
                                }
                            ]}
                />
            </div>
            <div className="container mb-32  -mt-10">
                <Info/>
            </div>

           <div className={languageDir}>


            <div className="container mb-32">

                <div>
                    <div className="text-24-children">
                        <div dangerouslySetInnerHTML={{__html: content}}/>
                    </div>
                </div>
            </div>

            <div className="container mb-32">

                <div className=" service-grid">
                    {RCAT.map(RC => (

                        <SubServiceBox linkTxt={trans("maintenance:linkTxt")} img={RC.image} subtitleAr={RC.subtitleAr}
                                       subtitleEn={RC.subtitleEn} titleAr={RC.titleAr} titleEn={RC.titleEn}
                                       link={"/maintenance/service/detail/" + RC.GUID}/>
                    ))}

                </div>

            </div>

            <div className={"container mb-36"}>
                {
                    CAT.map((BN,index) => {
                        if (lang === "en" && BN != null && BN.bannerEn !== null && BN.bannerEn !== "null") {

                            return (<img key={index} className={"border-radius object-full"}
                                         src={"data:image/png;base64," + BN.bannerEn} alt="banner"/>);

                        } else if (BN != null && BN.bannerAr !== null && BN.bannerAr !== "null") {

                            return (<img key={index} className={"border-radius object-full"}
                                         src={"data:image/png;base64," + BN.bannerAr} alt="banner"/>)

                        }
                    })
                }
            </div>
            <div className={"container mb-32"}>
                <PageHeading title={trans("maintenance:Featuer")}/>
                <div className="mx-20 service-grid service-grid-padding">

                    {Featured.map((a,index) => (

                        <InfoCircle
                            key={index}
                            icon={a.icon}
                            titleAr={a.titleAr}
                            titleEn={a.titleEn}
                        />
                    ))}

                </div>
            </div>
            </div>
            <OfferSections title={trans("home:offers")} offersList={offers}/>

            <WhyHJSection/>

        </Fragment>
    );
}