import { NavMaintenanceCard } from "./components/navMaintenanceCard";
import { Link } from "react-router-dom";
import { NavWorkHours } from "./components/navWorkHours";
import { useEffect, useState } from "react";
import config from '../../../configuration/config.json';
import axios from "axios";
import { slice } from 'lodash';
import { useTranslation } from "react-i18next";
import { slugCombine } from '../../../utils/slugManager';
import Skeleton from 'react-loading-skeleton'
export function NavMaintenance({ ...prop }) {
    const { t: trans } = useTranslation(["common", "form", "model", "navbar", "info"]);
    //----------------------------------------------------------
    const [maintenanceOffers, setMaintenanceOffers] = useState(null);
    const [categories, setCategories] = useState(null);
    //-----------------------------------------------------------
    useEffect(() => {
        intialOffers();
        intialCategories();
    }, []);
    //-----------------------------------------------------------
    // to intialize the offers
    const intialOffers = () => {
        axios.post(
            config.baseURL + "func",
            {
                "Name": "SiteFetchMaintenanceOffers"
            }
        ).then(response => {
            const responseData = response.data.map((e, index) => {
                return {
                    id: e[`${trans("navbar:slug")}`],
                    otherSlug: e.slugEn,
                    slug: e.slugAr,
                    image: e.image,
                    description: e[`${trans("model:name")}`],
                    price: e.price,
                    days: e.Days
                }
            });
            setMaintenanceOffers(responseData);
        }
        ).catch(error => {
            console.log(error);
        });

    };
    // get the days number between two days
    const getDays = (startDate, endDate) => {
        const date1 = new Date(startDate);
        const date2 = new Date(endDate);
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays;
    };
    // fetch the list of categories
    const intialCategories = () => {
        axios.post(
            config.baseURL + "func",
            {
                "Name": "site_fetchMainService",
            }
        ).then(response => {
            const responseData = response.data;
            const responseDataFiltered = responseData.map(item => {
                return { id: item.MainServiceID, description: item[`${trans("model:description")}`], path: item.path, ...item }
            });
            const _len = responseDataFiltered?.length;
            let _values = responseDataFiltered;
            if (_len > 5) {
                _values = slice(responseDataFiltered, 0, 5)
            }
            setCategories(_values);
        }).catch(error => {
            console.log(error);
        });
    };
    //-------------------------------------------------------------
    return (
        <div className={"nav-mega-menu bg-white box-shadow border-radius  " + prop.className}>
            <div className="my-4 text-20 p-8 border-radius nav-grid-3-cols">
                <div>
                    <h3 className="nav-heading">
                        {
                            trans("navbar:man_offer")
                        }
                    </h3>

                    {
                        maintenanceOffers != null ?

                            <div className="nav-maintenance-container nice-flex nice-flex-center">
                                {


                                    (maintenanceOffers != null && maintenanceOffers.length > 0) ? maintenanceOffers?.map((offer, index) => {
                                        return <NavMaintenanceCard
                                            key={index}
                                            id={slugCombine(offer.slug, offer.otherSlug)}
                                            description={offer.description}
                                            image={config.mainURL + offer.image}
                                            price={offer.price}
                                            days={offer.days}
                                        />
                                    }) : (<div className="nice-flex nice-flex-column nice-flex-center">
                                        <img src='/assets/icons/sad.svg' alt="hassan jameel sad" />
                                        <h1 className="text-14 text-bold my-3">{trans("info:no_offer")}</h1>
                                    </div>)


                                }
                            </div>
                            :
                            <>
                                <Skeleton count={1} borderRadius={"1rem"} width={"100%"} height={"100%"}
                                    containerClassName={"nav-maintenance-container "} className={""}
                                    inline={true} />

                            </>

                    }

                </div>
                <div className={"nav-grid-middle"}>
                    <h3 className="nav-heading">
                        {
                            trans("navbar:man_services")
                        }
                    </h3>

                    {

                        categories != null ?

                            <ul className={"nav-maintenance-links"}>
                                {
                                    categories?.map((cat, index) => {
                                        return (
                                            <li key={index}>
                                                <Link to={(cat?.MainServiceID == "0a5e145a-bf3f-4a8e-aa7b-d8f0a1e0d9b0" && cat?.SubService1ID == null) ? "/maintenance/" + cat.path : "#"}>{cat.description}</Link>
                                            </li>
                                        )
                                    })
                                }
                                {
                                    categories?.length > 4 && <li className={"show-more mt-auto"}><Link to={"/maintenance"}>{trans("navbar:show_more") + '...'}</Link></li>
                                }
                            </ul>

                            :
                            <>
                                <Skeleton count={1} borderRadius={"1rem"} width={"100%"} height={"100%"}
                                    containerClassName={"nav-maintenance-container "} className={""}
                                    inline={true} />

                            </>


                    }

                </div>
                <div>
                    <h3 className="nav-heading">
                        {
                            trans("navbar:work_hours")
                        }
                    </h3>
                    <NavWorkHours department={12} />
                </div>
            </div>
        </div>
    );
}