import React, {useEffect, useState} from 'react';
import {SwiperSlide, Swiper} from 'swiper/react';
import PartSlide from "./partSlide";
import {useTranslation} from "react-i18next";
import axios from "axios";
import config from "../../../configuration/config.json";

const SectionSlideEpart = () => {

    const {t: trans, i18n} = useTranslation(["common", "inputs", "offers", "home"]);
    const lang = i18n.language;

    const [slidEPartsList, setSlidEPartsList] = useState([]);

    const getSparePartsSlide = () => {
        const body = {"name": "func_getSparePartsSlide", "values": {"lang": lang.toLowerCase()}};

        axios.request({
            url: config.baseURL + "func",
            data: body, method: "POST", responseType: "json"
        })
            .then((response) => {
                console.info("func_getSparePartsSlide:Coach > ", response.data);

                if (response.data && response.data.length > 0) {
                    setSlidEPartsList(response.data);
                }

            })
            .catch((error) => {
                console.info("func_getSparePartsSlide:Coach > ", error);
            });
    };

    useEffect(() => {
        getSparePartsSlide();
    }, []);


    return (
        <div
            className={`  bg-gray-800 bg-opacity-5 rounded-3xl shadow 
             ${slidEPartsList && slidEPartsList.length > 0 ? " " : " hidden "}  `}>
            <Swiper
                className={"slide-landeng-container slide-part-container "}
                spaceBetween={0}
                slidesPerView={1}
                navigation
                pagination
                breakpoints={{
                    576: {
                        effect: "coverflow",
                    },
                }}
                effect="coverflow"
                watchSlidesVisibility
                watchSlidesProgress
                autoplay={true}
                loop={false}
                observer="true"
                resizeObserver={"true"}
                onUpdateSlider={(slide) => {
                    slide.update();
                }}
            >
                {
                    slidEPartsList.map((slide, index) => {

                        return (
                            <SwiperSlide key={index}>
                                <div className="rounded-3xl">
                                    <PartSlide
                                        viewSocialIcons={false}
                                        BackOpicity={slide?.backOpacity}

                                        Titel={slide?.title}
                                        Desc={slide?.description}
                                        backImageUrl={config.mainURL + slide?.urlImage}

                                        // Titel={lang == "ar" ? slide?.titleAr : slide?.titleEn}
                                        // SubTitel={lang == "ar" ? "slide?.MainBannerSubTitelAr" : "slide?.MainBannerSubTitel"}
                                        // backImage={lang == "ar" ? "slide?.imageAr" : "slide?.image"}
                                        // BtnText={lang == "ar" ? "slide?.MainBannerBtnTextAr" : "slide?.MainBannerBtnText"}
                                    />
                                </div>
                            </SwiperSlide>
                        )
                    })
                }

            </Swiper>
        </div>
    );
};

export default SectionSlideEpart;
