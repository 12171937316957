import react, {Fragment, useState, useEffect} from "react";
import Banner from "../../components/banner";
import {Campaign} from "./components/campaign";
import {Part} from "./components/part";
import PageHeading from "../../components/__simple/_pageheading";
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Autoplay, EffectFade, Navigation, Pagination} from "swiper";
import Breadcrumb from "../../components/__simple/breadcrumb";
import axios from "axios";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router";
import config from "../../configuration/config.json";
import {  User_Data_Key, getData } from "../../utils/localStorageManager";
SwiperCore.use([Pagination, Navigation, Autoplay, EffectFade]);

export function FeatureDetails() {
    const GetUrl = config.baseURL + 'details';
    const [PWD, setPWD] = useState([]);
    let {GroupId}=useParams();
    const [lang,setlang]=useState( localStorage.getItem('i18nextLng'));

    let {compaignId}=useParams();
    function RequsetProductsWithDetails(ID) {
        const  Body=  {
            "name":"Featch_PartsPackages",
            "values":{
                "packageId":ID
            }}
        const request =  axios.request({
            url: config.baseURL + 'func',
            data: Body, method:"POST",responseType:"json"
        })
        axios.all([request])
            .then(axios.spread(function (ProductsWithDetails) {
                setTimeout(setPWD(ProductsWithDetails.data), 500);

            })).catch(function (error) {
            console.log(error);
        });
    }
    const {t: trans} = useTranslation("eparts");
    const [EngineGroups,setEngineGroups]=useState([]);
    function  RequsetsetEngineGroups(){
        const BodyEngineGroups= {
            "object": "WebSparePackagesDatails",
            "ObjectSettings": {
                "MetaData": false

            },
            "Filters":"where GUID='"+compaignId+"'"
        };
        axios.request({
            url: GetUrl,
            data: BodyEngineGroups, method:"POST",responseType:"json"
        })
            .then(function (response) {
                // console.log(response.data.ApiObjectData);
                setTimeout( setEngineGroups(response.data.ApiObjectData),1000);
                RequsetProductsWithDetails(response.data.ApiObjectData[0].packageId)

            })
            .catch(function (error) {
                console.log(error);
            });


    }
    useEffect(() => {
       RequsetsetEngineGroups();
      //  console.log("setPWD", PWD);
    }, []);
    const [favoriteProducts,setFavoriteProducts] = useState([]);
    useEffect(async () => {
        const results = await fetchUserFavorites();
        setFavoriteProducts(results);
     }, []);
     // to fetch the user favorites
     const fetchUserFavorites = async () => {
         let _favorites = [];
         try {
             const isLogin = getData('isAuthenticated');
             if (isLogin) {
                 const { id } = JSON.parse(getData(User_Data_Key, true));
                 const userID = Number(id.toString().split('_')[1]);
                 const response = await axios.post(
                     config.baseURL + 'func',
                     {
                         "Name": "SiteFetchUserFavorites",
                         "values": {
                             "userID": userID
                         }
                     }
                 );
                 if(response.status == 200){
                     const favorites = response.data;
                     _favorites =  _favorites.concat(favorites);
                 }
             }
         } catch (err) {
             console.log(err);
         }
         return _favorites;
     };
    return (
        <Fragment>
            {EngineGroups.map(H=>{
                if(lang==="en"){
                    return(<Banner
                        background={"data:image/png;base64,"+H.image}
                        title={"باقة"}
                        subtitle={H.nameEn}
                        desc={H.descEn}
                        backOpasity={H?.backOpasity}
                    />);
                }else {
                    return(<Banner
                        background={"data:image/png;base64,"+H.image}
                        title={"باقة"}
                        subtitle={H.nameAr}
                        desc={H.descAr}
                        backOpasity={H?.backOpasity}
                    />);
                }
            })}
            
            <div className="container">
                <Breadcrumb className={"mt-16 "}
                            links={[
                                {
                                    name: trans("eparts:EpartTitel"),
                                    to: "/eparts"
                                },
                                {
                                    name:"قطع غيار الباقة"
                                }
                            ]}
                />
            </div>




            <div className=" container mb-36">


                <PageHeading
                    title={"قطع غيار الباقة"}
                />

                <div className="grid-4-cols mb-16 news-gap">
                    {PWD.map(P => (
                        <SwiperSlide className={"px-3"} key={P.ProductID}>
                            <Part favorites={favoriteProducts} ProductNo={P.ProductNo}  GUID={P.GUID}  ProductImage={P.ProductImage} DescriptionEn={P.DescriptionEn} DescriptionAr={P.DescriptionAr}  NetSalePrice={P.SalesPrice} ProductID={P.ProductID} />
                          </SwiperSlide>
                    ))}
                </div>
            </div>


        </Fragment>
    );
}