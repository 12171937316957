import { Link } from "react-router-dom";
import Moment from "react-moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { now } from "moment";
import { Base64 } from 'js-base64';
import SimpleButton from "./_input/SimpleButton";
import config from "../configuration/config.json";
import { Price } from "./__simple/_price";
function Banner({ background, subtitle, title, desc, secondImage, btn, offerDate, overlay = true, backOpasity, isOffer = false, handelButton, handelActicePackage, packages }) {
    const { t: trans } = useTranslation(["offers"]);
    const languageDir = 'language-' + localStorage.getItem('i18nextLng');
    const lang = localStorage.getItem('i18nextLng');
    const [acticePackage, setActicePackage] = useState(null);
    function showBtn() {
        if (btn != null) {
            return (
                <Link to={btn.link} className="hide-xs banner-btn">
                    {btn.text}
                </Link>
            )
        }
    }

    function onhandelActicePackage(data) {

        setActicePackage(data);
        handelActicePackage(data);

    }

    return (
        <div className={(isOffer && " is-offer ") + " mx-10 mb-8 banner-container p-8 border-radius " + (!isOffer && " box-shadow-large ") + languageDir + ' '} >
            {
                overlay ? <div className="banner-overlay" style={(backOpasity != 0) ? { background: "linear-gradient( to " + (lang == "ar" ? "left" : "right") + " , #090d0b 0%, rgba(26, 28, 27, 0.76) " + (backOpasity == null ? 50 : backOpasity) + "%, rgba(42, 42, 42, 0.13) 100%)" } : { background: "none" }}>

                </div> : null
            }
            <div className="banner-bg">
                {
                    (Base64.isValid(background) == 1 ?

                        <img src={background ? "data:image/png;base64," + background : null} onError={e => e.target.style.display = 'none'}
                            alt={""} /> :
                        <img src={background ? config.mainURL + background : null} onError={e => e.target.style.display = 'none'}
                            alt={""} />

                    )
                    //     background ?
                    // <img src={background} alt={title} />
                    // : null
                }
            </div>

            <div className="banner-info">

                {
                    secondImage &&
                    <div className="banner-info-img border-radius" >
                        <img className={"object-full "} hidden={(!secondImage || offerDate == null)} src={secondImage} alt={title} onError={e => e.target.style.display = 'none'} />
                    </div>
                }


                <div className="banner-info-flex">
                    <div className="banner-info-flex-subtitle">
                        <h3 className={"text-20"}>
                            {subtitle}
                        </h3>
                    </div>
                    <div className="banner-info-flex-text">
                        <h1 className={"text-30"}>
                            {title}
                        </h1>
                        <p className={"text-20 mt-4 desc"}>
                            {desc}
                        </p>

                        {
                            offerDate ?
                                <div className={"banner-date my-10"}>
                                    <p>{trans("offers:end-in")}</p>
                                    <Moment duration={now()}
                                        format={"D"}
                                        date={offerDate}
                                        className={"text-24"} />
                                    <p>{trans("offers:day")}</p>
                                    <p>|</p>
                                    <p>{trans("offers:date")}</p>
                                    <Moment className={"text-24"} format={"DD/MM/YYYY"} date={offerDate} />
                                </div>
                                : null
                        }

                    </div>
                    {
                        showBtn()
                    }

                    <div className={"py-2  w-100"}>

                        {
                            packages && packages.length > 0 &&
                            packages?.map((data, index) => {

                                return (

                                    <div className={"box-package-detail  nice-shadow-1 p-5 my-5 " + (data?.id == acticePackage?.id ? "active" : "")} key={index}
                                        onClick={() => {
                                            if (acticePackage?.id == data?.id) {
                                                onhandelActicePackage(null);
                                                return;
                                            }
                                            onhandelActicePackage(data);

                                        }}

                                    >
                                        <div className={"box-package-detail-name "}>
                                            <h3 className={"text-24 font-bold text-line-clamp text-line-clamp-2"}>{data?.title}</h3>
                                            <div className="box-package-detail-name-info">
                                                <h5 className={"text-24 fond-bold text-primary px-4"}>
                                                    <Price price={data?.price} />
                                                </h5>


                                            </div>

                                        </div>

                                        <div className={"box-package-detail-desc "}>

                                            <h3 className={"text-16 font-bold text-gray text-line-clamp text-line-clamp-1"}>{trans("offers:vehicles_supported")}:</h3>
                                            <h3 className={"text-16 font-bold text-line-clamp text-line-clamp-2"}>{data?.desc}</h3>
                                        </div>

                                    </div>

                                );
                            })
                        }

                    </div>

                    {
                        (isOffer == true) &&
                        <div className="offer-button  flex flex-row flex-wrap gap-1 items-cente mt-10 w-100">

                            <div className="basis-50">
                                <SimpleButton
                                    classes="bg-primary text-white text-30"
                                    title={trans("info:reserve")}
                                    onClick={() => {
                                        if (handelButton)
                                            handelButton("reserve");
                                    }}
                                />

                            </div>
                            <div className="basis-50">
                                <SimpleButton
                                    classes="bg-black text-white text-20"
                                    iconPath={'/assets/icons/cart.svg'}
                                    title={trans("info:add_to_cart")}
                                    onClick={() => {

                                        if (handelButton)
                                            handelButton("cart");
                                    }} />
                            </div>
                        </div>
                    }
                </div>



            </div>
        </div>
    );


}

export default Banner;