import { Fragment } from "react";

export function FormButton({ name, type = 'submit', style, onClick, isLoading = false, isPrimary = false, disabled = false, classes = '', icon = false,lang }) {

    return (


        <button disabled={isLoading} style={style} type={type} className={`btn text-20 ${isPrimary ? 'btn-primary' : ''} form-button ` + classes} onClick={() => {
            if (onClick)
                onClick();
        }
        }>


            <Fragment >
                {
                    icon &&
                    <div className={"mx-2  "+(lang=="ar"?" rotate-180 ":"") }>

                        <svg xmlns="http://www.w3.org/2000/svg" height="25" width="30" viewBox="0 0 448 512" >
                            <path fill="#3bd461" stroke-width="2" d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
                        </svg>

                        {/* <img src="/assets/icons/arrow-right-solid.svg" width='24' height='24'/> */}

                    </div>

                }

                {
                    name
                }
                {
                    isLoading && <span className='mx-2 loading-btn '>
                        <img src='/assets/icons/loading.svg' width='25' height='25' />
                    </span>
                }


            </Fragment>
        </button>
    );
}
