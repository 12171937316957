import {Fragment} from "react";
import { useTranslation } from "react-i18next";
import { numberFormat } from "../../utils/currencyFormatter";
export function Price({price,showZero=false,displayCurrencyLiter=true}) {
    const { i18n } = useTranslation([]);
    let lang =i18n.language;

    if (price === null || price === undefined || price === 'null' || price === '' || (price === '0' || price === 0 && showZero == false)){
        return null;
    }
 
    return (
        <Fragment>
            {numberFormat(parseFloat(price),lang,showZero,"",displayCurrencyLiter)}
        </Fragment>
    );
}

