import React, {useEffect, useState} from "react";
import Pagination from "react-js-pagination";

function PaginationComponent({
                                 activePageProp = 1,
                                 itemsCountPerPageProp = 5,
                                 totalItemsCountProp = 100,
                                 pageRangeDisplayedProp = 5,
                                 pageReturnProp
                             }) {

    const [activePage, setActivePage] = useState(activePageProp);

    const [lang, setlang] = useState(localStorage.getItem('i18nextLng'));

    const handlePageChange = (pageNumber) => {

        setActivePage(pageNumber);
        pageReturnProp(pageNumber);
    };

    return (
        <>
            <Pagination
                activePage={activePage}
                itemsCountPerPage={itemsCountPerPageProp}
                totalItemsCount={totalItemsCountProp}
                pageRangeDisplayed={pageRangeDisplayedProp}
                onChange={handlePageChange}
                firstPageText={
                    (
                        <div className="w-8 h-8">
                            <img src={
                                lang.toLowerCase() !== 'ar'
                                    ? "/assets/icons/pagination-icons/first_page_FILL.svg"
                                    : "/assets/icons/pagination-icons/last_page_FILL.svg"
                            }
                                 alt="FileList Page"
                            />
                        </div>
                    )
                }

                lastPageText={
                    (
                        <div className="w-8 h-8">
                            <img src={
                                lang.toLowerCase() === 'ar'
                                    ? "/assets/icons/pagination-icons/first_page_FILL.svg"
                                    : "/assets/icons/pagination-icons/last_page_FILL.svg"
                            }
                                 alt="FileList Page"
                            />
                        </div>
                    )
                }

                nextPageText={
                    (
                        <div className="w-5 h-5">
                            <img src=
                                     {
                                         lang.toLowerCase() !== 'ar'
                                             ? "/assets/icons/pagination-icons/arrow_forward.svg"
                                             : "/assets/icons/pagination-icons/arrow_back.svg"
                                     }
                                 alt="FileList Page"
                            />
                        </div>
                    )
                }

                prevPageText={
                    (
                        <div className="w-5 h-5">
                            <img src=
                                     {
                                         lang.toLowerCase() === 'ar'
                                             ? "/assets/icons/pagination-icons/arrow_forward.svg"
                                             : "/assets/icons/pagination-icons/arrow_back.svg"
                                     }
                                 alt="FileList Page"
                            />
                        </div>
                    )
                }
            />
        </>
    );
}

export default PaginationComponent;