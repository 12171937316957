let db,status;
const createDbRequest = (dbName , dbVersion)=>{
if(!indexedDB){
    console.log('Current browser is\'t supported the IndexedDb!!!');
    return;
}
let dbRequest = indexedDB.open(dbName,dbVersion);
dbRequest.onupgradeneeded = (event)=>{
let db = event.target.result;
let userStore = db.createObjectStore("UserProfile",{keyPath:"uId"});
};
dbRequest.onsuccess = (event)=>{
db = dbRequest.result;
console.log(`The Database ${db.name} with version (${db.version}) was created successfully.`);
};
dbRequest.onerror = (event)=>{
console.log(`Something wrong happened, and the error code : ${event.target.errorCode}`)
};
}; 
const proccessUserData = (data)=>{
    if(data){
     let trans = db.transaction("UserProfile","readwrite");
     let userStore = trans.objectStore("UserProfile");
     userStore.clear();
     let query =  userStore.put(data); 
     query.onsuccess = (event)=>{
     status = 1;
     console.log('The user was added successfully',event);
    };
    query.onerror = (event)=>{
    status = -1;
    console.log('The user wasn\'t added',event);
    };
    trans.oncomplete = (event)=>{
     console.log('The db was closed.');   
     db.close();
    };
  }
  return status;
};
const getUserDataByID = (id)=>{

       
 
    let trans = db.transaction("UserProfile","readwrite");
    let userStore = trans.objectStore("UserProfile");
    let query = userStore.get(id);
    query.onsuccess = (event)=>{
     if(!event.target.result){
         console.log(`The user with this id (${id}) is not existing.`);      
     }
    };
    trans.oncomplete = (event)=>{
    db.close();
    }; 
    return query;
};


const clearStorage = (dbName)=>{
try{
// localStorage.removeItem("isAuthenticated");
// localStorage.removeItem('secretKey');
// localStorage.removeItem("userData");
// localStorage.removeItem('extra');
localStorage.clear();
let dbDeleteRequest = indexedDB.deleteDatabase(dbName);
dbDeleteRequest.onsuccess = (event)=>{
console.log('Database was removed' , event);
};
dbDeleteRequest.onerror = (event)=>{
console.log('Database was not existed!' , event);
};
}catch(err){
console.log('Something wrong happened!' , err);
}
};

export {
    db,
    clearStorage,
    createDbRequest,
    proccessUserData,
    getUserDataByID
};